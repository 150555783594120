<template>
  <div class="payment">
    <form-layout
      :small-title="true"
      :title="$t('transfer.title')"
      :sub-title="$t('transfer.paymentSubTitle')"
      :back="goToTransferInstruction"
    >
      <div class="payment__main">
        <Input
          v-model="data.nickname"
          :disabled="true"
          icon="/transfer/people.svg"
          :placeholder="$t('transfer.nickname')"
        />
        <Input
          :value="data.totalRobux + ' R$'"
          :disabled="true"
          icon="/transfer/coin1.svg"
          :placeholder="$t('transfer.nickname')"
          :bonus=" $store.state.promoCode.bonus ? `+${$store.state.promoCode.bonus} R$` : null"
        />
        <Input
          :value="data.moneyTotal().toFixed(2) + ` ${currencyMap[$store.state.currency]}`"
          :disabled="true"
          icon="/transfer/rub.svg"
          :placeholder="$t('transfer.nickname')"
        />
        <Alert
          state="warning"
          :title="$t('general.warning')"
          :info="$t('transfer.subTitle')"
        />
        <Button
          class="payment__main__btn-pay"
          @click="changeModal"
        >
          {{ $t('payment.pay') }}
        </Button>
        <p class="payment__main__result">{{ $t('payment.toPay') }}: <span>{{ data.moneyTotal().toFixed(2) }} {{ currencyMap[$store.state.currency] }}</span></p>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './payment.scss'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Input from '@/components/Base/Input/Input.vue'
import Button from '@/components/Base/Button/Button.vue'
import Alert from '@/components/Base/Alert/Alert.vue'

export default {
  name: 'Payment',
  props: ['data'],
  inject: ['currencyMap'],
  components: {
    Button,
    Input,
    FormLayout,
    Alert
  },
  methods: {
    changeModal () {
      let emitParams = {}
      emitParams = { modal: !(this.$store.state.isAuth) ? 'SignIn' : 'TransferApproved' }
      this.$emit('changeModal', emitParams)
    },
    goToTransferInstruction () {
      this.$emit('changeModal', {
        modal: 'TransferInstructionGamepass'
      })
    }
  }
}
</script>
