<template>
  <div class="help">
    <div class="help-block">
      <div class="help-block__img">
        <img src="../../assets/images/help/yakor.png" alt="">
      </div>
      <div class="help-block__content">
        <p class="title">{{ $t('help.title') }}</p>
        <p class="text">{{ $t('help.text1') }}</p>
        <p class="text">{{ $t('help.text2') }} <a class="help-block__content__link" target="_blank" :href="linkUrl">{{ $t('general.link') }}</a>.</p>
        <div class="buttons">
          <a href="https://t.me/robuxpier" target="_blank" class="buttons-links telegram"></a>
          <a v-if="isRussian" :href="linkUrl" target="_blank" class="buttons-links vk"></a>
          <a v-else :href="linkUrl" target="_blank" class="buttons-links instagram"></a>
          <a href="https://discord.gg/robuxpier" target="_blank" class="buttons-links dis"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './help.scss'

import { mapGetters } from 'vuex'

export default {
  name: 'Help',
  computed: {
    ...mapGetters(['language']),
    isRussian () {
      return this.language === 'ru'
    },
    linkUrl () {
      return this.isRussian ? 'https://vk.com/@robuxpier-kak-kupit-robuksy' : 'https://instagram.com/ads.robuxpier?igshid=MzRlODBiNWFlZA=='
    }
  }
}
</script>
