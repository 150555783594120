<template>
  <div class="promo-code">
    <div class="promo-code__wrap" :class="{ error: status === 'error' }">
      <div class="promo-code__icon">
        <img src="@/assets/images/Premium/coupon.svg">
      </div>
      <div class="promo-code__input" :class="{ disabled: disabled }">
        <input type="text" :value="value" @input="$emit('input', $event.target.value)" :placeholder="$t('premium.enterPromocode')">
        <button
          class="promo-code__button"
          :disabled="!value"
          @click="$emit('apply')"
        >
          {{ $t('actions.apply') }}
        </button>
      </div>
    </div>
    <p
      class="promo-code__hint"
      :class="{ error: status === 'error', success: status === 'success' }"
    >
      {{
        status === 'success' ? $t('actions.promocode.success')
        : status === 'error' ? error
        : $t('actions.promocode.default')
      }}
    </p>
  </div>
</template>

<script>
import './PromoCodeInput.scss'
export default {
  name: 'PromoCodeInput',
  props: {
    value: {
      value: String,
      default: ''
    },
    status: {
      value: String,
      default: ''
    },
    error: {
      value: String,
      default: ''
    },
    disabled: {
      value: Boolean,
      default: false
    }
  }
}
</script>
