<template>
  <div class="fastpass">
    <Auth />
    <div class="wrapper">
      <Navigation />
    </div>
    <Loader v-if="loading" />
    <FastPassMainForm v-if="this.currentState === 'MainForm'" @changeModal="nextStep" />
    <FastpassCharacters v-if="this.currentState === 'TransferCharacters'" @changeModal="nextStep" :data="data" />
    <PluginInstruction v-if="this.currentState === 'InstructionForm'" @changeModal="nextStep" :data="data" />
    <Payment v-if="this.currentState === 'PaymentForm'" @changeModal="nextStep" :data="data" />
    <Approved v-if="this.currentState === 'TransferApproved'" @changeModal="nextStep" :data="data" />
    <OrderSuccesful v-if="this.currentState === 'TransferOrderSuccesful'" @changeModal="nextStep" :data="data" />
  </div>
</template>

<script>
import './fastpass.scss'

import Navigation from '@/components/Navigation/Navigation'
import FastPassMainForm from '@/components/FastPass/FastpassMainForm/FastPassMainForm'
import PluginInstruction from '@/components/FastPass/PluginInstruction/PluginInstruction'
import Payment from '@/components/Transfer/transferPayment/Payment'
import Auth from '@/components/Auth/Auth'
import Approved from '@/components/FastPass/FastpassApproved/Approved'
import OrderSuccesful from '@/components/Transfer/transferOrderSuccesful/OrderSuccesful'
import Loader from '@/components/LoaderBar/LoaderBar.vue'
import FastpassCharacters from '@/components/FastPass/FastpassCharacters/FastpassCharacters'

export default {
  name: 'FastPass',
  components: {
    Navigation,
    FastPassMainForm,
    FastpassCharacters,
    PluginInstruction,
    Payment,
    Auth,
    Approved,
    OrderSuccesful,
    Loader
  },
  data () {
    return {
      currentState: 'MainForm',
      data: {
        totalRobux: '',
        moneyTotal: '',
        robloxUserId: '',
        nickname: '',
        orders: [],
        assetId: ''
      },
      loading: true
    }
  },
  created () {
    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    this.loading = true

    fetch('https://transfer.backend-pier.com/active_orders', requestParams)
      .then((res) => res.json())
      .then((data) => {
        this.loading = false
        if (data.status) {
          this.data.orders = data.orders

          if (this.data.orders.length > 0) {
            this.currentState = 'TransferOrderSuccesful'
          }
        }
      })
      .catch((err) => console.log(err))
  },
  methods: {
    nextStep (params) {
      if (this.currentState === 'MainForm') {
        this.data.totalRobux = params.data[0]
        this.data.moneyTotal = params.data[1]
      } else if (this.currentState === 'TransferCharacters') {
        this.data.robloxUserId = params.data[0]
        this.data.nickname = params.data[1]
      } else if (this.currentState === 'InstructionForm' && params.data) {
        this.data.assetId = params.data[0]
      } else if (this.currentState === 'PaymentSuccessful' && params.data) {
        this.data.orders.unshift(params.data[0])
      }
      console.log(params)
      this.currentState = params.modal
    }
  }
}
</script>
