<template>
    <div
      class="logpass-orders"
      :class="{mobile: isMobile}"
    >
      <div class="logpass-orders__arrow left" @click="toLeft()">
        <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.71565 14.397L14.4963 1.63895C14.7062 1.42731 14.956 1.25932 15.2311 1.14468C15.5063 1.03004 15.8014 0.971021 16.0995 0.971021C16.3976 0.971021 16.6928 1.03004 16.9679 1.14468C17.2431 1.25932 17.4928 1.42731 17.7027 1.63895C18.1233 2.06203 18.3594 2.63434 18.3594 3.23089C18.3594 3.82744 18.1233 4.39975 17.7027 4.82282L6.52533 16.1131L17.7027 27.2906C18.1233 27.7136 18.3594 28.286 18.3594 28.8825C18.3594 29.4791 18.1233 30.0514 17.7027 30.4744C17.4936 30.6878 17.2442 30.8575 16.969 30.9738C16.6938 31.0901 16.3983 31.1506 16.0995 31.1519C15.8008 31.1506 15.5052 31.0901 15.23 30.9738C14.9548 30.8575 14.7054 30.6878 14.4963 30.4744L1.71565 17.7164C1.48644 17.5049 1.30352 17.2483 1.17841 16.9626C1.0533 16.677 0.988703 16.3685 0.988703 16.0567C0.988703 15.7449 1.0533 15.4364 1.17841 15.1507C1.30352 14.8651 1.48644 14.6085 1.71565 14.397Z" fill="#3079F7" fill-opacity="0.29"/>
        </svg>
      </div>
      <div class="logpass-orders__modal">
        <div class="logpass-orders__modal-arrow" @click="prevModal">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.25 11.75H8.17251L15.5975 4.34749C16.0212 3.92381 16.2592 3.34917 16.2592 2.74999C16.2592 2.15081 16.0212 1.57617 15.5975 1.15249C15.1738 0.728807 14.5992 0.490784 14 0.490784C13.4008 0.490784 12.8262 0.728807 12.4025 1.15249L1.15251 12.4025C0.94767 12.6165 0.787098 12.8688 0.680011 13.145C0.45497 13.6928 0.45497 14.3072 0.680011 14.855C0.787098 15.1312 0.94767 15.3835 1.15251 15.5975L12.4025 26.8475C12.6117 27.0584 12.8605 27.2258 13.1347 27.34C13.4089 27.4542 13.703 27.513 14 27.513C14.297 27.513 14.5911 27.4542 14.8653 27.34C15.1395 27.2258 15.3883 27.0584 15.5975 26.8475C15.8084 26.6383 15.9758 26.3895 16.09 26.1153C16.2042 25.8411 16.2631 25.547 16.2631 25.25C16.2631 24.953 16.2042 24.6589 16.09 24.3847C15.9758 24.1105 15.8084 23.8617 15.5975 23.6525L8.17251 16.25H25.25C25.8467 16.25 26.419 16.0129 26.841 15.591C27.263 15.169 27.5 14.5967 27.5 14C27.5 13.4033 27.263 12.831 26.841 12.409C26.419 11.987 25.8467 11.75 25.25 11.75Z" fill="white"/>
          </svg>
        </div>
        <div class="logpass-orders__modal-order">
          <p>#{{ orderId + 1 }}</p>
        </div>
        <div class="logpass-orders__modal-content">
          <div class="logpass-orders__modal-content__loader" v-if="loader"><img src="@/assets/images/Premium/anchor.png" alt=""></div>
          <div class="logpass-orders__modal-content__header" v-if="!loader">
            <img src="../../../assets/images/logPass/logo.svg" alt="">
            <p>{{ $t('order.status') }}</p>
          </div>
          <div class="logpass-orders__modal-content__block" v-if="!loader">
            <div class="logpass-orders__modal-content__block-pending" v-if="status == 'waiting'">
              <p class="order-text wait">{{ $t('order.wait') }}</p>
              <div class="logpass-orders__modal-content__block-pending__loader">
                <p class="logpass-orders__modal-content__block-pending__loader-timer" id="сountdown">{{ $t('order.waitTime') }} - {{ getTimeLogPass }}</p>
                <div class="logpass-orders__modal-content__block-pending__loader-bar" id="timer" :style="{width: getWidthTimer + '%'}">
                  <img src="../../../assets/images/logPass/loader-bar-arrow.svg" alt="">
                </div>
              </div>
              <div class="logpass-orders__modal-content__block-pending__code">
                <p class="logpass-orders__modal-content__block-pending__code__wait" v-if="!(orders[orderId].action_required)">{{ getOrderDescription(orders[orderId]) }}</p>
                <div class="logpass-orders__modal-content__block-pending__code__input" v-if="orders[orderId].action_required">
                  <div class="logpass-orders__modal-content__block-pending__code__input__img">
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.99975 7.66671C5.79678 7.66434 5.59796 7.7242 5.43004 7.83824C5.26212 7.95229 5.13318 8.11503 5.06055 8.30458C4.98793 8.49412 4.97512 8.70137 5.02384 8.89841C5.07257 9.09546 5.18049 9.27285 5.33308 9.40671V10.3334C5.33308 10.5102 5.40332 10.6798 5.52834 10.8048C5.65337 10.9298 5.82293 11 5.99975 11C6.17656 11 6.34613 10.9298 6.47115 10.8048C6.59617 10.6798 6.66641 10.5102 6.66641 10.3334V9.40671C6.819 9.27285 6.92692 9.09546 6.97565 8.89841C7.02437 8.70137 7.01156 8.49412 6.93894 8.30458C6.86631 8.11503 6.73737 7.95229 6.56945 7.83824C6.40154 7.7242 6.20271 7.66434 5.99975 7.66671ZM9.33308 5.00004V3.66671C9.33308 2.78265 8.98189 1.93481 8.35677 1.30968C7.73165 0.684563 6.8838 0.333374 5.99975 0.333374C5.11569 0.333374 4.26784 0.684563 3.64272 1.30968C3.0176 1.93481 2.66641 2.78265 2.66641 3.66671V5.00004C2.13598 5.00004 1.62727 5.21075 1.2522 5.58583C0.877126 5.9609 0.666412 6.46961 0.666412 7.00004V11.6667C0.666412 12.1971 0.877126 12.7058 1.2522 13.0809C1.62727 13.456 2.13598 13.6667 2.66641 13.6667H9.33308C9.86351 13.6667 10.3722 13.456 10.7473 13.0809C11.1224 12.7058 11.3331 12.1971 11.3331 11.6667V7.00004C11.3331 6.46961 11.1224 5.9609 10.7473 5.58583C10.3722 5.21075 9.86351 5.00004 9.33308 5.00004ZM3.99975 3.66671C3.99975 3.13627 4.21046 2.62757 4.58553 2.25249C4.9606 1.87742 5.46931 1.66671 5.99975 1.66671C6.53018 1.66671 7.03889 1.87742 7.41396 2.25249C7.78903 2.62757 7.99975 3.13627 7.99975 3.66671V5.00004H3.99975V3.66671ZM9.99975 11.6667C9.99975 11.8435 9.92951 12.0131 9.80448 12.1381C9.67946 12.2631 9.50989 12.3334 9.33308 12.3334H2.66641C2.4896 12.3334 2.32003 12.2631 2.19501 12.1381C2.06998 12.0131 1.99975 11.8435 1.99975 11.6667V7.00004C1.99975 6.82323 2.06998 6.65366 2.19501 6.52864C2.32003 6.40361 2.4896 6.33337 2.66641 6.33337H9.33308C9.50989 6.33337 9.67946 6.40361 9.80448 6.52864C9.92951 6.65366 9.99975 6.82323 9.99975 7.00004V11.6667Z" fill="white"/>
                    </svg>
                  </div>
                  <div
                    class="logpass-orders__modal-content__block-pending__code__input__main"
                    :class="{error: errorCode}"
                  >
                    <span>{{ $t('order.code') }}</span>
                    <input type="tel" :placeholder="$t('order.mailCode')" v-model="code">
                    <div class="send" @click="sendCode">
                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.93333 5.68333C9.89367 5.58103 9.8342 5.48758 9.75833 5.40833L5.59167 1.24166C5.51397 1.16396 5.42173 1.10233 5.32021 1.06028C5.21869 1.01823 5.10988 0.996582 5 0.996582C4.77808 0.996582 4.56525 1.08474 4.40833 1.24166C4.33064 1.31936 4.269 1.4116 4.22695 1.51312C4.1849 1.61464 4.16326 1.72344 4.16326 1.83333C4.16326 2.05524 4.25141 2.26807 4.40833 2.42499L7.15833 5.16666H0.833333C0.61232 5.16666 0.400358 5.25446 0.244078 5.41074C0.0877975 5.56702 0 5.77898 0 5.99999C0 6.22101 0.0877975 6.43297 0.244078 6.58925C0.400358 6.74553 0.61232 6.83333 0.833333 6.83333H7.15833L4.40833 9.57499C4.33023 9.65246 4.26823 9.74463 4.22592 9.84618C4.18362 9.94773 4.16184 10.0567 4.16184 10.1667C4.16184 10.2767 4.18362 10.3856 4.22592 10.4871C4.26823 10.5887 4.33023 10.6809 4.40833 10.7583C4.4858 10.8364 4.57797 10.8984 4.67952 10.9407C4.78107 10.983 4.88999 11.0048 5 11.0048C5.11001 11.0048 5.21893 10.983 5.32048 10.9407C5.42203 10.8984 5.5142 10.8364 5.59167 10.7583L9.75833 6.59166C9.8342 6.51241 9.89367 6.41895 9.93333 6.31666C10.0167 6.11378 10.0167 5.88621 9.93333 5.68333Z" fill="#2F2F2F"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="logpass-orders__modal-content__block-success" v-if="status == 'success'">
              <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="45" cy="45" r="43" stroke="#069C38" stroke-width="4"/>
                <path d="M24.5088 47.4107L36.5624 59.4643L65.0891 30.9375" stroke="#069C38" stroke-width="4.71429"/>
              </svg>
              <p class="order-text">{{ $t('order.success') }}</p>
            </div>
            <div class="logpass-orders__modal-content__block-error" v-if="status == 'error'">
              <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="45" cy="45" r="43" stroke="#EB0237" stroke-width="4"/>
                <line x1="28.2121" y1="28.1554" x2="64.4166" y2="62.5191" stroke="#EB0237" stroke-width="4.71"/>
                <line y1="-2.355" x2="49.9162" y2="-2.355" transform="matrix(-0.725306 0.688426 0.688426 0.725306 63.4092 29.8635)" stroke="#EB0237" stroke-width="4.71"/>
              </svg>
              <p class="order-text">{{ getOrderDescription(orders[orderId]) }}</p>
            </div>
            <div class="logpass-orders__modal-content__block-table">
              <table>
                <thead>
                  <tr>
                    <td>{{ $t('order.table.nickname') }}</td>
                    <td>{{ $t('order.table.count') }}</td>
                    <td>{{ $t('order.table.total') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><p class="border-right">{{ orders[orderId].username }}</p></td>
                    <td><p class="border-right">{{ orders[orderId].robux_amount }} R$</p></td>
                    <td><p>{{ orders[orderId].amount[orders[orderId].currency.toLowerCase()] }} {{ $store.getters.getCurrencySign(orders[orderId].currency.toLowerCase()) }}</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="logpass-orders__modal-content__block-button" @click="newOrder">
              <p>+</p>
              <p>{{ $t('order.makeNewOrder') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="logpass-orders__arrow right" @click="toRight()">
        <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.71565 14.397L14.4963 1.63895C14.7062 1.42731 14.956 1.25932 15.2311 1.14468C15.5063 1.03004 15.8014 0.971021 16.0995 0.971021C16.3976 0.971021 16.6928 1.03004 16.9679 1.14468C17.2431 1.25932 17.4928 1.42731 17.7027 1.63895C18.1233 2.06203 18.3594 2.63434 18.3594 3.23089C18.3594 3.82744 18.1233 4.39975 17.7027 4.82282L6.52533 16.1131L17.7027 27.2906C18.1233 27.7136 18.3594 28.286 18.3594 28.8825C18.3594 29.4791 18.1233 30.0514 17.7027 30.4744C17.4936 30.6878 17.2442 30.8575 16.969 30.9738C16.6938 31.0901 16.3983 31.1506 16.0995 31.1519C15.8008 31.1506 15.5052 31.0901 15.23 30.9738C14.9548 30.8575 14.7054 30.6878 14.4963 30.4744L1.71565 17.7164C1.48644 17.5049 1.30352 17.2483 1.17841 16.9626C1.0533 16.677 0.988703 16.3685 0.988703 16.0567C0.988703 15.7449 1.0533 15.4364 1.17841 15.1507C1.30352 14.8651 1.48644 14.6085 1.71565 14.397Z" fill="#3079F7" fill-opacity="0.29"/>
        </svg>
      </div>
    </div>
</template>

<script>
import './logPassOrders.scss'

export default {
  name: 'LogPassOrders',
  props: {
    orders: {
      type: Array,
      require: true
    },
    getOrders: {
      type: Function,
      require: true
    }
  },
  data () {
    return {
      timeLogPass: null,
      timerIntervalIdLogPass: null,
      intervalUpdate: null,
      status: null,
      orderId: 0,
      code: null,
      errorCode: false,
      isMobile: this.$store.state.isMobile,
      loader: false
    }
  },
  computed: {
    getTimeLogPass () {
      const hours = `0${Math.trunc(this.timeLogPass / 60 / 60)}`.slice(-2)
      const minutes = `0${Math.trunc(this.timeLogPass / 60 % 60)}`.slice(-2)
      const seconds = `0${Math.trunc(this.timeLogPass % 60 % 60)}`.slice(-2)
      return `${hours}:${minutes}:${seconds}`
    },
    getWidthTimer () {
      return (100 * this.timeLogPass / 7200)
    }
  },
  methods: {
    toRight () {
      clearInterval(this.timerIntervalIdLogPass)
      if (document.querySelector('#сountdown')) {
        document.querySelector('#сountdown').classList.remove('anotherHalf')
      }
      this.orderId += 1
      if (this.orderId === this.orders.length) {
        this.orderId = 0
      }
      this.start()
    },
    toLeft () {
      clearInterval(this.timerIntervalIdLogPass)
      if (document.querySelector('#сountdown')) {
        document.querySelector('#сountdown').classList.remove('anotherHalf')
      }
      this.orderId -= 1
      if (this.orderId === -1) {
        this.orderId = this.orders.length - 1
      }
      this.start()
    },
    sendCode: async function () {
      if (this.code.length !== 6 || !(/^[0-9]+$/.test(this.code))) {
        this.errorCode = true
        return
      }

      const requestBody = JSON.stringify({
        order_id: this.orders[this.orderId]._id,
        quick_code: this.code,
        is_premium_order: false
      })

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`,
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'PATCH',
        headers: requestHeaders,
        body: requestBody
      }

      this.loader = true
      const response = await fetch('https://logpass.backend-pier.com/api/v1/orders/set_quick_code', requestParams)

      const data = await response.json()
      console.log(data)
      this.getOrders().then((data) => {
        this.orders = data.orders
        this.start()
        this.loader = false
      })
    },
    getOrderDescription (order) {
      const code = order.code_description
      return this.$t(`logpass.codes.${code}`)
    },
    newOrder () {
      if (this.$store.state.isMobile) {
        this.$emit('changeModal', { modal: 'choice' })
        return
      }
      this.$emit('changeModal', { modal: 'instructionCookie' })
    },
    prevModal () {
      this.$emit('changeModal', { modal: 'logPassBuy' })
    },
    timerLogPass () {
      this.timerIntervalIdLogPass = setInterval(() => {
        // document.querySelector('#timer').style.width = `${100 * this.timeLogPass / 7200}%`
        if ((100 * this.timeLogPass / 7200) <= 50) {
          document.querySelector('#сountdown').classList.add('anotherHalf')
        }
        this.timeLogPass -= 1
        if (this.timeLogPass <= 0) {
          this.timeLogPass = 0
          clearInterval(this.timerIntervalIdLogPass)
        }
      }, 1000)
    },
    start () {
      clearInterval(this.timerIntervalIdLogPass)
      this.status = this.orders[this.orderId].status
      if (this.status === 'waiting') {
        this.timerLogPass()
        const orderDate = new Date(this.orders[this.orderId].datetime)
        orderDate.setHours(orderDate.getHours() + 2)
        orderDate.setMinutes(orderDate.getMinutes() + (new Date().getTimezoneOffset() * -1))
        this.timeLogPass = (orderDate - Date.now()) / 1000
      }
    }
  },
  mounted () {
    this.start()

    this.intervalUpdate = setInterval(() => {
      this.start()
    }, 10000)
  },
  destroyed () {
    clearInterval(this.timerIntervalIdLogPass)
    clearInterval(this.intervalUpdate)
  }
}
</script>
