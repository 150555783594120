<template>
  <div class="contacts">
    <div class="wrapper">
      <Navigation />
      <div class="contacts__main">
        <p class="contacts__main__title">контакты</p>
        <p class="contacts__main__official" v-if="!isCountryFromRu($store.state.ipCountry)">
        </p>
        <div class="contacts__main__items">
            <div class="contacts__main__items__item">
                <img src="@/assets/images/Contacts/internet.svg" alt="">
                <p>robuxpier.io</p>
            </div>
            <div class="contacts__main__items__item">
                <img src="@/assets/images/Contacts/support.svg" alt="">
                <p>EPIK582@gmail.com</p>
            </div>
            <div class="contacts__main__items__item">
                <img src="@/assets/images/Contacts/vk.svg" alt="">
                <p>vk.com/ROBUXPIER</p>
            </div>
        </div>
        <img v-if="isCountryRu($store.state.ipCountry)" class="right-block__img" src="../../assets/images/footer/address.svg" alt="address">
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import './contacts.scss'

import Navigation from '@/components/Navigation/Navigation'
import Footer from '@/components/Footer/Footer'
import ipUtil from '@/utils/ip'

export default {
  name: 'Contacts',
  components: {
    Navigation,
    Footer
  },
  methods: {
    isCountryFromRu (c) {
      return ipUtil.isCountryInRuRegion(c)
    },
    isCountryRu (c) {
      return ipUtil.isCountryRu(c)
    }
  }
}
</script>
