<template>
  <div class="premiumStatus">
    <template v-if="!isLoaded">
      <PremiumLoader />
    </template>
    <template v-else>
      <div class="premiumStatus__to-left" @click="changeOrder(-1)">
        <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.71565 14.397L14.4963 1.63895C14.7062 1.42731 14.956 1.25932 15.2311 1.14468C15.5063 1.03004 15.8014 0.971021 16.0995 0.971021C16.3976 0.971021 16.6928 1.03004 16.9679 1.14468C17.2431 1.25932 17.4928 1.42731 17.7027 1.63895C18.1233 2.06203 18.3594 2.63434 18.3594 3.23089C18.3594 3.82744 18.1233 4.39975 17.7027 4.82282L6.52533 16.1131L17.7027 27.2906C18.1233 27.7136 18.3594 28.286 18.3594 28.8825C18.3594 29.4791 18.1233 30.0514 17.7027 30.4744C17.4936 30.6878 17.2442 30.8575 16.969 30.9738C16.6938 31.0901 16.3983 31.1506 16.0995 31.1519C15.8008 31.1506 15.5052 31.0901 15.23 30.9738C14.9548 30.8575 14.7054 30.6878 14.4963 30.4744L1.71565 17.7164C1.48644 17.5049 1.30352 17.2483 1.17841 16.9626C1.0533 16.677 0.988703 16.3685 0.988703 16.0567C0.988703 15.7449 1.0533 15.4364 1.17841 15.1507C1.30352 14.8651 1.48644 14.6085 1.71565 14.397Z" fill="#3079F7" fill-opacity="0.29"/>
        </svg>
      </div>
      <div class="premiumStatus__main-block">
        <p v-if="$store.state.isMobile" class="premiumStatus__main-block__m-header">{{ $t('order.status') }}</p>
        <div v-if="!$store.state.isMobile" class="premiumStatus__main-block__logo">
          <img src="@/assets/images/Premium/author-logo.png" alt="">
          <div class="text">
            <h3>RobuxPier</h3>
            <p>{{ $t('premium.buyWithProfit') }}</p>
          </div>
        </div>
        <div class="premiumStatus__main-block__status-order">
          <div class="wait" v-if="orders[currentOrder].status == 'waiting'">
            <p class="wait__header" v-if="!orders[currentOrder].is_quick || orders[currentOrder].quick_code">{{ $t('order.wait') }}</p>
            <div class="wait__timer">
              <div class="wait__timer__in" id="timer" :style="{width: getWidthTimer + '%'}">
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.71967 5.05333C8.5947 5.17728 8.49551 5.32475 8.42782 5.48723C8.36012 5.64971 8.32527 5.82398 8.32527 6C8.32527 6.17601 8.36012 6.35029 8.42782 6.51277C8.49551 6.67525 8.5947 6.82272 8.71967 6.94667L12.7197 10.9467C12.844 11.0745 12.9923 11.1766 13.156 11.2471C13.3198 11.3176 13.4959 11.3552 13.6741 11.3576C13.8524 11.3601 14.0295 11.3275 14.1951 11.2615C14.3608 11.1956 14.5119 11.0976 14.6397 10.9733C14.7675 10.849 14.8696 10.7007 14.9401 10.537C15.0106 10.3732 15.0482 10.1971 15.0506 10.0189C15.0531 9.84057 15.0205 9.66354 14.9545 9.49787C14.8886 9.33221 14.7907 9.18115 14.6663 9.05333L11.5463 6L14.6663 2.94667C14.9174 2.68852 15.0557 2.34121 15.0507 1.98114C15.0456 1.62107 14.8978 1.27774 14.6397 1.02667C14.3815 0.775595 14.0342 0.637353 13.6741 0.642354C13.3141 0.647355 12.9707 0.795189 12.7197 1.05333L8.71967 5.05333ZM7.27967 9.05333L4.213 6L7.27967 2.94667C7.53074 2.68852 7.66898 2.34121 7.66398 1.98114C7.65898 1.62107 7.51115 1.27774 7.253 1.02667C6.99486 0.775595 6.64755 0.637353 6.28748 0.642354C5.92741 0.647355 5.58408 0.795188 5.333 1.05333L1.333 5.05333C1.20803 5.17728 1.10884 5.32475 1.04115 5.48723C0.973457 5.64971 0.938605 5.82398 0.938605 6C0.938605 6.17601 0.973457 6.35029 1.04115 6.51277C1.10884 6.67525 1.20803 6.82271 1.333 6.94667L5.333 10.9467C5.58408 11.1977 5.9246 11.3388 6.27967 11.3388C6.63474 11.3388 6.97527 11.1977 7.22634 10.9467C7.47741 10.6956 7.61846 10.3551 7.61846 10C7.61846 9.64493 7.47741 9.3044 7.22634 9.05333L7.27967 9.05333Z" fill="white"/>
                </svg>
              </div>
              <p id="сountdown">{{ $t('order.waitTime') }} - {{ getTimePremium }}</p>
            </div>
          </div>
          <div class="success" v-if="orders[currentOrder].status == 'success'">
            <img src="@/assets/images/Premium/success.png">
            <p>{{ $t('order.descriptions.success') }}</p>
          </div>
          <div class="error" v-if="orders[currentOrder].status == 'error'">
            <img src="@/assets/images/Premium/error.png">
            <p v-if="!orders[currentOrder].is_quick && orders[currentOrder].status_alias === 'cookie_error'">Ошибка cookie! Повторите заказ и не меняйте пароль до получения заказа!</p>
            <p v-if="!orders[currentOrder].is_quick && orders[currentOrder].status_alias === 'error'">Возникла ошибка при выполнение заказа! Пожалуйста обратитесь в поддержку.</p>
            <p v-if="orders[currentOrder].is_quick && orders[currentOrder].status_alias === 'error'">ВЫ НЕ УКАЗАЛИ КОД! ПОВТОРИТЕ ЗАКАЗ И ПОСТАРАЙТЕСЬ ВВЕСТИ КОД БЫСТРЕЕ!</p>
            <p v-if="orders[currentOrder].is_quick && orders[currentOrder].status_alias === 'code_error'">Возникла ошибка при выполнение заказа! Пожалуйста обратитесь в поддержку.</p>
          </div>
        </div>
        <div class="premiumStatus__main-block__m" v-if="$store.state.isMobile && orders[currentOrder].status == 'waiting' && orders[currentOrder].is_quick && !orders[currentOrder].quick_code">
          <p v-if="$store.state.isMobile && !orders[currentOrder].action_required">{{ $t('premium.mailCode') }}</p>
          <div class="premiumStatus__main-block__m-input" v-else>
            <small v-if="error.status">{{ error.message }}</small>
            <div class="premiumStatus__main-block__m-input__img">
              <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.12005 7.66671C5.91565 7.66434 5.71542 7.7242 5.54632 7.83824C5.37721 7.95229 5.24736 8.11503 5.17422 8.30458C5.10108 8.49412 5.08818 8.70137 5.13725 8.89841C5.18632 9.09546 5.295 9.27285 5.44867 9.40671V10.3334C5.44867 10.5102 5.5194 10.6798 5.64531 10.8048C5.77122 10.9298 5.94199 11 6.12005 11C6.29811 11 6.46888 10.9298 6.59478 10.8048C6.72069 10.6798 6.79143 10.5102 6.79143 10.3334V9.40671C6.9451 9.27285 7.05378 9.09546 7.10285 8.89841C7.15191 8.70137 7.13901 8.49412 7.06588 8.30458C6.99274 8.11503 6.86288 7.95229 6.69378 7.83824C6.52468 7.7242 6.32445 7.66434 6.12005 7.66671ZM9.47694 5.00004V3.66671C9.47694 2.78265 9.12327 1.93481 8.49373 1.30968C7.86419 0.684563 7.01035 0.333374 6.12005 0.333374C5.22975 0.333374 4.37591 0.684563 3.74637 1.30968C3.11683 1.93481 2.76316 2.78265 2.76316 3.66671V5.00004C2.22898 5.00004 1.71667 5.21075 1.33895 5.58583C0.961226 5.9609 0.749023 6.46961 0.749023 7.00004V11.6667C0.749023 12.1971 0.961226 12.7058 1.33895 13.0809C1.71667 13.456 2.22898 13.6667 2.76316 13.6667H9.47694C10.0111 13.6667 10.5234 13.456 10.9011 13.0809C11.2789 12.7058 11.4911 12.1971 11.4911 11.6667V7.00004C11.4911 6.46961 11.2789 5.9609 10.9011 5.58583C10.5234 5.21075 10.0111 5.00004 9.47694 5.00004ZM4.10591 3.66671C4.10591 3.13627 4.31812 2.62757 4.69584 2.25249C5.07356 1.87742 5.58587 1.66671 6.12005 1.66671C6.65423 1.66671 7.16653 1.87742 7.54426 2.25249C7.92198 2.62757 8.13418 3.13627 8.13418 3.66671V5.00004H4.10591V3.66671ZM10.1483 11.6667C10.1483 11.8435 10.0776 12.0131 9.95167 12.1381C9.82577 12.2631 9.655 12.3334 9.47694 12.3334H2.76316C2.5851 12.3334 2.41433 12.2631 2.28842 12.1381C2.16251 12.0131 2.09178 11.8435 2.09178 11.6667V7.00004C2.09178 6.82323 2.16251 6.65366 2.28842 6.52864C2.41433 6.40361 2.5851 6.33337 2.76316 6.33337H9.47694C9.655 6.33337 9.82577 6.40361 9.95167 6.52864C10.0776 6.65366 10.1483 6.82323 10.1483 7.00004V11.6667Z" fill="white"/>
              </svg>
            </div>
            <input type="tel" :placeholder="$t('order.mailCode')" v-model="userCode" @keydown.enter="sendCode">
            <div class="premiumStatus__main-block__m-arrow" @click="sendCode">
              <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.93333 5.68333C9.89367 5.58103 9.8342 5.48758 9.75833 5.40833L5.59167 1.24166C5.51397 1.16396 5.42173 1.10233 5.32021 1.06028C5.21869 1.01823 5.10988 0.996582 5 0.996582C4.77808 0.996582 4.56525 1.08474 4.40833 1.24166C4.33064 1.31936 4.269 1.4116 4.22695 1.51312C4.1849 1.61464 4.16326 1.72344 4.16326 1.83333C4.16326 2.05524 4.25141 2.26807 4.40833 2.42499L7.15833 5.16666H0.833333C0.61232 5.16666 0.400358 5.25446 0.244078 5.41074C0.0877975 5.56702 0 5.77898 0 5.99999C0 6.22101 0.0877975 6.43297 0.244078 6.58925C0.400358 6.74553 0.61232 6.83333 0.833333 6.83333H7.15833L4.40833 9.57499C4.33023 9.65246 4.26823 9.74463 4.22592 9.84618C4.18362 9.94773 4.16184 10.0567 4.16184 10.1667C4.16184 10.2767 4.18362 10.3856 4.22592 10.4871C4.26823 10.5887 4.33023 10.6809 4.40833 10.7583C4.4858 10.8364 4.57797 10.8984 4.67952 10.9407C4.78107 10.983 4.88999 11.0048 5 11.0048C5.11001 11.0048 5.21893 10.983 5.32048 10.9407C5.42203 10.8984 5.5142 10.8364 5.59167 10.7583L9.75833 6.59166C9.8342 6.51241 9.89367 6.41895 9.93333 6.31666C10.0167 6.11378 10.0167 5.88621 9.93333 5.68333Z" fill="#2F2F2F"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="premiumStatus__main-block__inf">
          <div class="premiumStatus__main-block__inf__head">
            <div class="premiumStatus__main-block__inf__head__atr">{{ $t('order.table.nickname') }}</div>
            <div class="premiumStatus__main-block__inf__head__atr">TYPE</div>
            <div class="premiumStatus__main-block__inf__head__atr">{{ $t('order.table.total') }}</div>
          </div>
          <div class="premiumStatus__main-block__inf__body">
            <div class="premiumStatus__main-block__inf__body__obj"><p>{{orders[currentOrder].username}}</p></div>
            <div class="premiumStatus__main-block__inf__body__obj">
              <p v-if="orders[currentOrder].premium_type === 'low'">PREMIUM 450</p>
              <p v-if="orders[currentOrder].premium_type === 'medium'">PREMIUM 1 000</p>
              <p v-if="orders[currentOrder].premium_type === 'high'">PREMIUM 2 200</p>
            </div>
            <div class="premiumStatus__main-block__inf__body__obj"><p>{{ orders[currentOrder].amount[orders[currentOrder].currency.toLowerCase()] }}</p></div>
            <div class="premiumStatus__main-block__inf__body__obj"><p>{{orders[currentOrder].amount.rub}}</p></div>
          </div>
        </div>
        <div class="premiumStatus__main-block__btn" @click="changeModal('main')">
          <p>+</p>
          <p>{{ $t('order.makeNewOrder') }}</p>
        </div>
        <div class="premiumStatus__main-block__back" v-if="comeBack != false" @click="changeModal('back')">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.25 11.75H8.17251L15.5975 4.34749C16.0212 3.92381 16.2592 3.34917 16.2592 2.74999C16.2592 2.15081 16.0212 1.57617 15.5975 1.15249C15.1738 0.728807 14.5992 0.490784 14 0.490784C13.4008 0.490784 12.8262 0.728807 12.4025 1.15249L1.15251 12.4025C0.94767 12.6165 0.787098 12.8688 0.680011 13.145C0.45497 13.6928 0.45497 14.3072 0.680011 14.855C0.787098 15.1312 0.94767 15.3835 1.15251 15.5975L12.4025 26.8475C12.6117 27.0584 12.8605 27.2258 13.1347 27.34C13.4089 27.4542 13.703 27.513 14 27.513C14.297 27.513 14.5911 27.4542 14.8653 27.34C15.1395 27.2258 15.3883 27.0584 15.5975 26.8475C15.8084 26.6383 15.9758 26.3895 16.09 26.1153C16.2042 25.8411 16.2631 25.547 16.2631 25.25C16.2631 24.953 16.2042 24.6589 16.09 24.3847C15.9758 24.1105 15.8084 23.8617 15.5975 23.6525L8.17251 16.25H25.25C25.8467 16.25 26.419 16.0129 26.841 15.591C27.263 15.169 27.5 14.5967 27.5 14C27.5 13.4033 27.263 12.831 26.841 12.409C26.419 11.987 25.8467 11.75 25.25 11.75Z" fill="white"/>
          </svg>
        </div>
        <div class="premiumStatus__main-block__num-order">
          <p>#{{currentOrder + 1}}</p>
        </div>
      </div>
      <div class="premiumStatus__to-right" @click="changeOrder(+1)">
        <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.71565 14.397L14.4963 1.63895C14.7062 1.42731 14.956 1.25932 15.2311 1.14468C15.5063 1.03004 15.8014 0.971021 16.0995 0.971021C16.3976 0.971021 16.6928 1.03004 16.9679 1.14468C17.2431 1.25932 17.4928 1.42731 17.7027 1.63895C18.1233 2.06203 18.3594 2.63434 18.3594 3.23089C18.3594 3.82744 18.1233 4.39975 17.7027 4.82282L6.52533 16.1131L17.7027 27.2906C18.1233 27.7136 18.3594 28.286 18.3594 28.8825C18.3594 29.4791 18.1233 30.0514 17.7027 30.4744C17.4936 30.6878 17.2442 30.8575 16.969 30.9738C16.6938 31.0901 16.3983 31.1506 16.0995 31.1519C15.8008 31.1506 15.5052 31.0901 15.23 30.9738C14.9548 30.8575 14.7054 30.6878 14.4963 30.4744L1.71565 17.7164C1.48644 17.5049 1.30352 17.2483 1.17841 16.9626C1.0533 16.677 0.988703 16.3685 0.988703 16.0567C0.988703 15.7449 1.0533 15.4364 1.17841 15.1507C1.30352 14.8651 1.48644 14.6085 1.71565 14.397Z" fill="#3079F7" fill-opacity="0.29"/>
        </svg>
      </div>
      <div class="arrow-block">
        <div class="premiumStatus__to-left moble-arrrow" @click="changeOrder(-1)">
          <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.71565 14.397L14.4963 1.63895C14.7062 1.42731 14.956 1.25932 15.2311 1.14468C15.5063 1.03004 15.8014 0.971021 16.0995 0.971021C16.3976 0.971021 16.6928 1.03004 16.9679 1.14468C17.2431 1.25932 17.4928 1.42731 17.7027 1.63895C18.1233 2.06203 18.3594 2.63434 18.3594 3.23089C18.3594 3.82744 18.1233 4.39975 17.7027 4.82282L6.52533 16.1131L17.7027 27.2906C18.1233 27.7136 18.3594 28.286 18.3594 28.8825C18.3594 29.4791 18.1233 30.0514 17.7027 30.4744C17.4936 30.6878 17.2442 30.8575 16.969 30.9738C16.6938 31.0901 16.3983 31.1506 16.0995 31.1519C15.8008 31.1506 15.5052 31.0901 15.23 30.9738C14.9548 30.8575 14.7054 30.6878 14.4963 30.4744L1.71565 17.7164C1.48644 17.5049 1.30352 17.2483 1.17841 16.9626C1.0533 16.677 0.988703 16.3685 0.988703 16.0567C0.988703 15.7449 1.0533 15.4364 1.17841 15.1507C1.30352 14.8651 1.48644 14.6085 1.71565 14.397Z" fill="#3079F7" fill-opacity="0.29"/>
          </svg>
        </div>
        <div class="premiumStatus__to-right moble-arrrow" @click="changeOrder(+1)">
          <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.71565 14.397L14.4963 1.63895C14.7062 1.42731 14.956 1.25932 15.2311 1.14468C15.5063 1.03004 15.8014 0.971021 16.0995 0.971021C16.3976 0.971021 16.6928 1.03004 16.9679 1.14468C17.2431 1.25932 17.4928 1.42731 17.7027 1.63895C18.1233 2.06203 18.3594 2.63434 18.3594 3.23089C18.3594 3.82744 18.1233 4.39975 17.7027 4.82282L6.52533 16.1131L17.7027 27.2906C18.1233 27.7136 18.3594 28.286 18.3594 28.8825C18.3594 29.4791 18.1233 30.0514 17.7027 30.4744C17.4936 30.6878 17.2442 30.8575 16.969 30.9738C16.6938 31.0901 16.3983 31.1506 16.0995 31.1519C15.8008 31.1506 15.5052 31.0901 15.23 30.9738C14.9548 30.8575 14.7054 30.6878 14.4963 30.4744L1.71565 17.7164C1.48644 17.5049 1.30352 17.2483 1.17841 16.9626C1.0533 16.677 0.988703 16.3685 0.988703 16.0567C0.988703 15.7449 1.0533 15.4364 1.17841 15.1507C1.30352 14.8651 1.48644 14.6085 1.71565 14.397Z" fill="#3079F7" fill-opacity="0.29"/>
          </svg>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import './premiumstatus.scss'

import PremiumLoader from '@/components/Premium/PremiumLoader/PremiumLoader.vue'

export default {
  name: 'PremiumStatus',
  components: {
    PremiumLoader
  },
  data () {
    return {
      isLoaded: false,
      time: null,
      timerIntervalId: null,
      updateTimerId: null,
      currentOrder: 0,
      orders: [],
      userCode: '',
      error: {
        status: false,
        message: 'Ошибка'
      }
    }
  },
  computed: {
    getTimePremium () {
      const hours = `0${Math.trunc(this.time / 60 / 60)}`.slice(-2)
      const minutes = `0${Math.trunc(this.time / 60 % 60)}`.slice(-2)
      const seconds = `0${Math.trunc(this.time % 60 % 60)}`.slice(-2)
      return `${hours}:${minutes}:${seconds}`
    },
    getWidthTimer () {
      return (100 * this.time / 7200)
    }
  },
  props: {
    comeBack: {
      type: Boolean
    }
  },
  methods: {
    changeModal (to) {
      if (to === 'back') {
        clearInterval(this.timerIntervalId)
        this.$emit('changeModal', {
          modal: 'buy'
        })
      }
      if (to === 'main') {
        clearInterval(this.timerIntervalId)
        !this.$store.state.isMobile ? this.$emit('changeModal', { modal: 'instruction' })
          : this.$emit('changeModal', { modal: 'buy' })
      }
    },
    changeOrder (to) {
      if (to === -1 && this.currentOrder !== 0) {
        this.currentOrder -= 1
        clearInterval(this.timerIntervalId)
        clearInterval(this.updateTimerId)
        this.startTimer()
      } else if (to === +1 && this.currentOrder < this.orders.length - 1) {
        this.currentOrder += 1
        clearInterval(this.timerIntervalId)
        clearInterval(this.updateTimerId)
        this.startTimer()
      }
    },
    timer () {
      clearInterval(this.timerIntervalId)
      this.timerIntervalId = setInterval(() => {
        if ((100 * this.time / 7200) <= 50) {
          document.querySelector('#сountdown').classList.add('anotherHalf')
        }
        this.time -= 1
        if (this.time <= 0) {
          this.time = 0
          clearInterval(this.timerIntervalId)
        }
      }, 1000)
    },
    startTimer () {
      if (this.orders[this.currentOrder].status === 'waiting') {
        this.timer()
        const orderDate = new Date(this.orders[this.currentOrder].datetime)
        orderDate.setHours(orderDate.getHours() + 2)
        orderDate.setMinutes(orderDate.getMinutes() + (new Date().getTimezoneOffset() * -1))
        this.time = (orderDate - Date.now()) / 1000
      }
    },
    sendCode () {
      if (this.userCode.length !== 6 || !this.userCode.match(/^[0-9]+$/)) {
        this.error.status = true
        this.error.message = 'Ошибка! Должно быть 6 цифр'
        return
      }
      const requestBody = JSON.stringify({
        order_id: this.orders[this.currentOrder]._id,
        quick_code: this.userCode,
        is_premium_order: true
      })
      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })
      const requestParams = {
        method: 'PATCH',
        headers: requestHeaders,
        body: requestBody
      }
      fetch('https://logpass.backend-pier.com/api/v1/orders/set_quick_code', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.error.status = false
          this.userCode = null
          this.getOrders()
        })
        .catch((err) => console.log(err))
    },
    getOrders () {
      fetch('https://logpass.backend-pier.com/api/v1/orders/fetch_last_orders/premium', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$cookies.get('access_token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          this.isLoaded = true
          this.orders = data.orders
          this.startTimer()
        })
    }
  },
  mounted () {
    setInterval(() => {
      this.getOrders()
    }, 10000)
  },
  destroyed () {
    clearInterval(this.timerIntervalId)
    clearInterval(this.updateTimerId)
  }
}
</script>
