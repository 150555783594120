<template>
  <div class="premiumBuy" :class="{mobile: stage != 0}">
    <template v-if="!isLoadedBuy">
      <PremiumLoader />
    </template>
    <template v-else>
      <div
        class="premiumBuy__types"
        v-if="(stage === 0 || stage === 1)"
      >
       <div
         class="premiumBuy__types__type"
         :class="{choice: premium == 450}"
         @click="changePremium(0, 'low')"
       >
         <div class="premiumBuy__types__type__icon">
           <img src="@/assets/images/Premium/icon-premium.png" alt="">
         </div>
         <div class="premiumBuy__types__type__text">
           <h3>PREMIUM 450</h3>
          <i18n path="premium.getRbx" tag="p">
            <template v-slot:rbx>
              <img src="@/assets/images/Premium/coin.svg" alt=""> <span>450</span>
            </template>
          </i18n>
         </div>
         <div class="premiumBuy__types__type__price">
           <p>{{ $store.getters.currencySign }} {{ $t("premium.currencyPerMonth", {currency: getLevelPrice('low')}) }}</p>
         </div>
      </div>
        <div
          class="premiumBuy__types__type"
          :class="{choice: premium == 1000}"
          @click="changePremium(1, 'medium')"
        >
          <div class="premiumBuy__types__type__icon">
            <img src="@/assets/images/Premium/icon-premium.png" alt="">
          </div>
          <div class="premiumBuy__types__type__text">
            <h3>PREMIUM 1 000</h3>
            <i18n path="premium.getRbx" tag="p">
              <template v-slot:rbx>
                <img src="@/assets/images/Premium/coin.svg" alt=""> <span>1 000</span>
              </template>
            </i18n>
          </div>
          <div class="premiumBuy__types__type__price">
            <p>{{ $store.getters.currencySign }} {{ $t("premium.currencyPerMonth", {currency: getLevelPrice('medium')}) }}</p>
          </div>
        </div>
        <div
          class="premiumBuy__types__type"
          :class="{choice: premium == 2200}"
          @click="changePremium(2, 'high')"
        >
          <div class="premiumBuy__types__type__icon">
            <img src="@/assets/images/Premium/icon-premium.png" alt="">
          </div>
          <div class="premiumBuy__types__type__text">
            <h3>PREMIUM 2 200</h3>
            <i18n path="premium.getRbx" tag="p">
              <template v-slot:rbx>
                <img src="@/assets/images/Premium/coin.svg" alt=""> <span>2 200</span>
              </template>
            </i18n>
          </div>
          <div class="premiumBuy__types__type__price">
            <p>{{ $store.getters.currencySign }} {{ $t("premium.currencyPerMonth", {currency: getLevelPrice('high')}) }}</p>
          </div>
        </div>
      </div>
      <div class="premiumBuy__main-block" v-if="stage === 0 || stage === 2">
        <div class="premiumBuy__main-block__logo" v-if="stage == 0">
          <img src="@/assets/images/Premium/author-logo.png" alt="">
          <div class="text">
            <h3>RobuxPier</h3>
            <p>{{ $t('premium.buyWithProfit') }}</p>
          </div>
        </div>
        <div v-if="stage != 0" class="premiumBuy__main-block__logoMob">
          <p>{{ $t('premium.buyPremium') }}</p>
        </div>
        <div class="premiumBuy__main-block__inputs">
          <div class="premiumBuy__main-block__inputs__wrap">
            <div class="premiumBuy__main-block__inputs__wrap__icon">
              <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.68 12.96H5.85V15.39H13.29V17.43H5.85V21H3.09V17.43H0.54V15.39H3.09V12.96H0.54V10.44H3.09V-1.43051e-06H10.68C12.5 -1.43051e-06 14.05 0.639999 15.33 1.92C16.61 3.18 17.25 4.71 17.25 6.51C17.25 8.31 16.61 9.84 15.33 11.1C14.07 12.34 12.52 12.96 10.68 12.96ZM5.85 2.61V10.44H10.68C11.72 10.44 12.61 10.06 13.35 9.3C14.11 8.52 14.49 7.59 14.49 6.51C14.49 5.43 14.12 4.51 13.38 3.75C12.64 2.99 11.74 2.61 10.68 2.61H5.85Z" fill="white"/>
              </svg>
            </div>
            <div class="premiumBuy__main-block__inputs__wrap__input">
              <p
                class="input-price"
                :class="{is: premiumPrice > 0}"
              >{{ premiumPrice }} {{ $store.getters.currencySign }}<span :class="{is: salePrice > 0}">- {{ salePrice }} {{ $store.getters.currencySign }}</span></p>
            </div>
          </div>
          <div class="premiumBuy__main-block__inputs__wrap">
            <div class="premiumBuy__main-block__inputs__wrap__icon">
              <img src="@/assets/images/Premium/coupon.svg">
            </div>
            <div class="premiumBuy__main-block__inputs__wrap__input">
              <input type="text" v-model="promoCode" :placeholder="$t('premium.enterPromocode')" id="voucher-code">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.88 9.42997C17.8086 9.24585 17.7016 9.07763 17.565 8.93497L10.065 1.43497C9.92514 1.29512 9.75911 1.18417 9.57637 1.10848C9.39364 1.03279 9.19779 0.993835 9 0.993835C8.60055 0.993835 8.21746 1.15252 7.935 1.43497C7.79514 1.57483 7.6842 1.74087 7.60851 1.9236C7.53282 2.10633 7.49386 2.30218 7.49386 2.49997C7.49386 2.89943 7.65254 3.28252 7.935 3.56497L12.885 8.49997H1.5C1.10218 8.49997 0.720645 8.65801 0.43934 8.93931C0.158036 9.22062 0 9.60215 0 9.99997C0 10.3978 0.158036 10.7793 0.43934 11.0606C0.720645 11.3419 1.10218 11.5 1.5 11.5H12.885L7.935 16.435C7.79441 16.5744 7.68282 16.7403 7.60666 16.9231C7.53051 17.1059 7.4913 17.302 7.4913 17.5C7.4913 17.698 7.53051 17.8941 7.60666 18.0768C7.68282 18.2596 7.79441 18.4255 7.935 18.565C8.07444 18.7056 8.24035 18.8172 8.42313 18.8933C8.60592 18.9695 8.80198 19.0087 9 19.0087C9.19802 19.0087 9.39408 18.9695 9.57687 18.8933C9.75965 18.8172 9.92556 18.7056 10.065 18.565L17.565 11.065C17.7016 10.9223 17.8086 10.7541 17.88 10.57C18.03 10.2048 18.03 9.79517 17.88 9.42997Z" fill="#2F2F2F"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="premiumBuy__main-block__res">
          <p>{{ $t('premium.toPay') }} </p>
          <div
            class="price"
          ><p :class="{is: totalPrice > 0}">{{ totalPrice }} {{ $store.getters.currencySign }}</p></div>
          <p
            class="sale"
            :class="{is: salePrice > 0}"
          >- {{ saleProcent }} %</p>
        </div>
        <button class="premiumBuy__main-block__btn" :disabled="!isSelected" @click="createOrder">{{ $t('actions.continue') }}</button>
        <div class="premiumBuy__main-block__inf">
          <i18n path="premium.premiumTime" tag="p">
            <template v-slot:time>
              <br>
              <span>{{ $t('premium.oneMonth') }}</span>
            </template>
          </i18n>
        </div>
        <div class="premiumBuy__main-block__back" @click="changeModal('back')">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.25 11.75H8.17251L15.5975 4.34749C16.0212 3.92381 16.2592 3.34917 16.2592 2.74999C16.2592 2.15081 16.0212 1.57617 15.5975 1.15249C15.1738 0.728807 14.5992 0.490784 14 0.490784C13.4008 0.490784 12.8262 0.728807 12.4025 1.15249L1.15251 12.4025C0.94767 12.6165 0.787098 12.8688 0.680011 13.145C0.45497 13.6928 0.45497 14.3072 0.680011 14.855C0.787098 15.1312 0.94767 15.3835 1.15251 15.5975L12.4025 26.8475C12.6117 27.0584 12.8605 27.2258 13.1347 27.34C13.4089 27.4542 13.703 27.513 14 27.513C14.297 27.513 14.5911 27.4542 14.8653 27.34C15.1395 27.2258 15.3883 27.0584 15.5975 26.8475C15.8084 26.6383 15.9758 26.3895 16.09 26.1153C16.2042 25.8411 16.2631 25.547 16.2631 25.25C16.2631 24.953 16.2042 24.6589 16.09 24.3847C15.9758 24.1105 15.8084 23.8617 15.5975 23.6525L8.17251 16.25H25.25C25.8467 16.25 26.419 16.0129 26.841 15.591C27.263 15.169 27.5 14.5967 27.5 14C27.5 13.4033 27.263 12.831 26.841 12.409C26.419 11.987 25.8467 11.75 25.25 11.75Z" fill="white"/>
          </svg>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import './premiumbuy.scss'

import PremiumLoader from '@/components/Premium/PremiumLoader/PremiumLoader.vue'

export default {
  name: 'PremiumBuy',
  data () {
    return {
      isSelected: false,
      userId: null,
      isLoadedBuy: true,
      premium: 450,
      premiumPrice: 375,
      premType: 'medium',
      salePrice: null,
      saleProcent: null,
      promoCode: null,
      typesPremium: [450, 1000, 2200],
      pricesPremium: {},
      stage: 0,
      levels: {
        rub: {
          low: 'level_1',
          medium: 'level_2',
          high: 'level_3'
        },
        usd: {
          low: 'level_1_usd',
          medium: 'level_2_usd',
          high: 'level_3_usd'
        },
        eur: {
          low: 'level_1_eur',
          medium: 'level_2_eur',
          high: 'level_3_eur'
        }
      }
    }
  },
  components: {
    PremiumLoader
  },
  props: {
    userData: {
      type: Array,
      require: true
    }
  },
  async created () {
    if (this.$store.state.isMobile) {
      this.stage = 1
    }

    const userId = localStorage.getItem('user_id') || 'default'
    await fetch(`https://prices.backend-pier.com/get_rates/${userId}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        const { premium } = data
        this.pricesPremium = premium.buyer
      })
  },
  computed: {
    totalPrice () {
      return this.getLevelPrice(this.premType)
    }
  },
  methods: {
    getLevelPrice (level) {
      return this.pricesPremium[this.levels[this.$store.state.currency][level]]
    },
    changeModal (to) {
      if (to === 'back') {
        if (this.$store.state.isMobile) {
          this.stage = 1
          return
        }
        this.$emit('changeModal', {
          modal: 'instruction'
        })
        return
      }
      if (this.$store.state.isMobile) {
        this.$emit('changeModal', {
          modal: 'choice'
        })
        return
      }
      if (this.premiumPrice && this.$store.state.isAuth && this.$store.getters.balance > this.totalPrice) {
        this.$emit('changeModal', {
          modal: 'status'
        })
      } else if (!(this.$store.state.isAuth)) {
        this.$store.state.sign_in = true
      } else if (this.$store.getters.balance < this.totalPrice) {
        this.$emit('changeModal', {
          modal: 'ordering'
        })
        this.$store.state.price = {
          prices: this.pricesPremium,
          levels: this.levels,
          selectedLevel: this.premType
        }
      }
    },
    changePremium (i, type) {
      const price = this.pricesPremium[this.levels[this.$store.state.currency][type]]

      this.isSelected = true
      this.premium = this.typesPremium[i]
      this.premiumPrice = price
      this.premType = type
      this.salePrice = null
      this.saleProcent = null

      if (this.$store.state.isMobile) {
        this.stage = 2
      }
    },
    createOrder () {
      if (!this.$store.state.isAuth) {
        this.$store.state.sign_in = true
        return
      }

      if (this.$store.getters.balance < this.totalPrice) {
        this.$emit('changeModal', {
          modal: 'ordering'
        })
        this.$store.state.price = {
          prices: this.pricesPremium,
          levels: this.levels,
          selectedLevel: this.premType
        }
      }

      this.isLoadedBuy = false

      if (!this.$store.state.isMobile) {
        const requestBody = JSON.stringify({
          username: this.userData[0],
          cookie: this.userData[1],
          is_quick: false,
          promocode_value: null,
          premium_type: this.premType,
          is_paid: this.$store.getters.balance >= this.totalPrice,
          password: null,
          currency: this.$store.state.currency.toUpperCase()
        })

        const requestHeaders = new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$cookies.get('access_token')}`
        })

        const requestOptions = {
          method: 'POST',
          headers: requestHeaders,
          body: requestBody
        }

        fetch('https://logpass.backend-pier.com/api/v1/orders/create_order', requestOptions)
          .then(res => res.json())
          .then(data => {
            // console.log(data)
            this.isLoadedBuy = true
            this.$emit('toggleComeBack')
            this.$emit('changeModal', { modal: 'status' })
          })
      } else {
        fetch(`https://logpass.backend-pier.com/api/v1/roblox/get_user_id?username=${this.$store.state.account.username}`)
          .then(res => res.json())
          .then(data => {
            this.userId = data.user_id

            fetch(`https://logpass.backend-pier.com/api/v1/roblox/check_premium?user_id=${this.userId}`)
              .then(res => res.json())
              .then(data => {
                // console.log(data)
                if (data.is_premium) {
                  this.$store.state.account.isPremium = true
                  this.$router.push('/methods')
                  this.$store.dispatch('showNotificationPremium')
                } else {
                  const requestBody = {
                    username: this.userData[0] ? this.userData[1] : null,
                    cookie: this.userData[1] ? this.userData[1] : null,
                    is_quick: true,
                    promocode_value: null,
                    premium_type: this.premType,
                    is_paid: this.$store.getters.balance >= this.totalPrice,
                    password: null,
                    currency: this.$store.state.currency.toUpperCase()
                  }
                  this.$emit('moveOrderData', { modal: 'choice', order: requestBody })
                }
              })
          })
      }
    }
  },
  mounted () {
    const userId = localStorage.getItem('user_id') || 'default'
    fetch(`https://prices.backend-pier.com/get_rates/${userId}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        const { premium } = data
        this.pricesPremium = premium.buyer
        this.totalPrice = this.pricesPremium.level_2
      })
  }
}
</script>
