<template>
  <div class="alert" :class="`${state}`">
    <Icon class="alert__icon" :name="currentIcon" />
    <div class="alert__body">
      <div class="alert__title">{{ title }}</div>
      <div class="alert__info">{{ info }}</div>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/Base/Icon/Icon.vue'
import './alert.scss'
export default {
  name: 'Button',
  props: {
    title: {
      value: String,
      default: ''
    },
    info: {
      value: String,
      default: ''
    },
    state: {
      value: String,
      default: 'warning',
      validator: function (value) {
        return ['warning', 'success', 'error'].includes(value)
      }
    },
    loading: {
      value: Boolean,
      default: false
    },
    size: {
      value: String,
      default: 'lg',
      validator: function (value) {
        return ['lg', 'md', 'sm'].includes(value)
      }
    }
  },
  components: { Icon },
  computed: {
    currentIcon () {
      return this.state === 'warning' ? '/base/warning.svg' : ''
    }
  }
}
</script>
