<template>
  <div class="vkauth">

  </div>
</template>
<script>

// import * as intercom from '@/utils/intercom'
export default {
  name: 'VKAuth',
  props: ['query'],
  created () {
    fetch(`https://users.backend-pier.com/api/v1/user/verify?q=${this.query}`)
      .then((res) => res.json())
      .then((data) => {
        // intercom.authCallback.call(this)
        this.$store.dispatch('signIn', data).then(() => {
          this.$router.push('/')

          this.$analytics.send('login', {
            type: 'login',
            method: 'vk',
            user_id: data.data?.user_id
          })
        })
      })
      .catch((err) => console.log(err))
  }
}
</script>
