<template>
  <div class="character fastpass__wrapper">
    <div class="fastpass__modal" v-if="inventoryModal" @click.self="closeInventoryModal">
      <div class="fastpass__modal__content">
        <p>{{ $t('fastpass.characters.inventory') }}</p>
        <button class="fastpass__modal__btn" @click="openInventory">
          {{ $t('actions.edit') }}
        </button>
      </div>
    </div>

    <div class="fastpass__form">
      <div class="fastpass__form-logo">
        <Logo dark />
      </div>
      <div class="fastpass__form-content">
        <div class="character-input">
          <div class="fastpass__form-content__bg">
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.71 11.71C14.6904 10.9387 15.406 9.88092 15.7572 8.68394C16.1085 7.48696 16.0779 6.21026 15.6698 5.03147C15.2617 3.85267 14.4963 2.83039 13.4801 2.10685C12.4639 1.38332 11.2474 0.994503 10 0.994503C8.75255 0.994503 7.53611 1.38332 6.51993 2.10685C5.50374 2.83039 4.73834 3.85267 4.33021 5.03147C3.92208 6.21026 3.89151 7.48696 4.24276 8.68394C4.59401 9.88092 5.3096 10.9387 6.29 11.71C4.61007 12.383 3.14428 13.4993 2.04889 14.9399C0.953495 16.3805 0.26956 18.0913 0.0699967 19.89C0.0555513 20.0213 0.0671132 20.1542 0.104022 20.2811C0.140931 20.4079 0.202464 20.5263 0.285108 20.6293C0.452016 20.8375 0.69478 20.9708 0.959997 21C1.22521 21.0292 1.49116 20.9518 1.69932 20.7849C1.90749 20.618 2.04082 20.3752 2.07 20.11C2.28958 18.1552 3.22168 16.3498 4.68822 15.0388C6.15475 13.7278 8.0529 13.003 10.02 13.003C11.9871 13.003 13.8852 13.7278 15.3518 15.0388C16.8183 16.3498 17.7504 18.1552 17.97 20.11C17.9972 20.3557 18.1144 20.5827 18.2991 20.747C18.4838 20.9114 18.7228 21.0015 18.97 21H19.08C19.3421 20.9698 19.5817 20.8373 19.7466 20.6312C19.9114 20.4252 19.9881 20.1624 19.96 19.9C19.7595 18.0962 19.0719 16.381 17.9708 14.9382C16.8698 13.4954 15.3969 12.3795 13.71 11.71ZM10 11C9.20887 11 8.43551 10.7654 7.77772 10.3259C7.11992 9.88635 6.60723 9.26164 6.30448 8.53073C6.00173 7.79983 5.92251 6.99556 6.07686 6.21964C6.2312 5.44371 6.61216 4.73098 7.17157 4.17157C7.73098 3.61216 8.44371 3.2312 9.21964 3.07686C9.99556 2.92252 10.7998 3.00173 11.5307 3.30448C12.2616 3.60723 12.8863 4.11992 13.3259 4.77772C13.7654 5.43551 14 6.20887 14 7C14 8.06086 13.5786 9.07828 12.8284 9.82843C12.0783 10.5786 11.0609 11 10 11Z" fill="white"/>
            </svg>
          </div>
          <input
            v-model="nickname"
            class="fastpass__form-content__input"
            type="text"
            @input="findUsers"
            :placeholder="$t('transfer.nickname')"
          >
        </div>
        <div class="fastpass__form-characters onfw">
          <Loader v-if="isLoaded === false" />
          <div
            v-for="(value, key) in characters"
            :key="key"
            @click="selectCharacter(key, value.name, $event)"
            class="fastpass__form-characters__item"
          >
            <p class="fastpass__form-characters__item-title">{{ value.name }}</p>
            <img :src="value.imageUrl" alt="">
          </div>
          <div class="fastpass__form-characters-not" v-if="characters.length === 0">
            <p>{{ $t('transfer.notFound') }}</p>
          </div>
        </div>
      </div>
      <button
        class="fastpass__form-btn"
        @click="changeModal"
        :disabled="!checked"
      >
        {{ $t('actions.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import './fastpassCharacters.scss'

import Loader from '@/components/LoaderBar/LoaderBar'
import Logo from '@/components/Logo/Logo.vue'

export default {
  name: 'FastpassCharacters',
  components: {
    Loader,
    Logo
  },
  data () {
    return {
      isLoaded: true,
      robloxUserId: '',
      nickname: '',
      choosedNickname: '',
      isSelectCharacter: false,
      isFilled: false,
      characters: [],
      interval: null,
      inventoryModal: false
    }
  },
  computed: {
    checked () {
      if (this.isSelectCharacter) return !this.isFilled
      return this.isFilled
    }
  },
  methods: {
    showInventoryModal () {
      this.inventoryModal = true
    },
    closeInventoryModal () {
      this.inventoryModal = false
    },
    openInventory () {
      window.open('https://www.roblox.com/my/account#!/privacy', '_blank')
      this.closeInventoryModal()
    },

    async changeModal () {
      this.isLoaded = false
      // const data = await this.getInventory()
      this.isLoaded = true

      // TODO вернуть проверку
      this.$emit('changeModal', {
        modal: 'InstructionForm',
        data: [this.robloxUserId, this.choosedNickname]
      })

      // if (data.status) {
      //   this.$emit('changeModal', {
      //     modal: 'InstructionForm',
      //     data: [this.robloxUserId, this.choosedNickname]
      //   })

      //   return
      // }

      // this.showInventoryModal()
    },
    getInventory () {
      return fetch(`https://transfer.backend-pier.com/check_view_inventory/${this.robloxUserId}`)
        .then((res) => res.json())
        .catch((err) => console.log(err))
    },
    selectCharacter (id, nickname, event) {
      if (this.isSelectCharacter) {
        const character = document.querySelector('.fastpass__form-characters__item.select-ch')
        character.classList.remove('select-ch')
      }
      const target = event.target.closest('.fastpass__form-characters__item')
      target.classList.add('select-ch')
      this.robloxUserId = id
      this.choosedNickname = nickname
      this.isSelectCharacter = true
    },
    findUsers () {
      clearTimeout(this.interval)

      this.interval = setTimeout(() => {
        if (this.nickname.length < 3) {
          this.characters = []
          return
        }

        this.isLoaded = false

        fetch(`https://transfer.backend-pier.com/search_users/${this.nickname}`)
          .then((res) => res.json())
          .then((data) => {
            this.isLoaded = true

            if (data.status) {
              this.characters = data.data
            } else {
              this.characters = []
            }
          })
          .catch((err) => console.log(err))
      }, 1000)
    }
  }
}
</script>
