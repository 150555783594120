<template>
  <div class="methods">
    <Auth />
    <!-- <div class="change-password" v-if="$store.state.change_password"><ChangePassword /></div> -->
    <div class="wrapper">
      <Navigation />
    </div>
    <main>
      <div class="content">
        <div class="content-premium__hint" :class="{ 'open-hint ': $store.state.showNotificationPremium }">
          <div class="content-premium__hint-modal">
            <div class="content-premium__hint-modal__content">
              <p class="content-premium__hint-modal__content-title">{{ $t('methods.hintError.title') }}</p>
              <p>{{ $t('methods.hintError.content') }}</p>
            </div>
          </div>
        </div>
        <h1 class="content__title">{{ $t('methods.title') }}</h1>
        <div class="content__methods">
          <router-link to="/transfer" class="content__methods-item">
            <div class="content__methods-item__overlay"></div>
            <div class="content__methods-item__label">
              <svg width="87" height="61" viewBox="0 0 87 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M85.172 18.8696L68.0054 1.70293C67.6052 1.30279 67.1302 0.985371 66.6074 0.768812C66.0845 0.552253 65.5242 0.44079 64.9583 0.44079C63.8154 0.44079 62.7193 0.894797 61.9112 1.70293C61.1031 2.51107 60.6491 3.60714 60.6491 4.75002C60.6491 5.8929 61.1031 6.98896 61.9112 7.7971L71.782 17.625H22.0416C20.9034 17.625 19.8118 18.0772 19.007 18.882C18.2021 19.6869 17.75 20.7785 17.75 21.9167C17.75 23.0549 18.2021 24.1465 19.007 24.9514C19.8118 25.7562 20.9034 26.2084 22.0416 26.2084H82.125C82.9722 26.2041 83.7993 25.9492 84.5019 25.4757C85.2046 25.0022 85.7513 24.3313 86.0733 23.5475C86.4019 22.766 86.4917 21.9046 86.3313 21.072C86.1709 20.2395 85.7675 19.4731 85.172 18.8696ZM64.9583 34.7917H4.87495C4.02766 34.7959 3.20059 35.0508 2.49796 35.5244C1.79532 35.9979 1.24858 36.6688 0.926614 37.4525C0.597961 38.2341 0.508168 39.0955 0.668565 39.928C0.828962 40.7605 1.23236 41.5269 1.82786 42.1304L18.9945 59.2971C19.3935 59.6993 19.8682 60.0186 20.3911 60.2365C20.9141 60.4544 21.4751 60.5666 22.0416 60.5666C22.6082 60.5666 23.1691 60.4544 23.6921 60.2365C24.2151 60.0186 24.6897 59.6993 25.0887 59.2971C25.491 58.8981 25.8102 58.4235 26.0281 57.9005C26.246 57.3775 26.3582 56.8166 26.3582 56.25C26.3582 55.6835 26.246 55.1225 26.0281 54.5995C25.8102 54.0766 25.491 53.6019 25.0887 53.2029L15.2179 43.375H64.9583C66.0965 43.375 67.1881 42.9229 67.993 42.118C68.7978 41.3132 69.25 40.2216 69.25 39.0833C69.25 37.9451 68.7978 36.8535 67.993 36.0487C67.1881 35.2438 66.0965 34.7917 64.9583 34.7917Z"
                  fill="white" />
              </svg>
            </div>
            <div class="content__methods-item__text">
              <p class="content__methods-item__text-title">{{ $t('methods.method') }}</p>
              <p class="content__methods-item__text-subtitle">{{ $t('methods.transfer') }}</p>
            </div>
            <div class="subscription available-method">
              <img src="../../assets/images/methods/ok.svg" alt="">
              <p>{{ $t('methods.available') }}</p>
            </div>
          </router-link>

          <router-link to="/fastpass" class="content__methods-item" :class="$store.state.methods.isFastPassSalesAvailable ? '' : 'inactive-methods'">
            <div class="content__methods-item__overlay"></div>
            <div class="content__methods-item__label">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M25.9487 30.6667C26.4127 30.6667 26.862 30.5013 27.214 30.1987L40.726 18.652C41.1113 18.3227 41.334 17.8413 41.334 17.3333C41.334 16.8253 41.1113 16.344 40.726 16.0133L27.214 4.46667C26.862 4.16533 26.4127 4 25.9487 4C24.8727 4 24.0007 4.872 24.0007 5.948V8.56667C13.0793 13.3467 5.33398 25.4867 5.33398 39.3333C5.33398 55.7147 16.3167 71.1907 34.498 71.9987C34.5113 72 34.5233 72 34.5367 72C35.3367 72 36.0007 71.3413 36.0007 70.5307C36.0007 69.9027 35.5927 69.3573 34.9993 69.1507C24.702 65.5573 17.334 55.944 17.334 44.6667C17.334 37.664 19.5047 31.328 24.0007 26.6533V28.7187C24.0007 29.7947 24.8727 30.6667 25.9487 30.6667Z"
                  fill="white" />
                <path
                  d="M74.666 40.6667C74.666 24.284 63.6833 8.80933 45.502 8.00133C45.4887 8 45.4753 8 45.4633 8C44.6633 8 43.9993 8.65867 43.9993 9.46933C43.9993 10.0987 44.4073 10.6427 45.002 10.8493C55.298 14.4427 62.666 24.056 62.666 35.3333C62.666 42.336 60.494 48.672 55.9993 53.3467V51.2813C55.9993 50.2053 55.1274 49.3333 54.0514 49.3333C53.5874 49.3333 53.138 49.4987 52.786 49.8L39.274 61.3467C38.8887 61.6773 38.666 62.1587 38.666 62.6667C38.666 63.1747 38.8887 63.656 39.274 63.9867L52.786 75.5333C53.138 75.8347 53.5874 76 54.0514 76C55.1274 76 55.9993 75.128 55.9993 74.052V71.4333C66.9207 66.6533 74.666 54.5133 74.666 40.6667Z"
                  fill="white" />
                <path
                  d="M56.942 33.7241L46.2754 23.0574C46.034 22.8161 45.7007 22.6667 45.3327 22.6667C44.9647 22.6667 44.6313 22.8161 44.39 23.0574L23.0567 44.3907C22.8153 44.6321 22.666 44.9654 22.666 45.3334C22.666 45.7014 22.8153 46.0347 23.0567 46.2761L33.7233 56.9427C33.9647 57.1841 34.298 57.3334 34.666 57.3334C35.034 57.3334 35.3674 57.1841 35.6087 56.9427L56.942 35.6094C57.1847 35.3681 57.3327 35.0347 57.3327 34.6667C57.3327 34.2987 57.1847 33.9654 56.942 33.7241ZM39.9993 44.0001C37.79 44.0001 35.9993 42.2094 35.9993 40.0001C35.9993 37.7907 37.79 36.0001 39.9993 36.0001C42.2073 36.0001 43.9993 37.7907 43.9993 40.0001C43.9993 42.2094 42.2073 44.0001 39.9993 44.0001Z"
                  fill="white" />
              </svg>

            </div>
            <div class="content__methods-item__text">
              <p class="content__methods-item__text-title">FAST</p>
              <p class="content__methods-item__text-subtitle">PASS</p>
            </div>
            <div class="subscription"
                 :class="$store.state.methods.isFastPassSalesAvailable ? 'available-method' : 'not-available'"
            >
              <img src="../../assets/images/methods/ok.svg" alt="">
              <p v-if="$store.state.methods.isFastPassSalesAvailable">{{ $t('methods.available') }}</p>
              <p v-else>{{ $t('methods.notAvailable') }}</p>
            </div>
          </router-link>
          <router-link to="/premium" class="content__methods-item"
            :class="$store.state.methods.isPremiumSalesAvailable ? '' : 'inactive-methods'">
            <div class="content__methods-item__overlay"></div>
            <div class="content__methods-item__label">
              <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.54303 78.6342V8.54302H78.6342V78.6342L22.1427 78.6342V14.2968H14.2967V78.6342L8.54303 78.6342ZM0.697021 8.01995C0.697021 3.9756 3.97562 0.697004 8.01997 0.697004H79.1573C83.2016 0.697004 86.4802 3.9756 86.4802 8.01995V79.1573C86.4802 83.2016 83.2016 86.4802 79.1573 86.4802L41.4962 86.4803V78.6342H35.7424V86.4803H27.8966V78.6342H22.1429V86.4803H14.2968V78.6342H8.54304V86.4803L8.01997 86.4802C3.97561 86.4802 0.697021 83.2016 0.697021 79.1573V8.01995ZM72.8805 14.2968H22.1429V22.1428H65.0344V65.0345H41.4965V72.8805H65.0346V72.8803H72.8804V22.1428H72.8805V14.2968ZM27.8963 27.8964H35.7423V78.634H27.8963V27.8964ZM59.2805 27.8964H35.7424V35.7424H59.2805V27.8964ZM51.4346 35.7427H59.2806V51.4347L59.2808 51.4347V59.2807H41.4965V51.4347L51.4346 51.4347V35.7427Z"
                  fill="white" />
              </svg>
            </div>
            <div class="content__methods-item__text">
              <p class="content__methods-item__text-title">{{ $t('methods.premium') }}</p>
              <p class="content__methods-item__text-subtitle">{{ $t('methods.logpass') }}</p>
            </div>
            <div class="subscription"
              :class="$store.state.methods.isPremiumSalesAvailable ? 'available-method' : 'not-available'">
              <img src="../../assets/images/methods/ok.svg" v-if="$store.state.methods.isPremiumSalesAvailable" alt="">
              <img src="../../assets/images/methods/no.svg" v-else alt="">
              <p v-if="$store.state.methods.isPremiumSalesAvailable">{{ $t('methods.available') }}</p>
              <p v-else>{{ $t('methods.notAvailable') }}</p>
            </div>
          </router-link>

          <router-link to="/logpass" class="content__methods-item"
            :class="$store.state.methods.isLogPassSalesAvailable ? '' : 'inactive-methods'">
            <div class="content__methods-item__overlay"></div>
            <div class="content__methods-item__label">
              <svg width="86" height="72" viewBox="0 0 86 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M32.52 64L20.68 43.6H9.8V64H0.6V8H23C28.0133 8 32.2533 9.76 35.72 13.28C39.24 16.7467 41 20.9867 41 26C41 29.5733 39.96 32.8533 37.88 35.84C35.8533 38.7733 33.1867 40.9067 29.88 42.24L42.6 64H32.52ZM9.8 16.64V35.36H23C25.4533 35.36 27.5333 34.4533 29.24 32.64C30.9467 30.8267 31.8 28.6133 31.8 26C31.8 23.3867 30.9467 21.1733 29.24 19.36C27.5333 17.5467 25.4533 16.64 23 16.64H9.8ZM85.5825 48.64C85.5825 53.1733 84.1158 56.88 81.1825 59.76C78.3025 62.5867 74.5158 64.2933 69.8225 64.88V72H64.6225V64.96C60.1958 64.6933 56.4092 63.4933 53.2625 61.36C50.1158 59.2267 47.8492 56.32 46.4625 52.64L54.3825 48.08C56.2492 52.7733 59.6625 55.3867 64.6225 55.92V39.28C62.2225 38.3733 60.2758 37.5733 58.7825 36.88C57.2892 36.1333 55.6892 35.12 53.9825 33.84C52.3292 32.56 51.0758 31.0133 50.2225 29.2C49.4225 27.3333 49.0225 25.1733 49.0225 22.72C49.0225 18.24 50.5158 14.6133 53.5025 11.84C56.4892 9.06666 60.1958 7.46666 64.6225 7.04V-9.53674e-07H69.8225V7.12C76.9692 8.08 81.9292 11.7333 84.7025 18.08L76.9425 22.56C75.5558 19.0933 73.1825 16.9867 69.8225 16.24V32.08C71.5292 32.72 72.8092 33.2267 73.6625 33.6C74.5692 33.9733 75.7425 34.5333 77.1825 35.28C78.6758 36.0267 79.8225 36.7733 80.6225 37.52C81.4225 38.2667 82.2492 39.1733 83.1025 40.24C84.0092 41.3067 84.6492 42.56 85.0225 44C85.3958 45.3867 85.5825 46.9333 85.5825 48.64ZM58.2225 22.72C58.2225 24.3733 58.7025 25.7333 59.6625 26.8C60.6758 27.8667 62.3292 28.9333 64.6225 30V16.08C62.6492 16.4 61.0758 17.1467 59.9025 18.32C58.7825 19.4933 58.2225 20.96 58.2225 22.72ZM69.8225 55.84C71.9558 55.4133 73.5825 54.56 74.7025 53.28C75.8225 52 76.3825 50.48 76.3825 48.72C76.3825 46.96 75.8492 45.5467 74.7825 44.48C73.7158 43.36 72.0625 42.2933 69.8225 41.28V55.84Z"
                  fill="white" />
              </svg>
            </div>
            <div class="content__methods-item__text">
              <p class="content__methods-item__text-title">{{ $t('methods.robux') }}</p>
              <p class="content__methods-item__text-subtitle">{{ $t('methods.logpass') }}</p>
            </div>
            <div class="subscription"
              :class="$store.state.methods.isLogPassSalesAvailable ? 'available-method' : 'not-available'">
              <img src="../../assets/images/methods/ok.svg" v-if="$store.state.methods.isLogPassSalesAvailable" alt="">
              <img src="../../assets/images/methods/no.svg" v-else alt="">
              <p v-if="$store.state.methods.isLogPassSalesAvailable">{{ $t('methods.available') }}</p>
              <p v-else>{{ $t('methods.notAvailable') }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import './buyMethod.scss'

import Auth from '@/components/Auth/Auth'
import Navigation from '@/components/Navigation/Navigation'

export default {
  name: 'BuyMethod',
  components: {
    Navigation,
    Auth
  },
  mounted () {
    fetch('https://logpass.backend-pier.com/api/v1/orders/are_sales_available?method=premium')
      .then(res => res.json())
      .then(data => {
        this.$store.state.methods.isPremiumSalesAvailable = data.are_sales_available
      })

    fetch('https://logpass.backend-pier.com/api/v1/orders/are_sales_available?method=logpass_normal')
      .then(res => res.json())
      .then(data => {
        this.$store.state.methods.isLogPassSalesAvailable = data.are_sales_available
      })
  }
}
</script>
