<template>
  <div v-if="false" class="support-chat" id="support-chat">
    <img class="support-chat__icon" src="@/assets/images/SupportChat/icon.png" alt="chat" srcset="">
  </div>
</template>

<script>
import './supportChat.scss'

export default {
  name: 'SupportChat'
}
</script>
