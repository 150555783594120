import policy from '../policy/ru'
import terms from '../terms/ru'

export default {
  app: {
    title: 'Пристань Робуксов',
    description: 'Честность. Удобство. Выгода. Лучший магазин робуксов с долгой историей. Получай удовольствие от игры вместе с robuxpier.',
    titlePostfix: {
      methods: 'Выбор метода',
      transfer: 'Метод Трансфер',
      logpass: 'Метод LogPass',
      premium: 'Метод Премиум LOG+PASS',
      payment: 'Пополнение'
    }
  },
  navigation: {
    title: 'НАВИГАЦИЯ',
    home: 'ГЛАВНАЯ',
    logoText: 'Работаем с 2018 г.',
    buyRobux: 'КУПИТЬ РОБУКСЫ',
    lk: 'ЛИЧНЫЙ КАБИНЕТ',
    vk: 'Группа ВК',
    discord: 'Дискорд',
    telegram: 'Телеграм',
    instagram: 'Инстаграм',
    tiktok: 'Тикток',
    dropdown: {
      history: 'История',
      changePassword: 'Сменить пароль',
      refill: 'Пополнить',
      logout: 'Выход',
      copyId: 'Копировать ID',
      copyIdBase: 'Copy ID'
    }
  },
  general: {
    warning: 'Внимание!',
    error: 'Ошибка',
    gotIt: 'Понятно',
    confirm: 'ПОДТВЕРЖДЕНИЕ',
    or: 'или',
    link: 'здесь',
    transfer: 'трансфер',
    policy: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
    terms: 'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ',

    errors: {
      internal: 'Внутренняя ошибка, пожалуйста, повторите попытку позже',
      server: 'Серверная ошибка'
    }
  },
  actions: {
    continue: 'Продолжить',
    submit: 'ПОДТВЕРДИТЬ',
    buyRobux: 'КУПИТЬ РОБУКСЫ',
    openDiscord: 'ПЕРЕЙТИ В ДИСКОРД',
    openInstruction: 'ПЕРЕЙТИ В ИНСТРУКЦИЮ',
    createAccount: 'Создать аккаунт',
    login: 'ВОЙТИ В АККАУНТ',
    loginVK: 'Войдите с помощью ВКонтакте',
    signin: 'Войдите',
    enterPassword: 'Введите его',
    skip: 'Пропустить',
    buy: 'Приобрести',
    buyOrder: 'Купить',
    tryAgain: 'Повторить еще раз',
    create: 'Создать',
    ready: 'Готово',
    edit: 'Настроить',
    apply: 'Применить',
    promocode: {
      default: 'Промокод не применен',
      success: 'Промокод применен',
      error: 'Не удалось применить промокод',
      minError: 'Минимальная сумма для активации промокода {min} R$',
      maxError: 'Максимальная сумма для активации промокода {max} R$',
      isNoPromo: 'Данного промокода не существует!',
      promoExpired: 'Истекло время действия промокода!',
      notAuth: 'Требуется авторизация!',
      reuse: 'Промокод можно использовать только один раз!'
    }
  },
  payment: {
    refill: 'Пополнение баланса',
    total: 'Итог',
    toPay: 'К оплате',
    pay: 'ОПЛАТИТЬ',
    makeOrder: 'Оплатить заказ',
    otherPaymentMethods: 'Прочие способы оплаты',
    enterSum: 'Введите сумму пополнения',
    commission: 'Комиссия',
    notEnough: 'У вас недостаточно средств для оплаты!',
    problems: 'В случае возникновения проблем напишите в нашу {support}',
    support: 'службу поддержки',
    selectPaymentMethod: 'ВЫБЕРИТЕ СПОСОБ ОПЛАТЫ'
  },
  order: {
    status: 'СТАТУС ЗАКАЗА',
    wait: 'ПОЖАЛУЙСТА, ОЖИДАЙТЕ выполнения заказа',
    waitTime: 'ОСТАЛОСЬ',
    code: 'Код должен состоять из 6 цифр',
    mailCode: 'Введите код с почты',
    makeNewOrder: 'Сделать еще заказ',
    table: {
      nickname: 'ИГРОВОЙ НИК',
      count: 'КОЛ-ВО R$',
      total: 'ИТОГО, РУБ'
    },
    descriptions: {
      wait: 'Ожидайте выполнения заказа',
      notEnoughFunds: 'Заказ не выполнен по технической причине',
      systemError: 'Системная ошибка!',
      checkVip: 'Проверьте настройки VIP ROBLOX!',
      connectionError: 'Ошибка связи с ROBLOX!',
      success: 'Заказ успешно выполнен!',
      checkGamepass: 'Проверьте настройки Gamepass!'
    }
  },
  auth: {
    authorization: 'Авторизация',
    registration: 'Регистрация',
    recovery: 'ВОССТАНОВЛЕНИЕ',
    change: 'СМЕНИТЬ ПАРОЛЬ',

    inputs: {
      email: 'Введите E-mail',
      login: 'Введите логин',
      loginOrEmail: 'Введите логин или E-mail',
      currentPassword: 'Текущий пароль',
      newPassword: 'Новый пароль',
      repeatNewPassword: 'Повторите новый пароль',
      password: 'Введите пароль',
      passwordSubmit: 'Подтвердите пароль'
    },

    errors: {
      email: 'Неверный E-mail!',
      incorrectPassword: 'Пароль неверный!',
      empty: 'Поле пустое',
      nicknameIsTooSmall: 'Вы должны указать имя, состоящее не менее чем из 4 символов',
      inactiveEmail: 'Электронная почта недействительна',
      passwordIsTooSmall: 'Ваш пароль должен состоять не менее чем из 6 символов',
      passwordNotMatch: 'Пароли не совпадают',
      oneUppercase: 'Ваш пароль должен иметь хотя бы одну заглавную букву',
      userExist: 'Пользователь с такими данными уже существует',
      checkEmail: 'Убедитесь в правильности вашего электронного письма/имени пользователя',
      checkPassword: 'Убедитесь, что ваш пароль верен',
      changePasswordIncorrect: 'Пароль должен быть от 6 до 18 символов и состоять из букв “A-z”!'
    },

    welcome: {
      title: 'ДОБРО ПОЖАЛОВАТЬ',
      content: 'ЗАРЕГИСТРИРУЙТЕСЬ НА НАШЕМ САЙТЕ И НАЧНИТЕ ПОКУПАТЬ РОБУКСЫ НАДЕЖНО, БЫСТРО И БЕЗОПАСНО'
    },

    forget: 'Забыли пароль?',
    remember: 'Вы вспомнили пароль?',
    agreement: 'Я согласен с {policy} и {agreement}',
    privacyPolicy: 'политикой конфиденциальности',
    userAgreement: 'пользовательским соглашением',
    haveAccount: 'У Вас уже есть аккаунт?',
    codeSent: 'На почту {email} отправлен код для восстановления пароля!',
    enterCode: 'Введите 6-ти значный код:',
    requestCode: 'Запросить снова',

    changePasswordSuccess: {
      title: 'Отлично!',
      content: 'Вы успешно изменили пароль! Обязательно запомните...'
    }
  },
  slider: {
    slide1: {
      title: 'НАМ ДОВЕРЯЮТ БЛОГЕРЫ',
      content: 'ЗА ШЕСТЬ ЛЕТ СУЩЕСТВОВАНИЯ САЙТА СОТНИ ТЫСЯЧ ЗАКАЗОВ. ПОКУПАЙ БЕЗОПАСНО В ПРОВЕРЕННОМ МАГАЗИНЕ'
    },
    slide2: {
      title: 'У НАС САМЫЕ НИЗКИЕ ЦЕНЫ!',
      content: 'КУПИ РОБУКСЫ И СТАНЬ ЛУЧШИМ СРЕДИ ДРУЗЕЙ. ОСУЩЕСТВИ МЕЧТУ!'
    },
    slide3: {
      title: 'ВСЁ ЕЩЁ СОМНЕВАЕШЬСЯ?',
      content: 'ТЫСЯЧИ ДОВОЛЬНЫХ КЛИЕНТОВ С РЕАЛЬНЫМИ ОТЗЫВАМИ В НАШИХ СОЦИАЛЬНЫХ СЕТЯХ!'
    }
  },
  faq: {
    title: 'КУПИТЬ РОБУКСЫ ЛЕГКО!',
    content: 'ВЫПОЛНИ ТРИ ПРОСТЫХ ШАГА',
    items: {
      item1: {
        index: '01',
        title: 'СОЗДАЙ ЗАКАЗ',
        content: 'ВВЕДИ КОЛИЧЕСТВО РОБУКСОВ И СВОЙ НИК ROBLOX'
      },
      item2: {
        index: '02',
        title: 'Оплати',
        content: 'Оплати заказ любым удобным способом'
      },
      item3: {
        index: '03',
        title: 'ПОЛУЧИ РОБУКСЫ',
        content: 'ДОСТАВКА В ТЕЧЕНИЕ <br/> 5 дней!'
      }
    }
  },
  help: {
    title: 'ВОЗНИКЛИ ВОПРОСЫ О ПОКУПКЕ?',
    text1: 'Онлайн поддержка на сайте и модераторы в социальных сетях помогут вам ежедневно с 7:00 до 23:00 по московскому времени.',
    text2: 'Ознакомиться с инструкцией по покупке можно'
  },
  footer: {
    privacy: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
    terms: 'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ',
    contacts: 'КОНТАКТНАЯ ИНФОРМАЦИЯ'
  },
  methods: {
    title: 'КАКИМ СПОСОБОМ ПОКУПКИ ВЫ ХОТИТЕ ВОСПОЛЬЗОВАТЬСЯ?',
    hintError: {
      title: 'Ошибка!',
      content: 'У вас уже есть Роблокс Премиум!'
    },
    method: 'Метод',
    robux: 'Робуксы',
    pass: 'Пропуск',
    premium: 'Премиум',
    transfer: 'Трансфера',
    logpass: 'log+pass',
    available: 'Есть в наличии',
    notAvailable: 'Нет в наличии'
  },
  cookie: {
    whatIsCookie: 'Что такое COOKIE?',
    checkInstruction: 'ОЗНАКОМЬТЕСЬ С COOKIE-ИНСТРУКЦИЕЙ',
    enter: 'Введите Cookie',
    description: {
      part1: 'Сами по себе куки не опасны — это обычные текстовые файлы. Они не могут запускать процессы на компьютере и вообще взаимодействовать с операционной системой. Но их могут попытаться перехватить или украсть, чтобы отследить ваши предыдущие действия в сети или входить в ваши аккаунты без авторизации.',
      part2: 'Обычно информацию, которую записывают в куки, зашифровывают перед отправкой, а сами куки передают по HTTPS-протоколу. Это помогает защитить пользовательские данные, но за внедрение шифрования и безопасную отправку отвечает разработчик сайта. Посетителям остаётся только надеяться, что всё настроили грамотно. Со своей стороны пользователь может только запретить браузеру использовать куки или время от времени чистить их самостоятельно.'
    }
  },
  transfer: {
    title: 'Покупка методом трансфер',
    subTitle: 'Срок поступления робуксов на счет 5 дней',
    charactersTitle: 'Введите никнейм',
    charactersSubTitle: 'Выберите своего персонажа из списка',
    placeTitle: 'Выбери свой place',
    paymentSubTitle: 'Ещё раз перед оплатой проверьте все данные',
    robuxDelivery: 'Робуксы будут зачислены в течении 5 дней (120 часов)',
    available: 'В наличии:',
    robuxCount: 'Количество робуксов',
    minumum: 'Минимум от {min} R$',
    nickname: 'Игровой никнейм',
    notFound: 'Ничего не найдено',
    checkPlaces: 'ПРОВЕРЬТЕ ПОЖАЛУЙСТА, ЧТО У ВАС СОЗДАН ХОТЯ БЫ 1 ПЛЕЙС, КОТОРЫЙ ЯВЛЯЕТСЯ ПУБЛИЧНЫМ',
    vipPrice: 'Установите цену VIP сервера на {price}',
    changePrice: 'Изменить цену',
    orderSuccess: 'Отслеживать поступление робуксов на ваш аккаунт вы можете {here}',
    orderError: 'Средства возвращены на баланс аккаунта ROBUXPIER. Попробуйте еще раз :)',
    here: 'здесь',
    promoSwitch: 'У меня есть промокод',
    gamepass: {
      checkData: 'Проверьте корректность данных в Gamepass!',
      creating: 'СОЗДАНИЕ',
      settingUp: 'НАСТРОЙКА',
      createGamepass: 'Создайте Game-pass',
      checkInstruction: 'Посмотрите инструкцию и установите цену <span class="text-color--blue">{price} R$</span>',
      checkInstructionNew: 'Внимательно посмотрите инструкцию',
      serverError: 'Что-то пошло не так. Попробуйте еще раз.',
      setPrice: 'Установите цену'
    },
    errors: {
      insufficientBalance: 'Недостаточно средств на балансе',
      notAuthorized: 'Вы не авторизованы',
      serverError: 'Ошибка на сервере',
      robuxNotAvailable: 'Количество робаксов недоступно для покупки',

      priceNotNumber: 'Цена должна быть числом',
      robuxMinimum: 'Нельзя заказать менее {robux} робаксов',
      robuxMaximun: 'Максимальная сумма покупки временно ограничена 5k R$',
      halfBalance: 'Нельзя заказать больше половины баланса: {balance} робаксов',
      emptyBalance: 'На сайте закончились робуксы, ожидайте пополнение',
      robuxMustDivide: 'R$ должно делиться на {orders}. Попробуйте <span class="min">{closestMin}R$</span> или <span class="max">{closestMax}R$</span>'
    },
    selectYourPlace: 'Выберите свой'
  },
  logpass: {
    enterNickname: 'ВВЕДИТЕ НИКНЕЙМ',
    buyRobuxTitle: 'ПОКУПКА робуксов',
    codes: {
      code_request: 'Пожалуйста, введите код, который пришел Вам на почту',
      order_awaiting: 'Ожидайте выполнение заказа',
      code_request_awaiting: 'Ожидайте запрос кода с почты',

      password_error: 'Неверный пароль в ROBLOX',
      code_error: 'Неверный код двухэтапной аутентификации из почты',
      cookie_error: 'Ошибка. Неверные данные для авторизации',
      email_timeout_error: 'Вы не указали код! Повторите заказ и постарайтесь ввести код как можно быстрее!',
      error: 'Ошибка. Попробуйте еще раз',
      success: 'Заказ успешно выполнен',
      waiting: 'Ожидайте выполнения заказа'
    }
  },
  premium: {
    buyPremium: 'ПОКУПКА ПРЕМИУМА',
    alreadyExist: 'У вас уже есть Роблокс Премиум!',
    subscribe: {
      title: '{percent} К РОБУКСАМ!',
      subtitle: 'с Премиум подпиской вы получите больше робуксов '
    },
    getRbx: 'Получи {rbx} на счет',
    buyWithProfit: 'ПОКУПАЙ С ВЫГОДОЙ!',
    currencyPerMonth: '{currency} на месяц',
    toPay: 'Итого к оплате: ',
    premiumTime: 'Премиум подписка покупается только на {time}',
    oneMonth: '1 месяц без автопродления',
    enterPromocode: 'Введите промокод',
    ordering: 'ОФОРМЛЕНИЕ ЗАКАЗА',
    mailCode: 'ОЖИДАЙТЕ ЗАПРОС КОДА С ПОЧТЫ',

    correctSum: 'Введите корректную сумму',
    minRefillSum: 'Сумма пополнения должна быть не менее 5 рублей'
  },
  fastpass: {
    characters: {
      inventory: 'Необходимо открыть ваш инвентарь в настройках приватности!'
    },
    instruction: {
      plugin: 'ПЛАГИНА',
      mobileError: 'Приносим свои извинения, но создать плагин с телефона невозможно.\n\nПожалуйста, воспользуйтесь компьютером.',
      checkPlugin: 'Проверьте корректность данных в ПЛАГИНЕ!',
      create: 'СОЗДАНИЕ',
      edit: 'НАСТРОЙКА',
      select: 'ВЫБОР',
      createSubtitle: 'Посмотрите инструкцию и выполните все указанные действия. Укажите цену',
      editSubtitle: 'Проведите настройку Вашего плагина. Укажите цену',
      selectSubtitle: 'Выберите плагин, который хотите использовать',
      actions: {
        create: 'СОЗДАТЬ ПЛАГИН',
        edit: 'НАСТРОИТЬ ПЛАГИН'
      }
    }
  },
  supportChat: {
    hint: 'У тебя есть вопрос?',
    title: 'ПОДДЕРЖКА ИГРОКОВ',
    operator: 'Ваш помощник',
    actions: 'Варианты действий',
    anket: 'Представьтесь, чтобы связаться с оператором. Вам обязательно ответят на вопросы, связанные с RobuxPier.',
    name: 'Имя',
    email: 'E-mail',
    vk: 'Ссылка на Вконтакте',
    send: 'Отправить',
    enterMessage: 'Введите сообщение...'
  },
  qiwi: {
    details: 'ДЕТАЛИ ПОПОЛНЕНИЯ',
    passport: 'У Вас есть Паспорт РФ?',
    noPassport: 'Так как у Вас нет Паспорта РФ, Вы не сможете пройти верификацию в платежной системе Qiwi.',
    hasPassport: 'Вам необходимо пройти верификацию на сайте Qiwi, чтобы комиссия была меньше.',
    noPassportCommission: 'Комиссия будет составлять 7.5%',
    hasPassportCommission: 'Комиссия будет составлять 3%',
    yes: 'Да',
    no: 'Нет'
  },
  history: {
    nickname: 'Игровой никнейм',
    sumRobux: 'Сумма, R$',
    sumRub: 'сумма',
    status: 'Статус',
    description: 'Описание',
    timer: 'Таймер',

    error: 'Ошибка',
    wait: 'Ожидание',
    success: 'Успешно',
    orderCount: 'Показаны {order} из {length} записей'
  },
  modals: {
    feedback: {
      title: 'Нравится наш сайт?',
      text: 'Пожалуйста, напишите хороший отзыв о нашем сайте! Он поможет радовать Вас низкими ценами и надежной доставкой!',
      submit: 'Оставить отзыв'
    },
    social: {
      title: 'Это окно появится всего 1 раз!',
      text: 'Промокоды, розыгрыши, мемы и новости — причины подписаться на наши социальные сети.'
    }
  },
  policy,
  terms
}
