export default {
  data () {
    return {
      screenWidth: window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy  () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      // Обработка изменения ширины экрана
      this.screenWidth = window.innerWidth
    }
  }
}
