<template>
  <div class="logpass-buy">
    <div class="logpass-buy__content">
      <div class="logpass-buy__content-arrow" @click="changeModal('back')">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.25 11.75H8.17251L15.5975 4.34749C16.0212 3.92381 16.2592 3.34917 16.2592 2.74999C16.2592 2.15081 16.0212 1.57617 15.5975 1.15249C15.1738 0.728807 14.5992 0.490784 14 0.490784C13.4008 0.490784 12.8262 0.728807 12.4025 1.15249L1.15251 12.4025C0.94767 12.6165 0.787098 12.8688 0.680011 13.145C0.45497 13.6928 0.45497 14.3072 0.680011 14.855C0.787098 15.1312 0.94767 15.3835 1.15251 15.5975L12.4025 26.8475C12.6117 27.0584 12.8605 27.2258 13.1347 27.34C13.4089 27.4542 13.703 27.513 14 27.513C14.297 27.513 14.5911 27.4542 14.8653 27.34C15.1395 27.2258 15.3883 27.0584 15.5975 26.8475C15.8084 26.6383 15.9758 26.3895 16.09 26.1153C16.2042 25.8411 16.2631 25.547 16.2631 25.25C16.2631 24.953 16.2042 24.6589 16.09 24.3847C15.9758 24.1105 15.8084 23.8617 15.5975 23.6525L8.17251 16.25H25.25C25.8467 16.25 26.419 16.0129 26.841 15.591C27.263 15.169 27.5 14.5967 27.5 14C27.5 13.4033 27.263 12.831 26.841 12.409C26.419 11.987 25.8467 11.75 25.25 11.75Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="logpass-buy__content__loader" v-if="loader">
        <img src="@/assets/images/Premium/anchor.png" alt="" />
      </div>
      <div class="logpass-buy__content-header" v-if="!loader">
        <h1>{{ $t("logpass.buyRobuxTitle") }}</h1>
        <!-- <div class="logpass-buy__content-header__subtitle">
          <p>В НАЛИЧИИ: <span>12 500 R$</span></p>
        </div> -->
      </div>
      <div class="logpass-buy__content-form" v-if="!loader">
        <div class="logpass-buy__content-form__input">
          <div class="logpass-buy__content-form__input-img">
            <svg
              width="28"
              height="23"
              viewBox="0 0 28 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 20L7.325 13.3H3.2V20H0.875V2.5H7.875C9.375 2.5 10.6583 3.03333 11.725 4.1C12.7917 5.15 13.325 6.425 13.325 7.925C13.325 9.05833 12.9833 10.0917 12.3 11.025C11.6333 11.9583 10.7667 12.6167 9.7 13L13.825 20H11.25ZM3.2 4.675V11.2H7.875C8.74167 11.2 9.475 10.8833 10.075 10.25C10.6917 9.6 11 8.825 11 7.925C11 7.025 10.6917 6.25833 10.075 5.625C9.475 4.99167 8.74167 4.675 7.875 4.675H3.2ZM27.1582 15.325C27.1582 16.6917 26.7082 17.8167 25.8082 18.7C24.9082 19.5833 23.7415 20.1083 22.3082 20.275V22.5H20.7332V20.3C19.3665 20.2 18.1999 19.825 17.2332 19.175C16.2832 18.525 15.5915 17.65 15.1582 16.55L17.1582 15.4C17.7249 16.95 18.9165 17.825 20.7332 18.025V11.975C19.9832 11.7083 19.3749 11.4667 18.9082 11.25C18.4582 11.0167 17.9832 10.7083 17.4832 10.325C16.9832 9.94167 16.6082 9.475 16.3582 8.925C16.1249 8.35833 16.0082 7.70833 16.0082 6.975C16.0082 5.625 16.4582 4.525 17.3582 3.675C18.2582 2.825 19.3832 2.33333 20.7332 2.2V-2.98023e-07H22.3082V2.225C23.3082 2.35833 24.1999 2.70833 24.9832 3.275C25.7665 3.84167 26.3749 4.60833 26.8082 5.575L24.8582 6.675C24.3749 5.45833 23.5249 4.73333 22.3082 4.5V10.25C23.0749 10.5333 23.6832 10.7833 24.1332 11C24.5832 11.2167 25.0749 11.525 25.6082 11.925C26.1415 12.3083 26.5332 12.7833 26.7832 13.35C27.0332 13.9167 27.1582 14.575 27.1582 15.325ZM18.3082 6.975C18.3082 7.60833 18.4999 8.125 18.8832 8.525C19.2665 8.925 19.8832 9.3 20.7332 9.65V4.475C19.9999 4.575 19.4082 4.85 18.9582 5.3C18.5249 5.73333 18.3082 6.29167 18.3082 6.975ZM22.3082 17.975C23.1082 17.8583 23.7249 17.5667 24.1582 17.1C24.6082 16.6167 24.8332 16.0333 24.8332 15.35C24.8332 14.6667 24.6249 14.125 24.2082 13.725C23.8082 13.3083 23.1749 12.9167 22.3082 12.55V17.975Z"
                fill="white"
              />
            </svg>
          </div>
          <input :value="userTotal + ' R$'" type="text" :disabled="true" />
          <div
            class="logpass-buy__content-form__input-arrow"
            @click="clearRobux()"
          >
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.25 10.75H9.7625L12.6375 7.8875C12.754 7.77095 12.8465 7.63259 12.9096 7.48031C12.9727 7.32803 13.0051 7.16482 13.0051 7C13.0051 6.83518 12.9727 6.67197 12.9096 6.51969C12.8465 6.36741 12.754 6.22905 12.6375 6.1125C12.521 5.99595 12.3826 5.9035 12.2303 5.84043C12.078 5.77735 11.9148 5.74489 11.75 5.74489C11.5852 5.74489 11.422 5.77735 11.2697 5.84043C11.1174 5.9035 10.979 5.99595 10.8625 6.1125L5.8625 11.1125C5.7487 11.2314 5.65949 11.3716 5.6 11.525C5.47498 11.8293 5.47498 12.1707 5.6 12.475C5.65949 12.6284 5.7487 12.7686 5.8625 12.8875L10.8625 17.8875C10.9787 18.0047 11.117 18.0977 11.2693 18.1611C11.4216 18.2246 11.585 18.2572 11.75 18.2572C11.915 18.2572 12.0784 18.2246 12.2307 18.1611C12.383 18.0977 12.5213 18.0047 12.6375 17.8875C12.7547 17.7713 12.8477 17.633 12.9111 17.4807C12.9746 17.3284 13.0072 17.165 13.0072 17C13.0072 16.835 12.9746 16.6716 12.9111 16.5193C12.8477 16.367 12.7547 16.2287 12.6375 16.1125L9.7625 13.25H24.25C24.5815 13.25 24.8995 13.1183 25.1339 12.8839C25.3683 12.6495 25.5 12.3315 25.5 12C25.5 11.6685 25.3683 11.3505 25.1339 11.1161C24.8995 10.8817 24.5815 10.75 24.25 10.75ZM1.75 0.75C1.41848 0.75 1.10054 0.881696 0.866116 1.11612C0.631696 1.35054 0.5 1.66848 0.5 2V22C0.5 22.3315 0.631696 22.6495 0.866116 22.8839C1.10054 23.1183 1.41848 23.25 1.75 23.25C2.08152 23.25 2.39946 23.1183 2.63388 22.8839C2.8683 22.6495 3 22.3315 3 22V2C3 1.66848 2.8683 1.35054 2.63388 1.11612C2.39946 0.881696 2.08152 0.75 1.75 0.75Z"
                fill="#818181"
              />
            </svg>
          </div>
        </div>
        <div class="logpass-buy__content-form__controlles">
          <div
            class="logpass-buy__content-form__controlles-item"
            @click="changeTotal(+1000)"
          >
            <p>+ {{ 1000 }} R$</p>
          </div>
          <div
            class="logpass-buy__content-form__controlles-item"
            @click="changeTotal(+2200)"
          >
            <p>+ {{ 2200 }} R$</p>
          </div>
          <div
            class="logpass-buy__content-form__controlles-item"
            @click="changeTotal(+5100)"
          >
            <p>+ {{ 5100 }} R$</p>
          </div>
          <div
            class="logpass-buy__content-form__controlles-item"
            @click="changeTotal(-1000)"
          >
            <p>- {{ 1000 }} R$</p>
          </div>
          <div
            class="logpass-buy__content-form__controlles-item"
            @click="changeTotal(-2200)"
          >
            <p>- {{ 2200 }} R$</p>
          </div>
          <div
            class="logpass-buy__content-form__controlles-item"
            @click="changeTotal(-5100)"
          >
            <p>- {{ 5100 }} R$</p>
          </div>
          <!-- <div class="logpass-buy__content-form__controlles-item" @click="changeTotal(- purchases.step * 3 )">
            <p>- {{ purchases.step * 3 }} R$</p>
          </div>
          <div class="logpass-buy__content-form__controlles-item" @click="changeTotal(- purchases.step * 4 )">
            <p>- {{ purchases.step * 4 }} R$</p>
          </div> -->
        </div>
      </div>
      <div class="logpass-buy__content-button" v-if="!loader">
        <button @click="buyOrder">{{ $t("actions.buyOrder") }}</button>
        <div class="logpass-buy__content-button__total">
          <p>
            {{ $t("payment.total") }}: <span>{{ userTotal }} R$</span
            ><span
              v-if="$store.state.account.isPremium && userTotal > 0 && false"
            >
              +
              {{ $store.state.account.isPremium ? userTotal / 10 : 0 }} R$</span
            >
          </p>
          <p>
            {{ $t("payment.toPay") }}: <span>{{ totalPrice }} {{ $store.getters.currencySign }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './logPassBuy.scss'

export default {
  name: 'LogPassBuy',
  props: {
    getCookie: {
      type: Function,
      require: true
    },
    getUserId: {
      type: Function,
      require: true
    },
    getPassword: {
      type: Function,
      require: true
    }
  },
  data () {
    return {
      userTotal: 0,
      purchases: {
        min: 1000,
        step: 1100,
        max: 64000,
        limit: 64000
      },
      logpassData: {},
      username: null,
      loader: false
    }
  },
  computed: {
    discount_rbxCourse () {
      const rateType = {
        rub: 'discount_rate',
        usd: 'discount_rate_usd',
        eur: 'discount_rate_eur'
      }

      return this.logpassData[rateType[this.$store.state.currency]]
    },
    rbxCourse () {
      const rateType = {
        rub: 'rate',
        usd: 'rate_usd',
        eur: 'rate_eur'
      }

      return this.logpassData[rateType[this.$store.state.currency]]
    },
    totalPrice () {
      if (this.userTotal >= 8000) {
        return Math.ceil(Number(this.userTotal * this.discount_rbxCourse) * 100) / 100
      }

      return Math.ceil(Number(this.userTotal * this.rbxCourse) * 100) / 100
    }
  },
  methods: {
    changeTotal (amount) {
      if (this.userTotal + amount > this.purchases.limit) {
        return
      }
      if (
        !this.$store.state.account.isPremium &&
        this.userTotal + amount < 400
      ) {
        return
      }
      if (this.userTotal + amount < this.purchases.min) {
        return
      }

      if (
        ((this.userTotal + amount) % 1000) / 100 >
        Math.floor(((this.userTotal + amount) % 10000) / 1000)
      ) {
        return
      }

      if (this.userTotal + amount >= 0) {
        this.userTotal += amount
      }
    },
    getUserName: async function () {
      const response = await fetch(`https://logpass.backend-pier.com/api/v1/roblox/get_username?user_id=${this.getUserId()}`)

      const data = await response.json()
      this.username = data.username
    },
    changeModal (to) {
      if (to === 'replenishment') {
        this.$emit('changeModal', { modal: 'replenishment' })
        return
      }
      if (to === 'logPassOrders') {
        this.$emit('changeModal', { modal: 'logPassOrders' })
        return
      }
      if (to === 'back') {
        // if (this.$store.state.account.isPremium) {
        //   this.$emit('changeModal', { modal: 'confirmAccount' })
        //   return
        // }
        // this.$emit('changeModal', { modal: 'premiumSubscribe' })

        this.$emit('changeModal', { modal: 'confirmAccount' })
      }
    },
    clearRobux () {
      // if (!(this.$store.state.account.isPremium)) {
      //   this.userTotal = 400
      //   this.totalPrice = this.userTotal * this.rbxCourse
      //   return
      // }
      this.userTotal = this.purchases.min
    },
    getRates: async function () {
      const userId = localStorage.getItem('user_id') || 'default'
      const response = await fetch(`https://prices.backend-pier.com/get_rates/${userId}`)

      const data = await response.json()
      this.logpassData = data.logpass

      this.changeTotal(1000)
    },
    buyOrder: async function () {
      this.loader = true
      if (!this.$store.state.isAuth) {
        this.$store.commit('stateSignIn')
        this.loader = false
        return
      }

      const isMobile = Boolean(this.$store.state.isMobile)

      const body = JSON.stringify({
        username: this.username,
        cookie: isMobile ? null : this.getCookie(),
        is_quick: isMobile,
        robux_amount:
            this.userTotal +
            (this.$store.state.account.isPremium ? this.userTotal / 10 : 0),
        method: 'logpass_normal',
        is_paid: this.$store.getters.balance >= this.totalPrice,
        password: isMobile ? this.getPassword() : null,
        currency: this.$store.state.currency.toUpperCase()
      })

      const response = await fetch(
        'https://logpass.backend-pier.com/api/v1/orders/create_order',
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.$cookies.get('access_token')}`
          }
        }
      )

      const data = await response.json()
      if (data.status) {
        this.changeModal('logPassOrders')
        this.loader = false
      }

      if (data.is_paid === false) {
        this.$store.commit('setPaymentData', { order: data, methodType: 2 })
        this.changeModal('replenishment')
        this.loader = false
      }
    }
  },
  created () {
    this.loader = true
    // fetch('https://logpass.backend-pier.com/api/v1/orders/fetch_robux_bounds')
    //   .then(res => res.json())
    //   .then(data => {
    //     this.purchases = data.purchases
    //     if (this.$store.state.account.isPremium) {
    //       this.changeTotal(80)
    //     } else {
    //       this.changeTotal(400)
    //     }
    //   })

    this.getRates().then()
    this.getUserName().then()
    this.loader = false
  }
}
</script>
