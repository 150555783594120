<template>
  <footer class="footer">
    <div class="footer-wrapper">
      <router-link to="/" class="footer-wrapper__img">
        <Logo />
      </router-link>
      <div class="right-block">
        <img v-if="isCountryRu($store.state.ipCountry)" class="right-block__img" src="../../assets/images/footer/address.svg" alt="address">
        <router-link class="footer-link" to="/privacy">{{ $t('footer.privacy') }}</router-link>
        <router-link class="footer-link" to="/termsofuse">{{ $t('footer.terms') }}</router-link>
        <router-link class="footer-link" to="/contacts">{{ $t('footer.contacts') }}</router-link>
      </div>
    </div>
    <div class="footer-middle">
      <div class="payment-block">
        <router-link to="/payment" id="masterCard">
          <img src="../../assets/images/footer/MasterCard-color.svg" alt="MasterCard">
        </router-link>
        <router-link to="/payment" id="Visa">
          <img src="../../assets/images/footer/Visa-color.svg" alt="Visa">
        </router-link>
      </div>
      <small v-if="!isCountryFromRu($store.state.ipCountry)">
      </small>
      <p>ROBUXPIER ©2018-2024</p>
    </div>
    <a href="//freekassa.com/" target="_blank" rel="noopener noreferrer" style="display: none;">
      <img src="//cdn.freekassa.com/banners/small-dark-1.png" title="Прием платежей">
    </a>
  </footer>
</template>

<script>
import './footer.scss'
import ipUtil from './../../utils/ip'
import Logo from '@/components/Logo/Logo.vue'

export default {
  name: 'Footer',
  components: {
    Logo
  },
  methods: {
    isCountryFromRu (c) {
      return ipUtil.isCountryInRuRegion(c)
    },
    isCountryRu (c) {
      return ipUtil.isCountryRu(c)
    }
  }
}
</script>
