<template>
  <div
    class="user-menu"
    :class="{open: $store.state.isOpenUserDropMenu}"
  >
    <div class="user-menu__wrapper">
      <div class="user-menu__wrapper-header">
        <img
          @click="$store.state.isOpenUserDropMenu = false"
          src="../../assets/images/userDropMenu/close.svg"
          alt="Close"
        >
        <p class="user-menu__wrapper-header__nick">{{ $store.state.account.username }}</p>
      </div>
      <div class="userMenu burgerMenu-wrapper__content">
        <ul class="burgerMenu-wrapper__content-list">
          <li  class="burgerMenu-wrapper__content-list__item"><router-link to="/history">{{ $t('navigation.dropdown.history') }}</router-link></li>
          <li v-if="$store.state.account.isVk !== 1" class="burgerMenu-wrapper__content-list__item"><p @click="$store.commit('stateChangePassword')">{{ $t('navigation.dropdown.changePassword') }}</p></li>
          <li class="burgerMenu-wrapper__content-list__item"><p @click.stop="copyText($store.state.account.userId); $store.state.isOpenUserDropMenu = false">{{ $t('navigation.dropdown.copyId') }}</p></li>
        </ul>
      </div>
      <div class="user-menu__wrapper-footer" @click="$store.dispatch('logOut')">
        <div class="user-menu__wrapper-footer__off">
          <div class="user-menu__wrapper-footer__circle">
            <svg width="52" height="57" viewBox="0 0 52 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M36.2276 7.08264C35.051 6.56858 33.6805 7.10574 33.1664 8.28239C32.6523 9.45904 33.1895 10.8296 34.3662 11.3436C41.9307 14.6483 46.9237 22.1301 46.9237 30.5278C46.9237 42.0834 37.5545 51.4519 25.9996 51.4519C14.4432 51.4519 5.0755 42.0843 5.0755 30.5278C5.0755 22.1319 10.0668 14.6502 17.6291 11.3433C18.8055 10.8289 19.3422 9.45817 18.8277 8.28174C18.3134 7.1053 16.9426 6.56858 15.7662 7.08307C6.52591 11.1235 0.425781 20.2675 0.425781 30.5277C0.425781 44.6521 11.8753 56.1016 25.9997 56.1016C40.1226 56.1016 51.5736 44.6514 51.5736 30.5277C51.5736 20.2651 45.471 11.1207 36.2276 7.08264Z" fill="white"/>
              <path d="M25.9997 25.8784C27.2837 25.8784 28.3245 24.8375 28.3245 23.5535V2.62946C28.3246 1.34535 27.2837 0.304489 25.9997 0.304489C24.7157 0.304489 23.6748 1.34535 23.6748 2.62935V23.5534C23.6748 24.8374 24.7157 25.8784 25.9997 25.8784Z" fill="white"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './userDropMenu.scss'
import { copyText } from '@/utils/copyText'
export default {
  name: 'UserDropMenu',
  methods: { copyText },
  mounted () {
    const links = document.querySelectorAll('.burgerMenu-wrapper__content-list__item')
    const logOut = document.querySelector('.user-menu__wrapper-footer')
    logOut.addEventListener('click', () => {
      this.$store.state.isOpenUserDropMenu = false
    })
    links.forEach(link => {
      link.addEventListener('click', () => {
        this.$store.state.isOpenUserDropMenu = false
      })
    })
  }
}
</script>
