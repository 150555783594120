<template>
  <img
    v-bind="$attrs"
    :src="require(`@/assets/images${name}`)"
    :alt="`icon-${name}`"
    :class="`icon ${size}`"
    @click="$emit('click')">
</template>
<script>
import './icon.scss'
export default {
  name: 'Icon',
  props: {
    name: {
      value: String,
      default: ''
    },
    size: {
      value: String,
      default: 'md'
    }
  }
}
</script>
