<template>
  <div class="website-logo" :class="{dark}">
    <img src="../../assets/images/Home/anchor.svg" alt="Logo" />
    <div class="website-logo__text">
      <span class="website-logo__text__name">RobuxPier</span>
      <span class="website-logo__text__subtext">{{ $t('navigation.logoText') }}</span>
    </div>
  </div>
</template>

<script>
import './logo.scss'

import { mapGetters } from 'vuex'
export default {
  name: 'Logo',
  props: {
    dark: {
      type: Boolean,
      require: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    isRussian () {
      return this.language === 'ru'
    }
  }
}
</script>
