import policy from '../policy/en'
import terms from '../terms/en'

export default {
  navigation: {
    title: 'NAVEGACIÓN',
    home: 'PRINCIPAL',
    buyRobux: 'COMPRAR LOS ROBUX',
    lk: 'CUENTA PERSONAL',
    vk: 'Grupo VK',
    discord: 'Discord',
    telegram: 'Telegram',
    instagram: 'Instagram',
    tiktok: 'TikTok',
    dropdown: {
      history: 'Historia',
      changePassword: 'Cambiar la contraseña',
      refill: 'Recargar',
      logout: 'Salir'
    }
  },
  general: {
    warning: '¡Atención!',
    error: 'Error',
    gotIt: 'Entendido',
    confirm: 'CONFIRMACIÓN',
    or: 'o',
    link: 'por el enlace',
    transfer: 'transferencia',
    policy: 'POLÍTICA DE PRIVACIDAD',
    terms: 'CONDICIONES DE USO',

    errors: {
      internal: 'Error interno, inténtalo de nuevo más tarde',
      server: 'Error del servidor'
    }
  },
  actions: {
    continue: 'Continuar',
    submit: 'CONFIRMAR',
    buyRobux: 'COMPRAR ROBUX',
    openDiscord: 'IR A DISCORD',
    openInstruction: 'IR A LAS INSTRUCCIONES',
    createAccount: 'Crear la cuenta',
    login: 'ENTRAR',
    loginVK: 'Entrar a trávez de VK',
    signin: 'Entrar',
    enterPassword: 'Ingresarlo',
    skip: 'Omitir',
    buy: 'Obtener',
    buyOrder: 'Comprar',
    tryAgain: 'Repetir de nuevo',
    create: 'Create',
    ready: 'Ready',
    edit: 'Edit',
    apply: 'Aplicar',
    promocode: {
      default: 'Código promocional no aplicado',
      success: 'Código promocional aplicado',
      error: 'No se pudo aplicar el código promocional',
      minError: 'Cantidad mínima para activar el código promocional {min} R$',
      maxError: 'Cantidad máxima para activar el código promocional {max} R$',
      isNoPromo: 'Este código promocional no existe!',
      promoExpired: '¡Se acabó el código promocional!',
      notAuth: '¡Se requiere autorización!'
    }
  },
  payment: {
    refill: 'Recargar saldo',
    total: 'Total',
    toPay: 'A pagar',
    pay: 'PAGAR ',
    makeOrder: 'Pagar por el pedido',
    otherPaymentMethods: 'Otros métodos de pago',
    enterSum: 'Ingresar el monto de la recarga',
    commission: 'Comisión',
    notEnough: '¡No tienes suficientes fondos para pagar!',
    problems: 'En caso de problemas, escriba a nuestro {support}',
    support: 'Atención al cliente',
    selectPaymentMethod: 'SELECCIONAR UN MÉTODO DE PAGO'
  },
  order: {
    status: 'ESTADO DEL PEDIDO',
    wait: 'POR FAVOR ESPERE su orden',
    waitTime: 'QUIEDA',
    code: 'El código debe tener 6 dígitos',
    mailCode: 'Introducir el código del correo.',
    makeNewOrder: 'Hacer otro pedido',
    table: {
      nickname: 'NICK DEL JUEGO',
      count: 'CANTIDAD DE R$',
      total: 'TOTAL, RUB'
    },
    descriptions: {
      wait: 'Esperar la ejecución de la orden',
      notEnoughFunds: 'Error. ¡El sitio no tiene fondos suficientes!',
      systemError: '¡Error del sistema!',
      checkVip: '¡Comprueba tu configuración VIP ROBLOX!',
      connectionError: '¡Error de comunicación con ROBLOX!',
      success: '¡Orden está completado con éxito!',
      checkGamepass: '¡Comprueba la configuración de tu Gamepass!'
    }
  },
  auth: {
    authorization: 'Autorización',
    registration: 'Registro',
    recovery: 'RECUPERACIÓN',
    change: 'CAMBIAR LA CONTRASEÑA',

    inputs: {
      email: 'Ingresar correo electrónico',
      login: 'Ingresar el nombre de usuario',
      loginOrEmail: 'Ingresar el nombre de usuario o correo electrónico',
      currentPassword: 'Contraseña actual',
      newPassword: 'Nueva contraseña',
      repeatNewPassword: 'Repetir la nueva contraseña',
      password: 'Introducir la contraseña',
      passwordSubmit: 'Confirmar la contraseña'
    },

    errors: {
      email: '¡Email inválido!',
      incorrectPassword: '¡Contraseña incorrecta!',
      empty: 'El campo esta vacio',
      nicknameIsTooSmall: 'Debes proporcionar un nombre con al menos 4 caracteres',
      inactiveEmail: 'El correo electrónico es invalido',
      passwordIsTooSmall: 'Tu contraseña debe tener al menos 6 caracteres',
      passwordNotMatch: 'Las contraseñas no coinciden',
      oneUppercase: 'Tu contraseña debe tener al menos una letra mayúscula',
      userExist: 'El usuario con dichos datos ya existe',
      checkEmail: 'Asegúrate de que tu correo electrónico/nombre de usuario sea correcto',
      checkPassword: 'Asegúrate de que tu contraseña sea correcta',
      changePasswordIncorrect: '¡La contraseña debe tener entre 6 y 18 caracteres y constar de las letras “A-z”!'
    },

    welcome: {
      title: 'BIENVENIDOS',
      content: 'REGÍSTRATE EN NUESTRO SITIO Y COMIENCA A COMPRAR ROBUX DE MANERA SEGURA, RÁPIDA Y CON SEGURIDAD'
    },

    forget: '¿Olvidaste tu contraseña?',
    remember: '¿Has recordado tu contraseña?',
    agreement: 'Estoy de acuerdo con {policy}',
    privacyPolicy: 'política de privacidad',
    haveAccount: 'Ya tienes una cuenta?',
    codeSent: 'A tu {email} ha sido enviado el código de recuperación de contraseña!',
    enterCode: 'Ingresar el código de 6 dígitos:',
    requestCode: 'Solicitar de nuevo',

    changePasswordSuccess: {
      title: '¡Excelente!',
      content: '¡Has cambiado satisfactoriamente tu contraseña! Asegúrate de recordar...'
    }
  },
  slider: {
    slide1: {
      title: '¡COMPRA ROBUX DE FORMA SEGURA!',
      content: 'Toda la moneda del juego se obtiene legalmente de los desarrolladores de juegos.'
    },
    slide2: {
      title: '¡LOS MEJORES PRECIOS!',
      content: '¡Solo en nuestra tienda puede comprar robux a los precios más bajos y tiene la garantía de obtenerlos!'
    },
    slide3: {
      title: '¡LOS CLIENTES CONFÍAN EN NOSOTROS!',
      content: '¡Nuestra tienda ha estado operando durante tres años y durante este tiempo se ha obtenido la fama!'
    }
  },
  faq: {
    title: '¿QUIERES COMPRAR ROBUX?',
    content: 'PRECIOS DE CATEGORIA, ENTREGA RÁPIDA!',
    items: {
      item1: {
        index: '01',
        title: 'HAZ PEDIDO',
        content: 'Elegir cuántos robux quieres comprar y tu cuenta de roblox'
      },
      item2: {
        index: '02',
        title: 'Pago',
        content: 'Paga el pedido de cualquier manera conveniente'
      },
      item3: {
        index: '03',
        title: 'OBTEN ROBUX',
        content: '¡Obten robux en tu cuenta de juego en 5 días!'
      }
    }
  },
  help: {
    title: '¿Tienes problemas con la compra de Robux?',
    text1: '¡Nuestro soporte definitivamente te ayudará con ellos en el servidor en discordia o en mensajes personales de Instagram!',
    text2: 'También puedes utilizar las instrucciones'
  },
  footer: {
    privacy: 'POLÍTICA DE PRIVACIDAD',
    terms: 'CONDICIONES DE USO',
    contacts: 'INFORMACIÓN DEL CONTACTO'
  },
  methods: {
    title: '¿QUÉ MÉTODO DE COMPRA QUIERES UTILIZAR?',
    hintError: {
      title: '¡Error!',
      content: '¡Ya tienes Roblox Premium!'
    },
    method: 'Método',
    robux: 'Robux',
    premium: 'Premium',
    transfer: 'Transfers',
    logpass: 'log+pass',
    available: 'Hay',
    notAvailable: 'No hay'
  },
  cookie: {
    whatIsCookie: '¿Qué es una COOKIE?',
    checkInstruction: 'LEA LAS INSTRUCCIONES DE LAS COOKIES',
    enter: 'Introducir las Cookies',
    description: {
      part1: 'Las cookies en sí mismas no son peligrosas, son archivos de texto normales. No pueden iniciar procesos en el ordenador y generalmente interactúar con el sistema operativo. Pero pueden ser interceptados o robados para rastrear tus actividades en línea anteriores o iniciar sesión en tus cuentas sin autorización.',
      part2: 'Por lo general, la información que se escribe en las cookies se cifra antes de enviarse y las cookies en sí se transmiten a través del protocolo HTTPS. Esto ayuda a proteger los datos del usuario, pero depende del desarrollador del sitio implementar el cifrado y enviarlo de forma segura. Los visitantes solo pueden esperar que todo esté configurado correctamente. Por su parte, el usuario sólo puede impedir que el navegador utilice cookies o borrarlas cada cierto tiempo.'
    }
  },
  transfer: {
    robuxDelivery: 'Robux se acreditará dentro de los 5 días (120 horas)',
    available: 'Hay:',
    robuxCount: 'Número de robux',
    minumum: 'Mínimo de {min} R$',
    nickname: 'Apodo del juego',
    notFound: 'No se encontró nada',
    checkPlaces: 'POR FAVOR COMPRUEBA QUE HAYA CREADO AL MENOS 1 LUGAR QUE ES PÚBLICO',
    vipPrice: 'Establezca el precio del servidor VIP en {price}',
    changePrice: 'Cambiar el precio',
    orderSuccess: 'Puedes rastrear el recibo de robux en tu cuenta {here}',
    orderError: 'Los fondos fueron devueltos al saldo de la cuenta ROBUXPIER. Intentar otra vez :)',
    here: 'aquí',
    gamepass: {
      checkData: 'Check the correctness of the data in Gamepass!',
      creating: 'CREATING',
      settingUp: 'SETUP',
      createGamepass: 'Create a Game-pass',
      checkInstruction: 'View the instructions and set the price <span>{price} R$</span>',
      serverError: 'Something went wrong. Try again.'
    },
    errors: {
      insufficientBalance: 'Saldo insuficiente',
      notAuthorized: 'Usted no está autorizado',
      serverError: 'Error en el servidor',
      robuxNotAvailable: 'El número de robux no está disponible para comprar',

      priceNotNumber: 'El precio debe ser un número',
      robuxMinimum: 'No puedo pedir menos de {robux} robux',
      robuxMaximun: 'La cantidad máxima de compra está limitada temporalmente a 5K R$',
      halfBalance: 'No se puede pedir más de la mitad del saldo: {balance} robux',
      robuxMustDivide: 'R$ debe ser dividido por {orders}. Probar <span class="min">{closestMin}R$</span> o <span class="max">{closestMax}R$</span>'
    },
    selectYourPlace: 'Elige tu'
  },
  logpass: {
    enterNickname: 'INTRODUCIR APODO',
    buyRobuxTitle: 'COMPRAR robux',
    codes: {
      code_request: 'Por favor ingresa el código que fue enviado a tu correo electrónico',
      order_awaiting: 'Esperar la ejecución de la orden',
      code_request_awaiting: 'Esperar una solicitud de código del correo',

      password_error: 'Contraseña en ROBLOX es incorrecta',
      code_error: 'Código de verificación de dos pasos del correo es incorrecto',
      cookie_error: 'Error. Datos de autorización no válidos',
      email_timeout_error: '¡No proporcionaste un código! ¡Repita el pedido e intente ingresar el código lo antes posible!',
      error: 'Error. Intentar otra vez',
      success: 'Pedido completado con éxito',
      waiting: 'Esperar la ejecución de la orden'
    }
  },
  premium: {
    buyPremium: 'COMPRAR PREMIUM',
    alreadyExist: '¡Ya tienes Roblox Premium!',
    subscribe: {
      title: '{percent} A ROBUX!',
      subtitle: 'con suscripción Premium obtendrás más robux '
    },
    getRbx: 'Obten {rbx} a la cuenta',
    buyWithProfit: '¡COMPRA CON BENEFICIO!',
    currencyPerMonth: '{currency} al mes',
    toPay: 'Total a pagar: ',
    premiumTime: 'La suscripción premium se compra solo por {time}',
    oneMonth: '1 mes sin renovación automática',
    enterPromocode: 'Introducir el código de promoción',
    ordering: 'ORDENAR EL PEDIDO',
    mailCode: 'POR FAVOR ESPERAR LA SOLICITUD DEL CÓDIGO DE CORREO',

    correctSum: 'Introducir la cantidad correcta',
    minRefillSum: 'El monto de la reposición debe ser de al menos 5 rublos'
  },
  supportChat: {
    hint: '¿Tienes una pregunta?',
    title: 'SOPORTE AL JUGADOR',
    operator: 'Tu asistente',
    actions: 'Opciones de acción',
    anket: 'Preséntate para contactar con el operador. Definitivamente obtendrás respuestas a las preguntas relacionadas con RobuxPier.',
    name: 'Nombre',
    email: 'E-mail',
    vk: 'Enlace a VK',
    send: 'Enviar',
    enterMessage: 'Tu mensaje...'
  },
  qiwi: {
    details: 'DETALLES DE PAGO',
    passport: '¿Tienes un pasaporte ruso?',
    noPassport: 'Como no tienes un pasaporte ruso, no podrás pasar la verificación en el sistema de pago de Qiwi.',
    hasPassport: 'Debes pasar la verificación en el sitio web de Qiwi para que la comisión sea menor.',
    noPassportCommission: 'La comisión será 7.5%',
    hasPassportCommission: 'La comisión será 3%',
    yes: 'Si',
    no: 'No'
  },
  history: {
    nickname: 'Apodo del juego',
    sumRobux: 'Monto, R$',
    sumRub: 'monto',
    status: 'Estado',
    description: 'Descripción',
    timer: 'Temporizador',

    error: 'Error',
    wait: 'Esperar',
    success: 'Con exíto',
    orderCount: 'Mostrados {order} de {length} grabaciones'
  },
  policy,
  terms
}
