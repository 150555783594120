<template>
    <div class="logpass"
        :class="{'replenishment-style': addReplenishmentStyle}">
      <Auth />
      <div class="wrapper">
        <Navigation />
      </div>
      <main class="logpass-main">
        <PremiumLoader v-if="!isLoaded" />
        <InstructionCookie :setCookie="setCookie" :setUserId="setUserId" :methodType="'LogPass'" v-if="stage === 'instructionCookie' && isLoaded" @changeModal="changeModal" />
        <ChoiceAccount
          :methodType="'LogPass'"
          @changeModal='changeModal'
          v-if="stage == 'choice' && isLoaded"
          :setUserName="setUserName"
          :setUserId="setUserId"
        />
        <ConfirmAccount
          :methodType="'LogPass'"
          @changeModal='changeModal'
          v-if="stage == 'confirmAccount' && isLoaded"
          :getUserName="getUserName"
          :getUserId="getUserId"
          :setPassword="setPassword"
        />
<!--        <LogPassPremiumSubscribe v-if="stage === 'premiumSubscribe' && isLoaded" @changeModal="changeModal" />-->
        <LogPassBuy :getCookie="getCookie" :getUserId="getUserId" :getPassword="getPassword" v-if="stage === 'logPassBuy' && isLoaded" @changeModal="changeModal" />
        <PremiumOrdering :methodType="'LogPass'" v-if="stage === 'replenishment' && isLoaded" @changeModal="changeModal" />
        <LogPassOrders :getOrders="getOrders" v-if="stage === 'logPassOrders' && isLoaded" @changeModal="changeModal" :orders="orders"/>
      </main>
    </div>
</template>

<script>
import './logPass.scss'

import Auth from '@/components/Auth/Auth'
import Navigation from '@/components/Navigation/Navigation'

import PremiumLoader from '@/components/Premium/PremiumLoader/PremiumLoader'
import PremiumOrdering from '@/components/Premium/PremiumOrdering/PremiumOrdering.vue'

import InstructionCookie from '@/components/InstructionCookie/InstructionCookie'
import ChoiceAccount from '@/components/ChoiceAccount/ChoiceAccount.vue'
import ConfirmAccount from '@/components/ConfirmAccount/ConfirmAccount.vue'
import LogPassBuy from '@/components/LogPass/LogPassBuy/LogPassBuy'
import LogPassOrders from '@/components/LogPass/LogPassOrders/LogPassOrders'

export default {
  name: 'LogPass',
  components: {
    Auth,
    Navigation,
    InstructionCookie,
    ChoiceAccount,
    ConfirmAccount,
    PremiumLoader,
    LogPassBuy,
    LogPassOrders,
    PremiumOrdering
  },
  computed: {
    addReplenishmentStyle () {
      if (this.stage === 'replenishment' && this.isLoaded && document.documentElement.clientWidth > 1150 && document.documentElement.clientHeight < 990) return true
      else return false
    }
  },
  data () {
    return {
      isLoaded: true,
      stage: 'instructionCookie',
      cookie: null,
      userId: null,
      username: null,
      password: null,
      orders: null,
      intervalOrders: null
    }
  },
  methods: {
    changeModal (data) {
      this.isLoaded = false
      this.stage = data.modal
      if (data.modal === 'logPassOrders') {
        this.getOrders().then((data) => {
          this.isLoaded = true
          this.orders = data.orders
        })
        this.checkOrders()
      }
      setTimeout(() => {
        this.isLoaded = true
      }, 2000)
    },
    setCookie (cookie) {
      this.cookie = cookie
    },
    setUserId (userId) {
      this.userId = userId
    },
    setUserName (username) {
      this.username = username
    },
    setPassword (password) {
      this.password = password
    },
    getCookie () {
      return this.cookie
    },
    getUserId () {
      return this.userId
    },
    getUserName () {
      return this.username
    },
    getPassword () {
      return this.password
    },
    getOrders: async function () {
      const response = await fetch('https://logpass.backend-pier.com/api/v1/orders/fetch_last_orders/roblox', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$cookies.get('access_token')}`
        }
      })

      const data = await response.json()
      this.orders = data.orders
      return data
    },
    checkOrders () {
      this.intervalOrders = setInterval(() => {
        this.getOrders().then((data) => {
          this.orders = data.orders
        })
      }, 10000)
    }
  },
  created () {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      this.$store.state.isMobile = true
      this.stage = 'choice'
    }

    this.$store.state.isMobile = true
    this.stage = 'choice'
  },
  mounted () {
    this.isLoaded = false
    if (this.$cookies.get('access_token')) {
      this.$store.dispatch('authorization', this.$cookies.get('access_token'))
      this.getOrders().then((data) => {
        this.isLoaded = true
        if (data.orders.length > 0) {
          this.stage = 'logPassOrders'
          this.checkOrders()
        }
        this.orders = data.orders
      })
    } else {
      this.isLoaded = true
    }
  }
}
</script>
