export default `REVISÃO DE 03.11.2021

ESTA POLÍTICA DE PRIVACIDADE DE DADOS PESSOAIS (SEGUINTE - POLÍTICA DE PRIVACIDADE) APLICA-SE A TODAS AS INFORMAÇÕES, QUE A LOJA ONLINE «ROBUXPIER.IO» ESTÁ LOCALIZADO EM UM NOME DE DOMÍNIO WWW.ROBUXPIER.IO, PODE OBTER SOBRE O USUÁRIO DURANTE O USO DO SITE DA LOJA ONLINE, PROGRAMAS E PRODUTOS DA LOJA ONLINE.

<b>1. DEFINIÇÃO DOS TERMOS</b>

1.1. OS SEGUINTES TERMOS SÃO UTILIZADOS NESTA POLÍTICA DE PRIVACIDADE:

1.1.1. «ADMINISTRAÇÃO DO SITE DA LOJA ONLINE (DORAVANTE – ADMINISTRAÇÃO DO SITE)» - FUNCIONÁRIOS AUTORIZADOS NA GESTÃO DO SITE, AGINDO EM NOME ROBUXPIER.IO, QUE ORGANIZAM E/OU PROCESSAM DADOS PESSOAIS, BEM COMO DETERMINAM OS OBJETIVOS DO PROCESSAMENTO DE DADOS PESSOAIS, A COMPOSIÇÃO DOS DADOS PESSOAIS A SEREM PROCESSADOS, AS AÇÕES (OPERAÇÕES) REALIZADAS COM DADOS PESSOAIS.

1.1.2. «DADOS PESSOAIS» - QUALQUER INFORMAÇÃO, RELATIVA A UMA PESSOA SINGULAR DIRETA OU INDIRETAMENTE IDENTIFICADA, OU IDENTIFICÁVEL (TITULAR DOS DADOS PESSOAIS).

1.1.3. «TRATAMENTO DE DADOS PESSOAIS» - QUALQUER AÇÃO (OPERAÇÃO) OU UM CONJUNTO DE AÇÕES (OPERAÇÕES) REALIZADAS COM OU SEM A UTILIZAÇÃO DE AUTOMATIZAÇÃO COM DADOS PESSOAIS, INCLUINDO RECOLHA, REGISTO, SISTEMATIZAÇÃO, ACUMULAÇÃO, ARMAZENAMENTO, CLARIFICAÇÃO (ATUALIZAÇÃO, MODIFICAÇÃO), EXTRAÇÃO, UTILIZAÇÃO, TRANSFERÊNCIA (DISTRIBUIÇÃO, FORNECIMENTO, ACESSO), DESPERSONALIZAÇÃO, BLOQUEIO, APAGAMENTO, DESTRUIÇÃO DE DADOS PESSOAIS.

1.1.4. «CONFIDENCIALIDADE DOS DADOS PESSOAIS» - SOLICITAÇÃO AO OPERADOR OU A OUTRA PESSOA QUE TENHA ACESSO AOS DADOS PESSOAIS IMPEDIR A SUA DIVULGAÇÃO SEM O CONSENTIMENTO DO SUJEITO DOS DADOS PESSOAIS OU QUALQUER OUTRA BASE JURÍDICA.

1.1.5. «USUÁRIO DO SITE DA LOJA ONLINE (DORAVANTE – USUÁRIO)» – UMA PESSOA, QUE TEM ACESSO AO SITE ATRAVÉS DA INTERNET E USA O SITE DA LOJA ONLINE.

1.1.6. «COOKIES» — UM PEQUENO PEDAÇO DE DADOS ENVIADO POR UM SERVIDOR DA WEB E ARMAZENADO NO COMPUTADOR DO USUÁRIO, QUE UM CLIENTE DA WEB OU NAVEGADOR DA WEB ENVIA AO SERVIDOR DA WEB CADA VEZ EM UMA SOLICITAÇÃO HTTP AO TENTAR ACESSAR UMA PÁGINA DO SITE EM QUESTÃO.

1.1.7. «IP-ENDEREÇO» — ENDEREÇO DE REDE EXCLUSIVO DE UM HOST EM UMA REDE DE COMPUTADORES CONSTRUÍDA SOBRE O PROTOCOLO IP.

<b>2. DISPOSIÇÕES GERAIS</b>

2.1. O USO PELO USUÁRIO DO SITE DA LOJA ONLINE CONSTITUI ACEITAÇÃO DESTA POLÍTICA DE PRIVACIDADE E DOS TERMOS DE PROCESSAMENTO DE DADOS PESSOAIS DO USUÁRIO.

2.2. EM CASO DE DESACORDO COM OS TERMOS DA POLÍTICA DE PRIVACIDADE, O USUÁRIO DEVE PARAR DE USAR O SITE DA LOJA ONLINE.

2.3. ESTA POLÍTICA DE PRIVACIDADE APLICA-SE APENAS AO SITE DA LOJA ONLINE ROBUXPIER.IO. A LOJA ONLINE NÃO CONTROLA E NÃO É RESPONSÁVEL POR SITES DE TERCEIROS AOS QUAIS O USUÁRIO PODE CLICAR NOS LINKS DISPONÍVEIS NO SITE DA LOJA ONLINE.

2.4. A ADMINISTRAÇÃO DO SITE NÃO VERIFICA A AUTENTICIDADE DOS DADOS PESSOAIS FORNECIDOS PELO USUÁRIO DO SITE DA LOJA ONLINE.

2.5. O PAGAMENTO DA ORDEM É POSSÍVEL COM CARTÕES BANCÁRIOS VISA, MASTER CARD, «MIR» OU ATRAVÉS DOS SISTEMAS DE PAGAMENTO APPLE PAY, YOOMONEY, QIWI OU PAYPAL (ESTE ÚLTIMO — APENAS PARA COMPRADORES DE FORA DA FEDERAÇÃO RUSSA). PARA PAGAR A COMPRA, VOCÊ SERÁ REDIRECIONADO PARA O SERVIDOR DO SISTEMA DE PAGAMENTO UNITPAY, ONDE VOCÊ PRECISARÁ INSERIR OS DADOS NECESSÁRIOS. AO PAGAR COM CARTÃO DE CRÉDITO, A SEGURANÇA DOS PAGAMENTOS É GARANTIDA PELO CENTRO DE PROCESSAMENTO UNITPAY.

O SISTEMA DE PAGAMENTO UNITPAY POSSUI UM CERTIFICADO COMPROVADO DE CONFORMIDADE COM OS REQUISITOS DO PADRÃO PCI DSS PARA ARMAZENAMENTO, PROCESSAMENTO E TRANSFERÊNCIA DE DADOS DO TITULAR DO CARTÃO. O PADRÃO DE SEGURANÇA DE CARTÕES BANCÁRIOS PCI DSS É SUPORTADO POR SISTEMAS DE PAGAMENTO INTERNACIONAIS, INCLUINDO MASTERCARD E VISA, INC. A UNITPAY TAMBÉM É MEMBRO DO PROGRAMA DE CONFORMIDADE CONTÍNUA COMPLIANCE CONTROL PCI DSS COMPLIANCE PROCESS (P. D. C. P.). SEUS DADOS CONFIDENCIAIS NECESSÁRIOS PARA O PAGAMENTO (DETALHES DO CARTÃO, DADOS DE REGISTRO, ETC.) NÃO CHEGAM À LOJA ONLINE-SEU PROCESSAMENTO É REALIZADO NO LADO DO CENTRO DE PROCESSAMENTO UNITPAY E É TOTALMENTE PROTEGIDO.

<b>3. OBJECTO DA POLÍTICA DE PRIVACIDADE</b>

3.1. ESTA POLÍTICA DE PRIVACIDADE ESTABELECE AS OBRIGAÇÕES DA ADMINISTRAÇÃO DO SITE DA LOJA ONLINE PARA GARANTIR A CONFIDENCIALIDADE E SEGURANÇA DOS DADOS PESSOAIS, QUE O UTILIZADOR FORNECE A PEDIDO DA ADMINISTRAÇÃO DO SITE QUANDO SE REGISTA NO SITE DA LOJA ONLINE OU AO FAZER UM PEDIDO PARA COMPRAR UM PRODUTO.

3.2. OS DADOS PESSOAIS AUTORIZADOS PARA PROCESSAMENTO NO ÂMBITO DESTA POLÍTICA DE PRIVACIDADE SÃO FORNECIDOS PELO USUÁRIO ATRAVÉS DO PREENCHIMENTO DO FORMULÁRIO DE REGISTRO NO SITE DA LOJA ONLINE ROBUXPIER.IO NA SEÇÃO TÍTULO DA SEÇÃO E INCLUEM AS SEGUINTES INFORMAÇÕES:

3.2.1. APELIDO, NOME DE USUÁRIO

3.2.2. E-MAIL ADRESSE (E-MAIL)

3.3. A LOJA ONLINE PROTEGE OS DADOS QUE SÃO TRANSMITIDOS AUTOMATICAMENTE DURANTE A VISUALIZAÇÃO DE BLOCOS DE ANÚNCIOS E AO VISITAR PÁGINAS:

· IP ENDEREÇO;

· INFORMAÇÕES DE COOKIES;

· INFORMAÇÕES SOBRE O NAVEGADOR (OU OUTRO PROGRAMA, QUE REALIZA O ACESSO EXIBIÇÃO DE ANÚNCIOS);

· TEMPO DE ACESSO;

· O ENDEREÇO DA PÁGINA EM QUE O BLOCO DE ANÚNCIOS ESTÁ LOCALIZADO;

· REFERENCIADOR (ENDEREÇO DA PÁGINA ANTERIOR).

3.3.1. A DESATIVAÇÃO DE COOKIES PODE RESULTAR NA IMPOSSIBILIDADE DE ACESSO A PARTES DO SITE DA LOJA ONLINE QUE REQUEREM AUTORIZAÇÃO.

3.3.2. A LOJA ONLINE COLETA ESTATÍSTICAS SOBRE OS ENDEREÇOS IP DE SEUS VISITANTES. ESSAS INFORMAÇÕES SÃO USADAS PARA IDENTIFICAR E RESOLVER PROBLEMAS TÉCNICOS, PARA CONTROLAR A LEGALIDADE DOS PAGAMENTOS FINANCEIROS REALIZADOS.

3.4. QUAISQUER OUTRAS INFORMAÇÕES PESSOAIS NÃO ESTIPULADAS ACIMA (HISTÓRICO DE COMPRAS, NAVEGADORES E SISTEMAS OPERATIVOS UTILIZADOS, E ASSIM POR DIANTE) DEVEM SER GUARDADAS EM SEGURANÇA E NÃO DEVEM SER DIVULGADAS, EXCEPTO CONFORME PREVISTO NAS CLÁUSULAS 5.2. E 5.3., DESTA POLÍTICA DE PRIVACIDADE.

<b>4. FINALIDADES DA COLETA DE INFORMAÇÕES PESSOAIS DO USUÁRIO</b>

4.1. A ADMINISTRAÇÃO DO SITE DA LOJA ONLINE PODE USAR OS DADOS PESSOAIS DO USUÁRIO PARA OS SEGUINTES FINS:

4.1.1. IDENTIFICAÇÃO DO USUÁRIO REGISTRADO NO SITE DA LOJA ONLINE PARA FAZER UM PEDIDO REMOTAMENTE COM ROBUXPIER.IO.

4.1.2. FORNECER AO USUÁRIO ACESSO A RECURSOS PERSONALIZADOS DO SITE DA LOJA ONLINE.

4.1.3. ESTABELECER FEEDBACK COM O USUÁRIO, INCLUINDO O ENVIO DE NOTIFICAÇÕES, SOLICITAÇÕES RELACIONADAS AO USO DO SITE DA LOJA ONLINE, PRESTAÇÃO DE SERVIÇOS, PROCESSAMENTO DE SOLICITAÇÕES E PEDIDOS DO USUÁRIO.

4.1.4. PARA CONFIRMAR A VERACIDADE E INTEGRIDADE DOS DADOS PESSOAIS FORNECIDOS PELO USUÁRIO.

4.1.5. PARA CRIAR UMA CONTA PARA FAZER COMPRAS, SE O USUÁRIO TIVER CONSENTIDO EM CRIAR UMA CONTA.

4.1.6. NOTIFICAÇÕES DO USUÁRIO DO SITE DA LOJA ONLINE SOBRE O STATUS DO PEDIDO.

4.1.8. PROCESSAMENTO E RECEBIMENTO DE PAGAMENTOS, CONTESTAÇÃO DE PAGAMENTO.

4.1.9. FORNECER AO USUÁRIO SUPORTE EFETIVO AO CLIENTE E TÉCNICO EM CASO DE PROBLEMAS RELACIONADOS AO USO DO SITE DA LOJA ONLINE.

4.1.10. FORNECER ACESSO AO USUÁRIO AOS SITES OU SERVIÇOS DE PARCEIROS DA LOJA ONLINE PARA OBTER PRODUTOS, ATUALIZAÇÕES E SERVIÇOS.

<b>5. MÉTODOS E PRAZOS DE PROCESSAMENTO DE INFORMAÇÕES PESSOAIS</b>

5.1. O PROCESSAMENTO DOS DADOS PESSOAIS DO USUÁRIO É REALIZADO SEM LIMITE DE TEMPO, DE QUALQUER MANEIRA LEGAL, INCLUSIVE EM SISTEMAS DE INFORMAÇÃO DE DADOS PESSOAIS USANDO FERRAMENTAS DE AUTOMAÇÃO OU SEM O USO DE TAIS FERRAMENTAS.

5.2. O USUÁRIO CONCORDA QUE A ADMINISTRAÇÃO DO SITE TEM O DIREITO DE TRANSFERIR DADOS PESSOAIS PARA TERCEIROS, EM PARTICULAR PARA OS SISTEMAS DE PAGAMENTO ATRAVÉS DOS QUAIS O USUÁRIO FEZ O PAGAMENTO DO PEDIDO.

5.3. OS DADOS PESSOAIS DO USUÁRIO PODEM SER TRANSFERIDOS PARA AS AUTORIDADES PÚBLICAS AUTORIZADAS DE ACORDO COM A LEGISLAÇÃO DA REPÚBLICA DA ESTÔNIA.

5.4. EM CASO DE PERDA OU DIVULGAÇÃO DE DADOS PESSOAIS, A ADMINISTRAÇÃO DO SITE INFORMA O USUÁRIO SOBRE A PERDA OU DIVULGAÇÃO DE DADOS PESSOAIS.

5.5. A ADMINISTRAÇÃO DO SITE TOMA AS MEDIDAS ORGANIZACIONAIS E TÉCNICAS NECESSÁRIAS PARA PROTEGER AS INFORMAÇÕES PESSOAIS DO USUÁRIO CONTRA ACESSO ILEGAL OU ACIDENTAL, DESTRUIÇÃO, ALTERAÇÃO, BLOQUEIO, CÓPIA, DISTRIBUIÇÃO, BEM COMO CONTRA OUTRAS AÇÕES ILEGAIS DE TERCEIROS.

5.6. A ADMINISTRAÇÃO DO SITE, JUNTAMENTE COM O USUÁRIO, TOMA TODAS AS MEDIDAS NECESSÁRIAS PARA EVITAR PERDAS OU OUTRAS CONSEQUÊNCIAS NEGATIVAS CAUSADAS PELA PERDA, OU DIVULGAÇÃO DE DADOS PESSOAIS DO USUÁRIO.

<b>6. OBRIGAÇÕES DAS PARTES</b>

6.1. O USUÁRIO É OBRIGADO:

6.1.1. FORNECER INFORMAÇÕES SOBRE OS DADOS PESSOAIS NECESSÁRIOS PARA O USO DO SITE DA LOJA ONLINE.

6.1.2. TUALIZAR, COMPLEMENTAR AS INFORMAÇÕES FORNECIDAS SOBRE OS DADOS PESSOAIS EM CASO DE ALTERAÇÃO DESSAS INFORMAÇÕES.

6.2. A ADMINISTRAÇÃO DO SÍTIO É OBRIGADA:

6.2.1. USAR AS INFORMAÇÕES OBTIDAS EXCLUSIVAMENTE PARA OS FINS ESPECIFICADOS NO PARÁGRAFO 4 DESTA POLÍTICA DE PRIVACIDADE.

6.2.2. A MANTER SECRETAS AS INFORMAÇÕES CONFIDENCIAIS, A NÃO AS DIVULGAR SEM O CONSENTIMENTO PRÉVIO POR ESCRITO DO USUÁRIO, E A NÃO VENDER, TROCAR, PUBLICAR OU DIVULGAR DE QUALQUER OUTRA FORMA POSSÍVEL OS DADOS PESSOAIS TRANSFERIDOS DO USUÁRIO, EXCEPTO PARA AS SUBCLÁUSULAS 5.2. E 5.3., DESTA POLÍTICA DE PRIVACIDADE.

6.2.3. TOMAR PRECAUÇÕES PARA PROTEGER A CONFIDENCIALIDADE DOS DADOS PESSOAIS DO USUÁRIO DE ACORDO COM O PROCEDIMENTO COMUMENTE USADO PARA PROTEGER ESSE TIPO DE INFORMAÇÃO NO VOLUME DE NEGÓCIOS EXISTENTE.

6.2.4. BLOQUEAR OS DADOS PESSOAIS RELACIONADOS AO USUÁRIO RELEVANTE A PARTIR DO MOMENTO DO PEDIDO OU SOLICITAÇÃO DO USUÁRIO OU SEU REPRESENTANTE LEGAL OU AUTORIDADE AUTORIZADA PARA PROTEGER OS DIREITOS DOS SUJEITOS DE DADOS PESSOAIS DURANTE O PERÍODO DE VERIFICAÇÃO, EM CASO DE DETECÇÃO DE DADOS PESSOAIS INCORRETOS OU AÇÕES ILEGAIS.

<b>7. RESPONSABILIDADE DAS PARTES</b>

7.1. A ADMINISTRAÇÃO DO SITE, QUE NÃO CUMPRIU SUAS OBRIGAÇÕES, É RESPONSÁVEL POR PERDAS INCORRIDAS PELO USUÁRIO EM CONEXÃO COM O USO INDEVIDO DE DADOS PESSOAIS, DE ACORDO COM A LEGISLAÇÃO DA REPÚBLICA DA ESTÔNIA.

7.2. EM CASO DE PERDA OU DIVULGAÇÃO DE INFORMAÇÕES CONFIDENCIAIS, A ADMINISTRAÇÃO DO SITE NÃO É RESPONSÁVEL SE ESSAS INFORMAÇÕES CONFIDENCIAIS:

7.2.1. TORNOU-SE DOMÍNIO PÚBLICO ANTES DE SUA PERDA OU DIVULGAÇÃO.

7.2.2. FORAM RECEBIDAS DE TERCEIROS ATÉ O MOMENTO EM QUE FORAM RECEBIDAS PELA ADMINISTRAÇÃO DO SITE.

7.2.3. FORAM DIVULGADOS COM O CONSENTIMENTO DO USUÁRIO.

<b>8. RESOLUÇÃO DE DISPUTAS</b>

8.1. ANTES DE RECORRER AO TRIBUNAL COM UMA AÇÃO SOBRE DISPUTAS DECORRENTES DA RELAÇÃO ENTRE O USUÁRIO DO SITE DA LOJA ONLINE E A ADMINISTRAÇÃO DO SITE, É OBRIGATÓRIO APRESENTAR UMA RECLAMAÇÃO (PROPOSTA POR ESCRITO SOBRE A RESOLUÇÃO VOLUNTÁRIA DA DISPUTA).

8.2. DENTRO DE 30 DIAS CORRIDOS A PARTIR DA DATA DE RECEBIMENTO DA RECLAMAÇÃO, O DESTINATÁRIO DA RECLAMAÇÃO NOTIFICARÁ POR ESCRITO O RECLAMANTE SOBRE O RESULTADO DA REVISÃO DA RECLAMAÇÃO.

8.3. SE O ACORDO NÃO FOR ALCANÇADO, A DISPUTA PODE SER SUBMETIDA A UMA AUTORIDADE JUDICIAL DE ACORDO COM A LEGISLAÇÃO ATUAL DA REPÚBLICA DA ESTÔNIA.

<b>9. TERMOS ADICIONAIS</b>

9.1. A ADMINISTRAÇÃO DO SITE TEM O DIREITO DE ALTERAR ESTA POLÍTICA DE PRIVACIDADE SEM O CONSENTIMENTO DO USUÁRIO.

9.2. A NOVA POLÍTICA DE PRIVACIDADE ENTRA EM VIGOR A PARTIR DO MOMENTO EM QUE É PUBLICADA NO SITE DA LOJA ONLINE, SALVO DISPOSIÇÃO EM CONTRÁRIO DA NOVA REDAÇÃO DA POLÍTICA DE PRIVACIDADE.

9.3. A POLÍTICA DE PRIVACIDADE ATUAL ESTÁ LOCALIZADA NA PÁGINA NO ENDEREÇO HTTPS://ROBUXPIER.IO/PRIVACY`.split(new RegExp(/\s+\r?\n/))
