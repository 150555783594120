<template>
  <div class="faq">
    <div class="faq-wrapper">
      <div class="faq-title">
        <h1>{{ $t('faq.title') }}</h1>
        <h2>{{ $t('faq.content') }}</h2>
      </div>
      <div class="faq-block">
        <div class="faq-block__item">
          <div class="faq-block__item-content">
            <p class="faq-block__item-content__index">{{ $t('faq.items.item1.index') }}</p>
            <p class="faq-block__item-content__title">{{ $t('faq.items.item1.title') }}</p>
            <p class="faq-block__item-content__content">{{ $t('faq.items.item1.content') }}</p>
          </div>
        </div>
        <div class="faq-block__item">
          <div class="faq-block__item-content">
            <p class="faq-block__item-content__index">{{ $t('faq.items.item2.index') }}</p>
            <p class="faq-block__item-content__title">{{ $t('faq.items.item2.title') }}</p>
            <p class="faq-block__item-content__content">{{ $t('faq.items.item2.content') }}</p>
          </div>
        </div>
        <div class="faq-block__item">
          <div class="faq-block__item-content">
            <p class="faq-block__item-content__index">{{ $t('faq.items.item3.index') }}</p>
            <p class="faq-block__item-content__title">{{ $t('faq.items.item3.title') }}</p>
            <p class="faq-block__item-content__content" v-html="$t('faq.items.item3.content')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './faq.scss'

export default {
  name: 'Faq'
}
</script>
