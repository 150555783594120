module.exports = function Slider () {
  const dots = document.querySelectorAll('.circle')
  const sliderItem = document.querySelector('.slider__wrapper-item')
  const sliderLenta = document.querySelector('.slider__wrapper-lenta')
  const LENGTH = document.querySelectorAll('.slider__wrapper-item').length
  const sliderSpeed = 0.055

  const changeSlider = (index) => {
    sliderLenta.style.transform = `translateX(${-index * sliderItem.clientWidth}px)`
    changeDote(index)
  }

  const changeDote = (index) => {
    dots.forEach(dote => {
      dote.classList.remove('active')
      if (Number(dote.dataset.index) === index) {
        dote.classList.add('active')
      }
    })
  }

  const checkEnd = (activeSlideIndex) => {
    if (activeSlideIndex + 1 >= LENGTH) changeSlider(0)
    else changeSlider(activeSlideIndex + 1)
  }

  // AutoChange Slide
  let count = 0
  let activeSlideIndex = 0
  setInterval(() => {
    if (count < 100) {
      count += sliderSpeed
    } else {
      count = 0
      activeSlideIndex = Number(document.querySelector('.circle.active').dataset.index)
      checkEnd(activeSlideIndex)
    }
  }, 1)

  dots.forEach(dote => {
    dote.addEventListener('click', (event) => {
      const index = Number(event.target.dataset.index)
      count = 0
      changeSlider(index)
    })
  })
}
