import policy from '../policy/de'
import terms from '../terms/de'

export default {
  navigation: {
    title: 'Navigation',
    home: 'HOME',
    buyRobux: 'ROBUX KAUFEN',
    lk: 'PERSÖNLICHER ACCOUNT',
    vk: 'VK Gruppe',
    discord: 'Diskord',
    telegram: 'Telegram',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    dropdown: {
      history: 'Historie',
      changePassword: 'Passwort ändern',
      refill: 'Nachfüllen',
      logout: 'Ausgang'
    }
  },
  general: {
    warning: 'Achtung!',
    error: 'Fehler',
    gotIt: 'Verständlich',
    confirm: 'BESTÄTIGUNG',
    or: 'oder',
    link: 'Link',
    transfer: 'Transfer',
    policy: 'DATENSCHUTZ',
    terms: 'BENUTZERVERTRAG',

    errors: {
      internal: 'Interner Fehler, bitte versuchen Sie es später noch einmal',
      server: 'Server Fehler'
    }
  },
  actions: {
    continue: 'fortsetzen',
    submit: 'BESTÄTIGEN',
    buyRobux: 'ROUBUXEN KAUFEN',
    openDiscord: 'ZUR DISKORD',
    openInstruction: 'ZUM HANDBUCH',
    createAccount: 'Account schaffen',
    login: 'ANMELDEN',
    loginVK: 'Mit VKontakte anmelden',
    signin: 'Anmelden',
    enterPassword: 'Eingeben',
    skip: 'Überspringen',
    buy: 'Kaufen',
    buyOrder: 'Kaufen',
    tryAgain: 'Noch einmal wiederholen',

    create: 'Create',
    ready: 'Ready',
    edit: 'Edit',
    apply: 'Anwenden',
    promocode: {
      default: 'Es wurde kein Gutscheincode verwendet',
      success: 'Gutscheincode angewendet',
      error: 'Der Gutscheincode konnte nicht angewendet werden',
      minError: 'Mindestbetrag für die Aktivierung des Gutscheincodes {min} R$',
      maxError: 'Der maximale Betrag für die Aktivierung des Gutscheincodes {max} R$',
      isNoPromo: 'Dieser Promo-Code existiert nicht!',
      promoExpired: 'Der Aktionscode ist abgelaufen!',
      notAuth: 'Autorisierung erforderlich!'
    }
  },
  payment: {
    refill: 'Nachfüllung',
    total: 'Fazit',
    toPay: 'Zu bezahlen',
    pay: 'BEZAHLEN',
    makeOrder: 'Bezahlen die Bestellung',
    otherPaymentMethods: 'Andere Zahlungsmöglichkeiten',
    enterSum: 'Geben Sie den Aufladebetrag ein',
    commission: 'Provisionen',
    notEnough: 'Sie haben nicht genug Geld für die Bezahlung!',
    problems: 'Wenn Sie Probleme haben, kontaktieren Sie unseren {support}',
    support: 'Kundenservice'
  },
  order: {
    status: 'BESTELLUNGSSTATUS',
    wait: 'BITTE WARTEN Sie auf die Fertigstellung Ihrer Bestellung',
    waitTime: 'GEBLIEBEN',
    code: 'Der Code muss aus 6 Ziffern bestehen',
    mailCode: 'Введите код с почты',
    makeNewOrder: 'Eine weitere Bestellung aufgeben',
    table: {
      nickname: 'SPIEL NICK',
      count: 'R$ ZAHL',
      total: 'TOTAL, RUBEL'
    },
    descriptions: {
      wait: 'Warten Sie auf die Ausführung Ihrer Bestellung',
      notEnoughFunds: 'Fehler. Website hat nicht genügend Mittel!',
      systemError: 'System Fehler!',
      checkVip: 'VIP ROBLOX-Einstellungen überprüfen!',
      connectionError: 'Kommunikationsfehler mit ROBLOX!',
      success: 'Bestellung erfolgreich abgeschlossen!',
      checkGamepass: 'Gamepass Einstellungen überprüfen!'
    }
  },
  auth: {
    authorization: 'Autorisierung',
    registration: 'Anmeldung',
    recovery: 'WIEDERHERSTELLUNG',
    change: 'Passwort ändern',

    inputs: {
      email: 'E-Mail eingeben',
      login: 'Login eingeben',
      loginOrEmail: 'Login oder E-Mail eingeben',
      currentPassword: 'Aktuelles Passwort',
      newPassword: 'Neues Passwort',
      repeatNewPassword: 'Wiederholen Sie neues Passwort',
      password: 'Passwort eingeben',
      passwordSubmit: 'Passwort bestätigen'
    },

    errors: {
      email: 'Falsche E-mail!',
      incorrectPassword: 'Passwort ist falsch!',
      empty: 'Das Feld ist leer',
      nicknameIsTooSmall: 'Sie müssen einen Namen mit mindestens 4 Zeichen angeben',
      inactiveEmail: 'E-Mail ist ungültig',
      passwordIsTooSmall: 'Ihr Passwort muss mindestens 6 Zeichen lang sein',
      passwordNotMatch: 'Passwörter stimmen nicht überein',
      oneUppercase: 'Ihr Passwort muss mindestens einen Grossbuchstaben enthalten',
      userExist: 'Ein Benutzer mit diesen Daten existiert bereits',
      checkEmail: 'Vergewissern Sie sich, dass Ihre E-Mail/Benutzername korrekt ist',
      checkPassword: 'Vergewissern Sie sich, dass Ihr Passwort korrekt ist',
      changePasswordIncorrect: 'Das Passwort muss zwischen 6 und 18 Zeichen lang sein und aus den Buchstaben „A-z“ bestehen!'
    },

    welcome: {
      title: 'HERZLICH WILLKOMMEN',
      content: 'MELDEN SIE SICH AUF UNSERER WEBSEITE AN UND KAUFEN SIE ROUBUXEN ZUVERLÄSSIG, SCHNELL UND SICHER'
    },

    forget: 'Haben Sie Ihr Passwort vergessen?',
    remember: 'Erinnern Sie sich noch an Ihr Passwort?',
    agreement: 'ich akzeptiere {policy}',
    privacyPolicy: 'Datenschutz',
    haveAccount: 'Haben Sie schon eine Account',
    codeSent: ' Ein Passwort-Wiederherstellungscode wurde an Ihre E-mail gesendet.!',
    enterCode: 'Geben Sie den 6-stelligen Code ein:',
    requestCode: 'Erneut anfragen',

    changePasswordSuccess: {
      title: 'Ausgezeichnet!',
      content: 'Sie haben Ihr Passwort erfolgreich geändert! Vergessen Sie nicht...'
    }
  },
  slider: {
    slide1: {
      title: 'ROBUXEN SICHER KAUFEN!',
      content: 'Die gesamte Spielwährung wurde legal von den Spielentwicklern erworben.'
    },
    slide2: {
      title: 'DIE BESTEN PREISE!',
      content: 'Nur in unserem Shop können Sie Robuxen zu den niedrigsten Preisen kaufen und erhalten sie garantiert!'
    },
    slide3: {
      title: 'KUNDEN VERTRAUEN UNS!',
      content: 'Unser Geschäft besteht seit drei Jahren und hat sich in dieser Zeit etabliert!'
    }
  },
  faq: {
    title: 'WOLLEN SIE ROBUXEN KAUFEN?',
    content: 'GÜNSTIGE PREISE, SCHNELLE LIEFERUNG!',
    items: {
      item1: {
        index: '01',
        title: 'BESTELLUNG MACHEN',
        content: 'Wählen Sie die Anzahl der zu kaufenden Robuxen und Ihr roblox-Account'
      },
      item2: {
        index: '02',
        title: 'Bezahlen',
        content: 'Bezahlen Sie Ihre Bestellung, wie Sie wollen'
      },
      item3: {
        index: '03',
        title: 'ERHALTEN SIE ROUBUXEN',
        content: 'Erhalten Sie innerhalb von 5 Tagen Robuxen auf Ihr Spielkonto!'
      }
    }
  },
  help: {
    title: 'Sind beim Kauf von Robux  Schwierigkeiten aufgetreten?',
    text1: 'Unser Support hilft dir auf jeden Fall dabei, auf dem Server im Discord oder in persönlichen Instagram-Nachrichten!',
    text2: 'Sie können auch die Anweisungen auf…nutzen '
  },
  footer: {
    privacy: 'DATENSCHUTZ',
    terms: 'BENUTZERVERTRAG',
    contacts: 'KONTAKTINFORMATION'
  },
  methods: {
    title: 'WELCHE KAUFMETHODE MÖCHTEN SIE VERWENDEN?',
    hintError: {
      title: 'Fehler!',
      content: 'Sie haben schon Robux Premium!'
    },
    method: 'Methode',
    robux: 'Robuxen',
    premium: 'Premium',
    transfer: 'Transfer',
    logpass: 'log+pass',
    available: 'ist vorhanden',
    notAvailable: 'ist nicht vorhanden'
  },
  cookie: {
    whatIsCookie: 'Was ist COOKIE?',
    checkInstruction: 'MACHEN SIE SICH MIT COOCIE-ANWEISUNGEN BEKANNT',
    enter: 'Geben Sie Cookie ein',
    description: {
      part1: 'Cookies selbst sind nicht gefährlich - es handelt sich um gewöhnliche Textdateien. Sie können keine Prozesse auf Ihrem Computer ausführen oder mit Ihrem Betriebssystem interagieren. Es kann jedoch versucht werden, sie abzufangen oder zu stehlen, um Ihre früheren Online-Aktivitäten nachzuverfolgen oder sich unbefugt bei Ihren Accounts anzumelden.',
      part2: 'Normalerweise werden Informationen, die in Cookies geschrieben werden, verschlüsselt, bevor sie gesendet werden, und die Cookies selbst werden über das HTTPS-Protokoll gesendet. Dies trägt zum Schutz der Nutzerdaten bei, aber der Entwickler der Website ist für die Implementierung der Verschlüsselung und die sichere Übertragung verantwortlich. Die Besucher können nur hoffen, dass alles richtig eingestellt ist. Der Nutzer seinerseits kann dem Browser nur verbieten, Cookies zu verwenden, oder sie von Zeit zu Zeit selbst löschen.'
    }
  },
  transfer: {
    robuxDelivery: 'Robuxen werden innerhalb von 5 Tagen (120 Stunden) gutgeschrieben.',
    available: 'ist vorhanden:',
    robuxCount: 'Robuxenzahl',
    minumum: 'Minimum von {min} R$',
    nickname: 'Spiel Nickname',
    notFound: 'Es ist Nichts gefunden',
    checkPlaces: 'BITTE ÜBERPRÜFEN SIE, OB SIE MINDESTENS 1 ÖFFENTLICHEN PLATZ ERSTELLT HABEN',
    vipPrice: 'Setzen Sie den Preis des VIP-Servers auf {price}',
    changePrice: 'Den Preis ändern',
    orderSuccess: 'Den Eingang der Robuxen in Ihrem Account können Sie… verfolgen {here}',
    orderError: 'Rückzahlung von Geldern auf das Konto ROBUXPIER. Versuchen Sie noch einmal :)',
    here: 'hier',
    gamepass: {
      checkData: 'Check the correctness of the data in Gamepass!',
      creating: 'CREATING',
      settingUp: 'SETUP',
      createGamepass: 'Create a Game-pass',
      checkInstruction: 'View the instructions and set the price <span>{price} R$</span>',
      serverError: 'Something went wrong. Try again.'
    },
    errors: {
      insufficientBalance: 'Ungenügende Mittel in der Bilanz',
      notAuthorized: 'Sie sind nicht autorisiert',
      serverError: 'Serverfehler',
      robuxNotAvailable: 'Anzahl der Robuxen, die nicht zum Kauf verfügbar sind',

      priceNotNumber: 'Der Preis sollte eine Zahl sein',
      robuxMinimum: 'Man darf unter 50 Robuxen nicht bestellen',
      robuxMaximun: 'Der maximale Kaufbetrag ist vorübergehend auf 5k R$ begrenzt',
      halfBalance: 'Sie können nicht mehr als die Hälfte des Restbetrags bestellen: {balance} Robuxen',
      robuxMustDivide: 'R in $ muss unterteilt werden {orders}. Versuchen Sie <span class="min">{closestMin}R$</span> oder <span class="max">{closestMax}R$</span>'
    },
    selectYourPlace: 'Wählen Sie Ihren'
  },
  logpass: {
    enterNickname: 'GEBEN SIE EINEN NICHNAMEN EIN',
    buyRobuxTitle: 'Ankauf von Robuxen',
    codes: {
      code_request: 'Bitte geben Sie den Code ein, der Ihnen per E-Mail zugeschickt wurde',
      order_awaiting: 'Warten Sie auf die Ausführung Ihrer Bestellung',
      code_request_awaiting: 'Warten Sie auf die Anforderung des Codes durch die Post',

      password_error: 'Falsches Passwort in ROBLOX',
      code_error: 'Falscher Code für die zweistufige Authentifizierung aus der E-Mail',
      cookie_error: 'Fehler. Falsche Zulassungsdaten',
      email_timeout_error: 'Sie haben den Code nicht eingegeben! Wiederholen Sie die Bestellung und versuchen Sie, den Code so schnell wie möglich einzugeben.!',
      error: 'Fehler. Versuchen Sie noch einmal',
      success: 'Ihre Bestellung ist erfolgreich ausgeführt',
      waiting: 'Warten Sie auf die Ausführung Ihrer Bestellung'
    }
  },
  premium: {
    buyPremium: 'PRÄMIENEINKAUF',
    alreadyExist: 'Sie haben schon Roblox Premium!',
    subscribe: {
      title: '{percent} ZU DEN ROBUXEN!',
      subtitle: 'Mit einem Premium-Abonnement erhalten Sie mehr Robuxen'
    },
    getRbx: 'Erhalten Sie {rbx} Auf Ihr Konto',
    buyWithProfit: 'MIT EINEM SCHNÄPPCHEN KAUFEN!',
    currencyPerMonth: '{currency} für einen Monat',
    toPay: 'Gesamtbetrag der Verbindlichkeiten:',
    premiumTime: 'Premium-Abonnements können nur für erworben werden {time}',
    oneMonth: '1 Monat ohne automatische Erneuerung',
    enterPromocode: 'Promotion-Code eingeben',
    ordering: 'BESTELLVERFAHREN',
    mailCode: 'EINE CODE-ANFORDERUNG VON DER E-MAIL ERWARTEN',

    correctSum: 'Geben Sie korrekten Betrag ein',
    minRefillSum: 'Nachfüllbetrag muss nicht unter 5 Rubek sein'
  },
  qiwi: {
    details: 'NACHSCHUBDETAILS',
    passport: 'Haben Sie einen Pass der Russischen Föderation?',
    noPassport: 'Da Sie keinen russischen Pass haben, können Sie nicht von Qiwi überprüft werden.',
    hasPassport: 'Sie müssen auf der Qiwi-Website verifiziert werden, damit die Gebühr niedriger ist.',
    noPassportCommission: 'Die Kommission wird 7,5 % betragen.',
    hasPassportCommission: ' Die Kommission wird 3 % betragen ',
    yes: 'Ja',
    no: 'Nein'
  },
  history: {
    nickname: 'Spiel Nickname',
    sumRobux: 'Betrag, R$',
    sumRub: 'Betrag',
    status: 'Status',
    description: 'Beschreibung',
    timer: 'Zeitmesser',

    error: 'Fehler',
    wait: 'Erwartung',
    success: 'Erfolgreich',
    orderCount: 'Angezeigte {order} von {length} Einträgen'
  },
  policy,
  terms
}
