import ru from '@/assets/images/navigation/flags/ru.svg'
import en from '@/assets/images/navigation/flags/en.svg'
import fr from '@/assets/images/navigation/flags/fr.svg'
import pt from '@/assets/images/navigation/flags/pt.svg'
import es from '@/assets/images/navigation/flags/es.svg'
import de from '@/assets/images/navigation/flags/de.svg'
import tr from '@/assets/images/navigation/flags/tr.svg'

export default [
  {
    name: 'en',
    icon: en
  },
  {
    name: 'ru',
    icon: ru
  },
  {
    name: 'fr',
    icon: fr
  },
  {
    name: 'pt',
    icon: pt
  },
  {
    name: 'es',
    icon: es
  },
  {
    name: 'de',
    icon: de
  },
  {
    name: 'tr',
    icon: tr
  }
]
