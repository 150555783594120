import Vue from 'vue'
import VueI18n from 'vue-i18n'

import languageRU from './languages/ru'
import languageEN from './languages/en'
import languageFR from './languages/fr'
import languagePT from './languages/pt'
import languageES from './languages/es'
import languageDE from './languages/de'
import languageTR from './languages/tr'

Vue.use(VueI18n)

const language = localStorage.getItem('language') || 'en'
export const i18n = new VueI18n({
  locale: language,
  fallbackLocale: 'en',
  messages: {
    ru: languageRU,
    en: languageEN,
    fr: languageFR,
    pt: languagePT,
    es: languageES,
    de: languageDE,
    tr: languageTR
  }
})
