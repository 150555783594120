<template>
  <div class="slider">
    <div class="slider__wrapper">
      <div class="slider__wrapper-lenta">
        <div class="slider__wrapper-item" v-for="slide in slides" :key="slide.img">
          <div class="slider__wrapper-item__content">
            <p class="slider__wrapper-item__title">{{ $t(slide.title) }}</p>
            <p class="slider__wrapper-item__text">{{ $t(slide.text) }}</p>
            <Button
              class="slider__wrapper-item__btn"
              right-icon="/slider/arrow.svg"
              @click="goToTransfer"
            >
              {{ $t('actions.buyRobux') }}
            </Button>
          </div>
          <div class="slider__wrapper-item__img">
            <img :src="slide.img" :alt="slide.title">
          </div>
        </div>
      </div>
    </div>
    <div class="slider-circles">
      <div class="circle active" data-index="0"></div>
      <div class="circle" data-index="1"></div>
      <div class="circle" data-index="2"></div>
    </div>
    <div class="slider-mobile-footer">
      <Button
        class="slider__wrapper-item__btn-mobile"
        right-icon="/slider/arrow.svg"
        @click="goToTransfer"
      >
        {{ $t('actions.buyRobux') }}
      </Button>
    </div>
  </div>
</template>

<script>
import './slider.scss'
import Slider from '@/utils/slider'
import Button from '@/components/Base/Button/Button.vue'

export default {
  name: 'Slider',
  components: { Button },
  mounted () {
    Slider()
  },
  data () {
    return {
      slides: [
        { title: 'slider.slide1.title', text: 'slider.slide1.content', img: require('@/assets/images/slider/banner_1.svg') },
        { title: 'slider.slide2.title', text: 'slider.slide2.content', img: require('@/assets/images/slider/banner_2.svg') },
        { title: 'slider.slide3.title', text: 'slider.slide3.content', img: require('@/assets/images/slider/banner_3.svg') }
      ]
    }
  },
  methods: {
    goToTransfer () {
      this.$router.push('/transfer')
    }
  }
}
</script>
