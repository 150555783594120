<template>
  <div class="privacy-page terms-of-use">
    <div class="wrapper">
      <Navigation />
      <div class="terms-of-use__main">
        <p class="terms-of-use__main__title">{{ $t('general.policy') }}</p>
        <div class="terms-of-use__main__text">
          <div class="terms-of-use__main__text__t">
            <div class="terms-of-use__main__text__t__company" v-if="!isCountryFromRu($store.state.ipCountry)">
            </div>

            <p v-for="item in $t('policy')" :key="item" v-html="item"></p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import './privacyPolicy.scss'

import Navigation from '@/components/Navigation/Navigation'
import Footer from '@/components/Footer/Footer'
import ipUtil from '@/utils/ip'

export default {
  name: 'PrivacyPolicy',
  components: {
    Navigation,
    Footer
  },
  methods: {
    isCountryFromRu (c) {
      return ipUtil.isCountryInRuRegion(c)
    }
  }
}
</script>
