<template>
  <div class="premium">
    <Auth/>
    <div class="wrapper">
      <Navigation />
    </div>
    <PremiumLoader v-if="!isLoaded"/>
    <template v-else>
      <InstructionCookie
        :setCookie="setCookie"
        :setUserId="setUserId"
        :methodType="'Premium'"
        v-if="stage == 'instruction' && !this.$store.state.isMobile"
        @changeModal='changeStage'
      />
      <PremiumLoader
        v-if="stage == 'loader'"
        @changeModal='changeStage'
      />
      <PremiumBuy
        v-if="stage == 'buy'"
        @changeModal='changeStage'
        @toggleComeBack="isComeBack = true"
        @moveOrderData='saveOrder'
        :userData='[username, cookie]'
      />
      <ChoiceAccount
        :methodType="'Premium'"
        @changeModal='changeStage'
        v-if="stage == 'choice'"
        :setUserName="setUserName"
        :setUserId="setUserId"
      />
      <ConfirmAccount
        :methodType="'Premium'"
        @changeModal='changeStage'
        v-if="stage == 'confirmAccount'"
        @toggleComeBack="isComeBack = true"
        :userOrder="userOrder"
        :getUserName="getUserName"
        :getUserId="getUserId"
        :setPassword="setPassword"
      />
      <PremiumStatus
        v-if="stage == 'status'"
        :comeBack='isComeBack'
        @changeModal='changeStage'
      />
      <PremiumOrdering
        :methodType="'Premium'"
        v-if="stage == 'ordering'"
        @changeModal='changeStage'
      />
    </template>
  </div>
</template>

<script>
import './premium.scss'

import Navigation from '@/components/Navigation/Navigation'
import Auth from '@/components/Auth/Auth'
import InstructionCookie from '@/components/InstructionCookie/InstructionCookie.vue'
import PremiumLoader from '@/components/Premium/PremiumLoader/PremiumLoader.vue'
import PremiumBuy from '@/components/Premium/PremiumBuy/PremiumBuy.vue'
import PremiumStatus from '@/components/Premium/PremiumStatus/PremiumStatus.vue'
import PremiumOrdering from '@/components/Premium/PremiumOrdering/PremiumOrdering.vue'
import ChoiceAccount from '@/components/ChoiceAccount/ChoiceAccount.vue'
import ConfirmAccount from '@/components/ConfirmAccount/ConfirmAccount.vue'

export default {
  name: 'Premium',
  components: {
    Navigation,
    Auth,
    InstructionCookie,
    PremiumLoader,
    PremiumBuy,
    PremiumStatus,
    PremiumOrdering,
    ChoiceAccount,
    ConfirmAccount
  },
  data () {
    return {
      isComeBack: false,
      isLoaded: false,
      stage: 'instruction',
      cookie: null,
      userId: null,
      username: null,
      password: null,
      userOrder: null,
      orders: []
    }
  },
  created () {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      this.$store.state.isMobile = true
      this.stage = 'buy'
    }

    this.$store.state.isMobile = true
    this.stage = 'buy'
  },
  methods: {
    changeStage (params) {
      this.stage = params.modal
    },
    setCookie (cookie) {
      this.cookie = cookie
    },
    setUserId (userId) {
      this.userId = userId

      fetch(`https://logpass.backend-pier.com/api/v1/roblox/get_username?user_id=${this.userId}`)
        .then(res => res.json())
        .then(data => {
          this.username = data.username
        })
    },
    setUserName (username) {
      this.username = username
    },
    getUserName () {
      return this.username
    },
    getUserId () {
      return this.userId
    },
    setPassword (password) {
      this.password = password
    },
    saveOrder (data) {
      this.stage = data.modal
      this.userOrder = data.order
    }
  },
  mounted () {
    // fetch(`https://logpass.backend-pier.com/api/v1/roblox/check_premium?user_id=${this.$store.state.account.userId}`)
    //   .then(res => res.json())
    //   .then(data => {
    //     console.log(data)
    //     if (data.is_premium) this.$store.state.account.isPremium = true
    //   })
    fetch('https://logpass.backend-pier.com/api/v1/orders/fetch_last_orders/premium', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        this.isLoaded = true
        this.isComeBack = false
        if (data.orders.length) {
          this.stage = 'status'
          this.orders = data.orders
        }
      })
  }
}
</script>
