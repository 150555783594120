<template>
  <div class="auth">
    <div class="auth__sign-in" v-if="$store.state.sign_in">
      <SignIn />
    </div>
    <div class="auth__sign-up" v-if="$store.state.sign_up">
      <SignUp />
    </div>
    <div class="auth__forget-password" v-if="$store.state.forget_password">
      <ForgetPassword />
    </div>
  </div>
</template>

<script>
import './auth.scss'
import SignIn from '@/components/SignIn/SignIn'
import SignUp from '@/components/SignUp/SignUp'
import ForgetPassword from '@/components/ForgetPassword/ForgetPassword'

export default {
  name: 'Auth',
  components: {
    SignIn,
    SignUp,
    ForgetPassword
  }
}
</script>
