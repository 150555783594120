<template>
  <div class="plugin-setup fastpass__wrapper">
    <div v-if="isLoading" class="fastpass-loader">
      <Loader />
    </div>

    <div class="plugin-setup__exception" v-if="currentStep === 'create' && isMobile">
      <img src="@/assets/images/transfer/deny.svg" alt="deny" srcset="" class="plugin-setup__exception-image">

      <div class="plugin-setup__exception-message">
        {{ $t('fastpass.instruction.mobileError') }}
      </div>
    </div>

    <div class="plugin-setup__instruction" v-else>
      <div class="hint" :class="{ active: isHintActive }">
        <div class="hint-border">
          <div class="hint__content">
            <p class="hint__content__title">{{ $t('general.warning') }}</p>
            <p class="hint__content__text">{{ $t('fastpass.instruction.checkPlugin') }}</p>
          </div>
        </div>
      </div>

      <!-- Title -->
      <div v-if="currentStep === 'create'" class="plugin-setup__title">
        {{ $t('fastpass.instruction.create') }} <span class="text-color--blue">{{ $t('fastpass.instruction.plugin') }}</span>
      </div>
      <div v-else-if="currentStep === 'edit'" class="plugin-setup__title">
        {{ $t('fastpass.instruction.edit') }} <span class="text-color--blue">{{ $t('fastpass.instruction.plugin') }}</span>
      </div>
      <div v-else class="plugin-setup__title">
        {{ $t('fastpass.instruction.select') }} <span class="text-color--blue">{{ $t('fastpass.instruction.plugin') }}</span>
      </div>

      <!-- Subtitle -->
      <div v-if="currentStep === 'create'" class="plugin-setup__subtitle">
        {{ $t('fastpass.instruction.createSubtitle') }} <span class="text-color--blue">{{ price }} R$</span>
      </div>
      <div v-else-if="currentStep === 'edit'" class="plugin-setup__subtitle">
        {{ $t('fastpass.instruction.editSubtitle') }} <span class="text-color--blue">{{ price }} R$</span>
      </div>
      <div v-else class="plugin-setup__subtitle">
        {{ $t('fastpass.instruction.selectSubtitle') }}
      </div>

      <div class="plugin-setup__content">
        <!-- Creating plugin -->
        <div v-if="currentStep === 'create'" class="plugin-setup__content-section">
          <video class="plugin-setup__video" src="@/assets/gif/creating-plugin.mp4" autoplay loop></video>
        </div>

        <!-- Editing plugin -->
        <div v-else-if="currentStep === 'edit'" class="plugin-setup__content-section plugin-setup__content-section_edit">
          <video class="plugin-setup__video" :src="editingPluginVideoUrl" autoplay loop></video>
        </div>

        <div v-else-if="currentStep === 'select'"
          class="plugin-setup__content-section plugin-setup__content-section_select">
          <div class="plugin-setup__content-section__list">
            <div class="plugin-setup__content-section__list-item" :class="{ active: plugin.assetId === assetId }"
              v-for="plugin in userPlugins" :key="plugin.assetId" @click="selectPlugin(plugin)">
              <img class="plugin-setup__content-section__list-item__image" src="@/assets/images/fastpass/plugin-item.jpg"
                alt="plugin-item">
              <div class="plugin-setup__content-section__list-item__name">{{ plugin.assetName }}</div>
            </div>
          </div>
        </div>
      </div>

      <a :href="pluginCreationUrl" target="_blank" v-if="currentStep === 'create' && !hasCreated" @click="setCreated"
        class="plugin-setup__button">
        {{ $t('fastpass.instruction.actions.create') }}
        <img class="plugin-setup__button-icon" src="@/assets/images/transfer/arrow-right.svg" alt="arrow-right">
      </a>

      <a :href="pluginEditingUrl" target="_blank" v-else-if="currentStep === 'edit' && !hasEdited" @click="setEdited"
        class="plugin-setup__button">
        {{ $t('fastpass.instruction.actions.edit') }}
        <img class="plugin-setup__button-icon" src="@/assets/images/transfer/arrow-right.svg" alt="arrow-right">
      </a>

      <div @click="submit" v-else-if="currentStep === 'select'" class="plugin-setup__button">{{ $t('actions.continue') }}</div>

      <div v-else @click="submit" class="plugin-setup__button plugin-setup__button--ready">
        <img class="plugin-setup__button-icon" src="@/assets/images/transfer/checkmark.svg" alt="checkmark">
        {{ $t('actions.ready') }}
      </div>

    </div>
  </div>
</template>

<script>
import './pluginInstruction.scss'

import Loader from '@/components/LoaderBar/LoaderBar'

export default {
  name: 'PluginInstruction',
  props: ['data'],
  components: {
    Loader
  },
  data () {
    return {
      currentStep: 'create',
      hasCreated: false,
      hasEdited: false,
      isLoading: true,
      isHintActive: false,
      assetId: null,
      userPlugins: [],
      pluginCreationUrl: 'https://create.roblox.com/dashboard/creations'
    }
  },
  async created () {
    this.isLoading = true
    await this.fetchUserPlugins()
    if (this.assetId) {
      this.currentStep = 'edit'
    } else if (this.userPlugins.length > 1) {
      this.currentStep = 'select'
    }

    this.isLoading = false
  },
  computed: {
    price () {
      return Math.round(this.data.totalRobux / 0.7)
    },
    isMobile () {
      return window.innerWidth <= 768
      // return screen.width <= 768
    },
    editingPluginVideoUrl () {
      if (!this.isMobile) {
        return require('@/assets/gif/editing-plugin.mp4')
      }

      return require('@/assets/gif/editing-plugin-mobile.mp4')
    },
    pluginEditingUrl () {
      return `https://create.roblox.com/dashboard/creations/marketplace/${this.assetId}/configure`
    }

  },
  methods: {
    async fetchUserPlugins () {
      try {
        const response = await fetch(`https://transfer.backend-pier.com/get_plugins/${this.data.robloxUserId}`)
        const data = await response.json()
        this.userPlugins = data.data
        if (this.userPlugins.length === 1) {
          this.assetId = this.userPlugins[0].assetId
        }
      } catch (error) {
        // console.error('Ошибка при получении плагинов: ', error)
      }
    },
    getPlugins () {
      return fetch(`https://transfer.backend-pier.com/get_plugins/${this.data.robloxUserId}`)
        .then((res) => res.json())
        .catch((err) => console.log(err))
    },
    validatePlugin () {
      return fetch(`https://transfer.backend-pier.com/validate_plugin/${this.assetId}/${this.price}`)
        .then((res) => res.json())
        .catch((err) => console.log(err))
    },
    selectPlugin (plugin) {
      this.assetId = plugin.assetId
    },
    setCreated () {
      this.hasCreated = true
    },
    setEdited () {
      this.hasEdited = true
    },
    showHint () {
      this.hasCreated = false
      this.hasEdited = false
      this.isHintActive = true
      setTimeout(() => { this.isHintActive = false }, 4000)
    },
    async submit () {
      this.isLoading = true

      if (!this.assetId) {
        await this.fetchUserPlugins()

        if (this.userPlugins.length > 1) {
          this.currentStep = 'select'
        } else this.showHint()

        this.isLoading = false
        return
      }

      const data = await this.validatePlugin()
      if (data.status) {
        this.$emit('changeModal', {
          modal: 'PaymentForm',
          data: [this.assetId]
        })
      } else {
        if (this.currentStep !== 'select') this.showHint()
        this.currentStep = 'edit'
      }

      this.isLoading = false
    }
  }
}
</script>
