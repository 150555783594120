export default `РЕДАКЦИЯ ОТ 03.11.2021

НАСТОЯЩАЯ ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ (ДАЛЕЕ – ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ) ДЕЙСТВУЕТ В ОТНОШЕНИИ ВСЕЙ ИНФОРМАЦИИ, КОТОРУЮ ИНТЕРНЕТ-МАГАЗИН «ROBUXPIER.IO», РАСПОЛОЖЕННЫЙ НА ДОМЕННОМ ИМЕНИ WWW.ROBUXPIER.IO , МОЖЕТ ПОЛУЧИТЬ О ПОЛЬЗОВАТЕЛЕ ВО ВРЕМЯ ИСПОЛЬЗОВАНИЯ САЙТА ИНТЕРНЕТ-МАГАЗИНА, ПРОГРАММ И ПРОДУКТОВ ИНТЕРНЕТ-МАГАЗИНА.

<b>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</b>

1.1. В НАСТОЯЩЕЙ ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ ИСПОЛЬЗУЮТСЯ СЛЕДУЮЩИЕ ТЕРМИНЫ:

1.1.1. «АДМИНИСТРАЦИЯ САЙТА ИНТЕРНЕТ-МАГАЗИНА (ДАЛЕЕ – АДМИНИСТРАЦИЯ САЙТА) » – УПОЛНОМОЧЕННЫЕ СОТРУДНИКИ НА УПРАВЛЕНИЯ САЙТОМ, ДЕЙСТВУЮЩИЕ ОТ ИМЕНИ ROBUXPIER.IO, КОТОРЫЕ ОРГАНИЗУЮТ И (ИЛИ) ОСУЩЕСТВЛЯЕТ ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ, А ТАКЖЕ ОПРЕДЕЛЯЕТ ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ, СОСТАВ ПЕРСОНАЛЬНЫХ ДАННЫХ, ПОДЛЕЖАЩИХ ОБРАБОТКЕ, ДЕЙСТВИЯ (ОПЕРАЦИИ), СОВЕРШАЕМЫЕ С ПЕРСОНАЛЬНЫМИ ДАННЫМИ.

1.1.2. «ПЕРСОНАЛЬНЫЕ ДАННЫЕ» - ЛЮБАЯ ИНФОРМАЦИЯ, ОТНОСЯЩАЯСЯ К ПРЯМО ИЛИ КОСВЕННО ОПРЕДЕЛЕННОМУ ИЛИ ОПРЕДЕЛЯЕМОМУ ФИЗИЧЕСКОМУ ЛИЦУ (СУБЪЕКТУ ПЕРСОНАЛЬНЫХ ДАННЫХ).

1.1.3. «ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ» - ЛЮБОЕ ДЕЙСТВИЕ (ОПЕРАЦИЯ) ИЛИ СОВОКУПНОСТЬ ДЕЙСТВИЙ (ОПЕРАЦИЙ), СОВЕРШАЕМЫХ С ИСПОЛЬЗОВАНИЕМ СРЕДСТВ АВТОМАТИЗАЦИИ ИЛИ БЕЗ ИСПОЛЬЗОВАНИЯ ТАКИХ СРЕДСТВ С ПЕРСОНАЛЬНЫМИ ДАННЫМИ, ВКЛЮЧАЯ СБОР, ЗАПИСЬ, СИСТЕМАТИЗАЦИЮ, НАКОПЛЕНИЕ, ХРАНЕНИЕ, УТОЧНЕНИЕ (ОБНОВЛЕНИЕ, ИЗМЕНЕНИЕ), ИЗВЛЕЧЕНИЕ, ИСПОЛЬЗОВАНИЕ, ПЕРЕДАЧУ (РАСПРОСТРАНЕНИЕ, ПРЕДОСТАВЛЕНИЕ, ДОСТУП), ОБЕЗЛИЧИВАНИЕ, БЛОКИРОВАНИЕ, УДАЛЕНИЕ, УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ.

1.1.4. «КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ» - ОБЯЗАТЕЛЬНОЕ ДЛЯ СОБЛЮДЕНИЯ ОПЕРАТОРОМ ИЛИ ИНЫМ ПОЛУЧИВШИМ ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ ЛИЦОМ ТРЕБОВАНИЕ НЕ ДОПУСКАТЬ ИХ РАСПРОСТРАНЕНИЯ БЕЗ СОГЛАСИЯ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ ИЛИ НАЛИЧИЯ ИНОГО ЗАКОННОГО ОСНОВАНИЯ.

1.1.5. «ПОЛЬЗОВАТЕЛЬ САЙТА ИНТЕРНЕТ-МАГАЗИНА (ДАЛЕЕ ‑ ПОЛЬЗОВАТЕЛЬ)» – ЛИЦО, ИМЕЮЩЕЕ ДОСТУП К САЙТУ, ПОСРЕДСТВОМ СЕТИ ИНТЕРНЕТ И ИСПОЛЬЗУЮЩЕЕ САЙТ ИНТЕРНЕТ-МАГАЗИНА.

1.1.6. «COOKIES» — НЕБОЛЬШОЙ ФРАГМЕНТ ДАННЫХ, ОТПРАВЛЕННЫЙ ВЕБ-СЕРВЕРОМ И ХРАНИМЫЙ НА КОМПЬЮТЕРЕ ПОЛЬЗОВАТЕЛЯ, КОТОРЫЙ ВЕБ-КЛИЕНТ ИЛИ ВЕБ-БРАУЗЕР КАЖДЫЙ РАЗ ПЕРЕСЫЛАЕТ ВЕБ-СЕРВЕРУ В HTTP-ЗАПРОСЕ ПРИ ПОПЫТКЕ ОТКРЫТЬ СТРАНИЦУ СООТВЕТСТВУЮЩЕГО САЙТА.

1.1.7. «IP-АДРЕС» — УНИКАЛЬНЫЙ СЕТЕВОЙ АДРЕС УЗЛА В КОМПЬЮТЕРНОЙ СЕТИ, ПОСТРОЕННОЙ ПО ПРОТОКОЛУ IP.

<b>2. ОБЩИЕ ПОЛОЖЕНИЯ</b>

2.1. ИСПОЛЬЗОВАНИЕ ПОЛЬЗОВАТЕЛЕМ САЙТА ИНТЕРНЕТ-МАГАЗИНА ОЗНАЧАЕТ СОГЛАСИЕ С НАСТОЯЩЕЙ ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ И УСЛОВИЯМИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ.

2.2. В СЛУЧАЕ НЕСОГЛАСИЯ С УСЛОВИЯМИ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ ПОЛЬЗОВАТЕЛЬ ДОЛЖЕН ПРЕКРАТИТЬ ИСПОЛЬЗОВАНИЕ САЙТА ИНТЕРНЕТ-МАГАЗИНА.

2.3. НАСТОЯЩАЯ ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПРИМЕНЯЕТСЯ ТОЛЬКО К САЙТУ ИНТЕРНЕТ-МАГАЗИНА ROBUXPIER.IO. ИНТЕРНЕТ-МАГАЗИН НЕ КОНТРОЛИРУЕТ И НЕ НЕСЕТ ОТВЕТСТВЕННОСТЬ ЗА САЙТЫ ТРЕТЬИХ ЛИЦ, НА КОТОРЫЕ ПОЛЬЗОВАТЕЛЬ МОЖЕТ ПЕРЕЙТИ ПО ССЫЛКАМ, ДОСТУПНЫМ НА САЙТЕ ИНТЕРНЕТ-МАГАЗИНА.

2.4. АДМИНИСТРАЦИЯ САЙТА НЕ ПРОВЕРЯЕТ ДОСТОВЕРНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ, ПРЕДОСТАВЛЯЕМЫХ ПОЛЬЗОВАТЕЛЕМ САЙТА ИНТЕРНЕТ-МАГАЗИНА.

2.5. ОПЛАТА ЗАКАЗА ВОЗМОЖНА БАНКОВСКИМИ КАРТАМИ VISA, MASTER CARD, «МИР» ИЛИ ЧЕРЕЗ ПЛАТЕЖНЫЕ СИСТЕМЫ APPLE PAY, ЯНДЕКС.ДЕНЬГИ, QIWI ИЛИ PAYPAL (ПОСЛЕДНЯЯ — ТОЛЬКО ДЛЯ ПОКУПАТЕЛЕЙ ИЗ-ЗА ПРЕДЕЛОВ РОССИЙСКОЙ ФЕДЕРАЦИИ). ЧТОБЫ ОПЛАТИТЬ ПОКУПКУ, ВЫ БУДЕТЕ ПЕРЕНАПРАВЛЕНЫ НА СЕРВЕР ПЛАТЕЖНОЙ СИСТЕМЫ UNITPAY, НА КОТОРОМ НУЖНО ВВЕСТИ НЕОБХОДИМЫЕ ДАННЫЕ. ПРИ ОПЛАТЕ БАНКОВСКОЙ КАРТОЙ БЕЗОПАСНОСТЬ ПЛАТЕЖЕЙ ГАРАНТИРУЕТ ПРОЦЕССИНГОВЫЙ ЦЕНТР UNITPAY.

ПЛАТЕЖНАЯ СИСТЕМА UNITPAY ОБЛАДАЕТ ПОДТВЕРЖДЕННЫМ СЕРТИФИКАТОМ СООТВЕТСТВИЯ ТРЕБОВАНИЯМ СТАНДАРТА PCI DSS В ЧАСТИ ХРАНЕНИЯ, ОБРАБОТКИ И ПЕРЕДАЧИ ДАННЫХ ДЕРЖАТЕЛЕЙ КАРТ. СТАНДАРТ БЕЗОПАСНОСТИ БАНКОВСКИХ КАРТ PCI DSS ПОДДЕРЖИВАЕТСЯ МЕЖДУНАРОДНЫМИ ПЛАТЕЖНЫМИ СИСТЕМАМИ, ВКЛЮЧАЯ MASTERCARD И VISA, INC. СИСТЕМА UNITPAY ТАКЖЕ ЯВЛЯЕТСЯ УЧАСТНИКОМ ПРОГРАММЫ НЕПРЕРЫВНОГО СООТВЕТСТВИЯ COMPLIANCE CONTROL PCI DSS COMPLIANCE PROCESS (P.D.C.P.). ВАШИ КОНФИДЕНЦИАЛЬНЫЕ ДАННЫЕ, НЕОБХОДИМЫЕ ДЛЯ ОПЛАТЫ (РЕКВИЗИТЫ КАРТЫ, РЕГИСТРАЦИОННЫЕ ДАННЫЕ И ДР.), НЕ ПОСТУПАЮТ В ИНТЕРНЕТ-МАГАЗИН — ИХ ОБРАБОТКА ПРОИЗВОДИТСЯ НА СТОРОНЕ ПРОЦЕССИНГОВОГО ЦЕНТРА UNITPAY И ПОЛНОСТЬЮ ЗАЩИЩЕНА.

<b>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</b>

3.1. НАСТОЯЩАЯ ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ УСТАНАВЛИВАЕТ ОБЯЗАТЕЛЬСТВА АДМИНИСТРАЦИИ САЙТА ИНТЕРНЕТ-МАГАЗИНА ПО НЕРАЗГЛАШЕНИЮ И ОБЕСПЕЧЕНИЮ РЕЖИМА ЗАЩИТЫ КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ, КОТОРЫЕ ПОЛЬЗОВАТЕЛЬ ПРЕДОСТАВЛЯЕТ ПО ЗАПРОСУ АДМИНИСТРАЦИИ САЙТА ПРИ РЕГИСТРАЦИИ НА САЙТЕ ИНТЕРНЕТ-МАГАЗИНА ИЛИ ПРИ ОФОРМЛЕНИИ ЗАКАЗА ДЛЯ ПРИОБРЕТЕНИЯ ТОВАРА.

3.2. ПЕРСОНАЛЬНЫЕ ДАННЫЕ, РАЗРЕШЁННЫЕ К ОБРАБОТКЕ В РАМКАХ НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ, ПРЕДОСТАВЛЯЮТСЯ ПОЛЬЗОВАТЕЛЕМ ПУТЁМ ЗАПОЛНЕНИЯ РЕГИСТРАЦИОННОЙ ФОРМЫ НА САЙТЕ ИНТЕРНЕТ-МАГАЗИНА ROBUXPIER.IO В РАЗДЕЛЕ НАЗВАНИЕ РАЗДЕЛА И ВКЛЮЧАЮТ В СЕБЯ СЛЕДУЮЩУЮ ИНФОРМАЦИЮ:

3.2.1. ФАМИЛИЮ, ИМЯ ПОЛЬЗОВАТЕЛЯ

3.2.2. АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ (E-MAIL)

3.3. ИНТЕРНЕТ-МАГАЗИН ЗАЩИЩАЕТ ДАННЫЕ, КОТОРЫЕ АВТОМАТИЧЕСКИ ПЕРЕДАЮТСЯ В ПРОЦЕССЕ ПРОСМОТРА РЕКЛАМНЫХ БЛОКОВ И ПРИ ПОСЕЩЕНИИ СТРАНИЦ:

· IP АДРЕС;

· ИНФОРМАЦИЯ ИЗ COOKIES;

· ИНФОРМАЦИЯ О БРАУЗЕРЕ (ИЛИ ИНОЙ ПРОГРАММЕ, КОТОРАЯ ОСУЩЕСТВЛЯЕТ ДОСТУП К ПОКАЗУ РЕКЛАМЫ);

· ВРЕМЯ ДОСТУПА;

· АДРЕС СТРАНИЦЫ, НА КОТОРОЙ РАСПОЛОЖЕН РЕКЛАМНЫЙ БЛОК;

· РЕФЕРЕР (АДРЕС ПРЕДЫДУЩЕЙ СТРАНИЦЫ).

3.3.1. ОТКЛЮЧЕНИЕ COOKIES МОЖЕТ ПОВЛЕЧЬ НЕВОЗМОЖНОСТЬ ДОСТУПА К ЧАСТЯМ САЙТА ИНТЕРНЕТ-МАГАЗИНА, ТРЕБУЮЩИМ АВТОРИЗАЦИИ.

3.3.2. ИНТЕРНЕТ-МАГАЗИН ОСУЩЕСТВЛЯЕТ СБОР СТАТИСТИКИ ОБ IP-АДРЕСАХ СВОИХ ПОСЕТИТЕЛЕЙ. ДАННАЯ ИНФОРМАЦИЯ ИСПОЛЬЗУЕТСЯ С ЦЕЛЬЮ ВЫЯВЛЕНИЯ И РЕШЕНИЯ ТЕХНИЧЕСКИХ ПРОБЛЕМ, ДЛЯ КОНТРОЛЯ ЗАКОННОСТИ ПРОВОДИМЫХ ФИНАНСОВЫХ ПЛАТЕЖЕЙ.

3.4. ЛЮБАЯ ИНАЯ ПЕРСОНАЛЬНАЯ ИНФОРМАЦИЯ НЕОГОВОРЕННАЯ ВЫШЕ (ИСТОРИЯ ПОКУПОК, ИСПОЛЬЗУЕМЫЕ БРАУЗЕРЫ И ОПЕРАЦИОННЫЕ СИСТЕМЫ И Т.Д.) ПОДЛЕЖИТ НАДЕЖНОМУ ХРАНЕНИЮ И НЕРАСПРОСТРАНЕНИЮ, ЗА ИСКЛЮЧЕНИЕМ СЛУЧАЕВ, ПРЕДУСМОТРЕННЫХ В П.П. 5.2. И 5.3. НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.

<b>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</b>

4.1. ПЕРСОНАЛЬНЫЕ ДАННЫЕ ПОЛЬЗОВАТЕЛЯ АДМИНИСТРАЦИЯ САЙТА ИНТЕРНЕТ-МАГАЗИНА МОЖЕТ ИСПОЛЬЗОВАТЬ В ЦЕЛЯХ:

4.1.1. ИДЕНТИФИКАЦИИ ПОЛЬЗОВАТЕЛЯ, ЗАРЕГИСТРИРОВАННОГО НА САЙТЕ ИНТЕРНЕТ-МАГАЗИНА, ДЛЯ ОФОРМЛЕНИЯ ЗАКАЗА ДИСТАНЦИОННЫМ СПОСОБОМ С ROBUXPIER.IO.

4.1.2. ПРЕДОСТАВЛЕНИЯ ПОЛЬЗОВАТЕЛЮ ДОСТУПА К ПЕРСОНАЛИЗИРОВАННЫМ РЕСУРСАМ САЙТА ИНТЕРНЕТ-МАГАЗИНА.

4.1.3. УСТАНОВЛЕНИЯ С ПОЛЬЗОВАТЕЛЕМ ОБРАТНОЙ СВЯЗИ, ВКЛЮЧАЯ НАПРАВЛЕНИЕ УВЕДОМЛЕНИЙ, ЗАПРОСОВ, КАСАЮЩИХСЯ ИСПОЛЬЗОВАНИЯ САЙТА ИНТЕРНЕТ-МАГАЗИНА, ОКАЗАНИЯ УСЛУГ, ОБРАБОТКА ЗАПРОСОВ И ЗАЯВОК ОТ ПОЛЬЗОВАТЕЛЯ.

4.1.4. ПОДТВЕРЖДЕНИЯ ДОСТОВЕРНОСТИ И ПОЛНОТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ, ПРЕДОСТАВЛЕННЫХ ПОЛЬЗОВАТЕЛЕМ.

4.1.5. СОЗДАНИЯ УЧЕТНОЙ ЗАПИСИ ДЛЯ СОВЕРШЕНИЯ ПОКУПОК, ЕСЛИ ПОЛЬЗОВАТЕЛЬ ДАЛ СОГЛАСИЕ НА СОЗДАНИЕ УЧЕТНОЙ ЗАПИСИ.

4.1.6. УВЕДОМЛЕНИЯ ПОЛЬЗОВАТЕЛЯ САЙТА ИНТЕРНЕТ-МАГАЗИНА О СОСТОЯНИИ ЗАКАЗА.

4.1.8. ОБРАБОТКИ И ПОЛУЧЕНИЯ ПЛАТЕЖЕЙ, ОСПАРИВАНИЯ ПЛАТЕЖА.

4.1.9. ПРЕДОСТАВЛЕНИЯ ПОЛЬЗОВАТЕЛЮ ЭФФЕКТИВНОЙ КЛИЕНТСКОЙ И ТЕХНИЧЕСКОЙ ПОДДЕРЖКИ ПРИ ВОЗНИКНОВЕНИИ ПРОБЛЕМ СВЯЗАННЫХ С ИСПОЛЬЗОВАНИЕМ САЙТА ИНТЕРНЕТ-МАГАЗИНА.

4.1.10. ПРЕДОСТАВЛЕНИЯ ДОСТУПА ПОЛЬЗОВАТЕЛЮ НА САЙТЫ ИЛИ СЕРВИСЫ ПАРТНЕРОВ ИНТЕРНЕТ-МАГАЗИНА С ЦЕЛЬЮ ПОЛУЧЕНИЯ ПРОДУКТОВ, ОБНОВЛЕНИЙ И УСЛУГ.

<b>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</b>

5.1. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ ОСУЩЕСТВЛЯЕТСЯ БЕЗ ОГРАНИЧЕНИЯ СРОКА, ЛЮБЫМ ЗАКОННЫМ СПОСОБОМ, В ТОМ ЧИСЛЕ В ИНФОРМАЦИОННЫХ СИСТЕМАХ ПЕРСОНАЛЬНЫХ ДАННЫХ С ИСПОЛЬЗОВАНИЕМ СРЕДСТВ АВТОМАТИЗАЦИИ ИЛИ БЕЗ ИСПОЛЬЗОВАНИЯ ТАКИХ СРЕДСТВ.

5.2. ПОЛЬЗОВАТЕЛЬ СОГЛАШАЕТСЯ С ТЕМ, ЧТО АДМИНИСТРАЦИЯ САЙТА ВПРАВЕ ПЕРЕДАВАТЬ ПЕРСОНАЛЬНЫЕ ДАННЫЕ ТРЕТЬИМ ЛИЦАМ, В ЧАСТНОСТИ ПЛАТЁЖНЫМ СИСТЕМАМ, ЧЕРЕЗ КОТОРЫЕ ПОЛЬЗОВАТЕЛЬ СОВЕРШАЛ ОПЛАТУ ЗАКАЗА.

5.3. ПЕРСОНАЛЬНЫЕ ДАННЫЕ ПОЛЬЗОВАТЕЛЯ МОГУТ БЫТЬ ПЕРЕДАНЫ УПОЛНОМОЧЕННЫМ ОРГАНАМ ГОСУДАРСТВЕННОЙ ВЛАСТИ В ПОРЯДКЕ, УСТАНОВЛЕННЫМ ЗАКОНОДАТЕЛЬСТВОМ ЭСТОНСКОЙ РЕСПУБЛИКИ.

5.4. ПРИ УТРАТЕ ИЛИ РАЗГЛАШЕНИИ ПЕРСОНАЛЬНЫХ ДАННЫХ АДМИНИСТРАЦИЯ САЙТА ИНФОРМИРУЕТ ПОЛЬЗОВАТЕЛЯ ОБ УТРАТЕ ИЛИ РАЗГЛАШЕНИИ ПЕРСОНАЛЬНЫХ ДАННЫХ.

5.5. АДМИНИСТРАЦИЯ САЙТА ПРИНИМАЕТ НЕОБХОДИМЫЕ ОРГАНИЗАЦИОННЫЕ И ТЕХНИЧЕСКИЕ МЕРЫ ДЛЯ ЗАЩИТЫ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ ОТ НЕПРАВОМЕРНОГО ИЛИ СЛУЧАЙНОГО ДОСТУПА, УНИЧТОЖЕНИЯ, ИЗМЕНЕНИЯ, БЛОКИРОВАНИЯ, КОПИРОВАНИЯ, РАСПРОСТРАНЕНИЯ, А ТАКЖЕ ОТ ИНЫХ НЕПРАВОМЕРНЫХ ДЕЙСТВИЙ ТРЕТЬИХ ЛИЦ.

5.6. АДМИНИСТРАЦИЯ САЙТА СОВМЕСТНО С ПОЛЬЗОВАТЕЛЕМ ПРИНИМАЕТ ВСЕ НЕОБХОДИМЫЕ МЕРЫ ПО ПРЕДОТВРАЩЕНИЮ УБЫТКОВ ИЛИ ИНЫХ ОТРИЦАТЕЛЬНЫХ ПОСЛЕДСТВИЙ, ВЫЗВАННЫХ УТРАТОЙ ИЛИ РАЗГЛАШЕНИЕМ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ.

<b>6. ОБЯЗАТЕЛЬСТВА СТОРОН</b>

6.1. ПОЛЬЗОВАТЕЛЬ ОБЯЗАН:

6.1.1. ПРЕДОСТАВИТЬ ИНФОРМАЦИЮ О ПЕРСОНАЛЬНЫХ ДАННЫХ, НЕОБХОДИМУЮ ДЛЯ ПОЛЬЗОВАНИЯ САЙТОМ ИНТЕРНЕТ-МАГАЗИНА.

6.1.2. ОБНОВИТЬ, ДОПОЛНИТЬ ПРЕДОСТАВЛЕННУЮ ИНФОРМАЦИЮ О ПЕРСОНАЛЬНЫХ ДАННЫХ В СЛУЧАЕ ИЗМЕНЕНИЯ ДАННОЙ ИНФОРМАЦИИ.

6.2. АДМИНИСТРАЦИЯ САЙТА ОБЯЗАНА:

6.2.1. ИСПОЛЬЗОВАТЬ ПОЛУЧЕННУЮ ИНФОРМАЦИЮ ИСКЛЮЧИТЕЛЬНО ДЛЯ ЦЕЛЕЙ, УКАЗАННЫХ В П. 4 НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.

6.2.2. ОБЕСПЕЧИТЬ ХРАНЕНИЕ КОНФИДЕНЦИАЛЬНОЙ ИНФОРМАЦИИ В ТАЙНЕ, НЕ РАЗГЛАШАТЬ БЕЗ ПРЕДВАРИТЕЛЬНОГО ПИСЬМЕННОГО РАЗРЕШЕНИЯ ПОЛЬЗОВАТЕЛЯ, А ТАКЖЕ НЕ ОСУЩЕСТВЛЯТЬ ПРОДАЖУ, ОБМЕН, ОПУБЛИКОВАНИЕ, ЛИБО РАЗГЛАШЕНИЕ ИНЫМИ ВОЗМОЖНЫМИ СПОСОБАМИ ПЕРЕДАННЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ, ЗА ИСКЛЮЧЕНИЕМ П.П. 5.2. И 5.3. НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.

6.2.3. ПРИНИМАТЬ МЕРЫ ПРЕДОСТОРОЖНОСТИ ДЛЯ ЗАЩИТЫ КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ СОГЛАСНО ПОРЯДКУ, ОБЫЧНО ИСПОЛЬЗУЕМОГО ДЛЯ ЗАЩИТЫ ТАКОГО РОДА ИНФОРМАЦИИ В СУЩЕСТВУЮЩЕМ ДЕЛОВОМ ОБОРОТЕ.

6.2.4. ОСУЩЕСТВИТЬ БЛОКИРОВАНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОТНОСЯЩИХСЯ К СООТВЕТСТВУЮЩЕМУ ПОЛЬЗОВАТЕЛЮ, С МОМЕНТА ОБРАЩЕНИЯ ИЛИ ЗАПРОСА ПОЛЬЗОВАТЕЛЯ ИЛИ ЕГО ЗАКОННОГО ПРЕДСТАВИТЕЛЯ ЛИБО УПОЛНОМОЧЕННОГО ОРГАНА ПО ЗАЩИТЕ ПРАВ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ НА ПЕРИОД ПРОВЕРКИ, В СЛУЧАЕ ВЫЯВЛЕНИЯ НЕДОСТОВЕРНЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ ИЛИ НЕПРАВОМЕРНЫХ ДЕЙСТВИЙ.

<b>7. ОТВЕТСТВЕННОСТЬ СТОРОН</b>

7.1. АДМИНИСТРАЦИЯ САЙТА, НЕ ИСПОЛНИВШАЯ СВОИ ОБЯЗАТЕЛЬСТВА, НЕСЁТ ОТВЕТСТВЕННОСТЬ ЗА УБЫТКИ, ПОНЕСЁННЫЕ ПОЛЬЗОВАТЕЛЕМ В СВЯЗИ С НЕПРАВОМЕРНЫМ ИСПОЛЬЗОВАНИЕМ ПЕРСОНАЛЬНЫХ ДАННЫХ, В СООТВЕТСТВИИ С ЗАКОНОДАТЕЛЬСТВОМ ЭСТОНСКОЙ РЕСПУБЛИКИ.

7.2. В СЛУЧАЕ УТРАТЫ ИЛИ РАЗГЛАШЕНИЯ КОНФИДЕНЦИАЛЬНОЙ ИНФОРМАЦИИ АДМИНИСТРАЦИЯ САЙТА НЕ НЕСЁТ ОТВЕТСТВЕННОСТЬ, ЕСЛИ ДАННАЯ КОНФИДЕНЦИАЛЬНАЯ ИНФОРМАЦИЯ:

7.2.1. СТАЛА ПУБЛИЧНЫМ ДОСТОЯНИЕМ ДО ЕЁ УТРАТЫ ИЛИ РАЗГЛАШЕНИЯ.

7.2.2. БЫЛА ПОЛУЧЕНА ОТ ТРЕТЬЕЙ СТОРОНЫ ДО МОМЕНТА ЕЁ ПОЛУЧЕНИЯ АДМИНИСТРАЦИЕЙ САЙТА.

7.2.3. БЫЛА РАЗГЛАШЕНА С СОГЛАСИЯ ПОЛЬЗОВАТЕЛЯ.

<b>8. РАЗРЕШЕНИЕ СПОРОВ</b>

8.1. ДО ОБРАЩЕНИЯ В СУД С ИСКОМ ПО СПОРАМ, ВОЗНИКАЮЩИМ ИЗ ОТНОШЕНИЙ МЕЖДУ ПОЛЬЗОВАТЕЛЕМ САЙТА ИНТЕРНЕТ-МАГАЗИНА И АДМИНИСТРАЦИЕЙ САЙТА, ОБЯЗАТЕЛЬНЫМ ЯВЛЯЕТСЯ ПРЕДЪЯВЛЕНИЕ ПРЕТЕНЗИИ (ПИСЬМЕННОГО ПРЕДЛОЖЕНИЯ О ДОБРОВОЛЬНОМ УРЕГУЛИРОВАНИИ СПОРА).

8.2 .ПОЛУЧАТЕЛЬ ПРЕТЕНЗИИ В ТЕЧЕНИЕ 30 КАЛЕНДАРНЫХ ДНЕЙ СО ДНЯ ПОЛУЧЕНИЯ ПРЕТЕНЗИИ, ПИСЬМЕННО УВЕДОМЛЯЕТ ЗАЯВИТЕЛЯ ПРЕТЕНЗИИ О РЕЗУЛЬТАТАХ РАССМОТРЕНИЯ ПРЕТЕНЗИИ.

8.3. ПРИ НЕ ДОСТИЖЕНИИ СОГЛАШЕНИЯ СПОР МОЖЕТ БЫТЬ ПЕРЕДАН НА РАССМОТРЕНИЕ В СУДЕБНЫЙ ОРГАН В СООТВЕТСТВИИ С ДЕЙСТВУЮЩИМ ЗАКОНОДАТЕЛЬСТВОМ ЭСТОНСКОЙ РЕСПУБЛИКИ

<b>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</b>

9.1. АДМИНИСТРАЦИЯ САЙТА ВПРАВЕ ВНОСИТЬ ИЗМЕНЕНИЯ В НАСТОЯЩУЮ ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ БЕЗ СОГЛАСИЯ ПОЛЬЗОВАТЕЛЯ.

9.2. НОВАЯ ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ВСТУПАЕТ В СИЛУ С МОМЕНТА ЕЕ РАЗМЕЩЕНИЯ НА САЙТЕ ИНТЕРНЕТ-МАГАЗИНА, ЕСЛИ ИНОЕ НЕ ПРЕДУСМОТРЕНО НОВОЙ РЕДАКЦИЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.

9.3. ДЕЙСТВУЮЩАЯ ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ РАЗМЕЩЕНА НА СТРАНИЦЕ ПО АДРЕСУ HTTPS://ROBUXPIER.IO/PRIVACY`.split(new RegExp(/\s+\r?\n/))
