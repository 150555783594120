export default {
  data () {
    return {
      promoCode: {
        value: null,
        loading: false,
        error: '',
        status: '',
        bonus: null,
        bonusForGamepass: null,
        validValue: null
      }
    }
  },
  methods: {
    async applyPromo (promoCodeValue, totalRobux) {
      promoCodeValue = promoCodeValue?.trim()?.toUpperCase()
      this.promoCode.loading = true
      const requestUrl = `https://transfer.backend-pier.com/check_promo?promo=${promoCodeValue}&robux_amount=${totalRobux}`
      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      try {
        const response = await fetch(requestUrl, requestParams)
        const data = await response.json()

        if (data.status) {
          this.promoCode.status = 'success'

          if (data.promo.type === 'fix') {
            this.promoCode.bonus = data.promo.bonus
            this.promoCode.bonusForGamepass = data.promo.bonus
          } else if (data.promo.type === 'percent') {
            this.promoCode.bonus = +Math.floor(Number(totalRobux * data.promo.bonus)).toFixed(2)
            this.promoCode.bonusForGamepass = +Math.floor(Number(totalRobux * data.promo.bonus)).toFixed(2)
          }

          this.$store.commit('setPromoBonus', this.promoCode.bonus)
        } else {
          this.promoCode.status = 'error'
          this.promoCode.error = this.$t('actions.promocode.error')

          if (data.promo_config) {
            let code = data.promo_config
            if (data.promo_config?.length) {
              code = data.promo_config[0]
              this.promoCode.validValue = data.promo_config[1]
            }
            this.promoCode.error = this.PROMO_ERRORS[code]
          } else if (!data.promo_usage && !this.$store.state.account.username) {
            this.promoCode.error = this.PROMO_ERRORS[6]
          } else if (data.promo_usage) {
            this.promoCode.error = this.$t('actions.promocode.reuse')
          }
        }

        return this.promoCode.status === 'success'
      } catch (error) {
        console.error(error)
        return false
      } finally {
        this.promoCode.loading = false
      }
    },
    resetPromo () {
      this.promoCode = {
        value: null,
        loading: false,
        error: '',
        status: '',
        bonus: null,
        bonusForGamepass: null,
        validValue: null
      }
    }
  },
  computed: {
    PROMO_ERRORS () {
      return {
        1: this.$t('actions.promocode.isNoPromo'),
        2: this.$t('actions.promocode.promoExpired'),
        4: this.$t('actions.promocode.minError', { min: this.promoCode.validValue }),
        5: this.$t('actions.promocode.maxError', { max: this.promoCode.validValue }),
        6: this.$t('actions.promocode.notAuth')
      }
    }
  }
}
