<template>
  <div class="referal">
    Proccess referal code...
  </div>
</template>
<script>

export default {
  name: 'Referal',
  created () {
    localStorage.setItem('ref_code', this.$route.params.code)
    this.$router.push('/')
  }
}
</script>
