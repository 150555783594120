<template>
  <div class="history">
    <div class="change-password" v-if="$store.state.change_password">
      <ChangePassword />
    </div>
    <!--    <div class="history-header">-->
    <div class="wrapper">
      <Navigation />
    </div>
    <!--    </div>-->
<!--  <img src="" alt="">-->
    <div class="history-content">
      <Loader v-if="loading" />
      <div class="history-content__header">
        <div class="history-content__header__block active">
          <p>{{ $t("general.transfer") }}</p>
          <small>{{ totalOrders }}</small>
        </div>
      </div>
      <div class="history-table">
        <Loader v-if="ordersLoading" />
        <div class="history-table__header">
          <p class="id">ID</p>
          <p class="nickname">{{ $t("history.nickname") }}</p>
          <p class="sum-robux">{{ $t("history.sumRobux") }}</p>
          <p class="sum-rub">{{ $t("history.sumRub") }}</p>
          <p class="status">{{ $t("history.status") }}</p>
          <p class="description">{{ $t("history.description") }}</p>
          <p class="timer">{{ $t("history.timer") }}</p>
        </div>
        <div class="history-table__rows">
          <div
            v-for="order in orders"
            :key="order._id"
            class="history-table__row"
          >
            <p class="id">
              <span>{{ order._id.slice(-4) }}</span>
              <Button
                class="history-table__copy"
                @click="copyText(order._id)"
                size="sm"
              >
                {{ $t('navigation.dropdown.copyIdBase') }}
              </Button>

            </p>
            <p class="copy">

            </p>
            <p class="nickname">{{ order.username }}</p>
            <p class="sum-robux">
              <template v-if="order.promo">
                <span>{{ order.promo.robux_amount_no_promo }} R$</span>
                <span v-if="order.promo" class="promo">+ {{ getPromoBonus(order.promo) }} R$</span>
              </template>
              <template v-else><span>{{ order.robux_amount }} R$</span></template>
            </p>
            <p v-if="false" class="sum-rub">
              {{ getOrderTotal(order) }}
              {{ $store.getters.getCurrencySign(order.currency.toLowerCase()) }}
            </p>
            <p class="sum-rub">{{ order.amount.rub.toFixed(2) }} ₽</p>
            <p class="status">
              <span
                class="error"
                v-if="
                  order.status === 'error' || order.status === 'system_error'
                "
                >{{ $t("history.error") }}</span
              >
              <span
                class="waiting"
                v-if="
                  ['waiting', 'paid', 'code', 'robuxship'].includes(
                    order.status
                  )
                "
                >{{ $t("history.wait") }}</span
              >
              <span class="success" v-if="order.status === 'success'">{{
                $t("history.success")
              }}</span>
            </p>
            <p class="description">{{ getOrderDescription(order) }}</p>
            <p class="timer">{{ parseDate(order._id) }}</p>
          </div>
        </div>
      </div>
      <div class="history-footer" v-show="totalOrders > 0">
        <div class="history-footer__nav">
          <div
            class="history-footer__nav__left"
            v-on="
              currentPage - 1 <= 0
                ? {}
                : { click: () => changeOrders(currentPage - 1) }
            "
          >
            <img
              src="./../../assets/images/history/left-arrow.svg"
              alt="left"
            />
          </div>
          <div class="history-footer__nav__center">
            <p
              v-for="i in currentPaginationPages"
              :key="i"
              :class="{ active: i === currentPage }"
              @click="changeOrders(i)"
            >
              {{ i }}
            </p>
            <p v-if="showEllipsisBefore">...</p>
            <p v-if="showLastPage" @click="currentPage = totalPages">{{ totalPages }}</p>
          </div>
          <div
            class="history-footer__nav__right"
            v-on="
              currentPage + 1 > totalPages
                ? {}
                : { click: () => changeOrders(currentPage + 1) }
            "
          >
            <img
              src="./../../assets/images/history/right-arrow.svg"
              alt="right"
            />
          </div>
        </div>
        <div class="history-footer__info">
          <i18n path="history.orderCount" tag="p">
            <template v-slot:order>
              {{ orders.length }}
            </template>

            <template v-slot:length>
              {{ totalOrders }}
            </template>
          </i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './history.scss'

import Navigation from '@/components/Navigation/Navigation'
import ChangePassword from '@/components/ChangePassword/ChangePassword'
import Loader from '@/components/LoaderBar/LoaderBar'
import Button from '@/components/Base/Button/Button.vue'
import { getPromoBonus } from '@/utils/promo'
import { copyText } from '@/utils/copyText'

export default {
  name: 'History',
  components: {
    Button,
    Navigation,
    ChangePassword,
    Loader
  },
  inject: ['currencyMap'],
  data () {
    return {
      loading: true,
      ordersLoading: false,
      totalPages: 1,
      currentPage: 1,
      totalOrders: 0,
      orders: [],
      timers: []
    }
  },
  created () {
    this.loading = true

    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch('https://transfer.backend-pier.com/get_history?count=7&page=1', requestParams)
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.totalPages = data.total_pages
          this.totalOrders = data.total_orders
          this.orders = data.orders

          // this.orders.forEach((order) => {
          //   const orderDate = new Date(order.date)
          //   orderDate.setHours(orderDate.getHours() + 1)
          //   const delta = orderDate - Date.now()

          //   this.timers.push({ [order._id]: delta })
          // })

          // this.initTimers()
        }

        this.loading = false
      })
      .catch(err => console.log(err))
  },
  computed: {
    currentPaginationPages () {
      const indexes = []
      const maxDisplayedIndexes = 5
      const halfDisplayedIndexes = Math.floor(maxDisplayedIndexes / 2)

      let start = Math.max(this.currentPage - halfDisplayedIndexes, 1)
      const end = Math.min(start + maxDisplayedIndexes - 1, this.totalPages)

      if (end - start < maxDisplayedIndexes - 1) {
        start = Math.max(end - maxDisplayedIndexes + 1, 1)
      }

      for (let i = start; i <= end; i++) {
        indexes.push(i)
      }

      return indexes
    },
    showEllipsisBefore () {
      return (this.currentPaginationPages[0] > 1 || this.totalPages > 5) && this.currentPage < this.totalPages - 2
    },
    showLastPage () {
      return this.currentPaginationPages[this.currentPaginationPages.length - 1] < this.totalPages
    }
  },
  methods: {
    copyText,
    getPromoBonus,
    getOrderTotal (order) {
      return order.amount[order.currency.toLowerCase()].toFixed(2)
    },
    getOrderDescription (order) {
      const code = order.code_description
      const texts = {
        0: this.$t('order.descriptions.wait'),
        1: this.$t('order.descriptions.notEnoughFunds'),
        2: this.$t('order.descriptions.systemError'),
        3: this.$t('order.descriptions.checkVip'),
        4: this.$t('order.descriptions.connectionError'),
        5: this.$t('order.descriptions.success'),
        6: this.$t('order.descriptions.checkGamepass')
      }

      return texts[code]
    },
    changeOrders (page) {
      this.ordersLoading = true
      this.currentPage = page

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(`https://transfer.backend-pier.com/get_history?count=7&page=${page}`, requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            this.timers = []
            this.totalPages = data.total_pages
            this.totalOrders = data.total_orders
            this.orders = data.orders

            // this.orders.forEach((order) => {
            //   const orderDate = new Date(order.date)
            //   orderDate.setHours(orderDate.getHours() + 1)
            //   const delta = orderDate - Date.now()

            //   this.timers.push({ [order._id]: delta })
            // })

            // this.initTimers()

            this.ordersLoading = false
          } else {
            this.ordersLoading = false
          }
        })
        .catch(err => console.log(err))
    },
    initTimers () {
      this.timers.forEach(timer => {
        const key = Object.entries(timer)[0][0]

        const iid = setInterval(() => {
          timer[key] -= 1000

          if (timer[key] <= 0) {
            clearInterval(iid)
          }
        }, 1000)
      })
    },
    getTimer (id) {
      const rawTimer = this.timers.find(t => Object.keys(t)[0] === id)
      const rawDelta = Object.values(rawTimer)[0]

      if (rawDelta <= 0) {
        return '00:00:00'
      } else {
        const formatedHours = (
          '0' + String(Math.trunc(rawDelta / 1000 / 60 / 60))
        ).slice(-2)
        const formatedMinutes = (
          '0' + String(Math.trunc((rawDelta / 1000 / 60) % 60))
        ).slice(-2)
        const formatedSeconds = (
          '0' + String(Math.trunc(((rawDelta / 1000) % 60) % 60))
        ).slice(-2)

        return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
      }
    },
    parseDate (id) {
      const foundOrder = this.orders.find(order => order._id === id)
      const date = new Date(foundOrder.date)

      const day = ('0' + date.getDate()).substr(-2)
      const month = ('0' + (date.getMonth() + 1)).substr(-2)
      const year = date.getFullYear()
      const hour = ('0' + date.getHours()).substr(-2)
      const minutes = ('0' + date.getMinutes()).substr(-2)

      return `${day}.${month}.${year} ${hour}:${minutes}`
    }
  }
}
</script>
