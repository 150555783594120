module.exports.validatePassword = (password) => {
  const passRegExp = /^(?=.*[a-z])(?=.*[A-Z]).{6,18}$/
  return passRegExp.test(String(password))
}

module.exports.validateEmail = (email) => {
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegExp.test(String(email))
}

module.exports.validateLength = (text, min, max) => {
  return (text.length >= min && text.length <= max)
}
