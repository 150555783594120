<template>
  <div class="base-input" :class="{ 'reset-margin': error || hint }">
    <div class="base-input__wrap" :class="{ error: state === 'error' }">
      <div class="base-input__icon">
        <Icon v-if="icon" :name="icon" />
        <span v-if="iconText">{{ iconText }}</span>
      </div>
      <div class="base-input__input" :class="{ disabled: disabled }">
        <input
          v-bind="$attrs"
          :value="value"
          :type="inputType"
          :placeholder="placeholder"
          @input="inputHandler($event.target.value)"
          @keyup.enter="$emit('enter')"
          @change="$emit('change')"
        >
        <div v-if="bonus" class="base-input__bonus">{{ bonus }}</div>
        <Icon
          v-if="password"
          class="base-input__right-icon"
          :name="`/SignIn/${inputValueHidden ? 'close-eye' : 'open-eye'}.svg`"
          @click="toggleHidden"
        />
        <Icon
          v-if="rightIcon"
          class="base-input__right-icon"
          :name="rightIcon"
          @click="$emit('rightIconClick')"
        />
      </div>
    </div>
    <p
      v-if="hint || error"
      class="base-input__hint"
      :class="{ error: state === 'error', success: state === 'success' }"
    >
      {{ state === 'error' ? error : hint }}
    </p>
  </div>
</template>
<script>
import './input.scss'
import Icon from '@/components/Base/Icon/Icon.vue'
export default {
  name: 'Input',
  components: { Icon },
  props: {
    value: {
      value: String,
      default: ''
    },
    icon: {
      value: String,
      default: ''
    },
    rightIcon: {
      value: String,
      default: ''
    },
    state: {
      value: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'success', 'error'].includes(value)
      }
    },
    info: {
      value: String,
      default: ''
    },
    type: {
      value: String,
      default: 'text',
      validator: function (value) {
        return ['text', 'number', 'password'].includes(value)
      }
    },
    iconText: {
      value: String,
      default: ''
    },
    placeholder: {
      value: String,
      default: ''
    },
    hint: {
      value: String,
      default: ''
    },
    error: {
      value: String,
      default: 'Ошибка'
    },
    password: {
      value: Boolean,
      default: false
    },
    disabled: {
      value: Boolean,
      default: false
    },
    bonus: {
      value: String,
      default: ''
    }
  },
  data () {
    return {
      inputValueHidden: true
    }
  },
  methods: {
    toggleHidden () {
      this.inputValueHidden = !this.inputValueHidden
    },
    inputHandler (data) {
      if (this.type === 'number') {
        data = data?.toString().replace(/\D/g, '')
      }
      this.$emit('input', data)
    }
  },
  computed: {
    inputType () {
      if (!this.password) return this.type

      return this.inputValueHidden ? 'password' : 'text'
    }
  }
}
</script>
