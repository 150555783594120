export const clickOutside = {
  bind: function (element, binding) {
    element.clickOutsideEvent = (event) => {
      if (!(element === event.target || element.contains(event.target))) {
        binding.value(event)
      }
    }
    document.addEventListener('click', element.clickOutsideEvent)
  },
  unbind: function (element) {
    document.removeEventListener('click', element.clickOutsideEvent)
  }
}
