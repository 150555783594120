<template>
  <div class="loader" >
    <img src="./../../assets/images/loader/icon.svg" alt="icon">
  </div>
</template>

<script>
import './loaderBar.scss'

export default {
  name: 'LoaderBar'
}
</script>
