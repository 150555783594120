<template>
  <div class="sign-up" :class="{ step: isStep }">
    <Captcha v-if="showCaptcha" @hideCaptcha="hideCaptcha" @onVerify="verifyToken" />
    <form-layout
      :title="$t('auth.registration')"
      :close="closeHandler"
      :back="isStep ? () => $emit('back') : goToSignIn"
    >
      <div class="sign-up__main">
      <Loader v-if="isLoading" />
      <Input
        v-model="values.email"
        :error="errors.email.message"
        :state="errors.email.message ? 'error' : 'default'"
        :placeholder="$t('auth.inputs.email')"
        icon="/SignUp/mail.svg"
        @change="validateRegisterEmail"
        @enter="validateCaptcha"
      />
      <Input
        v-model="values.login"
        :error="errors.username.message"
        :state="errors.username.message ? 'error' : 'default'"
        :placeholder="$t('auth.inputs.login')"
        icon="/SignUp/login.svg"
        @change="validateRegisterUsername"
        @enter="validateCaptcha"
      />
      <Input
        v-model="values.password"
        :error="errors.password.message"
        :state="errors.password.message ? 'error' : 'default'"
        :placeholder="$t('auth.inputs.password')"
        icon="/SignUp/lock.svg"
        type="password"
        :password="true"
        @change="validateRegisterPassword"
        @enter="validateCaptcha"
      />
      <Input
        v-model="values.password2"
        :error="errors.password2.message"
        :state="errors.password2.message ? 'error' : 'default'"
        :placeholder="$t('auth.inputs.passwordSubmit')"
        icon="/SignUp/lock.svg"
        type="password"
        :password="true"
        @change="validateRegisterPassword2"
        @enter="validateCaptcha"
      />

      <div class="sign-up__main__agree">
        <div class="sign-up__main__agree__point" @click="agree">
          <div class="sign-up__main__agree__point__img"><img id="sign-up_agree" src="@/assets/images/SignUp/point.svg"
              alt=""></div>
        </div>

        <i18n path="auth.agreement" tag="p">
          <template v-slot:policy>
            <span><router-link to="/privacy" target="_blank">{{ $t('auth.privacyPolicy') }}</router-link></span>
          </template>

          <template v-slot:agreement>
            <span><router-link to="/termsofuse" target="_blank">{{ $t('auth.userAgreement') }}</router-link></span>
          </template>
        </i18n>
      </div>
      <Button
        left-icon="/SignUp/create.svg"
        :disabled="!right"
        @click="validateCaptcha"
      >
        {{ $t('actions.createAccount') }}
      </Button>
      <p class="sign-up__main__have-account">{{ $t('auth.haveAccount') }} <span
          @click="goToSignIn">{{ $t('actions.signin') }}!</span></p>
    </div>
    </form-layout>
  </div>
</template>

<script>
import './signup.scss'

import Loader from '@/components/LoaderBar/LoaderBar'
import Captcha from '@/components/Captcha/Captcha'

import { validateEmail, validateLength, validatePassword } from './../../utils/validate'
import Input from '@/components/Base/Input/Input.vue'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Button from '@/components/Base/Button/Button.vue'

export default {
  name: 'SignUp',
  components: {
    Button,
    FormLayout,
    Input,
    Loader,
    Captcha
  },
  props: {
    isStep: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      showCaptcha: false,
      stateAgree: false,
      right: false,
      token: '',
      values: {
        email: '',
        login: '',
        password: '',
        password2: '',
        agreement: false
      },
      errors: {
        email: {
          show: false,
          status: false,
          message: ''
        },
        username: {
          show: false,
          status: false,
          message: ''
        },
        password: {
          show: false,
          status: false,
          message: ''
        },
        password2: {
          show: false,
          status: false,
          message: ''
        }
      }
    }
  },
  methods: {
    hideCaptcha () {
      this.showCaptcha = false
    },
    verifyToken (token) {
      this.hideCaptcha()
      this.token = token
      this.registration()
    },
    validateCaptcha () {
      this.showCaptcha = true
    },
    check () {
      let error = false

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key].status === false) {
          error = true
        }
      })

      this.right = error === false && this.values.agreement
    },
    agree () {
      const stateIcon = document.querySelector('#sign-up_agree')

      if (this.stateAgree === false) {
        stateIcon.style.display = 'block'
        this.values.agreement = true
      }

      this.check()
    },
    eye (el) {
      const icon = document.querySelector(`#${el}`)
      const input = icon.parentElement.querySelector('input')
      if (icon.src.includes('close-eye')) {
        icon.src = require('@/assets/images/SignUp/open-eye.svg')
        input.type = 'text'
      } else {
        icon.src = require('@/assets/images/SignUp/close-eye.svg')
        input.type = 'password'
      }
      return false
    },
    registration () {
      if (!this.token) return
      this.isLoading = true

      this.validateRegisterUsername()
      this.validateRegisterEmail()
      this.validateRegisterPassword()
      this.validateRegisterPassword2()

      let error = false

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key].status === false) {
          error = true
        }
      })

      if (error || this.values.agreement === false) {
        this.isLoading = false
        return
      }

      const requestBody = {
        email: this.values.email,
        username: this.values.login,
        password: this.values.password,
        language: this.$store.state.language.toUpperCase(),
        currency: this.$store.state.currency.toUpperCase(),
        clcaptcha: this.token
      }

      const refCode = localStorage.getItem('ref_code')

      if (refCode) {
        requestBody.ref_code = refCode
      }

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: requestHeaders
      }

      const codes = {
        1: this.$t('auth.errors.passwordIsTooSmall'),
        2: this.$t('auth.errors.oneUppercase'),
        3: this.$t('auth.errors.nicknameIsTooSmall'),
        4: this.$t('auth.errors.inactiveEmail'),
        5: this.$t('auth.errors.userExist')
      }

      fetch('https://users.backend-pier.com/api/v1/user/register', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.isLoading = false

          if (data.status === 400 || data.status === 500) {
            this.errors.email.status = false
            this.errors.email.show = true
            this.errors.email.message = data.status === 400 ? this.$t('general.errors.internal') : this.$t('general.errors.server')
            return
          }

          if (data.error_codes) {
            let errorMessage
            Object.keys(data.error_codes).forEach((key) => {
              if (!data.error_codes[key].length || !this.errors[key]) return

              this.errors[key].status = false
              this.errors[key].show = true
              this.errors[key].message = codes[data.error_codes[key][0]]
              errorMessage = codes[data.error_codes[key][0]]
            })

            this.$analytics.send('login_error', {
              type: 'req',
              method: 'email',
              message: errorMessage
            })
          } else {
            this.isStep ? this.$emit('success') : this.$store.commit('stateSignUp')
            this.$store.dispatch('signIn', data)

            this.$analytics.send('login', {
              type: 'reg',
              method: 'email',
              user_id: data.data?.user_id
            })
          }
        })
        .catch((err) => console.log(err))
    },
    validateRegisterUsername () {
      let errorMessage = ''
      let usernameState = true

      if (this.values.login.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        usernameState = false
      } else if (validateLength(this.values.login, 4, 60) === false) {
        errorMessage = this.$t('auth.errors.nicknameIsTooSmall')
        usernameState = false
      } else {
        errorMessage = ''
        usernameState = true
      }

      this.errors.username.status = usernameState
      this.errors.username.show = !usernameState
      this.errors.username.message = errorMessage

      this.check()
    },
    validateRegisterEmail () {
      let errorMessage = ''
      let emailState = true

      if (this.values.email.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        emailState = false
      } else if (validateEmail(this.values.email) === false) {
        errorMessage = this.$t('auth.errors.email')
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.errors.email.status = emailState
      this.errors.email.show = !emailState
      this.errors.email.message = errorMessage

      this.check()
    },
    validateRegisterPassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.values.password.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        passwordState = false
      } else if (this.values.password.length < 6) {
        errorMessage = this.$t('auth.errors.passwordIsTooSmall')
        passwordState = false
      } else if (validatePassword(this.values.password) === false) {
        errorMessage = this.$t('auth.errors.oneUppercase')
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.errors.password.status = passwordState
      this.errors.password.show = !passwordState
      this.errors.password.message = errorMessage

      this.check()
    },
    validateRegisterPassword2 () {
      let errorMessage = ''
      let passwordState = true

      if (this.values.password2 !== this.values.password) {
        errorMessage = this.$t('auth.errors.passwordNotMatch')
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.errors.password2.status = passwordState
      this.errors.password2.show = !passwordState
      this.errors.password2.message = errorMessage

      this.check()
    },
    goToSignIn () {
      this.$store.commit('stateSignIn')
      this.$store.commit('stateSignUp')
    },
    closeHandler () {
      if (this.isStep) {
        this.$emit('close')
        return
      }
      this.$store.commit('stateSignUp')
    }
  }
}
</script>
