export default `DIE BEDINGUNGEN FÜR DIE NUTZUNG DER INFORMATIONEN - DES UNTERNEHMEN ROBUXPIER.IO

DIESES ABKOMMEN REGELT DAS VERHÄLTNIS ZWISCHEN DEM BENUTZER UND DEM URHEBERRECHTSINHABER DER WEBSITE
<b>INHALT:</b>

1. DIE VERWENDETEN BEGRIFFE.

2. DEN GEGENSTAND DES ABKOMMENS.

3. VERFAHREN FÜR DAS INKRAFTTRETEN DES ABKOMMENS.

4. RECHTE UND PFLICHTEN DER PARTEIEN.

4.1 RECHTE DES BENUTZERS.

4.2 PFLICHTEN DES NUTZERS.

4.3 DER BENUTZER HAT KEINE RECHTE.

4.4 RECHTE DES BETREIBERS.

4.5 PFLICHTEN DES BETREIBERS.

4.6 BESCHRÄNKUNG DER HAFTUNG DES BETREIBERS.

4.7 DER BETREIBER ÜBERNIMMT KEINE GARANTIE.

5. VERTRAULICHKEIT UND SICHERHEIT.

6. ZUSÄTZLICHE KOSTENPFLICHTIGE DIENSTLEISTUNGEN UND DAS ZAHLUNGSVERFAHREN.

7. AUSSCHLUSS VON GARANTIEN.

8. ZUSÄTZLICHE BESTIMMUNGEN.

9. KONTAKTINFORMATIONEN.

<b>1. DIE ZU VERWENDENDEN BEGRIFFE</b>

<b>«BETREIBER»</b> - PORTAL-BESITZERHTTPS://ROBUXPIER.IO, DER RECHTSINHABER DES PORTALS IST VERTRAGSPARTEI DER NUTZUNGSVEREINBARUNG. DER BETREIBER FÜHRT DIE VERWALTUNG UND WARTUNG DURCH, BIETET DEN NUTZERN ZUGANG ZUM PORTAL, DIENSTLEISTUNGEN (AUCH BEZAHLTE), UNTER DEN BEDINGUNGEN DIESES ABKOMMENS.

<b>«BENUTZER»</b> - EINE PERSON, DIE DAS PORTAL BESUCHT ODER SICH AN PORTALPROJEKTEN BETEILIGT HTTPS://ROBUXPIER.IO

<b>BENUTZER</b>, IST EBENSO WIE DER BETREIBER EINE PARTEI DER ABKOMMENS.

<b>«PORTAL»</b> - SPEZIELLE SOFTWARE- UND HARDWAREKOMPLEXE, DIE DIE RESSOURCEN DES BETREIBERS BELASTEN. DER ZUGANG DER NUTZER ZUM PORTAL WIRD AUSSCHLIESSLICH VOM BETREIBER GEWÄHRT. ALLE RECHTE ZUR NUTZUNG DIESES PORTALS GEHÖREN AUSSCHLIESSLICH DEM BETREIBER.

<b>«WEBSEITE»</b> — WEBSITE IM INTERNET UNTER HTTPS://ROBUXPIER.IO

<b>«SERVICES»</b> — DEN BENUTZERN DEN ZUGANG ZUM PORTAL, DIE NUTZUNG VON FUNKTIONEN UND DIENSTEN UND DIE TEILNAHME AN PROJEKTEN ZU DEN IN DEN VEREINBARUNGEN FESTGELEGTEN BEDINGUNGEN ZU ERMÖGLICHEN. DIE DIENSTE DES BETREIBERS WERDEN KOSTENLOS ANGEBOTEN. DIE EINZIGEN AUSNAHMEN SIND ZUSÄTZLICHE KOSTENPFLICHTIGE DIENSTE. ALLE DIENSTE WERDEN VOM BETREIBER NUR INNERHALB DES PORTALS ERBRACHT, D. H. WÄHREND DER NUTZER ES NUTZT.

<b>« ZUSÄTZLICHE KOSTENPFLICHTIGE DIENSTE»</b> – DEM BENUTZER KOSTENPFLICHTIG ZUSÄTZLICHE SONDERFUNKTIONEN DES PORTALS ZUR VERFÜGUNG ZU STELLEN. DIESE DIENSTE SIND NICHT OBLIGATORISCH UND WERDEN AUF WUNSCH DES NUTZERS ERBRACHT. AUCH KOSTENPFLICHTIGE DIENSTE WERDEN VOM BETREIBER AUSSCHLIESSLICH INNERHALB DES PORTALS ANGEBOTEN.

<b>2. DER GEGENSTAND DES ABKOMMENS </b>

2.1. DIE BEREITSTELLUNG DES ZUGANGS ZUM PORTAL (DIENSTLEISTUNGEN, SERVICES, KOSTENPFLICHTIGE DIENSTE) DURCH DEN BETREIBER FÜR EINE UNBEGRENZTE ANZAHL VON PERSONEN UNTER DEN BEDINGUNGEN DIESES VERTRAGS.

2.2. DER BENUTZER VERSTEHT, DASS DER HAUPTZWECK DES PORTALPROJEKTS HTTPS://ROBUXPIER.IO DARIN BESTEHT, FREIZEITAKTIVITÄTEN UND UNTERHALTUNG DURCH DEN BETREIBER ANZUBIETEN, DIE IN KEINER WEISE MIT GLÜCKSSPIELEN ZUSAMMENHÄNGEN.

<b>3. VERFAHREN FÜR DAS INKRAFTTRETEN DES ABKOMMENS </b>

3.1. MIT DER ANNAHME DIESES ABKOMMENS HAT DER BENUTZER DIE RECHTE UND PFLICHTEN, DIE IN DIESER VEREINBARUNG FESTGELEGT SIND.

3.2. DER BENUTZER AKZEPTIERT UND STIMMT DEN BEDINGUNGEN DIESES ABKOMMENS ZU, INDEM ER SICH AUF DER WEBSITE HTTPS://ROBUXPIER.IO REGISTRIERT. DIE TATSACHE DER AKZEPTANZ BEDEUTET, DASS DER BENUTZER ALLEN BEDINGUNGEN UND ANHÄNGEN DIESES ABKOMMENS VOLLSTÄNDIG UND BEDINGUNGSLOS ZUSTIMMT.

3.3. WENN DER BENUTZER MIT DEN BEDINGUNGEN DIESES ABKOMMENS, AUS WELCHEN GRÜNDEN AUCH IMMER, NICHT EINVERSTANDEN IST, SOLLTE ER/SIE DIE WEITERE NUTZUNG DER WEBSITE EINSTELLEN.

3.4. DIE WEBSITE KANN NUR GENUTZT WERDEN, NACHDEM DER BENUTZER DIESE VEREINBARUNG AKZEPTIERT HAT.

3.5. DURCH DIE ANNAHME DIESER VEREINBARUNG BESTÄTIGT DER NUTZER SEINE RECHTSFÄHIGKEIT, DIESE VEREINBARUNG EINZUGEHEN. DER BETREIBER IST NICHT VERPFLICHTET, DIE VOM NUTZER BEI DER REGISTRIERUNG ANGEGEBENEN DATEN ZU ÜBERPRÜFEN.

3.6. SOFERN FÜR DIE NUTZUNG DES INFOTAINMENTPORTALS HTTPS:/ROBUXPIER.IO ODER FÜR DIE TEILNAHME AN PORTALPROJEKTEN DIE ERSTELLUNG EINES ACCOUNTS (NACHFOLGEND "ACCOUNT") ERFORDERLICH IST, HAT DER NUTZER DEN REGISTRIERUNGSPROZESS ABZUSCHLIESSEN, INDEM ER DEM BETREIBER AKTUELLE, VOLLSTÄNDIGE UND KORREKTE ANGABEN (EINSCHLIESSLICH E-MAIL-ADRESSE) IN DER ENTSPRECHENDEN FORM ZUR VERFÜGUNG STELLT. IM FALLE DER VERÖFFENTLICHUNG VON NACHRICHTEN ÜBER DIE TEILNAHME AN PARTNERPROGRAMMEN AUF DEM PORTAL HTTPS://ROBUXPIER.IO HAT DER REGISTRIERTE NUTZER DAS RECHT, AN PREISAUSSCHREIBEN, VERLOSUNGEN UND WETTBEWERBEN AUF DER PARTNER-WEBSITE HTTPS://ROBUXPIER.IO TEILZUNEHMEN.

<b>4. RECHTE UND PFLICHTEN DER PARTEIEN</b>

4.1. RECHTE DES BENUTZERS

IM RAHMEN DIESES ABKOMMENS HAT DER BENUTZER DAS RECHT:

DAS PORTAL NUR FÜR PERSÖNLICHE, NICHT KOMMERZIELLE ZWECKE ZU NUTZEN.

ALLE VOM BETREIBER BEREITGESTELLTEN DIENSTE UND RESSOURCEN (EINSCHLIESSLICH ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE) ZU NUTZEN.

FALLS ERFORDERLICH, DIE TECHNISCHE UNTERSTÜTZUNG VON WEBSITES ZU VERWENDEN, KONTAKTIEREN SIE DEN BETREIBER MIT FRAGEN ÜBER DIE KONTAKTINFORMATIONEN ODER FEEDBACK-FORMULAR.

DIE WEBSITE KOSTENLOS ZU NUTZEN UND AN DEN PORTALPROJEKTEN TEILZUNEHMEN (AUSGENOMMEN KOSTENPFLICHTIGE ZUSATZDIENSTE).

4.2. PFLICHTEN DES BENUTZERS

DURCH DIE EINHALTUNG DER BEDINGUNGEN DIESES ABKOMMENS IST DER BENUTZER VERPFLICHTET:

BEI DER REGISTRIERUNG AUF DER WEBSITE GENAUE ANGABEN ZU MACHEN.

SELBSTÄNDIG ALLE NOTWENDIGEN MASSNAHMEN ZU ERGREIFEN, UM DIE EFFEKTIVE SICHERHEIT DES PERSÖNLICHEN KONTOS ZU GEWÄHRLEISTEN. DRITTEN KEINEN ZUGANG DAZU ZU GEWÄHREN.

FALLS ERFORDERLICH, AUF VERLANGEN DES BETREIBERS DEN NACHWEIS IHRER PERSÖNLICHEN DATEN ZU ERBRINGEN, DIE SIE ZUM ZEITPUNKT DER REGISTRIERUNG AUF DER WEBSITE ANGEGEBEN HABEN.

DIE ANWEISUNGEN DES BETREIBERS INNERHALB DIESES PORTALS AUSZUFÜHREN.

INNERHALB DIESES PORTALS KEINE URHEBERRECHTE UND GEISTIGEN EIGENTUMSRECHTE DES BETREIBERS ZU VERLETZEN.

ALLE BEDINGUNGEN DIESER VEREINBARUNG UNEINGESCHRÄNKT EINZUHALTEN.

DER BENUTZER VERPFLICHTET SICH, DIESES PORTAL NUR ZU UNTERHALTUNGSZWECKEN ZU NUTZEN UND KEINE GEWINNE AUS DIESEM PORTAL ZU ERZIELEN.

4.3. DER BENUTZER HAT KEINEN RECHT AUF

ÜBER DAS PORTAL DES BETREIBERS, DARF DER BENUTZER NICHT:

FEHLER (BUGS) DER WEBSITE UND DER PORTALDIENSTE ZU NUTZEN, AUF DIE GEMEINSAME DATENBANK, DAS COMPUTERSYSTEM ZUZUGREIFEN, DEN PROGRAMMIERCODE ZU ÄNDERN. DEM NUTZER IST ES UNTERSAGT, MALWARE ZU VERWENDEN, DIE DAS PORTAL BESCHÄDIGEN KANN, SOWIE SPEZIELLE SOFTWARE, DIE IHM EINE ÜBERLEGENHEIT GEGENÜBER ANDEREN NUTZERN VERSCHAFFT. IM FALLE SOLCHER UND ÄHNLICHER VERSTÖSSE HAT DER BETREIBER DAS RECHT, SANKTIONEN GEGEN DEN BENUTZER ZU VERHÄNGEN, EINSCHLIESSLICH DER SPERRUNG DES ZUGANGS ZU DEN WEBSITES UND DER LÖSCHUNG DES KONTOS.

DEN ZUGANG ZU DEN WEBSITES ANDERER BENUTZER EINZUSCHRÄNKEN.

SICH AN BETRÜGERISCHEN UND ANDEREN ILLEGALEN AKTIVITÄTEN ZU BETEILIGEN.

OHNE SCHRIFTLICHE GENEHMIGUNG DES BETREIBERS FÜR ETWAS ZU WERBEN, DAS NICHT MIT DEM PORTAL IN VERBINDUNG STEHT.

OBSZÖNE SPRACHE ZU VERWENDEN, DROHUNGEN GEGEN DEN BETREIBER ODER ANDERE NUTZER AUSZUSPRECHEN, MATERIALIEN ZU VERBREITEN, DIE GEWALT, RASSENHASS, ABLEHNUNG RELIGIÖSER ÜBERZEUGUNGEN ZU FÖRDERN, PORNOGRAFIE ENTHALTEN, FÜR DROGEN WERBEN ODER ZUM GEWALTSAMEN UMSTURZ DER REGIERUNG AUFRUFEN.

ANTI-WERBUNG FÜR DAS PORTAL ZU BETREIBEN, AUCH AUSSERHALB DES PORTALS.

DER BENUTZER ERKLÄRT SICH DAMIT EINVERSTANDEN, DASS SEINE RECHTE UND PFLICHTEN VOM BETREIBER GEÄNDERT/ERGÄNZT WERDEN KÖNNEN UND ER DARÜBER UNTER DEN BEI DER REGISTRIERUNG ANGEGEBENEN KONTAKTDATEN (ODER AUF DER WEBSITE) INFORMIERT WIRD.
4.4. RECHTE DES BETREIBERS

DIESES ABKOMMEN GEWÄHRT DEM BETREIBER DIE FOLGENDEN RECHTE:

JEDERZEIT UND NACH EIGENEM ERMESSEN UND EINSEITIG OHNE VORHERIGE BENACHRICHTIGUNG DER NUTZER DIE BEREITSTELLUNG DER DIENSTE SOWIE ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE ZU ERWEITERN, ZU ÄNDERN, ZU BEENDEN ODER EINZUSCHRÄNKEN.

ALLE VORGÄNGE AUF DEN PORTALEN NACH EIGENEM ERMESSEN ZU VERWALTEN. DEN ABLAUF VON PROZESSEN UNTERBRECHEN ODER ÄNDERN, OHNE DEN BENUTZER VORHER ZU BENACHRICHTIGEN.

IM FALLE EINES VERSTOSSES GEGEN DIESE VEREINBARUNG SANKTIONEN GEGEN DEN NUTZER VERHÄNGEN.

DIE AUF DEN PORTALEN VERÖFFENTLICHTEN BENUTZERINFORMATIONEN LÖSCHEN/BEARBEITEN

DIE IDENTIFIZIERUNG UND STATISTISCHE INFORMATIONEN ÜBER DEN BENUTZER ZU ÜBERWACHEN UND AUFZUBEWAHREN.

DEN BENUTZERN TECHNISCHE, WERBLICHE UND ANDERE INFORMATIONEN ÜBER DAS PORTAL, DIE DIENSTLEISTUNGEN UND DIE KOSTENPFLICHTIGEN DIENSTE ZUKOMMEN ZU LASSEN.

DEN BENUTZER ZU INFORMIEREN, ZU VERWARNEN, ZU RÜGEN UND ZU BENACHRICHTIGEN, WENN ER DIE BESTIMMUNGEN DIESER VEREINBARUNG NICHT EINHÄLT ODER VERLETZT. ALLE ANWEISUNGEN DES BETREIBERS MÜSSEN STRIKT BEFOLGT WERDEN.

RECHTLICHE MASSNAHMEN ERGREIFEN, UM IHR GEISTIGES EIGENTUM, DAS URHEBERRECHT, ZU SCHÜTZEN.

DAS PORTAL NACH EIGENEM ERMESSEN ZU MODIFIZIEREN, ZU VERÄNDERN, ZU ERGÄNZEN, OHNE DEN NUTZER VORHER ZU BENACHRICHTIGEN.

DIE UNTÄTIGKEIT DES BETREIBERS BEI DER VERLETZUNG DIESER VEREINBARUNG DURCH DEN NUTZER SCHLIESST DIE ANWENDUNG VON SANKTIONEN DURCH DEN BETREIBER SPÄTER NICHT AUS.

4.5. PFLICHTEN DES BETREIBERS

ALS VERTRAGSPARTNER DER NUTZUNGSVEREINBARUNG IST DER BETREIBER VERPFLICHTET

DEM NUTZER DIE MÖGLICHKEIT ZU GEBEN, DIE DIENSTLEISTUNGEN DES BETREIBERS INNERHALB DIESES PORTALS IN ANSPRUCH ZU NEHMEN (EINSCHLIESSLICH DES EMPFANGS ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTLEISTUNGEN)

DIE FRAGEN DER NUTZER ZU BEANTWORTEN UND IN STRITTIGEN SITUATIONEN ALLE MASSNAHMEN ZU ERGREIFEN, UM DIESE ZU KLÄREN.

4.6. BESCHRÄNKUNG DER HAFTUNG DES BETREIBERS

IN ÜBEREINSTIMMUNG MIT DIESEM ABSCHNITT, SO HAFTET DER BETREIBER NICHT FÜR:

JEGLICHE SCHÄDEN, DIE AN DEN PERSÖNLICHEN DATEN UND DEM COMPUTER DES NUTZERS IM ZUSAMMENHANG MIT DER NUTZUNG DES PORTALS UND DER WEBSITE VERURSACHT WERDEN ODER NUR VERURSACHT WERDEN KÖNNEN.

SCHÄDEN (DIREKT/INDIREKT), DIE DEM NUTZER IM ZUSAMMENHANG MIT DER NUTZUNG ODER UNERREICHBARKEIT DES PORTALS (UNMÖGLICHKEIT DER NUTZUNG), DEM VERHALTEN DRITTER AUF DEM PORTAL SOWIE ANDERER TEILNEHMER AN PORTALPROJEKTEN, DEM UNBEFUGTEN ZUGRIFF AUF DIE PERSÖNLICHEN DATEN DES NUTZERS ENTSTEHEN

ÄUSSERUNGEN, VERBREITETE INFORMATIONEN, NUTZERÄUSSERUNGEN UND SONSTIGE RECHTSWIDRIGE HANDLUNGEN DES NUTZERS AUF DEM PORTAL UND AUSSERHALB DES PORTALS.

INFORMATIONEN, DIE DER NUTZER BEI DER REGISTRIERUNG ANGEGEBEN HAT, VERLORENER ZUGANG ZUM PORTAL (LOGIN, PASSWORT, USW.)

VERLUST VON VIRTUELLEN WERTEN, DIE DER NUTZER DURCH DIE BEREITSTELLUNG VON DIENSTEN UND ZUSÄTZLICHEN BEZAHLTEN DIENSTEN DES BETREIBERS ERWORBEN HAT.

ZAHLUNG ZUSÄTZLICHER DIENSTLEISTUNGEN GEGEN EINE GEBÜHR DURCH DEN NUTZER UND DAMIT VERBUNDENE KOSTEN.

DEN UNUNTERBROCHENEN BETRIEB DES PORTALS.

DIE FÄHIGKEIT DES NUTZERS, AUF DAS INTERNET ZUZUGREIFEN, DIE GESCHWINDIGKEIT DER DATENÜBERTRAGUNG.

4.7. DER BETREIBER ÜBERNIMMT KEINE GARANTIE:

UNUNTERBROCHENEN, ZUVERLÄSSIGEN UND FEHLERFREIEN BETRIEB DES PORTALS, DEN ZUGANG ZU SEINEN RESSOURCEN, DIENSTLEISTUNGEN, EINSCHLIESSLICH ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE.

DIE TATSACHE, DASS DAS PORTAL DIE ANFORDERUNGEN UND ERWARTUNGEN DES NUTZERS VOLLSTÄNDIG ERFÜLLEN MUSS.

DASS DIE QUALITÄT DER ERBRACHTEN LEISTUNGEN (KOSTENPFLICHTIGE ZUSATZLEISTUNGEN) DEN ERWARTUNGEN DES NUTZERS ENTSPRICHT.

DER BETREIBER IST NICHT VERPFLICHTET, DOKUMENTE UND ANDERE BEWEISE FÜR DIE VERLETZUNG DIESER VEREINBARUNG UND DIE VERHÄNGUNG VON STRAFEN/DISZIPLINARSTRAFEN GEGEN DEN NUTZER AUF DESSEN ANFRAGE VORZULEGEN.

DER BENUTZER NUTZT DAS PORTAL, DIE WEBSEITE DES BETREIBERS AUSSCHLIESSLICH AUF EIGENES RISIKO, AUS FREIWILLIGEN STELLEN UND OHNE ZWANG. ER IST SICH DER MÖGLICHEN RISIKEN BEWUSST, DIE MIT DER NUTZUNG DER RESSOURCEN DES BETREIBERS VERBUNDEN SIND, UND HAT KEINE WESENTLICHEN ANSPRÜCHE GEGEN DEN BETREIBER.

<b>5. DATENSCHUTZ UND -SICHERHEIT </b>

5.1. VERTRAULICHE INFORMATIONEN - INFORMATIONEN, DIE DER BETREIBER BEI DER REGISTRIERUNG DES NUTZERS AUF DER WEBSITE SOWIE BEIM BESUCH VON WEBSITES/PORTALEN UND BEI DER TEILNAHME AN PORTALVERANSTALTUNGEN ERHÄLT.

5.2. VERTRAULICHE INFORMATIONEN DÜRFEN NICHT AN DRITTE WEITERGEGEBEN ODER WEITERVERMITTELT WERDEN.

5.3. PERSONENBEZOGENE DATEN KÖNNEN VOM BETREIBER NUR IN FOLGENDEN FÄLLEN WEITERGEGEBEN WERDEN

EIN OFFIZIELLES ERSUCHEN DER STRAFVERFOLGUNGSBEHÖRDEN (VERSTOSS GEGEN LOKALES UND INTERNATIONALES RECHT).

DER PERSÖNLICHE WILLE DES BENUTZERS.

UNMÖGLICHKEIT DER NUTZUNG DER DIENSTLEISTUNGEN UND ZUSÄTZLICHEN KOSTENPFLICHTIGEN DIENSTE AUF DEN PORTALEN (WOVOR DER NUTZER IM VORAUS GEWARNT WIRD).

VERSTÖSSE GEGEN DIE KLAUSELN DIESES ABKOMMENS (NACH ERMESSEN DES BETREIBERS).

5.4. DER BETREIBER GEWÄHRLEISTET DIE SICHERHEIT DER PERSONENBEZOGENEN DATEN DES NUTZERS DURCH DEN EINSATZ SPEZIELLER SOFTWARE. IM FALLE EINES UNBEFUGTEN ZUGRIFFS AUF DAS PORTAL/DIE WEBSITE DURCH DRITTE IST DIE SICHERHEIT DER PERSONENBEZOGENEN DATEN NICHT GEWÄHRLEISTET.

<b>6. ZUSÄTZLICHE KOSTENPFLICHTIGE DIENSTE UND ZAHLUNGSMODALITÄTEN </b>

6.1. DER BETREIBER BIETET AUF WUNSCH DES NUTZERS EINEN ZUSÄTZLICHEN KOSTENPFLICHTIGEN DIENST AN. ES ERMÖGLICHT DEM NUTZER, ERWEITERTE FUNKTIONEN DES PORTALS ZU NUTZEN - ER ERHÄLT SPIELWÄHRUNG AUF SEIN KONTO.

6.2. ZUSÄTZLICHE KOSTENPFLICHTIGE DIENSTE SIND KEINE VORAUSSETZUNG FÜR DIE NUTZUNG DES PORTALS UND DIE TEILNAHME AN PORTALPROJEKTEN.

6.3. AB DEM ZEITPUNKT, AN DEM DER PORTALBETREIBER DAS KONTO DES NUTZERS BELASTET, GILT DIE BEZAHLTE ZUSATZLEISTUNG ALS VOLLSTÄNDIG UND IN ANGEMESSENER QUALITÄT ERBRACHT.

6.4. NACH DER ERBRINGUNG DER KOSTENPFLICHTIGEN ZUSATZLEISTUNG WIRD DAS FÜR DEREN ERWERB AUSGEGEBENE GELD NICHT ZURÜCKERSTATTET.

6.5. DER NUTZER ERKLÄRT SICH DAMIT EINVERSTANDEN, DASS DER BETREIBER DAS RECHT HAT, PERSONENBEZOGENE DATEN ZU SPEICHERN, DIE ER BEIM ERWERB ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE ERHALTEN HAT.

6.6. DIE LISTE DER KOSTENPFLICHTIGEN ZUSATZLEISTUNGEN UND DEREN PREISE WERDEN NUR VOM BETREIBER AUF DER WEBSITE/DEM PORTAL VERÖFFENTLICHT. ALLE INFORMATIONEN UND ANGEBOTE ZUM KAUF DIESER DIENSTE AUS DRITTQUELLEN GELTEN ALS BETRUG, FÜR DEN DER BETREIBER NICHT VERANTWORTLICH IST.

6.7. DIE ART UND WEISE UND DIE MÖGLICHKEIT, KOSTENPFLICHTIGE ZUSATZLEISTUNGEN ZU ERWERBEN, WERDEN VOM BETREIBER AUF DER WEBSITE ERLÄUTERT.

6.8. DER BETREIBER GIBT KEINE ERKLÄRUNGEN ZU FRAGEN DER ARBEIT MIT ZAHLUNGSSYSTEMEN, ÜBER DIE DER NUTZER ZUSÄTZLICHE KOSTENPFLICHTIGE DIENSTE ERWORBEN HAT, UND IST NICHT FÜR DEREN KORREKTE FUNKTIONSWEISE VERANTWORTLICH.

6.9. IM FALLE EINES TECHNISCHEN VERSAGENS DER WEBSEITEN ODER VORSÄTZLICHER HANDLUNGEN DES NUTZERS SOWIE IN ANDEREN FÄLLEN, IN DENEN DIE ZUSÄTZLICHEN KOSTENPFLICHTIGEN DIENSTE OHNE VOLLSTÄNDIGE/TEILWEISE ABBUCHUNG VOM KONTO DES NUTZERS ERBRACHT WURDEN, MUSS DER NUTZER DEN BETREIBER DARÜBER INFORMIEREN. DER NUTZER IST DANN VERPFLICHTET, DIE ENTSTANDENE SCHULD ZU BEGLEICHEN.

6.10. DER BETREIBER ERSTATTET DEM NUTZER KEINE UNGENUTZTEN (TEILWEISE GENUTZTEN) BEZAHLTEN ZUSATZDIENSTE.

6.11. DER NUTZER TRÄGT ALLE FINANZIELLEN KOSTEN, DIE MIT DEM ERWERB (GELDTRANSFER) ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE VERBUNDEN SIND, AUF EIGENE KOSTEN. DIESER ABSCHNITT UMFASST: GEBÜHREN, ABGABEN UND SONSTIGE KOSTEN.

6.12. DER NUTZER GARANTIERT DEM BETREIBER, DASS ER ÜBER ALLE GESETZLICHEN RECHTE UND BEFUGNISSE VERFÜGT, UM DIESEN VERTRAG FÜR KOSTENPFLICHTIGE ZUSATZLEISTUNGEN ABZUSCHLIESSEN.

6.13. IM FALLE DES ERWERBS ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE DURCH EINEN NUTZER UNTER 18 JAHREN IST DER NUTZER VERPFLICHTET, DIE VORHERIGE ZUSTIMMUNG SEINER GESETZLICHEN VERTRETER FÜR DIE FINANZIELLE TRANSAKTION EINZUHOLEN. DIE TATSACHE DES ERWERBS ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE IST EINE BESTÄTIGUNG FÜR DIE EINHOLUNG EINER SOLCHEN ZUSTIMMUNG DES GESETZLICHEN VERTRETERS. FALLS ERFORDERLICH, HAT DER BETREIBER DAS RECHT, EINE SCHRIFTLICHE BESTÄTIGUNG DER ZUSTIMMUNG UND DIE ANGABE VON PASSDATEN ZU VERLANGEN, UM DAS TATSÄCHLICHE ALTER DES NUTZERS FESTZUSTELLEN.

6.14. DIE VERANTWORTUNG FÜR DEN ERWERB ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE LIEGT VOLLSTÄNDIG BEIM NUTZER UND SEINEN GESETZLICHEN VERTRETERN.

6.15. STREITIGKEITEN MIT DEM PORTALBETREIBER ÜBER DIE HAFTUNG FÜR DEN ERWERB VON ZUSATZLEISTUNGEN SIND NICHT ZULÄSSIG.

6.16. DIE INANSPRUCHNAHME ZUSÄTZLICHER KOSTENPFLICHTIGER DIENSTE DURCH DEN NUTZER IST NUR NACH VOLLSTÄNDIGER BEZAHLUNG DES ENTGELTS MÖGLICH.

6.17. ZAHLUNGSVERFAHREN
6.17.1. ALS ZAHLUNGSKARTEN WERDEN AKZEPTIERT: VISA, MASTERCARD, MAESTRO, WORLD. FÜR DIE ZAHLUNG PER BANKKARTE BEI DER BESTELLUNG IM ONLINE-SHOP WÄHLT DER NUTZER DIE ZAHLUNGSART: PER BANKKARTE. BEI DER ZAHLUNG MIT BANKKARTE ERFOLGT DIE ZAHLUNGSABWICKLUNG AUF DER AUTORISIERUNGSSEITE DER BANK, WO DER NUTZER SEINE BANKKARTENDATEN EINGEBEN MUSS:
1) KARTENTYP
2) KARTENNUMMER
3) ABLAUFDATUM DER KARTE
4) NAME DES KARTENINHABERS (IN LATEINISCHEN BUCHSTABEN, GENAU SO WIE ER AUF DER KARTE STEHT))
5) CVC2/CVV2 CODE

6.17.2. WENN DIE KARTE DES NUTZERS MIT DEM 3D-SECURE-DIENST VERBUNDEN IST, WIRD DER NUTZER AUTOMATISCH AUF DIE SEITE DER BANK, DIE DIE KARTE AUSGESTELLT HAT, ZUR AUTHENTIFIZIERUNG WEITERGELEITET. FÜR WEITERE INFORMATIONEN ZU DEN REGELN UND METHODEN DER ZUSÄTZLICHEN IDENTIFIZIERUNG WENDEN SIE SICH BITTE AN DIE BANK, DIE DIE KARTE AUSGESTELLT HAT.

<b>7. GEWÄHRLEISTUNGSAUSSCHLUSS </b>

ALLE DIENSTE AUF DEM PORTAL WERDEN DEN NUTZERN NACH DEM ETABLIERTEN "AS IS"-KONZEPT ZUR VERFÜGUNG GESTELLT. DAS PORTAL LEHNT ES AB, GARANTIEN FÜR DIENSTLEISTUNGEN ODER VIRTUELLE WERTE ZU GEBEN.

DER BETREIBER WARNT UND ERINNERT DARAN, DASS EINE ÜBERMÄSSIGE NUTZUNG DES PC, EINSCHLIESSLICH DER PC-SPIELE, GESUNDHEITLICHE SCHÄDEN BEIM BENUTZER VERURSACHEN KANN. DER NUTZER ODER SEINE GESETZLICHEN VERTRETER VERPFLICHTEN SICH, IHREN GESUNDHEITSZUSTAND ZU KONTROLLIEREN UND DIE NUTZUNG DER DIENSTE IM FALLE VON KONTRAINDIKATIONEN NICHT ZU NUTZEN/EINZUSCHRÄNKEN.

DER BETREIBER HAFTET NICHT FÜR FOLGESCHÄDEN, BEILÄUFIG ENTSTANDENE SCHÄDEN ODER ANDERE SCHÄDEN (EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF ENTGANGENE GEWINNE), DIE SICH AUS DEN SPIELANGEBOTEN, PORTALSEITEN UND/ODER MATERIALIEN AUF DER WEBSITE, HANDLUNGEN DRITTER IN VERBINDUNG MIT DEN DIENSTEN UND PORTALBETREIBERN UND/ODER MATERIALIEN AUF DER WEBSITE, EINSCHLIESSLICH BETRÜGERISCHER UND/ODER FAHRLÄSSIGER HANDLUNGEN DRITTER ERGEBEN.

<b>8.ZUSÄTZLICHE BESTIMMUNGEN</b>

8.1. WENN DER BENUTZER NACH DER GESETZGEBUNG SEINES LANDES NICHT BERECHTIGT IST, DAS PORTAL ZU NUTZEN, ODER WENN ES ANDERE EINSCHRÄNKUNGEN GIBT (ALTERSGRENZEN USW.), IST ER VERPFLICHTET, DIE NUTZUNG DES PORTALS ODER EINER SEINER DIENSTLEISTUNGEN FRISTLOS AUFZUGEBEN. DER BENUTZER ÜBERNIMMT DIE VERANTWORTUNG FÜR DIE NUTZUNG DES PORTALS IN SEINEM LAND AUF DER GRUNDLAGE DER ÖRTLICHEN GESETZE UND UNTER BERÜCKSICHTIGUNG DER INTERNATIONALEN GESETZGEBUNG

8.2. DIE UNGÜLTIGKEIT EINER ODER MEHRERER KLAUSELN/ABSCHNITTE DER NUTZUNGSVEREINBARUNG HAT NICHT DEREN UNGÜLTIGKEIT IM GANZEN ZUR FOLGE. IN EINEM SOLCHEN FALL ERFÜLLEN DIE PARTEIEN IHRE VERPFLICHTUNGEN AUS DEN ÜBRIGEN KLAUSELN/ABSCHNITTEN DES VERTRAGS.

8.3. STREITIGKEITEN ZWISCHEN DEN PARTEIEN WERDEN ZUNÄCHST IM WEGE DER SCHLICHTUNG DURCH EINEN SCHRIFTWECHSEL ZWISCHEN DEM BETREIBER UND DEM NUTZER BEIGELEGT. IST DIE VERMITTELTE STREITBEILEGUNG UNWIRKSAM, WERDEN DIE STREITIGKEITEN NACH DEM RECHT DER REPUBLIK ESTLAND BEIGELEGT.
8.4. DIESES ABKOMMEN KÖNNEN VOM BETREIBER OHNE VORHERIGE ANKÜNDIGUNG AN DEN NUTZER GEÄNDERT ODER ERGÄNZT WERDEN. ÄNDERUNGEN TRETEN IN KRAFT, SOBALD DIE GEÄNDERTE VEREINBARUNG AUF DER WEBSITE VERÖFFENTLICHT WIRD. UM STREITIGKEITEN ZU VERMEIDEN, VERPFLICHTET SICH DER BENUTZER, DEN TEXT DER VEREINBARUNG AUF DER WEBSITE, AUF DER SIE ZUR VERFÜGUNG GESTELLT WIRD, ZU ÜBERPRÜFEN. WENN DER NUTZER DIESE BEDINGUNGEN NICHT ÜBERPRÜFT, HAT DIES KEINEN EINFLUSS AUF DIE GÜLTIGKEIT DES ABKOMMENS. DER BENUTZER ERKLÄRT SICH DAMIT EINVERSTANDEN, DASS ALLE ÄNDERUNGEN AN DIESER VEREINBARUNG, SOBALD SIE ÖFFENTLICH ZUGÄNGLICH GEMACHT WURDEN, DIESELBE RECHTSGÜLTIGKEIT HABEN WIE DIE URSPRÜNGLICHE VERSION DES ABKOMMENS.

<b>9. KONTAKTINFORMATIONEN</b>

KONTAKT-E-MAIL: EPIK582@GMAIL.COM
`.split(new RegExp(/\s+\r?\n/))
