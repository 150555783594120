const windowAnalytics = window
const ym = windowAnalytics.ym || (() => {})
const YM_COUNTER_ID = 95827082

export const analytics = {
  send (event, params) {
    if (process.env.NODE_ENV === 'production') {
      ym(YM_COUNTER_ID, 'reachGoal', event, params)
    }
  }
}
