<template>
  <div class="modal">
    <div class="modal__body">
      <div class="modal__actions">
        <div class="modal__close"  @click="$emit('close')">
          <Icon name="/base/close.svg" />
        </div>
      </div>
      <div class="modal__content">
        <div class="modal__header">
          <div class="modal__badge">
            <Icon :name="`/modal/${icon}.svg`" />
          </div>
          <div class="modal__title">{{ title }}</div>
        </div>
        <div class="modal__text">
          <slot name="text"></slot>
        </div>
        <div class="modal__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './modal.scss'
import Icon from '@/components/Base/Icon/Icon.vue'

export default {
  name: 'Modal',
  props: {
    title: {
      value: String,
      default: ''
    },
    subTitle: {
      value: String,
      default: ''
    },
    icon: {
      value: String,
      default: ''
    }
  },
  components: { Icon }
}
</script>
