<template>
  <div class="choiceAccount">
    <div class="choiceAccount__main-panel">
      <p class="choiceAccount__main-panel__header">{{ $t('logpass.enterNickname') }}</p>
      <div class="choiceAccount__main-panel__input">
        <div class="choiceAccount__main-panel__input__img">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.22805 6.42559C8.81919 5.96306 9.25067 5.32878 9.46246 4.611C9.67425 3.89323 9.65582 3.12765 9.40973 2.42078C9.16364 1.71391 8.70213 1.1009 8.08941 0.667026C7.47669 0.233154 6.74322 0 5.99106 0C5.23889 0 4.50543 0.233154 3.8927 0.667026C3.27998 1.1009 2.81848 1.71391 2.57239 2.42078C2.3263 3.12765 2.30787 3.89323 2.51966 4.611C2.73145 5.32878 3.16293 5.96306 3.75407 6.42559C2.74113 6.82919 1.85732 7.49858 1.19684 8.36243C0.536353 9.22627 0.123966 10.2522 0.00363697 11.3308C-0.00507307 11.4095 0.00189835 11.4892 0.024153 11.5653C0.0464077 11.6413 0.0835099 11.7123 0.133341 11.7741C0.23398 11.8989 0.380358 11.9789 0.540274 11.9964C0.70019 12.0139 0.860543 11.9675 0.986059 11.8674C1.11158 11.7673 1.19197 11.6217 1.20956 11.4627C1.34196 10.2905 1.90399 9.20788 2.78825 8.42172C3.67252 7.63556 4.81703 7.20096 6.00312 7.20096C7.18921 7.20096 8.33372 7.63556 9.21798 8.42172C10.1022 9.20788 10.6643 10.2905 10.7967 11.4627C10.8131 11.61 10.8838 11.7461 10.9951 11.8447C11.1065 11.9432 11.2506 11.9973 11.3996 11.9964H11.466C11.624 11.9783 11.7685 11.8988 11.8679 11.7753C11.9673 11.6517 12.0135 11.4941 11.9966 11.3368C11.8757 10.2551 11.4611 9.22657 10.7972 8.3614C10.1333 7.49623 9.24519 6.82705 8.22805 6.42559ZM5.99106 5.99984C5.51404 5.99984 5.04773 5.85916 4.6511 5.5956C4.25448 5.33204 3.94534 4.95742 3.7628 4.51913C3.58025 4.08084 3.53249 3.59856 3.62555 3.13327C3.71861 2.66799 3.94832 2.2406 4.28562 1.90514C4.62292 1.56969 5.05268 1.34124 5.52053 1.24869C5.98838 1.15614 6.47332 1.20364 6.91403 1.38519C7.35474 1.56673 7.73142 1.87417 7.99644 2.26862C8.26146 2.66307 8.40291 3.12682 8.40291 3.60122C8.40291 4.23737 8.1488 4.84747 7.69649 5.2973C7.24418 5.74713 6.63072 5.99984 5.99106 5.99984Z" fill="white"/>
          </svg>
        </div>
        <div class="choiceAccount__main-panel__input__main">
          <input
            type="text"
            :placeholder="$t('transfer.nickname')"
            v-model="nickname"
            @input="searchUsers()"
          >
        </div>
      </div>
      <div class="choiceAccount__main-panel__wait" v-if="isSearching">
        <img src="@/assets/images/Premium/anchor.png" alt="">
      </div>
      <div
        class="choiceAccount__main-panel__search-block"
        v-if="!isSearching"
      >
        <div
          class="choiceAccount__main-panel__search-block__wrap"
          v-for="user in foundUsers"
          :key="user.user_id"
          @click="choiceAcc($event, user.user_id, user.username)"
        >
          <div class="choiceAccount__main-panel__search-block__account">
            <div class="choiceAccount__main-panel__search-block__account__name">{{ user.username }}</div>
            <div class="choiceAccount__main-panel__search-block__account__avatar">
              <img :src="user.avatar_uri" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="choiceAccount__main-panel__btn" @click="changeModal()">
        <button :disabled="!userId">{{ $t('actions.continue') }}</button>
      </div>
      <div class="choiceAccount__main-panel__back" @click="changeModal('back')">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.25 11.75H8.17251L15.5975 4.34749C16.0212 3.92381 16.2592 3.34917 16.2592 2.74999C16.2592 2.15081 16.0212 1.57617 15.5975 1.15249C15.1738 0.728807 14.5992 0.490784 14 0.490784C13.4008 0.490784 12.8262 0.728807 12.4025 1.15249L1.15251 12.4025C0.94767 12.6165 0.787098 12.8688 0.680011 13.145C0.45497 13.6928 0.45497 14.3072 0.680011 14.855C0.787098 15.1312 0.94767 15.3835 1.15251 15.5975L12.4025 26.8475C12.6117 27.0584 12.8605 27.2258 13.1347 27.34C13.4089 27.4542 13.703 27.513 14 27.513C14.297 27.513 14.5911 27.4542 14.8653 27.34C15.1395 27.2258 15.3883 27.0584 15.5975 26.8475C15.8084 26.6383 15.9758 26.3895 16.09 26.1153C16.2042 25.8411 16.2631 25.547 16.2631 25.25C16.2631 24.953 16.2042 24.6589 16.09 24.3847C15.9758 24.1105 15.8084 23.8617 15.5975 23.6525L8.17251 16.25H25.25C25.8467 16.25 26.419 16.0129 26.841 15.591C27.263 15.169 27.5 14.5967 27.5 14C27.5 13.4033 27.263 12.831 26.841 12.409C26.419 11.987 25.8467 11.75 25.25 11.75Z" fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import './choiceaccount.scss'

export default {
  name: 'ChoiceAccount',
  data () {
    return {
      nickname: null,
      userId: null,
      searchTimeout: null,
      isSearching: false,
      foundUsers: null
    }
  },
  props: {
    methodType: {
      type: String,
      require: true,
      default: ''
    },
    setUserName: {
      type: Function,
      require: true
    },
    setUserId: {
      type: Function,
      require: true
    }
  },
  methods: {
    changeModal (to) {
      if (this.methodType === 'LogPass') {
        if (to === 'back') {
          this.$router.push('/methods')
          return
        }
      } else if (this.methodType === 'Premium') {
        if (to === 'back') {
          this.$emit('changeModal', { modal: 'buy' })
          return
        }
      }

      if (this.nickname) {
        this.setUserName(this.nickname)
        this.setUserId(this.userId)
        this.$emit('changeModal', { modal: 'confirmAccount' })
      }
    },
    choiceAcc (event, userId, username) {
      const box = document.querySelector('.choiceAccount__main-panel__search-block').childNodes
      for (let i = 0; i < box.length; i++) {
        box[i].querySelector('div').classList.remove('choice')
      }
      const block = event.target.closest('.choiceAccount__main-panel__search-block__account')
      block.classList.add('choice')
      this.nickname = username
      this.userId = userId
    },
    searchUsers () {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }

      this.searchTimeout = setTimeout(() => {
        if (this.nickname.length < 3) {
          return
        }

        this.isSearching = true

        fetch(`https://logpass.backend-pier.com/api/v1/roblox/search_users?keyword=${this.nickname}`)
          .then((res) => res.json())
          .then((data) => {
            this.isSearching = false
            this.foundUsers = data.found_users
          })
          .catch((err) => console.log(err))
      }, 1000)
    }
  }
}
</script>
