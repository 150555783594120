<template>
  <div class="premiumOrdering">
    <div class="premiumOrdering__left">
      <Loader v-if="loading"/>
      <div class="premiumOrdering__left__header">
        <h2><img src="@/assets/images/payments/payments-ico.svg" alt="ico"> {{ $t('premium.ordering') }}</h2>
      </div>
      <div class="premiumOrdering__left__blocks">
        <div class="premiumOrdering__left__blocks__row">
          <div
            class="premiumOrdering__left__blocks__block active"
            @click="choosePayType('qiwi', $event)"
            @mouseenter="hoverPayBlock('qiwi', $event)"
            @mouseleave="unHoverPayBlock('qiwi', $event)"
          >
            <img src="@/assets/images/payments/qiwi-hover.png" alt="qiwi">
            <div class="premiumOrdering__left__blocks__block__com qiwi">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('rub_card', $event)"
            @mouseenter="hoverPayBlock('rub_card', $event)"
            @mouseleave="unHoverPayBlock('rub_card', $event)"
          >
            <img src="@/assets/images/payments/rub_card.png" alt="rub_card">
            <div class="premiumOrdering__left__blocks__block__com rub_card">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('uah_card', $event)"
            @mouseenter="hoverPayBlock('uah_card', $event)"
            @mouseleave="unHoverPayBlock('uah_card', $event)"
          >
            <img src="@/assets/images/payments/uah_card.png" alt="uah_card">
            <div class="premiumOrdering__left__blocks__block__com uah_card">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('card', $event)"
            @mouseenter="hoverPayBlock('card', $event)"
            @mouseleave="unHoverPayBlock('card', $event)"
          >
            <img src="@/assets/images/payments/card.png" alt="card">
            <div class="premiumOrdering__left__blocks__block__com card">
              <p>0%</p>
            </div>
          </div>
        </div>
        <div class="premiumOrdering__left__blocks__row">
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('yoomoney', $event)"
            @mouseenter="hoverPayBlock('yoomoney', $event)"
            @mouseleave="unHoverPayBlock('yoomoney', $event)"
          >
            <img src="@/assets/images/payments/yoomoney.png" alt="yoomoney">
            <div class="premiumOrdering__left__blocks__block__com yoomoney">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('beeline', $event)"
            @mouseenter="hoverPayBlock('beeline', $event)"
            @mouseleave="unHoverPayBlock('beeline', $event)"
          >
            <img src="@/assets/images/payments/beeline.png" alt="beeline">
            <div class="premiumOrdering__left__blocks__block__com beeline">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('megaphone', $event)"
            @mouseenter="hoverPayBlock('megaphone', $event)"
            @mouseleave="unHoverPayBlock('megaphone', $event)"
          >
            <img src="@/assets/images/payments/megaphone.png" alt="megaphone">
            <div class="premiumOrdering__left__blocks__block__com megaphone">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('skinpay', $event)"
            @mouseenter="hoverPayBlock('skinpay', $event)"
            @mouseleave="unHoverPayBlock('skinpay', $event)"
          >
            <img src="@/assets/images/payments/skinpay.png" alt="skinpay">
            <div class="premiumOrdering__left__blocks__block__com skinpay">
              <p>0%</p>
            </div>
          </div>
        </div>
        <div class="premiumOrdering__left__blocks__row">
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('promsviaz', $event)"
            @mouseenter="hoverPayBlock('promsviaz', $event)"
            @mouseleave="unHoverPayBlock('promsviaz', $event)"
          >
            <img src="@/assets/images/payments/promsviaz.png" alt="promsviaz">
            <div class="premiumOrdering__left__blocks__block__com promsviaz">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('bitcoin', $event)"
            @mouseenter="hoverPayBlock('bitcoin', $event)"
            @mouseleave="unHoverPayBlock('bitcoin', $event)"
          >
            <img src="@/assets/images/payments/bitcoin.png" alt="bitcoin">
            <div class="premiumOrdering__left__blocks__block__com bitcoin">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('ethereum', $event)"
            @mouseenter="hoverPayBlock('ethereum', $event)"
            @mouseleave="unHoverPayBlock('ethereum', $event)"
          >
            <img src="@/assets/images/payments/ethereum.png" alt="ethereum">
            <div class="premiumOrdering__left__blocks__block__com ethereum">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('alfabank', $event)"
            @mouseenter="hoverPayBlock('alfabank', $event)"
            @mouseleave="unHoverPayBlock('alfabank', $event)"
          >
            <img src="@/assets/images/payments/alfabank.png" alt="alfabank">
            <div class="premiumOrdering__left__blocks__block__com alfabank">
              <p>0%</p>
            </div>
          </div>
        </div>
        <div class="premiumOrdering__left__blocks__row">
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('new_fk', $event)"
            @mouseenter="hoverPayBlock('new_fk', $event)"
            @mouseleave="unHoverPayBlock('new_fk', $event)"
          >
            <img src="@/assets/images/payments/freekassa.png" alt="freekassa">
            <div class="premiumOrdering__left__blocks__block__com freekassa">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('enot', $event)"
            @mouseenter="hoverPayBlock('enot', $event)"
            @mouseleave="unHoverPayBlock('enot', $event)"
          >
            <img src="@/assets/images/payments/enot.png" alt="enot">
            <div class="premiumOrdering__left__blocks__block__com enot">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('payok', $event)"
            @mouseenter="hoverPayBlock('payok', $event)"
            @mouseleave="unHoverPayBlock('payok', $event)"
          >
            <img src="@/assets/images/payments/payok.png" alt="payok">
            <div class="premiumOrdering__left__blocks__block__com payok">
              <p>0%</p>
            </div>
          </div>
          <div
            class="premiumOrdering__left__blocks__block"
            @click="choosePayType('another', $event)"
            @mouseenter="hoverPayBlock('another', $event)"
            @mouseleave="unHoverPayBlock('another', $event)"
          >
            <img src="@/assets/images/payments/another.png" alt="another">
            <small>{{ $t('payment.otherPaymentMethods') }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="premiumOrdering__right">
      <div class="premiumOrdering__right__image">
        <img :src="require(`@/assets/images/payments/${choosedPayment}.png`)" :alt="choosedPayment">
      </div>
      <div
        class="premiumOrdering__right__input"
        :class="{ error: error.status === false && error.show }"
      >
        <p class="premiumOrdering__right__input__error">{{ error.message }}</p>
        <div class="premiumOrdering__right__input__image">
          <small>₽</small>
        </div>
        <div class="premiumOrdering__right__input__input">
          <input
            type="number"
            :placeholder="$t('payment.enterSum')"
            v-model="priceWithoutPercent"
            @change="validatePrice"
            @keyup.enter="pay"
            @input="validateCommision"
            :readonly="payments.status"
            :class="payments.status ? 'inactive' : ''"
          >
        </div>
      </div>
      <div class="premiumOrdering__right__input">
        <p class="premiumOrdering__right__input__error">{{ $t('general.error') }}</p>
        <div class="premiumOrdering__right__input__image">
          <small>%</small>
        </div>
        <div class="premiumOrdering__right__input__input">
          <input
            type="text"
            :placeholder="$t('payment.commission')"
            :value="(Number(priceWithoutPercent) / Number(percent)).toFixed(2) + ' ₽'"
            readonly
            :class="payments.status ? 'inactive' : ''"
          >
        </div>
      </div>
      <div class="premiumOrdering__right__commision" v-if="choosedPayment === 'card'">
        <p>{{ $t('payment.commission') }}</p>
        <p>RUB: 4.5% + 10₽</p>
        <p>USD: 4.5% + 1.2$</p>
        <p>EUR: 4.5% + 0.1€</p>
      </div>
      <div class="premiumOrdering__right__button">
        <button @click="pay">{{ $t('payment.pay') }}</button>

        <i18n path="payment.problems" tag="p">
          <template v-slot:support>
            <a href="https://vk.me/robuxpier" target="_blank">{{ $t('payment.support') }}</a>
          </template>
        </i18n>
      </div>
    </div>
    <div class="premiumOrdering__mobile">
      <div class="premiumOrdering__mobile__header">
        <div class="premiumOrdering__mobile__header__left">
          <img src="@/assets/images/payments/left-arrow.png" alt="left" @click="switchMethod(-1)">
        </div>
        <div class="premiumOrdering__mobile__header__center">
          <img :src="require(`@/assets/images/payments/${choosedPayment}.png`)" :alt="choosedPayment">
          <div class="premiumOrdering__mobile__header__center__com">
            <p>{{ commision }}%</p>
          </div>
        </div>
        <div class="premiumOrdering__mobile__header__right">
          <img src="@/assets/images/payments/right-arrow.png" alt="right" @click="switchMethod(1)">
        </div>
      </div>
      <div
        class="premiumOrdering__right__input"
        :class="{ error: error.status === false && error.show }"
      >
        <p class="premiumOrdering__right__input__error">{{ error.message }}</p>
        <div class="premiumOrdering__mobile__input__image">
          <small>₽</small>
        </div>
        <div class="premiumOrdering__mobile__input__input">
          <input
            type="text"
            :placeholder="$t('payment.enterSum')"
            v-model="priceWithoutPercent"
            @input="validateCommision"
            @change="validatePrice"
            @keyup.enter="pay"
            :readonly="true"
            :class="payments.status ? 'inactive' : ''"
          >
        </div>
      </div>
      <div class="premiumOrdering__right__input">
        <div class="premiumOrdering__mobile__input__image">
          <small>%</small>
        </div>
        <div class="premiumOrdering__mobile__input__input">
          <input
            type="number"
            :placeholder="$t('payment.commission')"
            :value="(Number(priceWithoutPercent) / Number(percent)).toFixed(2)"
            :class="payments.status ? 'inactive' : ''"
            readonly
          >
        </div>
      </div>
      <div class="premiumOrdering__mobile__button">
        <button @click="pay">{{ $t('payment.pay') }}</button>
        <i18n path="payment.problems" tag="p">
          <template v-slot:support>
            <a href="https://vk.me/robuxpier" target="_blank">{{ $t('payment.support') }}</a>
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import './premiumordering.scss'

import Loader from '@/components/LoaderBar/LoaderBar'

export default {
  name: 'PremiumOrdering',
  components: {
    Loader
  },
  props: {
    methodType: {
      type: String,
      require: true,
      default: ''
    }
  },
  data () {
    return {
      choosedPayment: 'qiwi',
      choosedPaymentSystem: 'gamemoney',
      priceWithoutPercent: this.$store.getters.price,
      percent: 0.92,
      commision: 8,
      commisionConfig: {},
      qiwiBig: {},
      qiwiLow: {},
      error: {
        status: false,
        show: false,
        message: ''
      },
      loading: true,
      payments: {
        status: false,
        data: {}
      },
      mobileDirection: [
        'qiwi',
        'rub_card',
        'uah_card',
        'new_fk',
        'enot',
        'beeline',
        'card',
        'skinpay',
        'ethereum',
        'alfabank',
        'megaphone',
        'promsviaz',
        'yoomoney',
        'bitcoin',
        'another'
      ]
    }
  },
  created () {
    // console.log(this.$store.state.payments.order)
    if (this.$store.state.payments.order) {
      this.payments.status = true
      this.payments.data = this.$store.state.payments.order
    }

    if (this.payments.status) {
      // console.log(this.payments.data)
      const order = this.payments.data.order
      this.priceWithoutPercent = parseFloat(order.amount[order.currency.toLowerCase()].toFixed(2))
    }

    this.loading = true

    fetch('https://payments.backend-pier.com/api/v1/config/commissions')
      .then((res) => res.json())
      .then((data) => {
        this.loading = false

        data.forEach((commision) => {
          if (commision.name !== 'boleto') {
            this.commisionConfig[commision.name] = {
              commision: Math.round((1 - commision.value) * 100),
              percent: commision.value
            }

            if (commision.name === 'qiwi') {
              this.commisionConfig.qiwi.commision = 3
              this.commisionConfig.qiwi.percent = 0.97

              this.qiwiBig = {
                commision: Math.round((1 - commision.value) * 100),
                percent: commision.value
              }

              this.qiwiLow = {
                commision: 3,
                percent: 0.97
              }
            }
          }
        })

        this.commisionConfig.another = {
          commision: NaN,
          percent: NaN
        }

        this.commisionConfig['free-kassa'] = {
          commision: 0,
          percent: 1
        }

        this.commisionConfig.freekassa = {
          commision: 0,
          percent: 1
        }

        this.commisionConfig.new_fk = {
          commision: 0,
          percent: 1
        }

        this.commisionConfig.enot = {
          commision: 0,
          percent: 1
        }

        this.commisionConfig.payok = {
          commision: 0,
          percent: 1
        }

        this.commisionConfig.yandex = {
          commision: Math.round((1 - 0.892) * 100),
          percent: 0.892
        }

        this.displayCommision()

        this.commision = this.commisionConfig[this.choosedPayment].commision
        this.percent = this.commisionConfig[this.choosedPayment].percent
      })
      .catch((err) => console.log(err))
  },
  destroyed () {
    this.$store.commit('clearPaymentData')
  },
  methods: {
    changeModal (to) {
      if (this.methodType === 'LogPass') {
        this.$emit('changeModal', {
          modal: 'logPassOrders'
        })
      }
      if (this.methodType === 'Premium') {
        this.$emit('changeModal', {
          modal: 'status'
        })
      }
    },
    pay () {
      this.validatePrice()

      if (this.error.status === false) {
        return
      }

      // this.changeModal()

      const requestBody = {
        user_id: this.$store.state.account.userId || localStorage.getItem('user_id'),
        rubble_amount: parseFloat(parseFloat(this.priceWithoutPercent).toFixed(2)),
        payment_type: this.choosedPayment
      }

      if (this.choosedPayment === 'new_fk' ||
       this.choosedPayment === 'uah_card') {
        requestBody.payment_type = null
        requestBody.payment_system = 'new_fk'
      } else if (this.choosedPayment === 'payok' ||
        (this.choosedPayment === 'qiwi' && this.priceWithoutPercent < 200) ||
        (this.choosedPayment === 'rub_card' && this.priceWithoutPercent < 750)) {
        requestBody.payment_type = null
        requestBody.payment_system = 'payok'
      } else if (this.choosedPayment === 'enot' ||
       this.choosedPayment === 'yoomoney') {
        requestBody.payment_type = null
        requestBody.payment_system = 'enot'
      } else if (this.choosedPayment === 'free-kassa' ||
       this.choosedPayment === 'beeline' ||
       this.choosedPayment === 'megaphone') {
        requestBody.payment_type = null
        requestBody.payment_system = 'free-kassa'
      } else {
        requestBody.payment_system = 'gamemoney'
      }

      if (this.payments.status) {
        requestBody.method_type = this.payments.data.methodType
        requestBody.order_id = this.payments.data.order._id
      }

      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: requestHeaders
      }

      fetch('https://payments.backend-pier.com/api/v1/topups/create', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.redirect_uri) {
            location.href = data.redirect_uri
            return
          }

          const formUrl = data.m ? 'https://pay.freekassa.ru/' : 'https://pay.gmpays.com/api/terminal/create'
          let formHTML = ''

          Object.keys(data).forEach((key) => {
            formHTML += `<input type="hidden" name="${key}" value="${data[key]}"/>`
          })

          const formBlock = document.createElement('form')

          formBlock.action = formUrl
          formBlock.method = data.m ? 'GET' : 'POST'
          formBlock.innerHTML = formHTML

          document.body.appendChild(formBlock)
          formBlock.submit()
        })
        .catch((err) => console.log(err))
    },
    choosePayType (type, event) {
      const target = event.target.closest('.premiumOrdering__left__blocks__block')
      const currentChoosed = document.querySelector('.premiumOrdering__left__blocks__block.active')

      target.classList.add('active')
      currentChoosed.classList.remove('active')
      currentChoosed.childNodes[0].src = require(`@/assets/images/payments/${this.choosedPayment}.png`)

      this.choosedPayment = type

      this.commision = this.commisionConfig[type].commision
      this.percent = this.commisionConfig[type].percent
    },
    switchMethod (direction) {
      const currentMethodIndex = this.mobileDirection.findIndex((m) => m === this.choosedPayment)

      if (direction === 1) {
        if (currentMethodIndex + 1 >= this.mobileDirection.length) {
          this.choosedPayment = this.mobileDirection[0]
        } else {
          this.choosedPayment = this.mobileDirection[currentMethodIndex + 1]
        }
      } else {
        if (currentMethodIndex - 1 < 0) {
          this.choosedPayment = this.mobileDirection[this.mobileDirection.length - 1]
        } else {
          this.choosedPayment = this.mobileDirection[currentMethodIndex - 1]
        }
      }

      this.commision = this.commisionConfig[this.choosedPayment].commision
      this.percent = this.commisionConfig[this.choosedPayment].percent
    },
    hoverPayBlock (type, event) {
      const target = event.target.closest('.premiumOrdering__left__blocks__block')

      if (target.classList.contains('active')) {
        return
      }

      const img = target.childNodes[0]

      img.src = require(`@/assets/images/payments/${type}-hover.png`)
    },
    unHoverPayBlock (type, event) {
      const target = event.target.closest('.premiumOrdering__left__blocks__block')

      if (target.classList.contains('active')) {
        return
      }

      const img = target.childNodes[0]

      img.src = require(`@/assets/images/payments/${type}.png`)
    },
    displayCommision () {
      Object.keys(this.commisionConfig).forEach((key) => {
        if (key === 'another') {
          return
        }

        const mainBlock = document.querySelector(`.premiumOrdering__left__blocks__block__com.${key}`)

        if (mainBlock === null) {
          return
        }

        if (this.commisionConfig[key].commision === 0) {
          mainBlock.style.display = 'none'
        }

        if (mainBlock) {
          const commisionBlock = mainBlock.querySelector('.premiumOrdering__left__blocks__block__com p')
          commisionBlock.innerHTML = `${this.commisionConfig[key].commision}%`
        }
      })
    },
    validatePrice () {
      if (isNaN(this.priceWithoutPercent)) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('premium.correctSum')
      } else if (this.priceWithoutPercent < 5) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('premium.minRefillSum')
      } else {
        this.error.status = true
        this.error.show = false
        this.error.message = 'none'
      }
    },
    validateCommision (event) {
      if (this.choosedPayment !== 'qiwi') {
        return
      }

      if (Number(event.target.value) > 200) {
        this.commisionConfig.qiwi = this.qiwiBig
      } else {
        this.commisionConfig.qiwi = this.qiwiLow
      }

      this.percent = this.commisionConfig.qiwi.percent
      this.commision = this.commisionConfig.qiwi.commision
      this.displayCommision()
    }
  }
}
</script>
