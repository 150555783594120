export default `ACORDO DE TERMOS DE USO DA INFORMAÇÃO — A EMPRESA ROBUXPIER.IO

O PRESENTE ACORDO DESTINA-SE A REGULAR A RELAÇÃO ENTRE O USUÁRIO E O TITULAR DOS DIREITOS DO PORTAL DA INTERNET

<b>CONTEÚDO:</b>

1. TERMOS USADOS.

2. OBJETO DO ACORDO.

3. PROCEDIMENTO DE ENTRADA EM VIGOR DO ACORDO.

4. DIREITOS E OBRIGAÇÕES DAS PARTES.

4.1 DIREITOS DO USUÁRIO.

4.2 DEVERES DO USUÁRIO.

4.3 O USUÁRIO NÃO TEM DIREITO.

4.4 DIREITOS DO OPERADOR.

4.5 DEVERES DO OPERADOR.

4.6 LIMITAÇÃO DE RESPONSABILIDADE DO OPERADOR.

4.7 O OPERADOR NÃO GARANTE.

5. PRIVACIDADE E SEGURANÇA.

6. SERVIÇOS PAGOS ADICIONAIS E PROCEDIMENTO DE PAGAMENTO.

7. RECUSA DE PRESTAÇÃO DE GARANTIAS.

8. DISPOSIÇÕES ADICIONAIS.

9. INFORMAÇÕES DE CONTATO.

<b>1. TERMOS USADOS</b>

<b>«OPERADOR»</b> - PROPRIETÁRIO DO PORTAL HTTPS://ROBUXPIER.IO, É PARTE DO CONTRATO DO USUÁRIO. O OPERADOR REALIZA A ADMINISTRAÇÃO E MANUTENÇÃO, FORNECE ACESSO AOS USUÁRIOS AO PORTAL, SERVIÇOS (TAMBÉM SERVIÇOS PAGOS), NOS TERMOS DO PRESENTE ACORDO.

<b>«USUÁRIO»</b> - PESSOA FÍSICA, QUE VISITA O PORTAL OU PARTICIPA DE PROJETOS DO PORTAL HTTPS://ROBUXPIER.IO

<b>USUÁRIO</b>, ASSIM COMO O OPERADOR, É PARTE DO ACORDO.

<b>«PORTAL»</b> - ESTES SÃO COMPLEXOS ESPECIAIS DE SOFTWARE E HARDWARE COLOCADOS NOS RECURSOS DO OPERADOR. O ACESSO DOS USUÁRIOS AO PORTAL É REALIZADO APENAS PELO OPERADOR. TODOS OS DIREITOS DE UTILIZAÇÃO DESTE PORTAL PERTENCEM EXCLUSIVAMENTE AO OPERADOR.

<b>«SITE»</b> — SITE, ESTÁ LOCALIZADO NA INTERNET NO ENDEREÇO HTTPS://ROBUXPIER.IO

<b>«SERVIÇOS»</b> — FORNECER AOS USUÁRIOS ACESSO AO PORTAL, USO DE RECURSOS E SERVIÇOS, PARTICIPAÇÃO EM PROJETOS NAS CONDIÇÕES DEFINIDAS NOS ACORDOS. OS SERVIÇOS DO OPERADOR SÃO FORNECIDOS GRATUITAMENTE. AS EXCEÇÕES SÃO APENAS SERVIÇOS PAGOS ADICIONAIS. TODOS OS SERVIÇOS SÃO FORNECIDOS PELO OPERADOR APENAS DENTRO DO PORTAL, OU SEJA, DURANTE O SEU USO PELO USUÁRIO.

<b>«SERVIÇOS ADICIONAIS PAGOS»</b> – FORNECER AO USUÁRIO RECURSOS ESPECIAIS ADICIONAIS DO PORTAL POR UMA TAXA. ESTES SERVIÇOS NÃO SÃO OBRIGATÓRIOS E SÃO FORNECIDOS MEDIANTE SOLICITAÇÃO DO USUÁRIO. OS SERVIÇOS PAGOS TAMBÉM SÃO FORNECIDOS PELA OPERADORA EXCLUSIVAMENTE DENTRO DO PORTAL.

<b>2. OBJETO DO ACORDO</b>

2.1. FORNECIMENTO PELO OPERADOR DO ACESSO AO PORTAL (SERVIÇOS, SERVIÇOS PAGOS) A UM NÚMERO ILIMITADO DE PESSOAS, NOS TERMOS DESTE ACORDO.

2.2. O USUÁRIO ESTÁ CIENTE QUE O PRINCIPAL OBJETIVO DOS PROJETOS DO PORTAL HTTPS://ROBUXPIER.IO É A ORGANIZAÇÃO PELO OPERADOR DE ATIVIDADES DE LAZER E ENTRETENIMENTO, NÃO RELACIONADAS DE FORMA ALGUMA COM JOGOS DE AZAR.

<b>3. PROCEDIMENTO DE ENTRADA EM VIGOR DO ACORDO</b>

3.1. A PARTIR DO MOMENTO DA ACEITAÇÃO DESTE ACORDO, O USUÁRIO TEM OS DIREITOS E CUMPRE AS OBRIGAÇÕES ESTIPULADAS NO TEXTO DESTE ACORDO.

3.2. O USUÁRIO ACEITA E CONCORDA COM OS TERMOS E CONDIÇÕES DESTE ACORDO, REGISTRANDO-SE NO SITE: HTTPS://ROBUXPIER.IO O FATO DE ACEITAÇÃO SIGNIFICA O CONSENTIMENTO TOTAL E INCONDICIONAL DO USUÁRIO COM TODOS OS TERMOS E CONDIÇÕES E ANEXOS DESTE ACORDO.

3.3. SE O USUÁRIO, POR QUALQUER MOTIVO, NÃO CONCORDAR COM OS TERMOS OU APLICATIVOS DESTE CONTRATO, ELE É OBRIGADO A PARAR DE USAR O SITE.

3.4. O SITE PODE SER UTILIZADO SOMENTE APÓS A ACEITAÇÃO DESTE CONTRATO PELO USUÁRIO.

3.5. AO ACEITAR ESTE ACORDO, O USUÁRIO CONFIRMA SUA CAPACIDADE JURÍDICA, O DIREITO DE CELEBRAR ESTE ACORDO. O OPERADOR NÃO É OBRIGADO A VERIFICAR OS DADOS ESPECIFICADOS PELO USUÁRIO DURANTE O REGISTRO.

3.6. SE PARA O USO DO PORTAL DE INFORMAÇÃO E ENTRETENIMENTO HTTPS:/ROBUXPIER.IO OU A PARTICIPAÇÃO NOS PROJETOS DO PORTAL REQUER A CRIAÇÃO DE UMA CONTA (DORAVANTE - "CONTA"), O USUÁRIO DEVE PASSAR PELO PROCESSO DE REGISTRO, FORNECENDO AO OPERADOR INFORMAÇÕES ATUALIZADAS, COMPLETAS E PRECISAS (INCLUINDO ENDEREÇO DE E-MAIL) NO FORMULÁRIO APROPRIADO. EM CASO DE COLOCAÇÃO NO PORTAL HTTPS://ROBUXPIER.IO MENSAGENS SOBRE A PARTICIPAÇÃO EM PROGRAMAS DE AFILIADOS, O USUÁRIO REGISTRADO TEM O DIREITO DE PARTICIPAR DE CONCURSOS, SORTEIOS, COMPETIÇÕES NO SITE DOS PARCEIROS HTTPS://ROBUXPIER.IO.

<b>4. DIREITOS E OBRIGAÇÕES DAS PARTES</b>

4.1. DIREITOS DO USUÁRIO

DE ACORDO COM ESTE ACORDO, O USUÁRIO TEM O DIREITO:

USAR O PORTAL APENAS PARA FINS PESSOAIS E NÃO COMERCIAIS.

USAR TODOS OS SERVIÇOS, RECURSOS (INCLUINDO SERVIÇOS PAGOS ADICIONAIS) FORNECIDOS PELO OPERADOR.

SE NECESSÁRIO, USE O SUPORTE TÉCNICO DOS SITES, ENTRE EM CONTATO COM O OPERADOR, ATRAVÉS DOS DADOS DE CONTATO OU DO FORMULÁRIO DE CONTATO.

GRATUITAMENTE (EXCLUINDO A PRESTAÇÃO DE SERVIÇOS PAGOS ADICIONAIS) USAR O SITE E PARTICIPAR DE PROJETOS DO PORTAL.

4.2. DEVERES DO USUÁRIO

SUJEITO AOS TERMOS DESTE ACORDO, O USUÁRIO É OBRIGADO:

FORNECER INFORMAÇÕES CONFIÁVEIS AO SE REGISTRAR NO SITE.

POR SI, TOMAR TODAS AS MEDIDAS NECESSÁRIAS PARA GARANTIR A SEGURANÇA EFETIVA DA CONTA PESSOAL. NÃO PERMITIR O ACESSO A TERCEIROS.

POR SI, TOMAR TODAS AS MEDIDAS NECESSÁRIAS PARA GARANTIR A SEGURANÇA EFETIVA DA CONTA PESSOAL. NÃO FORNECER ACESSO A ELA A TERCEIROS, SE NECESSÁRIO, A PEDIDO DO OPERADOR, PARA FORNECER CONFIRMAÇÃO DE SEUS DADOS PESSOAIS INDICADOS NO SITE NO MOMENTO DO REGISTRO.

CUMPRIR AS INSTRUÇÕES DO OPERADOR DENTRO DESTE PORTAL.

NÃO INFRINGIR OS DIREITOS DE PROPRIEDADE INTELECTUAL E DE AUTOR DO OPERADOR LOCALIZADOS EM SEU PORTAL.

CUMPRIR SEM RESTRIÇÕES AS CONDIÇÕES DESTE CONTRATO.

O USUÁRIO COMPROMETE-SE A USAR O PORTAL EXCLUSIVAMENTE PARA FINS DE ENTRETENIMENTO, SEM, BUSCAR QUALQUER BENEFÍCIO DO PORTAL.

4.3. O USUÁRIO NÃO TEM DIREITO

USANDO O PORTAL DO OPERADOR, O USUÁRIO NÃO TEM O DIREITO DE:

DE USAR ERROS (BUGS) DO SITE E DOS SERVIÇOS DO PORTAL, NÃO ESTÁ AUTORIZADO A ACESSAR UMA BASE DE DADOS COMUM, SISTEMA DE COMPUTADOR, ALTERAR O CÓDIGO DO PROGRAMA. O USUÁRIO ESTÁ PROIBIDO DE USAR PROGRAMAS MALICIOSOS QUE POSSAM PREJUDICAR O PORTAL, BEM COMO SOFTWARE ESPECIAL QUE LHE DÊ SUPERIORIDADE SOBRE OUTROS USUÁRIOS. SE TAIS E TAIS VIOLAÇÕES FOREM IDENTIFICADAS, O OPERADOR TEM O DIREITO DE APLICAR PENALIDADES AO USUÁRIO, INCLUINDO A PROIBIÇÃO DE ACESSO AOS SITES E A EXCLUSÃO DA CONTA.

RESTRINGIR O ACESSO AOS SITES DE OUTROS USUÁRIOS.

ENVOLVER-SE EM FRAUDES E OUTRAS ATIVIDADES ILEGAIS.

ANUNCIAR QUALQUER COISA QUE NÃO ESTEJA RELACIONADA COM O PORTAL SEM A AUTORIZAÇÃO ESCRITA DO OPERADOR.

USAR PALAVRÕES, EXPRESSAR AMEAÇAS CONTRA O OPERADOR OU OUTROS USUÁRIOS, DISTRIBUIR MATERIAIS QUE PROMOVAM VIOLÊNCIA, ÓDIO RACIAL, REJEIÇÃO A CRENÇAS RELIGIOSAS, CONTENDO INFORMAÇÕES PORNOGRÁFICAS, PROMOVENDO DROGAS, PEDINDO A DERRUBADA VIOLENTA DO GOVERNO.

REALIZAR PUBLICIDADE ANTI-PUBLICIDADE PORTAL, INCLUSIVE FORA DELES.

O USUÁRIO CONCORDA QUE SEUS DIREITOS E OBRIGAÇÕES PODEM SER ALTERADOS/COMPLEMENTADOS PELO OPERADOR, O QUE SERÁ NOTIFICADO ATRAVÉS DOS DADOS DE CONTATO FORNECIDOS DURANTE O REGISTRO (OU NO SITE).

4.4. DIREITOS DO OPERADOR

ESTE CONTRATO CONCEDE AO OPERADOR OS SEGUINTES DIREITOS:

A QUALQUER MOMENTO, A SEU EXCLUSIVO CRITÉRIO, UNILATERALMENTE, SEM AVISO PRÉVIO AOS USUÁRIOS, PARA EXPANDIR, MODIFICAR, DESCONTINUAR, LIMITAR A PRESTAÇÃO DE SERVIÇOS, BEM COMO SERVIÇOS PAGOS ADICIONAIS.

PARA GERENCIAR TODOS OS PROCESSOS NOS PORTAIS A SEU EXCLUSIVO CRITÉRIO. SUSPENDER, ALTERAR O CURSO DE QUALQUER PROCESSO SEM NOTIFICAR PREVIAMENTE O USUÁRIO.

APLICAR SANÇÕES AO USUÁRIO EM CASO DE DETECÇÃO DE VIOLAÇÕES DESTE CONTRATO.

EXCLUIR/ALTERAR AS INFORMAÇÕES DO USUÁRIO POSTADAS NOS PORTAIS.

RASTREAR, RETER INFORMAÇÕES DE IDENTIFICAÇÃO E ESTATÍSTICAS DO USUÁRIO.

ENVIAR AOS USUÁRIOS INFORMAÇÕES TÉCNICAS, PROMOCIONAIS E OUTRAS RELACIONADAS AO PORTAL, AOS SERVIÇOS E AOS SERVIÇOS PAGOS.

INFORMAR, ALERTAR, FAZER COMENTÁRIOS, NOTIFICAR O USUÁRIO EM CASO DE NÃO CUMPRIMENTO/VIOLAÇÃO DOS TERMOS DESTE CONTRATO. TODAS AS INSTRUÇÕES DO OPERADOR DEVEM SER RIGOROSAMENTE SEGUIDAS.

TOMAR MEDIDAS LEGAIS PARA PROTEGER SUA PROPRIEDADE INTELECTUAL E DE DIREITOS AUTORAIS.

MODIFICAR, ALTERAÇÃO, COMPLEMENTAR O PORTAL A SEU CRITÉRIO, SEM AVISAR O USUÁRIO COM ANTECEDÊNCIA.

A INAÇÃO DO OPERADOR EM VIOLAÇÃO DO USUÁRIO DESTE ACORDO NÃO EXCLUI A APLICAÇÃO DE PENALIDADES POSTERIORES.

4.5. DEVERES DO OPERADOR

COMO PARTE DO CONTRATO DE USUÁRIO, O OPERADOR É OBRIGADO:

PERMITIR QUE O USUÁRIO RECEBA OS SERVIÇOS DO OPERADOR DENTRO DESTE PORTAL (INCLUINDO O RECEBIMENTO DE SERVIÇOS PAGOS ADICIONAIS).

RESPONDER ÀS PERGUNTAS DOS USUÁRIOS, EM CASO DE SITUAÇÕES CONTROVERSAS, TOMAR TODAS AS MEDIDAS PARA RESOLVÊ-LAS.

4.6. LIMITAÇÃO DE RESPONSABILIDADE DO OPERADOR

DE ACORDO COM ESTA SEÇÃO, O OPERADOR NÃO É RESPONSÁVEL POR:

QUALQUER DANO CAUSADO OU QUE POSSA SER CAUSADO AOS DADOS PESSOAIS E AO COMPUTADOR DO USUÁRIO EM CONEXÃO COM O USO DO PORTAL E DO SITE.

DANOS (DIRETOS/INDIRETOS) CAUSADOS AO USUÁRIO EM CONEXÃO COM O USO OU INACESSIBILIDADE DO PORTAL (INCAPACIDADE DE USÁ-LOS), COMPORTAMENTO DE TERCEIROS NO PORTAL, BEM COMO OUTROS PARTICIPANTES DOS PROJETOS DO PORTAL, ACESSO NÃO AUTORIZADO AOS DADOS PESSOAIS DO USUÁRIO.

DECLARAÇÕES, INFORMAÇÕES DIVULGADAS, DECLARAÇÕES DO USUÁRIO E OUTRAS AÇÕES ILEGAIS REALIZADAS POR ELE NO PORTAL E FORA DELE.

AS INFORMAÇÕES FORNECIDAS PELO USUÁRIO DURANTE O REGISTRO, A POSSIBILIDADE PERDIDA DE ACESSO AO PORTAL (LOGIN, SENHA, ETC.).

PERDA DE VALOR VIRTUAL ADQUIRIDO PELO USUÁRIO COMO RESULTADO DA PRESTAÇÃO DE SERVIÇOS E SERVIÇOS PAGOS ADICIONAIS PELO OPERADOR.

PAGAMENTO PELO USUÁRIO DE SERVIÇOS PAGOS ADICIONAIS E CUSTOS ASSOCIADOS.

O BOM FUNCIONAMENTO DO PORTAL.

AS POSSIBILIDADES DO USUÁRIO RELACIONADAS COM A CONEXÃO À INTERNET, A VELOCIDADE DE TRANSFERÊNCIA DE DADOS.

4.7. O OPERADOR NÃO GARANTE:

OPERAÇÃO CONTÍNUA, CONFIÁVEL, LIVRE DE ERROS DO PORTAL, ACESSO AOS SEUS RECURSOS, SERVIÇOS, INCLUINDO SERVIÇOS PAGOS ADICIONAIS.

O FATO DE QUE O PORTAL IRÁ SATISFAZER PLENAMENTE OS REQUISITOS E REPRESENTAÇÕES DO USUÁRIO.

A CONFORMIDADE COM A QUALIDADE DOS SERVIÇOS PRESTADOS (SERVIÇOS PAGOS ADICIONAIS) ÀS EXPECTATIVAS DO USUÁRIO.

O OPERADOR NÃO É OBRIGADO, A PEDIDO DO USUÁRIO, A FORNECER DOCUMENTOS E OUTRAS PROVAS QUE COMPROVEM A VIOLAÇÃO (PELO USUÁRIO) DESTE CONTRATO E A APLICAÇÃO DE SANÇÕES PENAIS/DISCIPLINARES CONTRA ELE.

O USUÁRIO USA O PORTAL, O SITE DO OPERADOR É EXCLUSIVAMENTE POR SUA CONTA E RISCO, DE BOA VONTADE, SEM COERÇÃO. ELE ENTENDE OS POSSÍVEIS RISCOS ASSOCIADOS AO USO DOS RECURSOS DO OPERADOR E NÃO TEM RECLAMAÇÕES DE NATUREZA MATERIAL CONTRA O OPERADOR.

<b>5. PRIVACIDADE E SEGURANÇA</b>

5.1. INFORMAÇÕES CONFIDENCIAIS – INFORMAÇÕES OBTIDAS PELO OPERADOR DURANTE O PROCESSO DE REGISTRO DO USUÁRIO NO SITE, BEM COMO DURANTE A VISITA AOS SITES/PORTAL E PARTICIPAÇÃO EM EVENTOS DO PORTAL.

5.2. INFORMAÇÕES CONFIDENCIAIS NÃO DEVEM SER DIVULGADAS OU TRANSFERIDAS PARA TERCEIROS.

5.3. OS DADOS PESSOAIS SÓ PODEM SER COMUNICADOS PELO OPERADOR EM CASOS:

PEDIDO OFICIAL DE APLICAÇÃO DA LEI (VIOLAÇÃO DAS LEIS LOCAIS E INTERNACIONAIS).

VONTADE PESSOAL DO USUÁRIO.

IMPOSSIBILIDADE DE UTILIZAÇÃO DOS SERVIÇOS E SERVIÇOS ADICIONAIS PAGOS NOS PORTAIS (SOBRE OS QUAIS O USUÁRIO É AVISADO COM ANTECEDÊNCIA).

VIOLAÇÕES DAS CLÁUSULAS DESTE CONTRATO (O CRITÉRIO DO OPERADOR).

5.4. O OPERADOR GARANTE A SEGURANÇA DOS DADOS PESSOAIS DO USUÁRIO USANDO UM SOFTWARE ESPECIAL. EM CASO DE ACESSO NÃO AUTORIZADO AO PORTAL/SITE DE TERCEIROS, A SEGURANÇA DOS DADOS PESSOAIS NÃO É GARANTIDA.

<b>6. SERVIÇOS PAGOS ADICIONAIS E PROCEDIMENTO DE PAGAMENTO</b>

6.1. A PEDIDO DO USUÁRIO, O OPERADOR FORNECE-LHE UM SERVIÇO PAGO ADICIONAL. ELE PERMITE QUE VOCÊ USE OS RECURSOS AVANÇADOS DO PORTAL — OBTER MOEDA DO JOGO NO SALDO DA SUA CONTA.

6.2. SERVIÇOS ADICIONAIS PAGOS NÃO SÃO UMA CONDIÇÃO NECESSÁRIA PARA O USO DO PORTAL E PARA A PARTICIPAÇÃO EM PROJETOS DO PORTAL.

6.3. A PARTIR DO MOMENTO DA COBRANÇA DE FUNDOS PELO OPERADOR DO PORTAL DA CONTA DO USUÁRIO, O SERVIÇO PAGO ADICIONAL É CONSIDERADO PRESTADO NA ÍNTEGRA, DE QUALIDADE ADEQUADA.

6.4. APÓS A PRESTAÇÃO DE UM SERVIÇO PAGO ADICIONAL, O DINHEIRO GASTO NA SUA AQUISIÇÃO NÃO É REEMBOLSÁVEL.

6.5. O USUÁRIO CONCORDA QUE O OPERADOR TEM O DIREITO DE ARMAZENAR INFORMAÇÕES PESSOAIS OBTIDAS ATRAVÉS DA COMPRA DE SERVIÇOS PAGOS ADICIONAIS.

6.6. A LISTA DE SERVIÇOS PAGOS ADICIONAIS E SEUS CUSTOS SÃO PUBLICADOS APENAS PELO OPERADOR NO SITE/PORTAL. QUALQUER INFORMAÇÃO E OFERTA DE COMPRA DESSES SERVIÇOS EM RECURSOS DE TERCEIROS É CONSIDERADA FRAUDE, PELA QUAL O OPERADOR NÃO É RESPONSÁVEL.

6.7. OS MÉTODOS E A POSSIBILIDADE DE ADQUIRIR SERVIÇOS PAGOS ADICIONAIS SÃO EXPLICADOS PELO OPERADOR NO SITE.

6.8. O OPERADOR NÃO FORNECE EXPLICAÇÕES SOBRE O TRABALHO COM SISTEMAS DE PAGAMENTO, ATRAVÉS DO QUAL O USUÁRIO DECIDIU COMPRAR SERVIÇOS PAGOS ADICIONAIS, E TAMBÉM NÃO É RESPONSÁVEL PELO SEU FUNCIONAMENTO CORRETO.

6.9. EM CASO DE FALHA TÉCNICA DOS SITES OU AÇÕES INTENCIONAIS DO USUÁRIO, BEM COMO EM OUTROS CASOS EM QUE SERVIÇOS PAGOS ADICIONAIS FORAM PRESTADOS SEM UM DÉBITO TOTAL/PARCIAL DA CONTA DO USUÁRIO, ELE É OBRIGADO A INFORMAR ESSE FATO AO OPERADOR. DEPOIS DISSO, O USUÁRIO É OBRIGADO A PAGAR A DÍVIDA RESULTANTE.

6.10. O OPERADOR NÃO REEMBOLSA O USUÁRIO POR SERVIÇOS PAGOS ADICIONAIS NÃO UTILIZADOS (PARCIALMENTE UTILIZADOS).

6.11. O USUÁRIO, POR SUA PRÓPRIA CONTA, ASSUME TODOS OS CUSTOS FINANCEIROS ASSOCIADOS À AQUISIÇÃO (TRANSFERÊNCIA DE DINHEIRO) DE SERVIÇOS PAGOS ADICIONAIS. ESTA SEÇÃO INCLUI: COMISSÕES, TAXAS E OUTROS CUSTOS.

6.12. O USUÁRIO GARANTE AO OPERADOR QUE TEM TODOS OS PODERES E DIREITOS LEGAIS PARA CELEBRAR ESTE CONTRATO EM TERMOS DE SERVIÇOS PAGOS ADICIONAIS.

6.13. NO CASO DE COMPRA DE SERVIÇOS PAGOS ADICIONAIS POR UM USUÁRIO MENOR DE 18 ANOS, ELE É OBRIGADO A OBTER O CONSENTIMENTO PRÉVIO PARA A REALIZAÇÃO DE UMA TRANSAÇÃO FINANCEIRA COM REPRESENTANTES LEGAIS. O FATO DE COMPRAR SERVIÇOS PAGOS ADICIONAIS É UMA CONFIRMAÇÃO DA OBTENÇÃO DE TAL CONSENTIMENTO DO REPRESENTANTE LEGAL. SE NECESSÁRIO, O OPERADOR TEM O DIREITO DE SOLICITAR A CONFIRMAÇÃO POR ESCRITO DO CONSENTIMENTO E O FORNECIMENTO DE DADOS DE PASSAPORTE PARA DETERMINAR A VERDADEIRA IDADE DO USUÁRIO.

6.14. A RESPONSABILIDADE PELA AQUISIÇÃO DE SERVIÇOS PAGOS ADICIONAIS CABE INTEIRAMENTE AO USUÁRIO E SEUS REPRESENTANTES LEGAIS.

6.15. DISPUTAS SOBRE A RESPONSABILIDADE PELA COMPRA DE SERVIÇOS PAGOS ADICIONAIS COM O OPERADOR DO PORTAL SÃO INACEITÁVEIS.

6.16. O RECEBIMENTO DE SERVIÇOS PAGOS ADICIONAIS PELO USUÁRIO SÓ É POSSÍVEL APÓS O PAGAMENTO INTEGRAL DE SEU CUSTO.

6.17. PROCEDIMENTO DE PAGAMENTO

6.17.1. CARTÕES DE PAGAMENTO ACEITOS: VISA, MASTERCARD, MAESTRO, MIR. PARA PAGAR AS MERCADORIAS COM CARTÃO DE CRÉDITO AO FAZER UM PEDIDO NA LOJA ONLINE, O USUÁRIO ESCOLHE O MÉTODO DE PAGAMENTO: CARTÃO DE CRÉDITO. AO PAGAR UM PEDIDO COM UM CARTÃO BANCÁRIO, O PROCESSAMENTO DO PAGAMENTO OCORRE NA PÁGINA DE AUTORIZAÇÃO DO BANCO, ONDE O USUÁRIO DEVE INSERIR OS DADOS DO SEU CARTÃO BANCÁRIO:
1) TIPO DE CARTÃO
2) NÚMERO DO CARTÃO,
3) DATA DE VALIDADE DO CARTÃO
4) NOME DO TITULAR DO CARTÃO (EM LETRAS LATINAS, COMO EXATAMENTE INDICADO NO CARTÃO)
5) CVC2/CVV2 CÓDIGO

6.17.2. SE O CARTÃO DO USUÁRIO ESTIVER CONECTADO AO SERVIÇO 3D-SECURE, ELE SERÁ AUTOMATICAMENTE REDIRECIONADO PARA A PÁGINA DO BANCO EMISSOR DO CARTÃO PARA PASSAR PELO PROCEDIMENTO DE AUTENTICAÇÃO. INFORMAÇÕES SOBRE AS REGRAS E MÉTODOS DE IDENTIFICAÇÃO ADICIONAL PODEM SER ESCLARECIDAS NO BANCO QUE EMITIU O CARTÃO BANCÁRIO PARA O USUÁRIO.

<b>7. RECUSA DE PRESTAÇÃO DE GARANTIAS</b>

TODOS OS SERVIÇOS NO PORTAL SÃO FORNECIDOS AOS USUÁRIOS DE ACORDO COM O CONCEITO ESTABELECIDO "COMO ESTÁ". O PORTAL SE ISENTA DE FORNECER GARANTIAS RELATIVAS A SERVIÇOS OU VALORES VIRTUAIS.

O OPERADOR ADVERTE E LEMBRA QUE O USO EXCESSIVO DE UM PC, INCLUINDO JOGOS EM UM COMPUTADOR PESSOAL, PODE PREJUDICAR A SAÚDE DO USUÁRIO. O USUÁRIO OU SEUS REPRESENTANTES LEGAIS CONCORDAM EM MONITORAR INDEPENDENTEMENTE O ESTADO DE SAÚDE E NÃO USAR OS SERVIÇOS /LIMITAR SEU USO NA PRESENÇA DE QUAISQUER CONTRA-INDICAÇÕES.

O OPERADOR NÃO SERÁ RESPONSÁVEL POR QUAISQUER DANOS INDIRETOS, INCIDENTAIS OU OUTROS (INCLUINDO, SEM LIMITAÇÃO, PERDA DE LUCROS) RESULTANTES DOS SERVIÇOS DE JOGO, SITES DO PORTAL E/OU MATERIAIS NO SITE, AÇÕES DE TERCEIROS RELACIONADAS AOS SERVIÇOS E PORTAL DO OPERADOR E/OU MATERIAIS NO SITE, INCLUINDO AÇÕES FRAUDULENTAS E/OU NEGLIGENTES DE TERCEIROS.

<b>8. DISPOSIÇÕES ADICIONAIS</b>

8.1. SE O USUÁRIO NÃO TIVER O DIREITO DE USAR O PORTAL DE ACORDO COM A LEGISLAÇÃO DE SEU PAÍS OU SE HOUVER OUTRAS RESTRIÇÕES (TOLERÂNCIA POR IDADE E OUTRAS), ELE É OBRIGADO A SE RECUSAR A USAR O PORTAL, BEM COMO SEUS SERVIÇOS INDIVIDUAIS, SEM AVISO PRÉVIO. O USUÁRIO ASSUME TODA A RESPONSABILIDADE DE USAR O PORTAL EM SEU PRÓPRIO PAÍS, COM BASE NAS LEIS LOCAIS E LEVANDO EM CONSIDERAÇÃO A LEGISLAÇÃO INTERNACIONAL.

8.2. A INVALIDADE DE UM/VÁRIOS PONTOS/SEÇÕES DO CONTRATO DO USUÁRIO NÃO IMPLICA SUA INVALIDADE COMO UM TODO. NESTE CASO, AS PARTES DEVEM CUMPRIR AS OBRIGAÇÕES ASSUMIDAS NOS TERMOS DOS OUTROS PARÁGRAFOS/SEÇÕES DO ACORDO.

8.3. AS DISPUTAS QUE SURJAM ENTRE AS PARTES ESTÃO SUJEITAS A UMA RESOLUÇÃO INICIAL PRÉ-JULGAMENTO, POR CORRESPONDÊNCIA ENTRE O OPERADOR E O USUÁRIO. EM CASO DE INEFICÁCIA DA RESOLUÇÃO DE LITÍGIOS MEDIATIVOS, ELES SERÃO RESOLVIDOS DE ACORDO COM A LEGISLAÇÃO DA REPÚBLICA DA ESTÓNIA.

8.4. ESTE CONTRATO PODE SER ALTERADO, COMPLEMENTADO PELO OPERADOR SEM AVISO PRÉVIO AO USUÁRIO. QUAISQUER ALTERAÇÕES ENTRARÃO EM VIGOR IMEDIATAMENTE APÓS A PUBLICAÇÃO DA VERSÃO MODIFICADA DO CONTRATO NO SITE. PARA EVITAR A OCORRÊNCIA DE QUESTÕES CONTROVERSAS, O USUÁRIO COMPROMETE-SE A VERIFICAR O TEXTO DO CONTRATO NO SITE, ONDE ESTÁ DISPONÍVEL GRATUITAMENTE. EM CASO DE FALHA NA VERIFICAÇÃO DO TEXTO DO CONTRATO POR PARTE DO USUÁRIO, ESTE FATO NÃO PODE SERVIR DE BASE PARA A RECUSA DE CUMPRIR AS OBRIGAÇÕES ASSUMIDAS. A VERSÃO MODIFICADA DO ACORDO APÓS A PUBLICAÇÃO NO SITE TEM EFEITO LEGAL SEMELHANTE AO TEXTO ORIGINAL.

<b>9. INFORMAÇÕES DE CONTATO</b>

E-MAIL DE CONTATO: EPIK582@GMAIL.COM
`.split(new RegExp(/\s+\r?\n/))
