<template>
  <div class="payments">
    <div class="change-password" v-if="$store.state.change_password">
      <ChangePassword />
    </div>

    <div class="wrapper">
      <Navigation />
    </div>
    <div class="payments-content">
      <div class="payments-content__left" :class="{ selected: selectedMethod.name }">
        <Loader v-if="loading" />
        <div class="payments-content__left__header">
          <div class="payments-content__left__header__left">
            <div class="payments-content__left__header__image">
              <img src="@/assets/images/payments/payments-ico.svg" alt="ico" />
            </div>
            <h2>
              {{ $t("payment.refill") }}
            </h2>
          </div>

          <div class="payments-content__left__header__right">
            <div class="payments-content__left__header__right__currency">
              <div class="payments-content__left__header__right__currency__item"
                :class="{ active: filters.currency === item }" v-for="item in ['ALL', 'USD', 'RUB', 'EUR']" :key="item"
                @click="selectCurrency(item)">
                {{ item }}
              </div>
            </div>

            <div v-click-outside="() => isOpenLanguageDropdown = false" class="payments-content__left__header__right__languages"
              @click="isOpenLanguageDropdown = !isOpenLanguageDropdown">
              <img :src="require(`@/assets/images/payments/countries/${selectedCountry.toLowerCase()}.svg`)" alt=""
                srcset="" class="payments-content__left__header__right__languages__icon">
              {{ selectedCountry }}
              <img src="@/assets/images/navigation/arrow.svg" alt="arrow"
                class="payments-content__left__header__right__languages__arrow" :class="{ active: isOpenLanguageDropdown }">

              <div class="payments-content__left__header__right__languages__dropdown" v-if="isOpenLanguageDropdown">
                <div class="payments-content__left__header__right__languages__dropdown__item"
                  v-for="country of countries.filter(country => country !== selectedCountry)" :key="country"
                  @click="selectCountry(country)">
                  <img :src="require(`@/assets/images/payments/countries/${country.toLowerCase()}.svg`)"
                    class="payments-content__left__header__right__languages__dropdown__item__icon" alt="flag" srcset="">
                  {{ country }}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="payments-content__left__blocks">
          <div class="payments-content__left__blocks__block" v-for="(method, idx) in filteredMethods" :key="idx"
            :class="{ 'choosed': method.key === selectedMethod.name && method.currency === selectedMethod.currency }"
            @click="choosePaymentMethod(method)">

            <div class="payments-content__left__blocks__block__info">
              <p class="currency">{{ method.currency }}</p>
              <p class="commission">{{ method.commission }}%</p>
            </div>
            <img :src="getMethodIcon(method)" alt="">
          </div>

        </div>
      </div>

      <div class="payments-content__mobile" v-if="selectedMethod.name">
        <div class="payments-content__mobile__header">
          <div class="payments-content__mobile__header__back" @click="resetSelectedMethod">
            <img src="@/assets/images/payments/arrow-left.svg" alt="" srcset="">
          </div>
          {{ $t("payment.refill") }}
        </div>

        <div class="payments-content__mobile__content">
          <div class="payments-content__mobile__content__up">
            <img :src="getMethodIcon(selectedMethod)" :alt="selectedMethod.name" />

            <div class="payments-content__right__input" :class="{ error: error.status === false && error.show }">
              <p class="payments-content__right__input__error">
                {{ error.message }}
              </p>
              <div class="payments-content__right__input__image">
                <small>{{ currencyMap[$store.state.currency] }}</small>
              </div>
              <div class="payments-content__right__input__input">
                <input type="number" :placeholder="$t('payment.enterSum')" v-model="amount" @keyup.enter="createOrder" />
              </div>
            </div>
            <div v-if="paymentIsRubCard" class="payments-content__right__input" :class="{ error: emailError.status === false && emailError.show }">
              <p class="payments-content__right__input__error">
                {{ emailError.message }}
              </p>
              <div class="payments-content__right__input__image">
                <img src="@/assets/images/ForgetPassword/mail.svg" alt="">
              </div>
              <div class="payments-content__right__input__input">
                <input type="email" :placeholder="$t('auth.inputs.email')" v-model="email" @keyup.enter="createOrder" />
              </div>
            </div>

            <div class="payments-content__right__input">
              <p class="payments-content__right__input__error">
                {{ $t("general.error") }}
              </p>
              <div class="payments-content__right__input__image">
                <small>%</small>
              </div>
              <div class="payments-content__right__input__input">
                <input type="text" :placeholder="$t('payment.commission')" :value="commissionValue" readonly />
              </div>
            </div>
          </div>

          <div class="payments-content__mobile__content__bottom">
<!--            <i18n path="payment.problems" tag="p">-->
<!--              <template v-slot:support>-->
<!--                <a href="https://vk.me/robuxpier" target="_blank">{{-->
<!--                  $t("payment.support")-->
<!--                }}</a>-->
<!--              </template>-->
<!--            </i18n>-->
            <div class="payments-content__mobile__content__company"  v-if="!isRussian">
            </div>
            <div class="payments-content__mobile__content__bottom__line"></div>
            <button @click="createOrder">{{ $t("payment.pay") }}</button>
          </div>
        </div>
      </div>

      <div class="payments-content__right no-item" v-if="!selectedMethod.name">
        <div class="payments-content__right__warn">
          <img src="@/assets/images/payments/warning.svg" alt="ico" srcset="">
          {{ $t('payment.selectPaymentMethod') }}
        </div>
      </div>
      <div class="payments-content__right" v-else>
        <div class="payments-content__right__image">
          <img :src="getMethodIcon(selectedMethod)" :alt="selectedMethod.name" />
        </div>
        <div class="payments-content__right__input" :class="{ error: error.status === false && error.show }">
          <p class="payments-content__right__input__error">
            {{ error.message }}
          </p>
          <div class="payments-content__right__input__image">
            <small>{{ currencyMap[$store.state.currency] }}</small>
          </div>
          <div class="payments-content__right__input__input">
            <input type="number" :placeholder="$t('payment.enterSum')" v-model="amount" @keyup.enter="createOrder" />
          </div>
        </div>
        <div v-if="paymentIsRubCard" class="payments-content__right__input" :class="{ error: emailError.status === false && emailError.show }">
          <p class="payments-content__right__input__error">
            {{ emailError.message }}
          </p>
          <div class="payments-content__right__input__image">
              <img src="@/assets/images/ForgetPassword/mail.svg" alt="">
          </div>
          <div class="payments-content__right__input__input">
            <input type="email" :placeholder="$t('auth.inputs.email')" v-model="email" @keyup.enter="createOrder" />
          </div>
        </div>
        <div class="payments-content__right__input">
          <p class="payments-content__right__input__error">
            {{ $t("general.error") }}
          </p>
          <div class="payments-content__right__input__image">
            <small>%</small>
          </div>
          <div class="payments-content__right__input__input">
            <input type="text" :placeholder="$t('payment.commission')" :value="commissionValue" readonly />
          </div>
        </div>
        <div class="payments-content__right__button">
          <button @click="createOrder">{{ $t("payment.pay") }}</button>
          <div class="payments-content__right__button__company" v-if="!isRussian">
          </div>
<!--          <i18n path="payment.problems" tag="p">-->
<!--            <template v-slot:support>-->
<!--              <a href="https://vk.me/robuxpier" target="_blank">{{-->
<!--                $t("payment.support")-->
<!--              }}</a>-->
<!--            </template>-->
<!--          </i18n>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './payments.scss'

import { mapGetters } from 'vuex'
import Navigation from '@/components/Navigation/Navigation'
import ChangePassword from '@/components/ChangePassword/ChangePassword'
import Loader from '@/components/LoaderBar/LoaderBar'

import languages from '@/constants/languages'
import paymentsMethodsInfo from '@/constants/paymentsMethodsInfo'
import { validateEmail } from '@/utils/validate'
import { clickOutside } from '@/directives/clickOutside'

export default {
  name: 'Payments',
  components: {
    Navigation,
    ChangePassword,
    Loader
  },
  inject: ['currencyMap'],
  directives: {
    clickOutside
  },
  data () {
    return {
      selectedMethod: {
        name: '',
        currency: '',
        commission: 0
      },
      ipCountry: 'EU',
      languages,
      countryList: {
        RU: ['RU', 'UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'TH', 'ID', 'PH', 'MY', 'VN', 'HK'],
        EN: ['EU', 'USA', 'UA', 'MX', 'PE', 'UY', 'CL', 'BR', 'TH', 'ID', 'PH', 'MY', 'VN', 'HK']
      },

      paymentMethod: JSON.parse(
        JSON.stringify(paymentsMethodsInfo.methodsToGatewayMap)
      ),
      paymentSystemsMap: JSON.parse(
        JSON.stringify(paymentsMethodsInfo.methodsToSystemMap)
      ),
      methodMaps: JSON.parse(JSON.stringify(paymentsMethodsInfo.methodsConfig)),
      allMethods: JSON.parse(JSON.stringify(paymentsMethodsInfo.allMethods)),

      filters: {
        country: 'RU',
        currency: 'ALL'
      },

      currencyRate: {
        RUB: 1,
        USD: 73,
        EUR: 80,
        EURUSD: 1,
        USDEUR: 1
      },

      amount: 5,
      email: '',
      error: {
        status: false,
        show: false,
        message: ''
      },
      emailError: {
        status: false,
        show: false,
        message: ''
      },
      loading: false,
      isOpenLanguageDropdown: false
    }
  },
  created () {
    this.getCurrencyRate()
    this.getCountry()
  },
  mounted () {
    this.setUserRegion()
  },
  destroyed () {
    this.$store.commit('clearPaymentData')
  },
  computed: {
    ...mapGetters(['currency', 'language', 'country']),
    isRussian () {
      return this.language === 'ru'
    },
    countries () {
      return this.countryList[this.language.toUpperCase()] || this.countryList.EN
    },
    selectedCountry () {
      return this.filters.country
    },
    selectedLanguage () {
      for (const language of languages) {
        if (this.filters.country === language.name.toUpperCase()) return language
      }

      return languages[0]
    },
    filteredMethods: function () {
      const filterByCurrency = (m) => {
        return this.filters.currency === 'ALL'
          ? true
          : this.methodMaps[m].currency.includes(this.filters.currency)
      }
      const filterByCountry = (m) => {
        return this.methodMaps[m].country.includes(this.selectedCountry)
      }
      const sortByPriority = (a, b) => {
        return this.methodMaps[a.key]?.priority[this.selectedCountry] >
          this.methodMaps[b.key]?.priority[this.selectedCountry]
          ? 1
          : -1
      }

      const generateMultiCurrencyList = (methods) => {
        const currency = this.filters.country !== 'RU' ? 'USD' : this.currency
        return methods
          .map((m) => {
            if (!this.methodMaps[m].currency.includes(this.currency)) {
              return this.methodMaps[m].currency.map((c) => {
                return {
                  key: m,
                  currency: c,
                  commission: this.getMethodCommission(m),
                  iconType: this.methodMaps[m].iconType
                }
              })
            }
            return {
              key: m,
              currency: currency,
              commission: this.getMethodCommission(m),
              iconType: this.methodMaps[m].iconType,
              min: this.methodMaps[m]?.min?.default
            }
          })
          .flat()
      }
      let filteredMethods = this.allMethods.filter(
        (m) => filterByCountry(m) && filterByCurrency(m)
      )
      if (this.filters.currency === 'ALL') {
        filteredMethods = generateMultiCurrencyList(filteredMethods)
      } else {
        filteredMethods = filteredMethods.map((m) => ({
          key: m,
          currency: this.filters.currency,
          commission: this.getMethodCommission(m),
          iconType: this.methodMaps[m].iconType
        }))
      }
      return filteredMethods.sort((a, b) => sortByPriority(a, b)).filter(
        (
          item, index, self
        ) => { return index === self.findIndex(obj => obj.key === item.key) })
    },
    commissionValue: function () {
      const method = this.selectedMethod.name
      const fixFee = this.methodMaps[method]?.fix?.[this.currency] || 0
      if (method === 'new_fk' ||
        method === 'payok') {
        return 'Unknown'
        // return this.$t('payments.inputPlaceholder.gm')
      }

      if (method === 'qiwi') {
        return Math.ceil((Number(this.amount) + (this.amount * this.methodMaps[method].commission / 100)) * 100) / 100
      }

      let commission = 0
      const methodCommission = this.methodMaps[method].commission

      if (typeof methodCommission !== 'object') {
        commission = methodCommission
      } else {
        const key = Object.keys(methodCommission)
        commission = methodCommission.default

        if (key.includes(this.filters.country)) {
          commission = methodCommission[this.filters.country]
        }
      }

      const getPrice = (multiplier = 0) => {
        return Math.ceil(((Number(this.amount) + (this.amount * commission / 100)) + multiplier) * 100) / 100
      }

      const multipliers = {
        redpagos: {
          RUB: 0.5 * this.currencyRate.USD,
          EUR: 0.5 * this.currencyRate.USDEUR,
          USD: 0.5
        },
        servipag: {
          RUB: 1.5 * this.currencyRate.USD,
          EUR: 1.5 * this.currencyRate.USDEUR,
          USD: 1.5
        },
        boleto: {
          RUB: this.currencyRate.USD,
          EUR: this.currencyRate.USDEUR,
          USD: 1
        }
      }

      if (multipliers[method] && multipliers[method][this.currency]) {
        return getPrice(multipliers[method][this.currency])
      }

      if (this.filters.country === 'EU') {
        const cardGm = {
          RUB: 0.1 * this.currencyRate.EUR,
          EUR: 0.1,
          USD: 0.1 * this.currencyRate.EURUSD
        }
        if (method === 'card_gm') return getPrice(cardGm[this.currency])
      } else if (!['RU', 'UA', 'EU'].includes(this.filters.country)) {
        const cardGm = {
          RUB: 0.12 * this.currencyRate.EUR,
          EUR: 0.12,
          USD: 0.12 * this.currencyRate.EURUSD
        }
        if (method === 'card_gm') return getPrice(cardGm[this.currency])
      }

      if (this.paymentSystemsMap[method] === 'gm') {
        return (getPrice() + fixFee).toFixed(3)
      }

      if (this.paymentSystemsMap[method] === 'gram') {
        const c = Number(Number(this.amount) / 7).toFixed(2)
        if (c > (4.9 / this.currencyRate[this.currency])) {
          console.log(77777)
          return Math.ceil((Number(this.amount) + Number(c)) * 100) / 100
        } else {
          console.log(1117567565611)
          return Math.ceil((Number(this.amount) + (4.9 / this.currencyRate[this.currency])) * 100) / 100
        }
      }
      return getPrice()
    },
    paymentIsRub () {
      return this.selectedMethod.name === 'rub_card' || this.selectedMethod.name === 'lava' || this.selectedMethod.name === 'card_gm' || this.selectedMethod.name === 'sbp_gm'
    },
    paymentIsRubCard () {
      return this.selectedMethod.name === 'rub_card'
    }
  },
  watch: {
    '$state.account.country' (val) {
      this.setUserRegion()
    },
    'language' (value) {
      if (value === 'ru' && this.filters.country === 'EU') {
        this.filters.country = 'RU'
      }

      if (value === 'en' && this.filters.country === 'RU') {
        this.filters.country = 'EU'
      }

      if (value !== 'ru' && this.filters.currency === 'RUB') {
        this.filters.currency = 'USD'
      }

      const defaultMethod = this.filteredMethods[0]
      this.choosePaymentMethod(defaultMethod)
    },
    'currency' (value) {
      const defaultMethod = this.filteredMethods[0]
      this.choosePaymentMethod(defaultMethod.currency)
    },
    'filters.country' (val) {
      if (!['RU', 'UA'].includes(val) && this.filters.currency === 'RUB') {
        this.filters.currency = 'USD'
      }
    },
    amount (val) {
      if (this.filters.country === 'USA' &&
        this.selectedMethod.name.includes('card') &&
        this.currency.toUpperCase() === 'USD' &&
        +val > 50) {
        this.amount = 50
      }
    },
    'selectedMethod.name': function (val) {
      if (val) {
        const minVar = this.methodMaps[val].min
        let minVal = null
        if (typeof minVar === 'object') {
          minVal = minVar[this.currency.toUpperCase()]
        } else {
          minVal = minVar
        }
        this.amount = this.ceil(minVal, 2)
      }
    }
  },
  methods: {
    async getCountry () {
      fetch('https://users.backend-pier.com/current-country')
        .then(res => res.json())
        .then(data => {
          let country = data.country
          if (country === 'US') {
            country = 'USA'
          }

          if (this.countries.includes(country)) {
            this.filters.country = country
            return
          }
          this.filters.country = 'EU'
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCurrencyRate () {
      fetch('https://status.starpets.gg/api/currency')
        .then((res) => res.json())
        .then((data) => {
          this.currencyRate.EUR = data['eur-rub']
          this.currencyRate.USD = data['usd-rub']
          this.currencyRate.EURUSD = data['eur-usd']
          this.currencyRate.USDEUR = data['usd-eur']
        })
    },

    getMethodCommission (method) {
      if (typeof this.methodMaps[method].commission === 'object') {
        const key = Object.keys(this.methodMaps[method].commission)
        if (key.includes(this.filters.country)) {
          return this.methodMaps[method].commission[this.filters.country]
        }
        return this.methodMaps[method].commission.default
      }
      return this.methodMaps[method].commission
    },

    setUserRegion () {
      const EU_COUNRIES = ['AU', 'BE', 'BG', 'GB', 'HU', 'DE', 'GR', 'DK', 'IE', 'ES', 'IT', 'LV', 'LT', 'LU', 'MT', 'LI',
        'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'FI', 'FR', 'HR', 'CZ', 'SE', 'EE', 'BA', 'IE', 'IS', 'NO']
      const RU_COUNRIES = ['AZ', 'AM', 'BY', 'KZ', 'KG', 'MD', 'TJ', 'UZ', 'TM', 'GE']
      const SELECTED_COUNTRIES = ['RU', 'UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR']

      EU_COUNRIES.includes(this.country) ? this.filters.country = 'EU'
        : RU_COUNRIES.includes(this.country) ? this.filters.country = 'RU'
          : SELECTED_COUNTRIES.includes(this.country) ? this.filters.country = this.country
            : this.filters.country = 'USA'
    },

    ceil (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },

    selectCurrency (currency) {
      this.filters.currency = currency
    },

    selectCountry (country) {
      this.filters.country = country
    },

    async createOrder () {
      if (this.paymentIsRub) {
        this.validatePrice()
      }
      if (this.paymentIsRubCard) {
        this.validateEmail()
      }
      if (this.error.message || this.emailError.message) return
      this.loading = true
      const requestBody = this.createRequestBody()
      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })
      // if (this.choosedPayment === 'qiwi') {
      //   if (this.hasPassport === null) {
      //     this.openQiwiVerification()
      //     return
      //   }
      //   requestBody = this.passportProcess(requestBody)
      // }

      const requestParams = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: requestHeaders
      }

      fetch('https://payments.backend-pier.com/api/v1/topups/create', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.handleResponse(data)

          // OLD FROM MAIN BRANCH
          // if (data.redirect_uri) {
          //   location.href = data.redirect_uri
          //   return
          // }

          // const formUrl = data.m ? 'https://pay.freekassa.ru/' : 'https://pay.gmpays.com/api/terminal/create'
          // let formHTML = ''

          // Object.keys(data).forEach((key) => {
          //   formHTML += `<input type="hidden" name="${key}" value="${data[key]}"/>`
          // })

          // const formBlock = document.createElement('form')

          // formBlock.action = formUrl
          // formBlock.method = data.m ? 'GET' : 'POST'
          // formBlock.innerHTML = formHTML

          // document.body.appendChild(formBlock)
          // formBlock.submit()
        }).finally(() => {
          this.loading = false
        })
    },

    handleResponse (data) {
      const source = this.$store.getters.payments.afterOrder ? 'perchase' : 'direct'
      if (data.pay_ok) {
        this.$analytics.send('topup', {
          topup_id: data?.id,
          source
        })
        location.href = data.pay_ok.uri
        return
      }
      if (data.new_fk) {
        this.$analytics.send('topup', {
          topup_id: data?.id,
          source
        })
        location.href = data.new_fk.uri
        return
      }
      if (data.gram) {
        this.$analytics.send('topup', {
          topup_id: data?.id,
          source
        })
        location.href = data.gram.uri
        return
      }
      if (data.gm) {
        this.$analytics.send('topup', {
          topup_id: data?.id,
          source
        })
        location.href = data.gm.uri
        return
      }
      if (data.lava) {
        this.$analytics.send('topup', {
          topup_id: data?.id,
          source
        })
        location.href = data.lava.uri
        return
      }
      this.$analytics.send('topup_error', {
        source
      })
      // this.$store.dispatch('addNotification', {
      //   message: data.message || this.$t('money.hints.methodNotAllowed'),
      //   type: 'error'
      // })
    },

    createRequestBody () {
      const methodName = this.selectedMethod.name

      const body = {
        amount: parseFloat(this.amount),
        paymentSystem: this.paymentSystemsMap[methodName],
        currency: this.currency.toLowerCase(),
        email: this.email || ''
      }

      if (methodName === 'gram') {
        body.gramGateway = this.paymentMethod[methodName]
        return body
      }

      if (methodName === 'qiwi') {
        body.gameMoneyGateway = 'qiwi'
        body.paymentSystem = 'gm'
        body.gameMoneyRedirectOn = true
      }

      body.gameMoneyGateway = methodName === 'gm' ||
        methodName === 'payok' ||
        methodName === 'new_fk' ? null
        : this.paymentMethod[methodName]

      if (methodName !== 'gm' && this.paymentSystemsMap[methodName] === 'gm') {
        body.gameMoneyRedirectOn = true
      }

      if (methodName === 'card_gm_ua') {
        body.gameMoneyUserCurrency = 'uah'
      }

      if (methodName === 'card_gm_br') {
        body.gameMoneyUserCurrency = 'brl'
      }
      return body
    },

    passportProcess (body) {
      if (this.hasPassport) {
        body.paymentSystem = 'pay_ok'
        body.gameMoneyGateway = null
      } else {
        body.gameMoneyGateway = 'qiwi'
        body.paymentSystem = 'gm'
        body.gameMoneyRedirectOn = true
      }
      return body
    },
    choosePaymentMethod (method) {
      this.selectedMethod.name = method.key
      this.selectedMethod.currency = method.currency
      this.selectedMethod.commission = method.commission
      this.selectedMethod.iconType = method.iconType
      this.selectedMethod.min = method.min
      this.error = {
        status: false,
        show: false,
        message: ''
      }
      this.emailError = {
        status: false,
        show: false,
        message: ''
      }
      // this.closeMobileMethodsBlock()
    },
    resetSelectedMethod () {
      this.selectedMethod.name = ''
      this.selectedMethod.currency = ''
      this.selectedMethod.commission = 0
      this.selectedMethod.iconType = ''
    },
    switchMethod (direction) {
      const index = this.filteredMethods.findIndex(
        (m) => m.key === this.selectedMethod.name
      )

      const nextMethod = () => {
        if (index + 1 >= this.filteredMethods.length) {
          return this.filteredMethods[0]
        }

        return this.filteredMethods[index + 1]
      }

      const previousMethod = () => {
        if (index - 1 < 0) {
          return this.filteredMethods[this.filteredMethods.length - 1]
        }

        return this.filteredMethods[index - 1]
      }

      const method = direction === 1 ? nextMethod() : previousMethod()
      this.choosePaymentMethod(method)
    },
    validatePrice () {
      const isCustomMinValue = ['lava', 'rub_card', 'sbp_gm', 'card_gm'].includes(this.selectedMethod?.name)
      const min = isCustomMinValue ? this.selectedMethod?.min : 100
      if (isNaN(this.amount)) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'Введите корректную сумму'
      } else if (this.amount < min) {
        this.error.status = false
        this.error.show = true
        this.error.message = `Сумма пополнения должна быть не менее ${min}`
      } else {
        this.error.status = true
        this.error.show = false
        this.error.message = ''
      }
    },
    validateEmail () {
      let errorMessage = ''
      let emailState = true

      if (this.email.length === 0) {
        errorMessage = 'Поле E-mail пустое'
        emailState = false
      } else if (validateEmail(this.email) === false) {
        errorMessage = 'Введённая почта неверная!'
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.emailError.status = emailState
      this.emailError.show = !emailState
      this.emailError.message = errorMessage
    },
    getMethodIcon (method) {
      const icon = method.iconType || 'png'
      const name = method.name || method.key

      return require(`./../../assets/images/payments/methods/${name}.${icon}`)
    }
    // validatecommission (event) {
    //   if (this.selectedMethod.name !== 'qiwi') {
    //     return
    //   }

    //   if (Number(event.target.value) > 200) {
    //     this.commissionConfig.qiwi = this.qiwiBig
    //   } else {
    //     this.commissionConfig.qiwi = this.qiwiLow
    //   }

    //   this.percent = this.commissionConfig.qiwi.percent
    //   this.commission = this.commissionConfig.qiwi.commission
    //   this.displaycommission()
    // }
  }
}
</script>
