<template>
  <div class="approved">
    <div class="approved__main">
      <Loader class="transferLoader" v-if="isLoaded === false"/>
      <img
        v-if="status"
        src="./../../../assets/images/transfer/allow.svg"
        alt="allow"
      >
      <img
        v-if="status === false && isLowBalance === false"
        src="./../../../assets/images/transfer/deny.svg"
        alt="deny"
      >
      <img
        v-if="status === false && isLowBalance === true"
        src="./../../../assets/images/transfer/wait.svg"
        alt="wait"
      >
      <p v-if="status === false" class="error">{{ errorMessage }}</p>
      <button v-if="status === false && isLowBalance === false" class="error-btn" @click="goBack">
        {{ $t('actions.tryAgain') }}
      </button>
      <button v-if="status === false && isLowBalance === true" class="wait-btn" @click="buyOrder">
        {{ $t('payment.makeOrder') }}
      </button>
    </div>
  </div>
</template>

<script>
import './approved.scss'

import Loader from '@/components/LoaderBar/LoaderBar'

export default {
  name: 'Approved',
  components: {
    Loader
  },
  props: ['data'],
  data () {
    return {
      isLoaded: false,
      order: null,
      status: null,
      errorMessage: '',
      isLowBalance: false
    }
  },
  methods: {
    changeModal () {
      this.$emit('changeModal', {
        modal: 'TransferOrderSuccesful'
      })
    },
    goBack () {
      this.$emit('changeModal', {
        modal: 'TransferPayment'
      })
    },
    buyOrder () {
      this.isLoaded = false
      const currency = this.$store.state.currency.toUpperCase()

      const requestUrl = `https://transfer.backend-pier.com/make_order?plugin=true&asset_id=${this.data.assetId}&roblox_user_id=${this.data.robloxUserId}&robux_amount=${this.data.totalRobux}&username=${this.data.nickname}&currency=${currency}&flag=true`
      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(requestUrl, requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.isLoaded = true
          // if (data.status) {
          //   this.$store.dispatch('buyOrder', { order: data.order, methodType: 1 })
          // }

          // TODO BACKEND FIX
          this.$store.dispatch('buyOrder', { order: data.order, methodType: 1 })
        })
        .catch((err) => console.log(err))
    }
  },
  mounted () {
    this.isLoaded = false
    const currency = this.$store.state.currency.toUpperCase()

    const requestUrl = `https://transfer.backend-pier.com/make_order?plugin=true&asset_id=${this.data.assetId}&roblox_user_id=${this.data.robloxUserId}&robux_amount=${this.data.totalRobux}&username=${this.data.nickname}&currency=${currency}`
    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch(requestUrl, requestParams)
      .then((res) => res.json())
      .then((data) => {
        this.isLoaded = true
        this.status = data.status

        if (data.status) {
          this.order = data.order

          setTimeout(() => {
            this.$emit('changeModal', {
              modal: 'TransferOrderSuccesful',
              data: [this.order]
            })
          }, 800)
        } else {
          if (data.data.includes(4)) {
            this.errorMessage = this.$t('transfer.errors.insufficientBalance')
            this.isLowBalance = true
          } else if (data.data.includes(5)) {
            this.errorMessage = this.$t('transfer.errors.notAuthorized')
          } else if (data.data.includes(1)) {
            this.errorMessage = this.$t('transfer.errors.serverError')
          } else if (data.data.includes(6)) {
            this.errorMessage = this.$t('transfer.errors.robuxNotAvailable')
          }
        }
      })
      .catch((err) => console.log(err))
  }
}
</script>
