<template>
  <div class="button" :class="[`size_${size} ${type}`, { disabled, loading }]" v-on="$listeners">
    <template v-if="leftIcon && !loading">
      <Icon :name="leftIcon" />
    </template>

    <slot v-if="!loading"></slot>

    <template v-if="rightIcon && !loading">
      <Icon class="right-icon" :name="rightIcon" />
    </template>

    <Icon class="button__refresh-icon" v-if="loading" type="solid" name="refresh" />
  </div>
</template>
<script>
import Icon from '@/components/Base/Icon/Icon.vue'
import './button.scss'
export default {
  name: 'Button',
  props: {
    leftIcon: {
      value: String,
      default: ''
    },
    rightIcon: {
      value: String,
      default: ''
    },
    disabled: {
      value: Boolean,
      default: false
    },
    loading: {
      value: Boolean,
      default: false
    },
    size: {
      value: String,
      default: 'lg',
      validator: function (value) {
        return ['lg', 'md', 'sm'].includes(value)
      }
    },
    type: {
      value: String,
      default: 'primary',
      validator: function (value) {
        return ['secondary', 'primary'].includes(value)
      }
    }
  },
  components: { Icon }
}
</script>
