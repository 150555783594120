<template>
  <div class="confirmAccount">
    <PremiumLoader v-if="!isLoaded" />
    <div class="confirmAccount__main-panel" v-else>
      <div class="confirmAccount__main-panel__header">
        <p>{{ $t('general.confirm') }}</p>
      </div>
      <div class="confirmAccount__main-panel__input">
        <div class="confirmAccount__main-panel__input__img">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.22805 6.42559C8.81919 5.96306 9.25067 5.32878 9.46246 4.611C9.67425 3.89323 9.65582 3.12765 9.40973 2.42078C9.16364 1.71391 8.70213 1.1009 8.08941 0.667026C7.47669 0.233154 6.74322 0 5.99106 0C5.23889 0 4.50543 0.233154 3.8927 0.667026C3.27998 1.1009 2.81848 1.71391 2.57239 2.42078C2.3263 3.12765 2.30787 3.89323 2.51966 4.611C2.73145 5.32878 3.16293 5.96306 3.75407 6.42559C2.74113 6.82919 1.85732 7.49858 1.19684 8.36243C0.536353 9.22627 0.123966 10.2522 0.00363697 11.3308C-0.00507307 11.4095 0.00189835 11.4892 0.024153 11.5653C0.0464077 11.6413 0.0835099 11.7123 0.133341 11.7741C0.23398 11.8989 0.380358 11.9789 0.540274 11.9964C0.70019 12.0139 0.860543 11.9675 0.986059 11.8674C1.11158 11.7673 1.19197 11.6217 1.20956 11.4627C1.34196 10.2905 1.90399 9.20788 2.78825 8.42172C3.67252 7.63556 4.81703 7.20096 6.00312 7.20096C7.18921 7.20096 8.33372 7.63556 9.21798 8.42172C10.1022 9.20788 10.6643 10.2905 10.7967 11.4627C10.8131 11.61 10.8838 11.7461 10.9951 11.8447C11.1065 11.9432 11.2506 11.9973 11.3996 11.9964H11.466C11.624 11.9783 11.7685 11.8988 11.8679 11.7753C11.9673 11.6517 12.0135 11.4941 11.9966 11.3368C11.8757 10.2551 11.4611 9.22657 10.7972 8.3614C10.1333 7.49623 9.24519 6.82705 8.22805 6.42559ZM5.99106 5.99984C5.51404 5.99984 5.04773 5.85916 4.6511 5.5956C4.25448 5.33204 3.94534 4.95742 3.7628 4.51913C3.58025 4.08084 3.53249 3.59856 3.62555 3.13327C3.71861 2.66799 3.94832 2.2406 4.28562 1.90514C4.62292 1.56969 5.05268 1.34124 5.52053 1.24869C5.98838 1.15614 6.47332 1.20364 6.91403 1.38519C7.35474 1.56673 7.73142 1.87417 7.99644 2.26862C8.26146 2.66307 8.40291 3.12682 8.40291 3.60122C8.40291 4.23737 8.1488 4.84747 7.69649 5.2973C7.24418 5.74713 6.63072 5.99984 5.99106 5.99984Z" fill="white"/>
          </svg>
        </div>
        <div class="confirmAccount__main-panel__input__main">
          <input type="text" readonly :value="username">
        </div>
      </div>
      <div class="confirmAccount__main-panel__input">
        <div class="confirmAccount__main-panel__input__img">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99975 7.66671C5.79678 7.66434 5.59796 7.7242 5.43004 7.83824C5.26212 7.95229 5.13318 8.11503 5.06055 8.30458C4.98793 8.49412 4.97512 8.70137 5.02384 8.89841C5.07257 9.09546 5.18049 9.27285 5.33308 9.40671V10.3334C5.33308 10.5102 5.40332 10.6798 5.52834 10.8048C5.65337 10.9298 5.82293 11 5.99975 11C6.17656 11 6.34613 10.9298 6.47115 10.8048C6.59617 10.6798 6.66641 10.5102 6.66641 10.3334V9.40671C6.819 9.27285 6.92692 9.09546 6.97565 8.89841C7.02437 8.70137 7.01156 8.49412 6.93894 8.30458C6.86631 8.11503 6.73737 7.95229 6.56945 7.83824C6.40154 7.7242 6.20271 7.66434 5.99975 7.66671ZM9.33308 5.00004V3.66671C9.33308 2.78265 8.98189 1.93481 8.35677 1.30968C7.73165 0.684563 6.8838 0.333374 5.99975 0.333374C5.11569 0.333374 4.26784 0.684563 3.64272 1.30968C3.0176 1.93481 2.66641 2.78265 2.66641 3.66671V5.00004C2.13598 5.00004 1.62727 5.21075 1.2522 5.58583C0.877126 5.9609 0.666412 6.46961 0.666412 7.00004V11.6667C0.666412 12.1971 0.877126 12.7058 1.2522 13.0809C1.62727 13.456 2.13598 13.6667 2.66641 13.6667H9.33308C9.86351 13.6667 10.3722 13.456 10.7473 13.0809C11.1224 12.7058 11.3331 12.1971 11.3331 11.6667V7.00004C11.3331 6.46961 11.1224 5.9609 10.7473 5.58583C10.3722 5.21075 9.86351 5.00004 9.33308 5.00004ZM3.99975 3.66671C3.99975 3.13627 4.21046 2.62757 4.58553 2.25249C4.9606 1.87742 5.46931 1.66671 5.99975 1.66671C6.53018 1.66671 7.03889 1.87742 7.41396 2.25249C7.78903 2.62757 7.99975 3.13627 7.99975 3.66671V5.00004H3.99975V3.66671ZM9.99975 11.6667C9.99975 11.8435 9.92951 12.0131 9.80448 12.1381C9.67946 12.2631 9.50989 12.3334 9.33308 12.3334H2.66641C2.4896 12.3334 2.32003 12.2631 2.19501 12.1381C2.06998 12.0131 1.99975 11.8435 1.99975 11.6667V7.00004C1.99975 6.82323 2.06998 6.65366 2.19501 6.52864C2.32003 6.40361 2.4896 6.33337 2.66641 6.33337H9.33308C9.50989 6.33337 9.67946 6.40361 9.80448 6.52864C9.92951 6.65366 9.99975 6.82323 9.99975 7.00004V11.6667Z" fill="white"/>
          </svg>
        </div>
        <div
          class="confirmAccount__main-panel__input__main"
          :class="{error: passwordError}"
        >
          <span>{{ $t('auth.errors.incorrectPassword') }}</span>
          <input type="password" v-model="password">
          <img src="@/assets/images/logPass/close-eye.svg" alt="" @click="eye($event)">
        </div>
      </div>
      <div class="confirmAccount__main-panel__btn" @click="enterCode()">
        <button>{{ $t('actions.continue') }}</button>
      </div>
      <div class="choiceAccount__main-panel__back" @click="changeModal('back')">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.25 11.75H8.17251L15.5975 4.34749C16.0212 3.92381 16.2592 3.34917 16.2592 2.74999C16.2592 2.15081 16.0212 1.57617 15.5975 1.15249C15.1738 0.728807 14.5992 0.490784 14 0.490784C13.4008 0.490784 12.8262 0.728807 12.4025 1.15249L1.15251 12.4025C0.94767 12.6165 0.787098 12.8688 0.680011 13.145C0.45497 13.6928 0.45497 14.3072 0.680011 14.855C0.787098 15.1312 0.94767 15.3835 1.15251 15.5975L12.4025 26.8475C12.6117 27.0584 12.8605 27.2258 13.1347 27.34C13.4089 27.4542 13.703 27.513 14 27.513C14.297 27.513 14.5911 27.4542 14.8653 27.34C15.1395 27.2258 15.3883 27.0584 15.5975 26.8475C15.8084 26.6383 15.9758 26.3895 16.09 26.1153C16.2042 25.8411 16.2631 25.547 16.2631 25.25C16.2631 24.953 16.2042 24.6589 16.09 24.3847C15.9758 24.1105 15.8084 23.8617 15.5975 23.6525L8.17251 16.25H25.25C25.8467 16.25 26.419 16.0129 26.841 15.591C27.263 15.169 27.5 14.5967 27.5 14C27.5 13.4033 27.263 12.831 26.841 12.409C26.419 11.987 25.8467 11.75 25.25 11.75Z" fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import './confirmaccount.scss'

import PremiumLoader from '@/components/Premium/PremiumLoader/PremiumLoader.vue'

export default {
  name: 'ConfirmAccount',
  data () {
    return {
      username: null,
      userId: null,
      password: null,
      passwordError: false,
      isPremium: false,
      isLoaded: true
    }
  },
  components: {
    PremiumLoader
  },
  props: {
    methodType: {
      type: String,
      require: true,
      default: ''
    },
    getUserName: {
      type: Function,
      require: true
    },
    getUserId: {
      type: Function,
      require: true
    },
    setPassword: {
      type: Function,
      require: true
    },
    userOrder: {
      type: Object,
      require: false
    }
  },
  methods: {
    changeModal (to) {
      if (this.methodType === 'LogPass') {
        if (to === 'back') {
          this.$emit('changeModal', { modal: 'choice' })
          return
        }

        if (this.username && this.password) {
          this.$emit('changeModal', { modal: 'logPassBuy' })
          return
        }
      }
      if (this.methodType === 'Premium') {
        if (to === 'back') {
          this.$emit('changeModal', { modal: 'choice' })
          return
        }
        if (this.$store.getters.balance < this.$store.getters.price) {
          this.$emit('changeModal', {
            modal: 'ordering'
          })
        } else if (this.username && this.password) {
          this.isLoaded = false
          this.userOrder.username = this.username
          this.userOrder.password = this.password
          console.log(this.userOrder)
          fetch('https://logpass.backend-pier.com/api/v1/orders/create_order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.$cookies.get('access_token')}`
            },
            body: JSON.stringify(this.userOrder)
          })
            .then(res => res.json())
            .then(data => {
              // console.log(data)
              this.isLoadedBuy = true
              this.isLoaded = true
              this.$emit('toggleComeBack')
              this.$emit('changeModal', { modal: 'status' })
            })
        }
      }
    },
    enterCode () {
      if (!this.username) {
        return
      }

      if (this.validateRobloxPassword(this.password) === false) {
        this.passwordError = true
        return
      }

      fetch(`https://logpass.backend-pier.com/api/v1/roblox/check_premium?user_id=${this.userId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.is_premium) {
            this.$store.state.account.isPremium = true
            this.isPremium = true
          } else {
            this.$store.state.account.isPremium = false
            this.isPremium = false
          }
          this.changeModal()
          this.setPassword(this.password)
        })
        .catch((err) => console.log(err))
    },
    eye (event) {
      const block = event.target.closest('.confirmAccount__main-panel__input__main')
      if (block.querySelector('input').type === 'password') {
        block.querySelector('input').type = 'text'
        block.querySelector('img').src = require('@/assets/images/logPass/open-eye.svg')
      } else {
        block.querySelector('input').type = 'password'
        block.querySelector('img').src = require('@/assets/images/logPass/close-eye.svg')
      }
    },
    validateRobloxPassword (password) {
      const passRegExp = /^.{8,}$/i
      return passRegExp.test(String(password))
    }
  },
  mounted () {
    this.username = this.getUserName()
    this.userId = this.getUserId()
  }
}
</script>
