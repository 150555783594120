module.exports.allMethods = [
  // 'yoomoney',
  'bitcoin',
  'tether',
  // 'payok',
  'gm',
  'card_gm',
  'sbp_gm',
  'mexico_bank',
  'pagoefectivo',
  'redpagos',
  'servipag',
  'webpay',
  'tether_trc20',
  'pix',
  'indonesia_bank',
  'indonesia_bank_transfer',
  'malaysia_bank',
  'momopay',
  'philippines_bank',
  'promptpay',
  'thailand_bank',
  'thailand_bank_transfer',
  'vietnam_bank',
  'vietnam_bank_qr',
  'vietnam_bank_transfer',
  'dana',
  'google_cash',
  'touch_and_go',
  'alipay_hk',
  'lava',
  'rub_card'
]

module.exports.allWithdrawalsMethods = [
  'cards',
  'yoomoney',
  'payeer',
  'bitcoin',
  'beeline',
  'tele2',
  'tether',
  'megaphone',
  'ethereum',
  'mts',
  'tether_trc20',
  'webmoney',
  'gm_cards',
  'pix',
  'lava',
  'rub_card'
  // 'card_gm'
]

module.exports.methodsConfig = {
  direct_banking_europe: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 3,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      ID: 100,
      BR: 100,
      MY: 0
    },
    commission: 4,
    min: {
      RUB: 32,
      USD: 0.53,
      EUR: 0.53,
      default: 5
    },
    max: 150000,
    country: ['EU'],
    currency: ['EUR']
  },
  touch_and_go: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      ID: 100,
      BR: 100,
      MY: 0
    },
    commission: 5,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.55,
      default: 5
    },
    max: 150000,
    country: ['MY'],
    currency: ['EUR']
  },
  alipay_hk: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      ID: 100,
      BR: 100,
      HK: 0
    },
    commission: 5,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.55,
      default: 5
    },
    max: 150000,
    country: ['HK'],
    currency: ['EUR']
  },
  google_cash: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 0,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      ID: 100,
      BR: 100
    },
    commission: 5,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.55,
      default: 5
    },
    max: 150000,
    country: ['PH'],
    currency: ['EUR']
  },
  dana: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      ID: 0,
      BR: 100
    },
    commission: 5,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.55,
      default: 5
    },
    max: 150000,
    country: ['ID'],
    currency: ['EUR']
  },
  pix: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      ID: 100,
      BR: 0
    },
    commission: 6,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    fix: {
      USD: 0.12
    },
    max: 150000,
    country: ['BR'],
    currency: ['USD']
  },
  indonesia_bank: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 0
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['ID'],
    currency: ['USD']
  },
  indonesia_bank_transfer: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 1
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['ID'],
    currency: ['USD']
  },
  malaysia_bank: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 0
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['MY'],
    currency: ['USD']
  },
  momopay: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 0
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['VN'],
    currency: ['USD']
  },
  philippines_bank: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 100,
      PH: 0
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['PH'],
    currency: ['USD']
  },
  promptpay: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 100,
      PH: 100,
      TH: 0
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['TH'],
    currency: ['USD']
  },
  thailand_bank: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 100,
      PH: 100,
      TH: 1
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['TH'],
    currency: ['USD']
  },
  thailand_bank_transfer: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 100,
      PH: 100,
      TH: 2
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['TH'],
    currency: ['USD']
  },
  vietnam_bank: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 1,
      PH: 100,
      TH: 100
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['VN'],
    currency: ['USD']
  },
  vietnam_bank_qr: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 2,
      PH: 100,
      TH: 100
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['VN'],
    currency: ['USD']
  },
  vietnam_bank_transfer: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100,
      ID: 100,
      MY: 100,
      VN: 3,
      PH: 100,
      TH: 100
    },
    commission: 12,
    min: {
      RUB: 400,
      USD: 5,
      EUR: 5,
      default: 5
    },
    max: 150000,
    country: ['VN'],
    currency: ['USD']
  },
  card: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 3.9,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 150000,
    country: 'all',
    currency: ['RUB', 'USD', 'EUR']
  },
  sberbank: {
    priority: {
      RU: 5,
      UA: 5,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 6,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 150000,
    country: ['RU', 'UA'],
    currency: ['RUB']
  },
  alfabank: {
    priority: {
      RU: 6,
      UA: 6,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 6,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 150000,
    country: ['RU', 'UA'],
    currency: ['RUB']
  },
  yoomoney: {
    priority: {
      RU: 7,
      UA: 7,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 7,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 150000,
    country: ['RU', 'UA'],
    currency: ['RUB']
  },
  lava: {
    priority: {
      RU: 3,
      UA: 7,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 5.8,
    min: {
      RUB: 100,
      USD: 3,
      EUR: 3,
      default: 10
    },
    max: 150000,
    country: ['RU'],
    currency: ['RUB']
  },
  bitcoin: {
    priority: {
      RU: 13,
      UA: 13,
      EU: 6,
      USA: 6,
      PH: 6,
      PE: 6,
      MX: 6,
      UY: 6,
      CL: 6,
      BR: 6
    },
    commission: {
      RU: 3,
      UA: 3,
      EU: 7,
      default: 7
    },
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 150000,
    country: ['UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR']
  },
  tether: {
    priority: {
      RU: 14,
      UA: 14,
      EU: 7,
      USA: 7,
      PH: 7,
      PE: 7,
      MX: 7,
      UY: 7,
      CL: 7,
      BR: 7
    },
    commission: {
      RU: 3,
      UA: 3,
      EU: 7,
      default: 7
    },
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 150000,
    country: ['UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR']
  },
  payok: {
    priority: {
      RU: 1,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 0,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['RU', 'UA'],
    currency: ['RUB']
  },
  gm: {
    priority: {
      RU: 5,
      UA: 5,
      EU: 5,
      PH: 5,
      USA: 5,
      PE: 5,
      MX: 5,
      UY: 5,
      CL: 5,
      BR: 5
    },
    commission: 0,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['USD', 'EUR']
  },
  gram: { // Карты gram
    priority: {
      RU: 100,
      UA: 4,
      EU: 2,
      USA: 2,
      PH: 2,
      PE: 2,
      MX: 2,
      UY: 2,
      CL: 2,
      BR: 2
    },
    commission: {
      RU: 7,
      UA: 7,
      EU: 11,
      default: 11
    },
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR']
  },
  card_gm: {
    priority: {
      RU: 4,
      UA: 2,
      EU: 1,
      USA: 1,
      PH: 1,
      PE: 1,
      MX: 1,
      UY: 1,
      CL: 1,
      BR: 1
    },
    commission: {
      RU: 9.66,
      EU: 5.5,
      USA: 4.5,
      default: 9.66
    },
    min: {
      RUB: 100,
      USD: 0.74,
      EUR: 0.69,
      default: 1
    },
    fix: {
      USD: 0.2,
      EUR: 0.15
    },
    max: 200000,
    country: ['RU', 'UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR']
  },
  sbp_gm: {
    priority: {
      RU: 5,
      UA: 2,
      EU: 1,
      USA: 1,
      PH: 1,
      PE: 1,
      MX: 1,
      UY: 1,
      CL: 1,
      BR: 1
    },
    commission: {
      RU: 9.66,
      EU: 5.5,
      USA: 4.5,
      default: 9.66
    },
    min: {
      RUB: 100,
      USD: 0.74,
      EUR: 0.69,
      default: 1
    },
    fix: {
      USD: 0.2,
      EUR: 0.15
    },
    max: 200000,
    country: ['RU', 'UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR'],
    iconType: 'svg'
  },
  card_gm_ru: {
    priority: {
      RU: 2,
      UA: 2,
      EU: 1,
      USA: 1,
      PH: 1,
      PE: 1,
      MX: 1,
      UY: 1,
      CL: 1,
      BR: 1
    },
    commission: {
      RU: 4.5,
      EU: 5.5,
      USA: 4.5,
      default: 5
    },
    min: {
      RUB: 25,
      USD: 0.74,
      EUR: 0.69,
      default: 25
    },
    fix: {
      USD: 0.2,
      EUR: 0.15
    },
    max: 200000,
    country: ['RU', 'UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB']
  },
  card_gm_ua: {
    priority: {
      RU: 2,
      UA: 2,
      EU: 1,
      USA: 1,
      PH: 1,
      PE: 1,
      MX: 1,
      UY: 1,
      CL: 1,
      BR: 1
    },
    commission: {
      RU: 6,
      UA: 6,
      EU: 6,
      default: 6
    },
    min: {
      RUB: 25,
      USD: 0.35,
      EUR: 0.35,
      default: 25
    },
    max: 200000,
    country: ['UA'],
    currency: ['UAH']
  },
  local_card_brazil: {
    priority: {
      RU: 2,
      UA: 2,
      EU: 1,
      USA: 1,
      PH: 1,
      PE: 1,
      MX: 1,
      UY: 1,
      CL: 1,
      BR: 1
    },
    commission: {
      RU: 6,
      UA: 6,
      EU: 6,
      default: 6
    },
    min: {
      RUB: 50,
      USD: 0.6,
      EUR: 0.6,
      default: 6
    },
    fix: {
      USD: 0.6
    },
    max: 200000,
    country: ['BR'],
    currency: ['BRL']
  },
  card_payok: {
    priority: {
      RU: 3,
      UA: 3,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: {
      RU: 4,
      UA: 4,
      default: 4
    },
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['RU', 'UA'],
    currency: ['RUB']
  },
  applePay: {
    priority: {
      RU: 8,
      UA: 8,
      EU: 4,
      USA: 4,
      PH: 8,
      PE: 4,
      MX: 4,
      UY: 4,
      CL: 4,
      BR: 4
    },
    commission: {
      RU: 7,
      UA: 7,
      EU: 3,
      default: 5.4
    },
    min: {
      RUB: 5,
      USD: 0.74,
      EUR: 0.62,
      default: 5
    },
    fix: {
      USD: 0.2,
      EUR: 0.1
    },
    max: 200000,
    country: ['EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'ID', 'MY', 'HK', 'VN', 'TH'],
    currency: ['USD', 'EUR']
  },
  mexico_bank: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      MX: 0,
      BR: 100
    },
    commission: 10.5,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['MX'],
    currency: ['USD']
  },
  pagoefectivo: {
    priority: {
      PE: 0
    },
    commission: 8.5,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    fix: {
      USD: 1.1
    },
    max: 200000,
    country: ['PE'],
    currency: ['USD']
  },
  redpagos: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 0
    },
    commission: 10,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['UY'],
    currency: ['USD']
  },
  webpay: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      CL: 0
    },
    commission: 11,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['CL'],
    currency: ['USD']
  },
  boleto: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      CL: 100,
      BR: 0
    },
    commission: 6,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['BR'],
    currency: ['USD']
  },
  googlePay: {
    priority: {
      RU: 9,
      UA: 9,
      EU: 5,
      USA: 5,
      PH: 5,
      PE: 5,
      MX: 5,
      UY: 5,
      CL: 5,
      BR: 5
    },
    commission: {
      RU: 7,
      UA: 7,
      EU: 3,
      default: 5.4
    },
    min: {
      RUB: 5,
      USD: 0.74,
      EUR: 0.62,
      default: 5
    },
    fix: {
      USD: 0.2,
      EUR: 0.1
    },
    max: 200000,
    country: ['EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'ID', 'MY', 'HK', 'VN', 'TH'],
    currency: ['USD', 'EUR']
  },
  tron: {
    priority: {
      RU: 16,
      UA: 16,
      EU: 9,
      USA: 9,
      PH: 9,
      PE: 9,
      MX: 9,
      UY: 9,
      CL: 9,
      BR: 9
    },
    commission: {
      RU: 3,
      UA: 3,
      EU: 7,
      default: 7
    },
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['RU', 'UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR']
  },
  servipag: {
    priority: {
      RU: 100,
      UA: 100,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 0,
      BR: 100
    },
    commission: 9,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    fix: {
      USD: 1.5
    },
    max: 200000,
    country: ['CL'],
    currency: ['USD']
  },
  tether_trc20: {
    priority: {
      RU: 15,
      UA: 15,
      EU: 8,
      USA: 8,
      PH: 8,
      PE: 8,
      MX: 8,
      UY: 8,
      CL: 8,
      BR: 8
    },
    commission: {
      default: 3
    },
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR']
  },
  WMZ: {
    priority: {
      RU: 11,
      UA: 11,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 9,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['RU', 'UA'],
    currency: ['USD']
  },
  mobileOperators: {
    priority: {
      RU: 10,
      UA: 10,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 5,
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['RU', 'UA'],
    currency: ['RUB']
  },
  gramPaySystem: {
    priority: {
      RU: 18,
      UA: 18,
      EU: 11,
      USA: 11,
      PH: 11,
      PE: 11,
      MX: 11,
      UY: 11,
      CL: 11,
      BR: 11
    },
    commission: {
      RU: 7,
      UA: 7,
      EU: 7,
      default: 7
    },
    min: {
      RUB: 5,
      USD: 0.05,
      EUR: 0.05,
      default: 5
    },
    max: 200000,
    country: ['RU', 'UA', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'ID', 'MY', 'HK', 'VN', 'PH', 'TH'],
    currency: ['RUB', 'USD', 'EUR']
  },
  rub_card: {
    priority: {
      RU: 2,
      UA: 7,
      EU: 100,
      USA: 100,
      PH: 100,
      PE: 100,
      MX: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    commission: 9,
    min: {
      RUB: 100,
      USD: 3,
      EUR: 3,
      default: 10
    },
    max: 150000,
    country: ['RU', 'UA', 'KZ', 'AZ'],
    currency: ['RUB']
  }
}

module.exports.withdrawalsMethodsConfig = {
  pix: {
    priority: {
      RU: 100,
      UA: 100,
      KZ: 100,
      AZ: 100,
      EU: 100,
      USA: 100,
      MX: 100,
      PE: 100,
      UY: 100,
      CL: 100,
      BR: 0
    },
    country: ['BR'],
    currency: ['USD']
  },
  gm_cards: {
    priority: {
      RU: 1,
      UA: 1,
      KZ: 1,
      AZ: 1,
      EU: 1,
      USA: 1,
      MX: 1,
      PE: 1,
      UY: 1,
      CL: 1,
      BR: 1,
      PH: 1
    },
    country: ['RU', 'UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['EUR', 'USD']
  },
  webmoney: {
    priority: {
      RU: 8,
      UA: 8,
      KZ: 8,
      AZ: 8,
      EU: 100,
      USA: 100,
      MX: 100,
      PE: 100,
      UY: 100,
      CL: 100,
      BR: 100
    },
    country: ['RU', 'UA', 'KZ', 'AZ'],
    currency: ['USD']
  },
  cards: {
    priority: {
      RU: 1,
      UA: 1,
      KZ: 1,
      AZ: 1,
      EU: 1,
      USA: 1,
      MX: 1,
      PE: 1,
      UY: 1,
      CL: 1,
      BR: 1,
      PH: 1
    },
    country: ['RU', 'UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['RUB', 'EUR', 'USD']
  },
  yoomoney: {
    priority: {
      RU: 3,
      UA: 3,
      KZ: 3,
      AZ: 3
    },
    country: ['RU', 'UA', 'KZ', 'AZ'],
    currency: ['RUB']
  },
  lava: {
    priority: {
      RU: 3,
      UA: 3,
      KZ: 3,
      AZ: 3
    },
    country: ['RU', 'UA', 'KZ', 'AZ'],
    currency: ['RUB']
  },
  payeer: {
    priority: {
      RU: 8,
      UA: 8,
      KZ: 8,
      AZ: 8,
      EU: 3,
      USA: 3,
      MX: 3,
      PE: 3,
      UY: 3,
      CL: 3,
      BR: 3
    },
    country: ['RU', 'UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['RUB', 'EUR', 'USD']
  },
  bitcoin: {
    priority: {
      RU: 9,
      UA: 9,
      KZ: 9,
      AZ: 9,
      EU: 4,
      USA: 4,
      MX: 4,
      PE: 4,
      UY: 4,
      CL: 4,
      BR: 4
    },
    country: ['UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['RUB', 'EUR', 'USD']
  },
  tether: {
    priority: {
      RU: 10,
      UA: 10,
      KZ: 10,
      AZ: 10,
      EU: 5,
      USA: 5,
      MX: 5,
      PE: 5,
      UY: 5,
      CL: 5,
      BR: 5
    },
    country: ['UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['RUB', 'EUR', 'USD']
  },
  tron: {
    priority: {
      RU: 12,
      UA: 12,
      KZ: 12,
      AZ: 12,
      EU: 7,
      USA: 7,
      MX: 7,
      PE: 7,
      UY: 7,
      CL: 7,
      BR: 7
    },
    country: ['RU', 'UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['RUB', 'EUR', 'USD']
  },
  beeline: {
    priority: {
      RU: 4,
      UA: 4
    },
    country: ['RU'],
    currency: ['RUB']
  },
  tele2: {
    priority: {
      RU: 5,
      UA: 5
    },
    country: ['RU'],
    currency: ['RUB']
  },
  megaphone: {
    priority: {
      RU: 6,
      UA: 6
    },
    country: ['RU'],
    currency: ['RUB']
  },
  mts: {
    priority: {
      RU: 7,
      UA: 7
    },
    country: ['RU'],
    currency: ['RUB']
  },
  ethereum: {
    priority: {
      RU: 11,
      UA: 11,
      KZ: 11,
      AZ: 11,
      EU: 6,
      USA: 6,
      MX: 6,
      PE: 6,
      UY: 6,
      CL: 6,
      BR: 6
    },
    country: ['RU', 'UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['RUB', 'EUR', 'USD']
  },
  tether_trc20: {
    priority: {
      RU: 12,
      UA: 12,
      KZ: 12,
      AZ: 12,
      EU: 8,
      USA: 8,
      MX: 8,
      PE: 8,
      UY: 8,
      CL: 8,
      BR: 8
    },
    country: ['UA', 'KZ', 'AZ', 'EU', 'USA', 'MX', 'PE', 'UY', 'CL', 'BR', 'PH'],
    currency: ['RUB', 'EUR', 'USD']
  }
}

module.exports.methodsToSystemMap = {
  dana: 'gm',
  touch_and_go: 'gm',
  direct_banking_europe: 'gm',
  google_cash: 'gm',
  alipay_hk: 'gm',
  card: 'pay_ok',
  alfabank: 'gm',
  rub_card: 'new_fk',
  yoomoney: 'gm',
  lava: 'lava',
  megaphone: 'gm',
  beeline: 'gm',
  bitcoin: 'gm',
  tether: 'gm',
  uah_card: 'gm',
  payok: 'pay_ok',
  gm: 'gm',
  sberbank: 'gm',
  gram: 'gram',
  pagoefectivo: 'gm',
  mexico_bank: 'gm',
  redpagos: 'gm',
  servipag: 'gm',
  webpay: 'gm',
  boleto: 'gm',
  applePay: 'gm',
  googlePay: 'gm',
  tether_trc20: 'gm',
  tron: 'gm',
  WMZ: 'gm',
  mobileOperators: 'pay_ok',
  card_gm: 'gm',
  sbp_gm: 'gm',
  card_gm_ru: 'pay_ok',
  card_gm_ua: 'gm',
  local_card_brazil: 'gm',
  card_payok: 'pay_ok',
  gramPaySystem: 'gram',
  pix: 'gm',
  indonesia_bank: 'gm',
  indonesia_bank_transfer: 'gm',
  malaysia_bank: 'gm',
  momopay: 'gm',
  philippines_bank: 'gm',
  promptpay: 'gm',
  thailand_bank: 'gm',
  thailand_bank_transfer: 'gm',
  vietnam_bank: 'gm',
  vietnam_bank_qr: 'gm',
  vietnam_bank_transfer: 'gm'
}

module.exports.methodsToGatewayMap = {
  dana: 'alipay_plus',
  touch_and_go: 'alipay_plus',
  google_cash: 'alipay_plus',
  alipay_hk: 'alipay_plus',
  card: 'pay_ok',
  alfabank: 'alfabank',
  rub_card: null,
  yoomoney: 'yandex',
  lava: 'yandex',
  megaphone: 'gm',
  beeline: 'gm',
  bitcoin: 'bitcoin',
  tether: 'tether',
  uah_card: null,
  payok: 'pay_ok',
  gm: 'gm',
  sberbank: 'sberbank',
  gram: 'gram_cards',
  pagoefectivo: 'pagoefectivo',
  mexico_bank: 'mexico_bank',
  redpagos: 'redpagos',
  servipag: 'servipag',
  webpay: 'webpay',
  boleto: 'boleto',
  applePay: 'applepay',
  googlePay: 'googlepay',
  tether_trc20: 'tether_trc20',
  tron: 'tron',
  WMZ: 'wmz',
  mobileOperators: null,
  card_gm: 'card',
  sbp_gm: 'sbp',
  card_gm_ru: 'card',
  card_gm_ua: 'card',
  local_card_brazil: 'card',
  card_payok: null,
  gramPaySystem: null,
  pix: 'pix',
  direct_banking_europe: 'direct_banking_europe',
  indonesia_bank: 'indonesia_bank',
  indonesia_bank_transfer: 'indonesia_bank_transfer',
  malaysia_bank: 'malaysia_bank',
  momopay: 'momopay',
  philippines_bank: 'philippines_bank',
  promptpay: 'promptpay',
  thailand_bank: 'thailand_bank',
  thailand_bank_transfer: 'thailand_bank_transfer',
  vietnam_bank: 'vietnam_bank',
  vietnam_bank_qr: 'vietnam_bank_qr',
  vietnam_bank_transfer: 'vietnam_bank_transfer'
}
