<template>
  <div class="sign-in" :class="{ step: isStep }">
    <Captcha v-if="showCaptcha" @hideCaptcha="hideCaptcha" @onVerify="verifyToken" />

    <form-layout
      :title="$t('auth.authorization')"
      :flat-footer="bannerHide"
      :close="closeHandler"
      :back="isStep ? () => $emit('back') : null"
    >
      <div class="sign-in__main">
        <Loader v-if="isLoading" />
        <Input
          v-model="values.login"
          :error="errors.login.message"
          :state="errors.login.message ? 'error' : 'default'"
          :placeholder="$t('auth.inputs.loginOrEmail')"
          icon="/SignIn/login.svg"
          @input="validateLogin"
          @enter="validateCaptcha"
        />
        <Input
          v-model="values.password"
          :error="errors.password.message"
          :state="errors.password.message ? 'error' : 'default'"
          :placeholder="$t('auth.inputs.password')"
          icon="/SignIn/lock.svg"
          type="password"
          :password="true"
          @input="validatePassword"
          @enter="validateCaptcha"
        />

        <p
          class="sign-in__main__forget-pas"
          @click="
          $store.commit('stateSignIn'), $store.commit('stateForgetPassword')
        "
        >
          {{ $t("auth.forget") }}
        </p>
        <Button
          left-icon="/SignIn/logout.svg"
          @click="validateCaptcha"
        >
          {{ $t("actions.login") }}
        </Button>
        <template v-if="language === 'ru'">
          <p class="sign-in__main__or">{{ $t("general.or") }}</p>
          <a :href="vkUrl" class="sign-in__main__btn-vk">
            <img src="@/assets/images/SignIn/vk.svg" alt="" />
            <p>{{ $t("actions.loginVK") }}</p>
          </a>
        </template>
      </div>
    </form-layout>
    <div class="sign-in__welcome">
      <p class="sign-in__welcome__title">{{ $t("auth.welcome.title") }}</p>
      <p class="sign-in__welcome__inf">{{ $t("auth.welcome.content") }}</p>
      <button
        class="sign-in__welcome__btn"
        @click="createAccountHandler"
      >
        <img src="@/assets/images/SignIn/plus.svg" alt="" />
        <p>{{ $t("actions.createAccount") }}</p>
      </button>
    </div>
  </div>
</template>

<script>
import './signin.scss'

import { mapGetters } from 'vuex'
import Loader from '@/components/LoaderBar/LoaderBar'
import Captcha from '@/components/Captcha/Captcha'
// import * as intercom from '@/utils/intercom'
import Input from '@/components/Base/Input/Input.vue'
import Button from '@/components/Base/Button/Button.vue'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
export default {
  name: 'SignIn',
  components: {
    FormLayout,
    Button,
    Input,
    Loader,
    Captcha
  },
  props: {
    isStep: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      showCaptcha: false,
      vkUrl: '',
      login: '',
      password: '',
      values: {
        login: '',
        password: ''
      },
      token: '',
      errors: {
        login: {
          status: '',
          show: '',
          message: ''
        },
        password: {
          status: '',
          show: '',
          message: ''
        }
      }
    }
  },
  created () {
    fetch('https://users.backend-pier.com/api/v1/user/vkurl')
      .then((res) => res.json())
      .then((data) => {
        this.vkUrl = data.vk_url
      })
      .catch((err) => console.log(err))
  },
  computed: {
    ...mapGetters(['language']),
    bannerHide () {
      return window.innerWidth < 1600 || window.innerHeight < 800 || this.isStep
    }
  },
  methods: {
    hideCaptcha () {
      this.showCaptcha = false
    },
    verifyToken (token) {
      this.hideCaptcha()
      this.token = token
      this.signIn()
    },
    validateCaptcha () {
      this.showCaptcha = true
    },
    signIn () {
      if (!this.token) return
      this.isLoading = true

      this.validateLogin()
      this.validatePassword()

      let error = false

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key].status === false) {
          error = true
        }
      })

      if (error) {
        this.isLoading = false
        return
      }

      const requestBody = JSON.stringify({
        login: this.values.login,
        password: this.values.password,
        clcaptcha: this.token
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      const codes = {
        1: this.$t('auth.errors.checkEmail'),
        2: this.$t('auth.errors.checkPassword')
      }

      fetch('https://users.backend-pier.com/api/v1/user/login', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.isLoading = false

          if (data.error_codes) {
            let errorMessage
            Object.keys(data.error_codes).forEach((key) => {
              if (!data.error_codes[key].length) return

              let newKey = key
              if (key === 'email') {
                newKey = 'login'
              }

              if (!this.errors[newKey]) return

              this.errors[newKey].status = false
              this.errors[newKey].show = true
              this.errors[newKey].message = codes[data.error_codes[key][0]]
              errorMessage = codes[data.error_codes[key][0]]
            })

            this.$analytics.send('login_error', {
              type: 'login',
              method: 'email',
              message: errorMessage
            })
          } else {
            this.isStep ? this.$emit('success') : this.$store.commit('stateSignIn')
            this.$store.dispatch('signIn', data)
            // intercom.authCallback.call(this)

            this.$analytics.send('login', {
              type: 'login',
              method: 'email',
              user_id: data.data?.user_id
            })
          }
        })
        .catch((err) => console.log(err))
    },
    eye (el) {
      const icon = document.querySelector(`#${el}`)
      const input = icon.parentElement.querySelector('input')
      if (icon.src.includes('close-eye')) {
        icon.src = require('@/assets/images/SignIn/open-eye.svg')
        input.type = 'text'
      } else {
        icon.src = require('@/assets/images/SignIn/close-eye.svg')
        input.type = 'password'
      }
      return false
    },
    validateLogin () {
      let errorMessage = ''
      let emailState = true

      if (this.values.login.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.errors.login.status = emailState
      this.errors.login.show = !emailState
      this.errors.login.message = errorMessage
    },
    validatePassword () {
      const passwordState = this.values.password.length !== 0

      this.errors.password.status = passwordState
      this.errors.password.show = !passwordState
      this.errors.password.message = passwordState ? '' : this.$t('auth.errors.empty')
    },
    closeHandler () {
      if (this.isStep) {
        this.$emit('close')
        return
      }
      this.$store.commit('stateSignIn')
    },
    createAccountHandler () {
      if (this.isStep) {
        this.$emit('createAccount')
        return
      }
      this.$store.commit('stateSignIn')
      this.$store.commit('stateSignUp')
    }
  }
}
</script>
