<template>
    <div class="premiumLoader">
      <div class="premiumLoader__block">
        <img src="@/assets/images/Premium/anchor.png" alt="">
      </div>
      <div class="premiumLoader__errors" v-if="error">
        <div class="color1"><div class="color2"></div></div>
        <div class="main">
          <h3>{{ $t('general.error') }}!</h3>
          <p>{{ $t('premium.alreadyExist') }}</p>
        </div>
      </div>
    </div>
</template>

<script>
import './premiumloader.scss'

export default {
  name: 'PremiumLoader',
  data () {
    return {
      error: false
    }
  },
  methods: {
    changeModal () {
      if (this.error) {
        this.$router.push('/methods')
      } else {
        this.$emit('changeModal', {
          modal: 'buy'
        })
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.changeModal()
    }, 2000)
  }
}
</script>
