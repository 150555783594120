export default ` UNTERDRÜCKT VON 03.11.2021

DIESE DATENSCHUTZERKLÄRUNG FÜR PERSÖNLICHE DATEN (NACHFOLGEND - DATENSCHUTZERKLÄRUNG) GILT FÜR ALLE INFORMATIONEN, DIE DER ONLINE-SHOP "ROBUXPIER.IO", DER SICH AUF DER DOMAIN WWW.ROBUXPIER.IO BEFINDET, ÜBER DEN BENUTZER WÄHREND DER NUTZUNG DES ONLINE-SHOPS, DER PROGRAMME UND DER PRODUKTE DES ONLINE-SHOPS ERHALTEN KANN.

<b>1. BEGRIFFSBESTIMMUNGEN</b>

1.1. DIE FOLGENDEN BEGRIFFE WERDEN IN DIESER DATENSCHUTZERKLÄRUNG VERWENDET:

1.1.1. "VERWALTUNG DER WEBSITE DES ONLINE-SHOPS (IM FOLGENDEN - DIE WEBSITE-VERWALTUNG) " - AUTORISIERTE MITARBEITER, UM DIE WEBSITE ZU VERWALTEN, DIE IM NAMEN VON ROBUXPIER.IO, DIE ORGANISIERT UND (ODER) FÜHRT DIE VERARBEITUNG PERSONENBEZOGENER DATEN, SOWIE BESTIMMT DEN ZWECK DER VERARBEITUNG PERSONENBEZOGENER DATEN, DIE ZUSAMMENSETZUNG DER PERSONENBEZOGENEN DATEN VERARBEITET WERDEN, AKTIONEN (OPERATIONEN) MIT PERSÖNLICHEN DATEN DURCHGEFÜHRT.

1.1.2 " PERSÖNLICHE DATEN ": ALLE INFORMATIONEN, DIE SICH AUF EINE DIREKT ODER INDIREKT IDENTIFIZIERTE ODER IDENTIFIZIERBARE NATÜRLICHE PERSON (BETROFFENE PERSON) BEZIEHEN.

1.1.3. DIE "VERARBEITUNG PERSÖNLICHER DATEN" - JEDE HANDLUNG (VORGANG) ODER EINE REIHE VON HANDLUNGEN (VORGÄNGEN), DIE MIT ODER OHNE VERWENDUNG AUTOMATISIERTER MITTEL MIT PERSONENBEZOGENEN DATEN DURCHGEFÜHRT WERDEN, EINSCHLIESSLICH DER SAMMLUNG, AUFZEICHNUNG, SYSTEMATISIERUNG, AKKUMULATION, SPEICHERUNG, KLÄRUNG (AKTUALISIERUNG, ÄNDERUNG), EXTRAKTION, VERWENDUNG, ÜBERTRAGUNG (VERTEILUNG, BEREITSTELLUNG, ZUGANG), ANONYMISIERUNG, SPERRUNG, ENTFERNUNG, VERNICHTUNG PERSÖNLICHER DATEN.

1.1.4 "VERTRAULICHKEIT DER PERSÖNLICHEN DATEN" - DAS ERFORDERNIS, DASS DER BETREIBER ODER EINE ANDERE PERSON, DIE ZUGANG ZU PERSÖNLICHEN DATEN HAT, DEREN VERBREITUNG OHNE DIE ZUSTIMMUNG DER BETROFFENEN PERSON ODER DAS VORHANDENSEIN EINER ANDEREN RECHTSGRUNDLAGE VERHINDERN MUSS.

1.1.5 " BENUTZER DER WEBSITE (NACHSTEHEND "NUTZER") - EINE PERSON, DIE ÜBER DAS INTERNET AUF DIE WEBSITE DES ONLINE-SHOPS ZUGREIFT UND DIE WEBSITE DES ONLINE-SHOPS NUTZT.

1.1.6. "COOKIES" - KLEINE, VON EINEM WEBSERVER GESENDETE UND AUF DEM COMPUTER DES NUTZERS GESPEICHERTE DATENFRAGMENTE, DIE DER WEB-CLIENT ODER WEB-BROWSER JEDES MAL IN EINER HTTP-ANFRAGE AN DEN WEBSERVER SENDET, WENN ER VERSUCHT, DIE SEITE DER BETREFFENDEN WEBSITE ZU ÖFFNEN.

1.1.7. "IP-ADDRESS" IST DIE EINDEUTIGE NETZADRESSE EINES KNOTENS IN EINEM IP-PROTOKOLL-COMPUTERNETZ.

<b>2. ALLGEMEINE BESTIMMUNGEN</b>

2.1. DIE NUTZUNG DER WEBSITE DES ONLINE-SHOPS DURCH DEN BENUTZER BEDEUTET DIE ZUSTIMMUNG ZU DIESER DATENSCHUTZERKLÄRUNG UND DEN BEDINGUNGEN FÜR DIE VERARBEITUNG DER PERSÖNLICHEN DATEN DES BENUTZERS.

2.2. IM FALLE EINER NICHTÜBEREINSTIMMUNG MIT DEN BEDINGUNGEN DER DATENSCHUTZPOLITIK MUSS DER BENUTZER DIE NUTZUNG DER WEBSITE DES ONLINE-SHOPS EINSTELLEN.

2.3. DIESE DATENSCHUTZBESTIMMUNGEN GELTEN NUR FÜR DIE WEBSITE DES ONLINE-SHOPS ROBUXPIER.IO. DER ONLINESHOP HAT KEINE KONTROLLE ÜBER UND IST NICHT VERANTWORTLICH FÜR DIE WEBSITES DRITTER, ZU DENEN DER NUTZER ÜBER DIE AUF DER WEBSITE DES ONLINESHOPS VERFÜGBAREN LINKS GELANGEN KANN.

2.4. DIE VERWALTUNG DER WEBSITE ÜBERPRÜFT NICHT DIE RICHTIGKEIT DER VOM BENUTZER DER E-SHOP-WEBSITE ANGEGEBENEN PERSONENBEZOGENEN DATEN.

2.5. DIE ZAHLUNG KANN PER BANKKARTEN VISA, MASTER CARD, WORLD ODER ÜBER APPLE PAY, YANDEX.MONEY, QIWI ODER PAYPAL ERFOLGEN (LETZTERES NUR FÜR KÄUFER VON AUSSERHALB DER RUSSISCHEN FÖDERATION). UM ZU BEZAHLEN, WERDEN SIE AUF DEN SERVER DES UNITPAY-ZAHLUNGSSYSTEMS WEITERGELEITET, WO SIE DIE ERFORDERLICHEN DATEN EINGEBEN MÜSSEN. WENN SIE MIT KREDITKARTE BEZAHLEN, IST DIE SICHERHEIT DER ZAHLUNG DURCH DAS UNITPAY-VERARBEITUNGSZENTRUM GEWÄHRLEISTET.

UNITPAY PAYMENT SYSTEM VERFÜGT ÜBER EIN NACHGEWIESENES ZERTIFIKAT ZUR EINHALTUNG DES PCI DSS-STANDARDS FÜR DIE SPEICHERUNG, VERARBEITUNG UND ÜBERTRAGUNG VON KARTENINHABERDATEN. DER PCI DSS-STANDARD FÜR DIE SICHERHEIT VON BANKKARTEN WIRD VON INTERNATIONALEN ZAHLUNGSSYSTEMEN UNTERSTÜTZT, DARUNTER MASTERCARD UND VISA, INC. UNITPAY IST AUCH MITGLIED DES PCI DSS COMPLIANCE PROCESS (P.D.C.P.). IHRE VERTRAULICHEN DATEN, DIE SIE FÜR DIE ZAHLUNG BENÖTIGEN (KARTENDATEN, ANMELDEDATEN USW.), WERDEN NICHT AN DEN ONLINE-SHOP GESENDET, SONDERN IM RECHENZENTRUM VON UNITPAY VERARBEITET UND SIND VOLLSTÄNDIG GESCHÜTZT.

<b>3. GEGENSTAND DER DATENSCHUTZERKLÄRUNG</b>

3.1. DIE VORLIEGENDE DATENSCHUTZERKLÄRUNG LEGT DIE VERPFLICHTUNGEN DER VERWALTUNG DER WEBSITE DES ONLINE-SHOPS ZUR GEHEIMHALTUNG UND ZUM SCHUTZ DER VERTRAULICHKEIT DER PERSONENBEZOGENEN DATEN FEST, DIE DER NUTZER AUF ANFRAGE DER VERWALTUNG DER WEBSITE BEI DER REGISTRIERUNG AUF DER WEBSITE DES ONLINE-SHOPS ODER BEI DER AUFGABE EINER BESTELLUNG FÜR DEN KAUF VON WAREN ANGIBT.

3.2. PERSÖNLICHE DATEN, DIE IM RAHMEN DIESER DATENSCHUTZERKLÄRUNG VERARBEITET WERDEN DÜRFEN, WERDEN VOM BENUTZER DURCH AUSFÜLLEN DES REGISTRIERUNGSFORMULARS AUF DER WEBSITE DES ONLINE-SHOPS ROBUXPIER.IO IN DER RUBRIK NAME ZUR VERFÜGUNG GESTELLT UND ENTHALTEN DIE FOLGENDEN INFORMATIONEN:

3.2.1. NAME, VORNAME DES BENUTZERS

3.2.2. E-MAIL-ADRESSE (E-MAIL)

3.3. DER WEBSHOP SCHÜTZT DATEN, DIE AUTOMATISCH ÜBERMITTELT WERDEN, WENN SIE ANZEIGEN ANSEHEN UND SEITEN BESUCHEN:

· IP ADRESSE;

· Information aus COOKIES;

· INFORMATIONEN ÜBER DEN BROWSER (ODER EIN ANDERES PROGRAMM, DAS AUF DIE WERBUNG ZUGREIFT);

· ZUGANGSZEIT;

· DIE ADRESSE DER WEBSEITE, AUF DER SICH DER ANZEIGEBLOCK BEFINDET;

· REFERRER (ADRESSE DER VORHERIGEN SEITE).

3.3.1. DIE DEAKTIVIERUNG VON COOKIES KANN DAZU FÜHREN, DASS DER ZUGRIFF AUF DIE TEILE DER WEBSHOP-WEBSITE, DIE EINE AUTORISIERUNG ERFORDERN, NICHT MÖGLICH IST.

3.3.2. DER ONLINE-SHOP SAMMELT STATISTIKEN ÜBER DIE IP-ADRESSEN SEINER BESUCHER. DIESE INFORMATIONEN WERDEN VERWENDET, UM TECHNISCHE PROBLEME ZU ERKENNEN UND ZU BEHEBEN, UM DIE RECHTMÄSSIGKEIT DER GELEISTETEN ZAHLUNGEN ZU ÜBERWACHEN.

3.4. ALLE ANDEREN PERSÖNLICHEN DATEN, DIE OBEN NICHT AUFGEFÜHRT SIND (KAUFHISTORIE, VERWENDETE BROWSER UND BETRIEBSSYSTEME USW.), WERDEN SICHER AUFBEWAHRT UND NICHT WEITERGEGEBEN, ES SEI DENN, DIES IST IN ABSCHNITT 5.2 VORGESEHEN. И 5.3. DIESER DATENSCHUTZERKLÄRUNG.

<b>4. DIE ZWECKE, FÜR DIE PERSÖNLICHE DATEN DES BENUTZERS GESAMMELT WERDEN</b>

4.1. DIE PERSÖNLICHEN DATEN DES BENUTZERS KÖNNEN VON DER VERWALTUNG DES ONLINE-SHOPS FÜR DIE ZWECKE VERWENDET WERDEN:

4.1.1. ZUR IDENTIFIZIERUNG DES AUF DER WEBSITE DES ONLINE-SHOPS REGISTRIERTEN BENUTZERS, UM EINE BESTELLUNG AUS DER FERNE BEI ROBUXPIER.IO AUFZUGEBEN.

4.1.2. DEM BENUTZER DEN ZUGANG ZU DEN PERSONALISIERTEN RESSOURCEN DER WEBSITE DES ONLINE-SHOPS ZU ERMÖGLICHEN.

4.1.3. DIE EINRICHTUNG EINES FEEDBACKS MIT DEM BENUTZER, EINSCHLIESSLICH DER ZUSENDUNG VON MITTEILUNGEN, ANFRAGEN BEZÜGLICH DER NUTZUNG DER WEBSEITE DES ONLINE-SHOPS, DER ERBRINGUNG VON DIENSTLEISTUNGEN, DER BEARBEITUNG VON ANFRAGEN UND ANTRÄGEN DES BENUTZERS.

4.1.4. DIE BESTÄTIGUNG DER RICHTIGKEIT UND VOLLSTÄNDIGKEIT DER VOM BENUTZER ANGEGEBENEN PERSÖNLICHEN DATEN.

4.1.5. DIE ERSTELLUNG EINES ACCOUNTS FÜR DEN EINKAUF, WENN DER NUTZER DER ERSTELLUNG EINES ACCOUNTS ZUGESTIMMT HAT.

4.1.6. DIE BENACHRICHTIGUNG DES NUTZERS DER WEBSITE DES ONLINE-SHOPS ÜBER DEN STAND DER BESTELLUNG.

4.1.8. BEARBEITUNG UND ENTGEGENNAHME VON ZAHLUNGEN, ANFECHTUNG DER ZAHLUNG.

4.1.9. DIE BEREITSTELLUNG EINES EFFEKTIVEN KUNDEN- UND TECHNISCHEN SUPPORTS FÜR DEN NUTZER BEI PROBLEMEN IM ZUSAMMENHANG MIT DER NUTZUNG DER WEBSITE DES ONLINE-SHOPS.

4.1.10. DEM NUTZER ZUGANG ZU DEN WEBSITES ODER DIENSTEN DER PARTNER DES ONLINE-SHOPS ZU VERSCHAFFEN, UM PRODUKTE, UPDATES UND DIENSTLEISTUNGEN ZU ERHALTEN.

<b>5. DIE ART UND WEISE DER VERARBEITUNG VON PERSÖNLICHEN DATEN</b>

5.1. DIE VERARBEITUNG DER PERSÖNLICHEN DATEN DES NUTZERS ERFOLGT OHNE ZEITLICHE BEGRENZUNG AUF JEDE RECHTMÄSSIGE ART UND WEISE, AUCH IN INFORMATIONSSYSTEMEN FÜR PERSÖNLICHE DATEN MIT ODER OHNE EINSATZ AUTOMATISIERTER INSTRUMENTE.

5.2. DER BENUTZER ERKLÄRT SICH DAMIT EINVERSTANDEN, DASS DIE VERWALTUNG DER WEBSITE DAS RECHT HAT, PERSONENBEZOGENE DATEN AN DRITTE ZU ÜBERMITTELN, INSBESONDERE AN DIE ZAHLUNGSSYSTEME, ÜBER DIE DER BENUTZER DIE BESTELLUNG BEZAHLT HAT.

5.3. PERSÖNLICHE DATEN DES BENUTZERS KÖNNEN IN DER VON DEN GESETZEN DER REPUBLIK ESTLAND VORGESCHRIEBENEN WEISE AN BEFUGTE ÖFFENTLICHE BEHÖRDEN WEITERGEGEBEN WERDEN.

5.4. BEI VERLUST ODER WEITERGABE VON PERSÖNLICHEN DATEN INFORMIERT DIE VERWALTUNG DER WEBSITE DEN NUTZER ÜBER DEN VERLUST ODER DIE WEITERGABE VON PERSÖNLICHEN DATEN.

5.5. DIE VERWALTUNG DER WEBSITE DIE NOTWENDIGEN ORGANISATORISCHEN UND TECHNISCHEN MASSNAHMEN ERGREIFT, UM DIE PERSÖNLICHEN DATEN DES NUTZERS VOR UNBEFUGTEM ODER VERSEHENTLICHEM ZUGRIFF, ZERSTÖRUNG, ÄNDERUNG, SPERRUNG, KOPIE, VERBREITUNG SOWIE VOR ANDEREN UNRECHTMÄSSIGEN HANDLUNGEN DRITTER ZU SCHÜTZEN.

5.6. DIE VERWALTUNG DER WEBSITE ERGREIFT GEMEINSAM MIT DEM BENUTZER ALLE NOTWENDIGEN MASSNAHMEN, UM VERLUSTE ODER ANDERE NEGATIVE FOLGEN ZU VERHINDERN, DIE DURCH DEN VERLUST ODER DIE OFFENLEGUNG DER PERSÖNLICHEN DATEN DES BENUTZERS VERURSACHT WERDEN.

<b>6. VERPFLICHTUNGEN DER PARTEIEN</b>

6.1. BENUTZER IST VERPFLICHTET:

6.1.1. DIE FÜR DIE NUTZUNG DER WEBSITE DES ONLINE-SHOPS ERFORDERLICHEN ANGABEN ZU PERSONENBEZOGENEN DATEN ZU MACHEN.

6.1.2. DIE BEREITGESTELLTEN INFORMATIONEN ÜBER PERSONENBEZOGENE DATEN ZU AKTUALISIEREN UND ZU ERGÄNZEN, WENN SICH DIESE INFORMATIONEN ÄNDERN.

6.2. DIE VERWALTUNG DER WEBSITE IST VERPFLICHTET

6.2.1. DIE ERHALTENEN INFORMATIONEN AUSSCHLIESSLICH FÜR DIE IN P. 4. DIESER DATENSCHUTZERKLÄRUNG GENANNTEN ZWECKE ZU VERWENDEN.

6.2.2. VERTRAULICHE INFORMATIONEN GEHEIM ZU HALTEN, SIE NICHT OHNE VORHERIGE SCHRIFTLICHE ZUSTIMMUNG DES NUTZERS WEITERZUGEBEN SOWIE DIE ÜBERMITTELTEN PERSÖNLICHEN DATEN DES BENUTZERS NICHT ZU VERKAUFEN, AUSZUTAUSCHEN, ZU VERÖFFENTLICHEN ODER AUF IRGENDEINE ANDERE ART UND WEISE WEITERZUGEBEN, MIT AUSNAHME DER PUNKTE 5.2. И 5.3. DIESER DATENSCHUTZERKLÄRUNG.

6.2.3. VORSICHTSMASSNAHMEN ZUM SCHUTZ DER VERTRAULICHKEIT DER PERSÖNLICHEN DATEN DES BENUTZERS ERGREIFEN, IN ÜBEREINSTIMMUNG MIT DEN VERFAHREN, DIE IM ALLGEMEINEN FÜR DEN SCHUTZ SOLCHER INFORMATIONEN IM BESTEHENDEN GESCHÄFTSVERKEHR VERWENDET WERDEN.

6.2.4. DIE PERSÖNLICHEN DATEN DES BETREFFENDEN BENUTZERS AB DEM ZEITPUNKT DES ANTRAGS ODER DER ANFRAGE DES BENUTZERS ODER SEINES GESETZLICHEN VERTRETERS ODER DER ZUSTÄNDIGEN BEHÖRDE FÜR DEN SCHUTZ DER RECHTE DER BETROFFENEN PERSONEN FÜR DEN ZEITRAUM DER ÜBERPRÜFUNG ZU SPERREN, WENN UNRICHTIGE PERSÖNLICHE DATEN ODER RECHTSWIDRIGE HANDLUNGEN FESTGESTELLT WERDEN.

<b>7. PARTEIENHAFTUNG</b>

7.1. DIE VERWALTUNG DER WEBSITE, DIE IHREN VERPFLICHTUNGEN NICHT NACHGEKOMMEN IST, HAFTET FÜR SCHÄDEN, DIE DEM NUTZER IM ZUSAMMENHANG MIT DER MISSBRÄUCHLICHEN VERWENDUNG PERSONENBEZOGENER DATEN ENTSTEHEN, IN ÜBEREINSTIMMUNG MIT DER GESETZGEBUNG DER REPUBLIK ESTLAND.

7.2. DIE VERWALTUNG DER WEBSITE HAFTET NICHT FÜR DEN VERLUST ODER DIE WEITERGABE VERTRAULICHER INFORMATIONEN, WENN DIE VERTRAULICHEN INFORMATIONEN

7.2.1. VOR DEM VERLUST ODER DER OFFENLEGUNG ÖFFENTLICH ZUGÄNGLICH WAR.

7.2.2. VOR IHREM EINGANG BEI DER VERWALTUNG VON EINEM DRITTEN ERHALTEN WURDE.
7.2.3. MIT ZUSTIMMUNG DES BENUTZERS WEITERGEGEBEN WORDEN SIND.

<b>8. BEILEGUNG VON STREITIGKEITEN</b>

8.1. VOR DER ANRUFUNG DES GERICHTS BEI STREITIGKEITEN, DIE SICH AUS DER BEZIEHUNG ZWISCHEN DEM NUTZER DES E-SHOPS UND DER VERWALTUNG DER WEBSITE ERGEBEN, IST ES ZWINGEND ERFORDERLICH, EINE KLAGE EINZUREICHEN (SCHRIFTLICHES ANGEBOT ZUR FREIWILLIGEN BEILEGUNG DER STREITIGKEIT).

8.2 DER ANSPRUCHSEMPFÄNGER UNTERRICHTET DEN ANTRAGSTELLER INNERHALB VON 30 KALENDERTAGEN NACH EINGANG DES ANTRAGS SCHRIFTLICH ÜBER DAS ERGEBNIS DES ANTRAGS.

8.3. KOMMT KEINE EINIGUNG ZUSTANDE, SO KANN DIE STREITIGKEIT NACH DEM GELTENDEN RECHT DER REPUBLIK ESTLAND VOR EIN GERICHT GEBRACHT WERDEN.

<b>9. ZUSATZBEDINGUNGEN</b>

9.1. DIE VERWALTUNG DER WEBSITE HAT DAS RECHT, DIESE DATENSCHUTZERKLÄRUNG OHNE DIE ZUSTIMMUNG DES BENUTZERS ZU ÄNDERN.

9.2. DIE NEUE DATENSCHUTZERKLÄRUNG TRITT IN KRAFT, SOBALD SIE AUF DER WEBSITE DES ONLINE-SHOPS VERÖFFENTLICHT WIRD, SOFERN IN DER NEUEN DATENSCHUTZERKLÄRUNG NICHTS ANDERES VORGESEHEN IST.

9.3. DIE AKTUELLE DATENSCHUTZERKLÄRUNG IST AUF DER SEITE ABRUFBAR UNTER HTTPS://ROBUXPIER.IO/PRIVACY`.split(new RegExp(/\s+\r?\n/))
